import axios from "axios";

import apiClient from "../interceptor/ApiCilent";
import { SettingPathModel } from "../../Model/settingPath/SettingPathModel";

 const backEndUr=process.env.REACT_APP_BACKEND_URL
const serverApi =process.env.REACT_APP_SERVER_URL


// getAll setting path
export function GetSettingPathData(){
  return apiClient.get(`${serverApi}/get-setting-path`) 
  .then(response => {
         
    return response
}).catch(error => {
    throw error;
});
}

// add setting path
export function AddSettingPathData(data:SettingPathModel){
  return apiClient.post(`${serverApi}/add-setting-path`,{data:data}) 
  .then(response => {
         
    return response
}).catch(error => {
    throw error;
});
}

// update setting path
export function UpdateSettingPathData(data:SettingPathModel){
  const updateData={
    updateSet:data
  }
  return apiClient.post(`${serverApi}/update-setting-path`,updateData) 
  .then(response => {
         
    return response
}).catch(error => {
    throw error;
});
}


// delete setting path
export function DeleteSettingPathData(id:number){
  return apiClient.post(`${serverApi}/delete-setting-path`,{id:id}) 
  .then(response => {
         
    return response
}).catch(error => {
    throw error;
});
}