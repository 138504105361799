import React, { useEffect, useRef } from 'react'
import { GetMasterDataThroughApi } from '../../../api/modelApi/ModelApi'
import { SegmentDetails } from '../../../Model/Job/SamModel'


type Props={
  segmentationInt:number[]
  segName:string
  resetAnnotation:(data:SegmentDetails)=>void
  resetFailAnnotation:()=>void
}
const AnnotationUpdateApi = ({segmentationInt,segName,resetAnnotation,resetFailAnnotation}:Props) => {
    const isApi= useRef(true)

  useEffect(()=>{
   
    if(segmentationInt &&
      segName && 
      isApi.current){
      isApi.current= false
      GetMasterDataManualAnnoatation(segmentationInt,segName)
    }
  },[segmentationInt,segName])

  const GetMasterDataManualAnnoatation=async(segmentationInt:number[], segName:string)=>{
    try{

       const response= await GetMasterDataThroughApi(segmentationInt, segName)
       if(response && response.status==200){
        //console.log("update manual response", response)
        isApi.current= true
        
        resetAnnotation(response.data)
       }
    } catch(err){
      isApi.current= true
      resetFailAnnotation()
      console.log("error", err)

    }
  }
  return (
    <>
      
    </>
  )
}

export default AnnotationUpdateApi