import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SettingPathModel } from "../../Model/settingPath/SettingPathModel";


interface SettingPathStates {
   settingPath:SettingPathModel[],
   isRefetch:boolean,
   settingPathResponse:string,
   
    
}

const initialState: SettingPathStates = {
 
 settingPath:[],
    isRefetch:false,
    settingPathResponse:"",


};

const SettingPathSlice = createSlice({
    name: "settingPath",
    initialState,
    reducers: {
      resetSettingPath:(state)=>{
        return initialState
      },
       addSettingPath:(state,action)=>{
        const {pathData}=action.payload
        state.settingPath=pathData
        if(pathData.length>0){
          state.settingPathResponse="Data Found"
       }else{
        state.settingPathResponse="Data Not Found"
       }
      
    },
    addUpdateSettingPath:(state,action)=>{
      const {pathData}=action.payload
        const pathIndex= state.settingPath.findIndex(item=>item.id==pathData.id)

         if(pathIndex==-1){
          state.settingPath.push(pathData)

         }else{
          state.settingPath[pathIndex].title=pathData.title
          state.settingPath[pathIndex].is_active=pathData.is_active
          state.settingPath[pathIndex].path=pathData.path
          state.settingPath[pathIndex].slug=pathData.slug
          state.settingPath[pathIndex].type=pathData.type
         }

    },
    deleteSettingPath:(state,action)=>{
      const {id}=action.payload
      const pathIndex= state.settingPath.findIndex(item=>item.id==id)
      if(pathIndex!=-1){
        state.settingPath.splice(pathIndex,1)

       }
    },
    addRefetch:(state,action)=>{
      state.isRefetch=action.payload
    }
    }
}
)

export const {
    resetSettingPath,
    addSettingPath,
    addRefetch,
    addUpdateSettingPath,
    deleteSettingPath
    }=SettingPathSlice.actions;
        export const getSettingPath=  (state:{settingPath:SettingPathStates})=>state.settingPath.settingPath;
       
        export const getIsRefetch=  (state:{settingPath:SettingPathStates})=>state.settingPath.isRefetch;
       
        
export default SettingPathSlice.reducer