import React, { useEffect, useRef } from 'react'
import { getUserLogin } from '../../../slice/user/UserSlice'
import { getUserImageFile, getUserNewProject } from '../../../slice/user/UserProject'
import { useSelector } from 'react-redux'
import { handleImageScale } from '../../../page/samModel/new/helper/scaleHelper'
import { setParmsandQueryModel } from '../../../page/samModel/new/modelApi/ModelApi'
const API_ENDPOINT = process.env.REACT_APP_BACKEND_URL;
const serverApi= process.env.REACT_APP_SERVER_URL;

 type Props = {
    resetEmbeded: () => void;
  };

const UploadEmdedingHome = ({resetEmbeded}:Props) => {
    const getUserNewProjects = useSelector(getUserNewProject)
    const getUserImageFiles = useSelector(getUserImageFile)
    const getUserLogins = useSelector(getUserLogin)
    const imageUpload= useRef(true)
    useEffect(()=>{
        if(
          getUserNewProjects &&
          getUserNewProjects.id &&
          getUserImageFiles &&
          imageUpload.current
        ){
          imageUpload.current = false
          const file = getUserImageFiles;
          
          if (file instanceof File) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const dataUrl = e.target?.result as string;
              const name = file.name;
             
              loadImage(dataUrl, name, false, getUserNewProjects?.id??0);
            };
            reader.readAsDataURL(file);
          } else {
            console.error("getUserImageFiles is not a File object");
          }
       
        }else {
          imageUpload.current = true
        }
      }, [getUserNewProjects, getUserImageFiles]);


      const loadImage = async (dataUrl: string, imageName: string, init: boolean, projectId: number) => {
        try {
          const imageBlob = await fetch(dataUrl).then(res => res.blob());
          if (!API_ENDPOINT || !imageBlob) return;

          const req_data = new FormData();
          req_data.append("file", imageBlob, imageName);
          
          const segResponse = await fetch(`${API_ENDPOINT}/image-embedding`, {
            method: "POST",
            body: req_data,
          });

          const segJSON = await segResponse.json();
          
          if (segJSON && segJSON.image_embedding) {
            const saveToS3Response = await saveEmbeddingToS3(segJSON.image_embedding, imageName, projectId);
            //console.log("saveToS3Response", saveToS3Response);
         
                imageUpload.current = true
                resetEmbeded()
            
       
          
          }
        } catch (error) {
          console.error("Error loading image:", error);
        }
      }

      const saveEmbeddingToS3 = async (embedding: string, imgName: string, projectId: number) => {
        const embeddingBlob = new Blob([embedding], { type: 'text/plain' });
        const fileName = `${projectId}_${imgName}_embedding.txt`;
      
        const formData = new FormData();
        formData.append('file', embeddingBlob, fileName);
        formData.append('projectId', projectId.toString());
        formData.append('imgName', imgName);
      
        const response = await fetch(`${serverApi}/save-embedding`, {
          method: 'POST',
          body: formData,
        });
      }
  return (
    <>
        
    </>
  )
}

export default UploadEmdedingHome
