import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "../slice/user/UserSlice";
import UserProjectReducer from "../slice/user/UserProject";
import LoadingReducer from "../slice/loading/LoadingSlice";
import UserJobSlice from "../slice/userJobSlice/UserJobSlice";
import userScreenResolution from "../slice/userScreenSlice/userScreenResolution";
import TabSlice from "../slice/tabControl/TabControlSlice";
import MediaSlice from "../slice/media/MediaSlice";
import ToastSlice from "../slice/messageToast/ToastSlice";
import WebSocketSlice from "../slice/webSocketSlice/WebSocketSlice";
import SettingPathSlice from "../slice/settingPathSlice/SettingPathSlice";

const userStore = configureStore({
    reducer: {
        user: UserReducer,
        userProjects: UserProjectReducer,
        userJob: UserJobSlice,
        loading: LoadingReducer,
        windowResolution: userScreenResolution,
        tabControl: TabSlice,
        media: MediaSlice,
        toastSlice: ToastSlice,
        webSocket: WebSocketSlice,
        settingPath: SettingPathSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default userStore;
export type UserRootState = ReturnType<typeof userStore.getState>;
