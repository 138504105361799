import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice'
import { GetS3BucketFolderFiles } from '../../../api/folderApi/FolderApi'
import { addGenAi } from '../../../slice/userJobSlice/UserJobSlice'
import { stopGenAiImage, updateGenAiImageFromFolder } from '../../../slice/genAi/GenAiAttributesSlice'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import axios from 'axios'


type Props={
  resetGenAi:()=>void
}
const GetGenAiImage = ({resetGenAi}:Props) => {
     const dispatch= useDispatch()
    const getMasterArrays= useSelector(getMasterArray)
    const isApi= useRef(true)
    useEffect(()=>{
        if(getMasterArrays && 
            getMasterArrays.project_id &&
            isApi.current
        ){
          isApi.current= false
            genAiImages( getMasterArrays.project_id)
        }
    },[getMasterArrays])

    const genAiImages=async(projectid:number)=>{
        const folderName=`projects/${projectid}/styleGen/`
        try{
           const response= await GetS3BucketFolderFiles(folderName)
         // console.log("genApi respinse---", response)
           if(response && response.status==200){

            dispatch(updateGenAiImageFromFolder({
              allImages:response.data}))
            }

            dispatch(stopGenAiImage())
            isApi.current= true;
            resetGenAi()
        }catch(err){
           // console.log("Error on  getting GenAi Image", err)

             isApi.current= true;
             if(axios.isAxiosError(err)){
            
              dispatch(addMessage({
                  isShow:true,
                  mess: err.response?.data.message,
                  toastType:"Error"
                 }))
          }
             resetGenAi()
        }

    }
  return (
    <>
        
    </>
  )
}

export default GetGenAiImage

// https://api.dzinly.org/api/project-read
// https://api.dzinly.org/api/project-read