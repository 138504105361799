import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { GetSegDetectModel } from '../../../../api/segDetectModelApi/SegDetectModelApi'
import { addSegDetectModel } from '../../../../slice/segDetectModelSlice/SegDetectModelSlice'

type Props={
    resetGetAllSegDetectModel: ()=>void
}
const GetAllSegDetectModel = ({resetGetAllSegDetectModel}:Props) => {

    const dispatch = useDispatch()
    const isApiCall = useRef(true)

useEffect(() => {
    if(isApiCall.current){
        getAllModels()
        isApiCall.current = false
    }
    return () => {
        resetGetAllSegDetectModel()
    }
},[])

const getAllModels= async()=>{
    try {
        const response = await GetSegDetectModel()
       // console.log("model---->",response)
        if(response.status === 200){
             dispatch(addSegDetectModel(response.data))
        }
    } catch (error) {
        console.log(error)
    }       
}
  return (
    <>
    </>
  )
}

export default GetAllSegDetectModel