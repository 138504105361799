import axios from "axios";
import apiCilent from "../interceptor/ApiCilent"
import { SegDetectModel } from "../../Model/segDetectModel/SegDetect";


 const serverApi =process.env.REACT_APP_SERVER_URL


// get all segment detect models from collection
export function GetSegDetectModel(){
    return apiCilent.get(`/seg-detect-model/get-all`)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}

// add segment detect model into collection
export function AddSegDetectModel(data:SegDetectModel){
    const model={
        modelData:data
    }
    return apiCilent.post(`/seg-detect-model/add-model`,model)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}

// uplaod seg deetct model (zip file)
export function UploadSegDetectModel(data:FormData){
    return apiCilent.post(`/seg-detect-model/upload-zip`,data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}
