import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGenAiAttributes } from '../../../../../slice/genAi/GenAiAttributesSlice'
import { OptionJDetails } from '../../../../../Model/genAiAttributes/GenAiAttributes'
import { GenAiAttributesModel } from '../../../../../Model/genAiAttributes/GenAiAttributes'
import { CiViewColumn } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'
import { addGenAiFeature } from '../../../../../slice/genAi/featureSlice/GenAiFeatureSlice'

const GenAiSideBarFeature = () => {
  const dispatch= useDispatch()
    const navigator = useNavigate( )  
    const [allGenAttrFeature, setAllGenAttrFeature] = useState<GenAiAttributesModel | null>(null);
    const getGenAiAttributess= useSelector(getGenAiAttributes)

    useEffect(() => {
        if (getGenAiAttributess && getGenAiAttributess.length > 0) {
          const allAtt = getGenAiAttributess[0] as GenAiAttributesModel; // Explicitly cast to GenAiAttributesModel
          // Set features and segments from attributes
          const featureAttributes: GenAiAttributesModel = {};
        
          Object.keys(allAtt).forEach((key) => {
            const attribute = allAtt[key] as OptionJDetails; // Access attribute directly
            if (attribute.type === 'feature' && attribute.status) {
              featureAttributes[key] = attribute; // Correctly typed attribute
            } 
          });
    
          setAllGenAttrFeature(featureAttributes);
       
          
        }
      }, [getGenAiAttributess]);

     // console.log("allGenAttrFeature",allGenAttrFeature)

      const handleFeature=(allVal: OptionJDetails)=>{
        console.log("allVal---",allVal)

        dispatch(addGenAiFeature(allVal))
        navigator(`/dashboard-page/gen-ai/feature/${allVal.key}`);
      }
  return (
    <>

             
<div>
<i className="bx bx-chevron-down dropicon"></i>
              <ul className="submenu">
                {allGenAttrFeature &&
                  Object.keys(allGenAttrFeature).map((key, index) => {

                    return (
                        <li
                        // className={activeSubmenuIndex[3] === index + 1 ? "active" : ""}
                        onClick={() => handleFeature(allGenAttrFeature[key])} 
                        >
                        {allGenAttrFeature[key].title}
                        </li>   
                    );
                  })}
                  
               
              
              </ul>
           

</div>
             
    </>
  )
}

export default GenAiSideBarFeature