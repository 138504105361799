import React, { useEffect } from 'react'
import ShowProjects from '../../module/projectPage/ProjectHome'
import GetAllSwatchImage from '../../module/ImageView/LeftSections/segmentbasedSwatch/GetAllSwatchImage'
import { useDispatch } from 'react-redux'
import { updateRefetchDoor, updateRefetchGarage, updateRefetchGutter, updateRefetchRoof, updateRefetchTrim, updateRefetchWall, updateRefetchWindow } from '../../slice/swatches/SwatchSlices'

const Projects = () => {
  const dispatch = useDispatch()
   
  // fectch for door
  useEffect(() => {
     dispatch(updateRefetchDoor(true)) // start fetching door swatches
     dispatch(updateRefetchWindow(true)) // stop fetching window swatches
     dispatch(updateRefetchRoof(true)) // stop fetching  roof swatches
     dispatch(updateRefetchGarage(true)) // stop fetching garage swatches
     dispatch(updateRefetchGutter(true)) // stop fetching gutter swatches
     dispatch(updateRefetchWall(true)) // stop fetching wall swatches
     dispatch(updateRefetchTrim(true)) // stop fetching trim swatches
  },[])

  return (
    <div className='p-0'>
        <ShowProjects/>
        <GetAllSwatchImage/>
    </div>
  )
}

export default Projects