import React, { useEffect, useState } from 'react'
import SearchAdd from '../../dasboardpages/components/pageContent/SearchAdd'
import PageContentHeader from '../../dasboardpages/components/pageContent/PageContentHeader'
import Sidebar from '../../dasboardpages/components/Sidebar'
import Header from '../../header/Header'
import GetAllSegDetectModel from './api/GetAllSegDetectModel'
import SegModeltable from './segModelTable/SegModeltable'
import AddSegDetectModel from './addModels/AddSegDetectModel'
import { useDispatch, useSelector } from 'react-redux'
import { getSegModelStatus, updateSegModal } from '../../../slice/segDetectModelSlice/SegDetectModelSlice'

const SegDetectModelHome = () => {
    const dispatch= useDispatch()
    const [isActive, setIsActive] = useState(false); // State to track whether the div is active
    const [firstHeader, setFirstHeader] = useState<string>('Segment Detection Model'); 
    const [secondHeader, setSecondHeader] =useState<string>('Segment Detection Model') 
   
    const [breadCum1, setbreadCum1] =useState<string>('Home') 
    const [breadCum2, setbreadCum2] =useState<string>('Segment Detection Model') 
    const [breadCum3, setbreadCum3] =useState<string>('') 
    const [isSegDetectModel, setIsSegDetectModel] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State to control modal visibility
    const getSegModelStatuss= useSelector(getSegModelStatus)
    useEffect(() => {
      setIsSegDetectModel(true)
    },[])
    const toggleActiveClass = () => {
        setIsActive(!isActive); // Toggle the isActive state
      };

      const handleResetSegModel = ()=>{
          setIsSegDetectModel(false)
      }

      const handleOpenModal = () => {
        setIsModalOpen(true); // Open the modal
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
          dispatch(updateSegModal(false))
        // Close the modal
    }


    // update the model to add new model
    useEffect(() => {
      
        if(getSegModelStatuss){
            setIsModalOpen(getSegModelStatuss)
        }else{
            setIsModalOpen(false)
        }
    },[getSegModelStatuss])

  return (
    <>

<Header/>
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
             <Sidebar toggleActiveClass={toggleActiveClass} />
            {/* content header */}
            
            
             <PageContentHeader
             firstHeader={firstHeader}
             secondHeader={secondHeader}
             breadCum1={breadCum1}
             breadCum2={breadCum2}
             breadCum3={breadCum3}
             
              />
            
          
           
           <AddSegDetectModel
           isOpen={isModalOpen}
            onClose={handleCloseModal} 
           />
    
  
              {/* table */}
            
       <SegModeltable/>
    
    
    
             
        </div>

       { isSegDetectModel &&
       <GetAllSegDetectModel
        resetGetAllSegDetectModel={handleResetSegModel}
        />}
    
    </>
  )
}

export default SegDetectModelHome