import { SettingPathModel } from "../../../../../Model/settingPath/SettingPathModel";

export const getImagePath = (
    allImagePath:SettingPathModel[],
    title:string,
    imagePath:(data:string)=>void
) => {

    const imagePathData=allImagePath.filter(item=>item.title==title)
    if(imagePathData && 
        imagePathData.length>0){
        imagePath(imagePathData[0].path??"")
    }else {
        console.warn(`No image path found for title: ${title}`);
      }
}