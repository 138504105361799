import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { addcategoryBasedSwatch } from '../../../../../../slice/materialSlice/SearchMaterial'
import axios from 'axios'
import { addMessage } from '../../../../../../slice/messageToast/ToastSlice'
import { GetStyleBrandBasedCatBasedSwatches } from '../../../../../../api/material/swatch/SwatchApi'


type Props={
    segType:string,
    catId:number
    brands:number[],
    styles:number[]
    resetSearchCatBrandStyle:()=>void
 }
const SearchSwatchCatBrndStyle = ({catId,brands,styles,segType,resetSearchCatBrandStyle}:Props) => {

    const dispatch= useDispatch()
    const isApi= useRef(true)

    useEffect(()=>{
        if(catId && 
            brands &&
            styles &&
            segType &&
             isApi.current){
            isApi.current=false
            getStyleBrandBasedCatBasedSwatches(catId, brands, styles)
        }
    }, [catId, brands,segType,styles]) // added 'catId' and 'brands' as dependencies

    const getStyleBrandBasedCatBasedSwatches=async(catId:number, brands:number[], styles:number[])=>{
        try{
            const response= await GetStyleBrandBasedCatBasedSwatches(catId, brands, styles)
           // console.log("response styles swaycthch---",response)
            if(response  && response.status==200){
              
                dispatch(addcategoryBasedSwatch(
                    {
                        type:segType,
                        catId:catId,
                        swatches:response.data.data
                    }))
                isApi.current=true
                resetSearchCatBrandStyle()
            }
    }catch(err){
        if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }

        
        isApi.current=true
        resetSearchCatBrandStyle()
    }
}
  return (
    <>

    </>
  )
}

export default SearchSwatchCatBrndStyle