import React from 'react'
import SetttingPathHome from '../../module/admin/settingPath/SetttingPathHome'

const SettingPathPage = () => {
  return (
    <>
    <SetttingPathHome/>
    </>
  )
}

export default SettingPathPage