import React from 'react'
import "./UserProjects.scss"
const UserProjects = () => {
  return (
    <div className='user-projects-section'>
           {/* <h4>Projects</h4> */}
            <p>you have 2 project</p>

          <div className='row g-3 g-md-4'>
            <div className="col-12 col-md-4">
              <div className='card shadow-lg rounded-3 border-0'>
                <div className='pro-images rounded-top-3 overflow-hidden'>
                <img src="https://betadzinly.s3.us-east-2.amazonaws.com/projects/897/image_897_compress.jpeg" alt="Van Test"></img>
                </div>
                <div className='card-content'>
                  <div className='job-content p-3 pt-2 align-items-center justify-content-between'>
                    <div className="pt-2">
                      <h5>Project One</h5>
                      <div className="job-project-title fs-5 fw-medium p-0 border-0 bg-white">
                        <div className="cre-pro-date fw-medium d-flex">Modified: 12/19/2024</div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className='card shadow-lg rounded-3 border-0'>
                <div className='pro-images rounded-top-3 overflow-hidden'>
                <img src="https://betadzinly.s3.us-east-2.amazonaws.com/projects/897/image_897_compress.jpeg" alt="Van Test"></img>
                </div>
                <div className='card-content'>
                  <div className='job-content p-3 pt-2 align-items-center justify-content-between'>
                    <div className="pt-2">
                      <h5>Project One</h5>
                      <div className="job-project-title fs-5 fw-medium p-0 border-0 bg-white">
                        <div className="cre-pro-date fw-medium d-flex">Modified: 12/19/2024</div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
    </div>
  )
}

export default UserProjects
