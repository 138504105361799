import React, { useEffect, useState } from 'react'
import {
    CiPalette,
    CiIndent,
    CiViewColumn,
    CiViewList,
    CiUser,
    CiGlobe,
    CiStop1,
    CiPenpot,
    CiSettings,
  } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux'
import { getGenAiAttributes } from '../../../../slice/genAi/GenAiAttributesSlice'
import { GenAiAttributesModel, OptionJDetails } from '../../../../Model/genAiAttributes/GenAiAttributes'
import { useNavigate } from 'react-router-dom'
import GenAiSideBarFeature from './feature/GenAiSideBarFeature';
import GenAiSideBarSegment from './segment/GenAiSideBarSegment';

const GenAiSideBar = () => {

    const getGenAiAttributess= useSelector(getGenAiAttributes)
    const dispatch= useDispatch()
    const navigator = useNavigate( )
    const[attriType, setAttriType]= useState<OptionJDetails[]>([])
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [isFeature, setIsFeature]= useState<boolean>(false)
    const [isSegment, setIsSegment]= useState<boolean>(false)
    const [activeSubmenuIndex, setActiveSubmenuIndex] = useState<{
        [key: number]: number | null;
      }>({});

              console.log("getGenAiAttributess",getGenAiAttributess)


              useEffect(() => {
                if(getGenAiAttributess &&
                   getGenAiAttributess.length>0){
                    
                    getGenAiAttributess.map(atri=>{
                      Object.keys(atri).forEach(key=>{
                        const value= atri[key]
                      })
                    }) 
                }
              
                  
              }, [getGenAiAttributess])
              
      const handleItemClick = (index: number) => {
        setActiveIndex(index);
      };
      
  const handleAiAttributes=(parentIndex: number, submenuIndex: number)=>{
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    navigator("/dashboard-page/gen-ai");
  }
  const handleGenAiFeature=(parentIndex: number, submenuIndex: number)=>{
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    setIsFeature(!isFeature)  
   // navigator("/dashboard-page/gen-ai");
  }

  const handleGenAiSegment=(parentIndex: number, submenuIndex: number)=>{
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
    setIsSegment(!isSegment)
   // navigator("/dashboard-page/gen-ai");
  }

   const getAttributeType=(type:string)=>{


   }
  return (
    <>
 <li
              className={activeIndex === 3 ? "active" : ""}
              role="button"
              onClick={() => handleItemClick(3)}>
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiViewColumn />
                </span>
                <span className="sidebar-nav-text">Gen Ai</span>
              </div>

              <i className="bx bx-chevron-down dropicon"></i>
              <ul className="submenu">
                <li
                  className={activeSubmenuIndex[3] === 0 ? "active" : ""}
                  onClick={() => handleAiAttributes(1, 0)}>
                  Gen-Ai Attribites
                </li>
                <li
                  className={activeSubmenuIndex[3] === 1 ? "active" : ""}
                  onClick={()=>handleGenAiFeature(2,0)}

                 // onClick={handleJob}
                  >
                  Feature
                </li>
               { isFeature &&
               <GenAiSideBarFeature/>}


                <li
                  className={activeSubmenuIndex[3] === 2 ? "active" : ""}
                 
                 onClick={()=>handleGenAiSegment(3,0)}
                  >
                  Segment
                </li>

                {isSegment &&
                <GenAiSideBarSegment  />}
              </ul>
            </li>
    </>
  )
}

export default GenAiSideBar