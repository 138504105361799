import React, { useEffect, useRef } from 'react'
import { AuthLogin } from '../../../api/auth/Auth'
import { AuthModel } from '../../../Model/auth/AuthModel'
import { addUser } from '../../../slice/user/UserSlice'

import { useDispatch } from 'react-redux'
import { userModel } from '../../../Model/user/UserModel'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import axios from 'axios'
type Props={
    loginForm:AuthModel,
    resetAuth:()=>void
}

const AuthApi = ({loginForm,resetAuth}:Props) => {
   const dispatch= useDispatch()
  
   const isApi= useRef(true)
    useEffect(()=>{
        if(loginForm && loginForm.email && loginForm.password &&isApi.current){
            // console.log("loginForm",loginForm)
            checkAuth(loginForm)
            isApi.current=false
        }
    },[loginForm])


    const checkAuth=async(loginForm:AuthModel)=>{
       
        try{
            const authUser= await AuthLogin(loginForm) ;
            console.log("auth user",authUser)
            if(authUser.status===200 && authUser.data){
                const useData =authUser.data as userModel
                dispatch(addUser(useData))
                
                dispatch(addMessage({
                    isShow:true,
                    mess: "Login sucessfully",
                    toastType:"success"
                   }))
                resetAuth()
            }
           

        }catch(err){
            
            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))

                  
            }
           
           // alert(err)

           
            resetAuth()
        }finally {
            isApi.current = true
          }
    }
  return (
    <></>
  )
}

export default AuthApi