import React, { useEffect, useState } from 'react'
import { Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import "./ActionProject.scss";
import DeleteModal from './deleteProject/DeleteModal';
import DeletEProjectById from './deleteProject/DeletEProjectById';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../slice/messageToast/ToastSlice';
import DeleteFolder from './deleteFolder/DeleteFolder';
import DeleteJob from './deleteJob/DeleteJob';
import { ProjectModel } from '../../../Model/project/ProjectModel';


type Props = {
  projectItem:ProjectModel,
  // jobId: number;
  // projectId: number;
  onEditClick: () => void; // Ensure this is included
}



// const ActionProject = ({projectId}:Props) => {
  const ActionProject: React.FC<Props> = ({ projectItem, onEditClick }) => {
  const [isShowAction, setIsShowAction] = useState<boolean>(false)
  const [isDeletModal, setIsDeleteModal] = useState<boolean>(false)
  const[isDeleteFolder, setIsDeleteFolder]= useState<boolean>(false)
  const[isDeleteJob, setIsDeleteJob]= useState<boolean>(false)
 const [projectId, setProjectId] = useState<number|null>(null)
  const [jobId, setJobId] = useState<number|null>(null)

  useEffect(() => {
    if(projectItem && projectItem.id){
      setProjectId(projectItem.id)
    }
    if(projectItem && projectItem.jobs && 
      projectItem.jobs.length>0){
      setJobId(projectItem.jobs[0]??0)
    }else{
      setJobId(null)
    }
  },[projectItem])

  const [isDeleteApi, setIsDeleteApi] = useState<boolean>(false)
    const dispatch= useDispatch()
  
  const handleDelete = () => {
    setIsDeleteModal(true)
   
  }

  const handleActionProject = (id: number) => {
    // setIsShowAction(true)
    // setProjectId(id)
  }

  const handleCloseAction = () => {
    //setIsShowAction(false)
  }

  const handleConfirmDelete = () => {
    setIsDeleteModal(false)
    setIsDeleteFolder(true)
   // setIsDeleteApi(true)
  }
  const handleCloseModal = () => {
    setIsDeleteModal(false)
  }


  const handleResetDelete = (data: boolean) => {
    setIsDeleteApi(false)
    
    if (data) {
      
      dispatch(addMessage({
        isShow:true,
        mess:"Project deleted successfully",
         toastType:"success"
       }))
      //alert("Project deleted successfully")
    } else {
      dispatch(addMessage({
        isShow:true,
        mess:"Some thing went wrong",
         toastType:"Error"
       }))
      // alert("Some thing went wrong")
    }
  }


  const handleDeleteFolder=()=>{
    setIsDeleteFolder(false)
    if(jobId!=null){
      setIsDeleteJob(true)
    }
    else{
      setIsDeleteApi(true)
    }

   
  }

  const handleResetDeleteJob=()=>{
    setIsDeleteJob(false)
     setIsDeleteApi(true)
  }

  return (
    <>
       <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" >View</Tooltip>}>

       <span
  onClick={() => {
    const url = "#"; // Replace with your static URL
    window.open(url, "_blank");
  }}
  style={{ cursor: "pointer" }} // Ensures the element looks clickable
>
  <i className="bi bi-box-arrow-up-right"></i>
</span>


</OverlayTrigger>

<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled"  >Edit</Tooltip>}>
<span onClick={onEditClick}>
  <i className="bi bi-pencil"></i>
</span>
</OverlayTrigger>

<OverlayTrigger  placement="bottom" overlay={<Tooltip id="tooltip-disabled" >Delete</Tooltip>}>
<span 
onClick={handleDelete}
 >  
  <i className="bi bi-trash"></i> 
</span>
</OverlayTrigger>


{/* delete  confirmation  modal*/}
{isDeletModal &&
        <DeleteModal
          isDeleteModal={isDeletModal}
          okDelete={handleConfirmDelete}
          okClose={handleCloseModal}
        />
      }


     {/* delete folder */}
       { isDeleteFolder &&
       projectId &&  
        <DeleteFolder
      projectId= {projectId}
      resetDeleteFolder={handleDeleteFolder}
       />}

      
      {/* delete job */}
      {isDeleteJob &&
      jobId &&
        <DeleteJob
        jobId={jobId}
        resetDeleteJob={handleResetDeleteJob}
        />
      }


{/* delete Api */}

{isDeleteApi &&
        <DeletEProjectById
          projectId={projectId ?? 0}
          resetProjectId={handleResetDelete}
        />
      }


      
    </>
  )
}

export default ActionProject
