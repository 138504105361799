import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EachOption, GenAiAttributesModel, GeneratedPromptModel, OptionJDetails, PromptModel } from "../../Model/genAiAttributes/GenAiAttributes";
import { GenAiImageModel } from "../../Model/genAiAttributes/GenAiImages";
import { stat } from "fs";
import { ProjectImage } from "../../Model/mediaModel/MediaModel";


interface GenAiAttributesStates {
    genAi_attributes:GenAiAttributesModel[],
    projectGenAiImages:GenAiImageModel,
    promptvalue:PromptModel,
    generatedPrompt:GeneratedPromptModel,
    isStartGenAiImage:boolean,
    refetchGenAiImage:boolean,
    isStartUpdateGenAiImage:boolean,
    isOpenFeature:boolean,
    showAttributeonModal:OptionJDetails,
    uploadPercentage:number

   
    
}

const initialState: GenAiAttributesStates = {
    genAi_attributes:[],
    projectGenAiImages:{},
    promptvalue:{},
    isStartGenAiImage:false,
    generatedPrompt:{},
    refetchGenAiImage:false,
    isStartUpdateGenAiImage:false,
    isOpenFeature:false,
    uploadPercentage:0,
    showAttributeonModal:{}


};

const GenAiAttributesSlice = createSlice({
    name: "genai_attributes",
    initialState,
    reducers: {
      addGenAiAttributes:(state,action)=>{
        state.genAi_attributes=action.payload
      },
      resetGenAiAttributes:(state)=>{
        state.genAi_attributes=[]
      },
      addPrompvalue:(state,action)=>{
        const {promptKey, promptvalue}= action.payload
        const allvalue= state.promptvalue;
        if(allvalue){
            const updated={
              ...allvalue,
              [promptKey]:promptvalue
            }
            state.promptvalue=updated
        }else{
    state.promptvalue={
      [promptKey]:promptvalue

    }
        }
      }, 
      updatePromptValue:(state,action)=>{
        const {promptkeyValues}= action.payload
           Object.keys(promptkeyValues).forEach((key)=>{
             const allvalue= state.promptvalue;
             if(allvalue){
              const updated={
                ...allvalue,
                [key]:promptkeyValues[key]
              }
              state.promptvalue=updated   
            }
      })
    },

      resetPrompValue:(state)=>{
        state.promptvalue={}
      }, 
      startGenAiImage:(state)=>{
        state.isStartGenAiImage=true
      }, stopGenAiImage:(state)=>{
        state.isStartGenAiImage= false
      },
      addGeneratedPrompt:(state,action)=>{
        state.generatedPrompt= action.payload
      },
      resetGeneratedPrompt:(state)=>{
        state.generatedPrompt={}
      },
       fetchGenAiImage:(state,action)=>{
        state.refetchGenAiImage=action.payload
       },
       addProjectGenAiImages:(state, action)=>{
        state.projectGenAiImages=action.payload
       }
       , resetProjectGenAiImages:(state)=>{
        state.projectGenAiImages={}
       },
       updateProjectPrompTbasedImage:(state,action)=>{
        const {jobId, projectId,projectImage}=action.payload
        state.projectGenAiImages.job_id=jobId[0]
        state.projectGenAiImages.project_id= projectId
        state.projectGenAiImages.gallery=[{
          image_name: "Project Image",
          image_path: projectImage,
          prompt: "",
        }]
       },
       updateGenAiGallery: (state, action) => {
        const { gallery } = action.payload;
        const existingGallery = state.projectGenAiImages.gallery;
      
        if (Array.isArray(existingGallery) && Array.isArray(gallery)) {
          const newGalleryItems = gallery.map(item => ({
            image_name: item.image_name,
            image_path: item.image_path,
            prompt: item.prompt,
          }));
          state.projectGenAiImages.gallery = [...existingGallery, ...newGalleryItems];
        }
      },
      
       addProjectPrompTbasedImage: (state, action) => {
        const { prompt, imagePath, name } = action.payload;
      
        const data = {
          image_name: name,
          image_path: imagePath,
          prompt: prompt ?? state.generatedPrompt,
          isnew:true
        };
      
        const gall = state.projectGenAiImages.gallery;
      
        // Check if the imagePath already exists in the gallery
        const exists = gall?.some((item) => item.image_path === imagePath);
      
        if (!exists) {
          if (gall?.length === 0) {
            state.projectGenAiImages.gallery = [data];
          } else {
            state.projectGenAiImages.gallery?.push(data);
          }
        } 
      },
      updateGenAiImageFromFolder: (state, action) => {
        const { allImages } = action.payload;
        const existingGallery = state.projectGenAiImages.gallery;
        if (Array.isArray(allImages) && Array.isArray(existingGallery)) {
          const newGalleryItems = allImages.map(item => {
            const match = item.image.match(/styleGen\/([^/]+)$/);
            const name = match ? match[1] : "";
            const imagePath = match ? match[1] : "";
            return {
              image_name: name,
              image_path: imagePath,
              prompt: "",
            };
          });
          state.projectGenAiImages.gallery = [...existingGallery, ...newGalleryItems];
        }
      }
,      resetProjectGenAiImagesValue:(state)=>{
               const gal= state.projectGenAiImages.gallery;
               if( gal&&gal.length>0){
                state.projectGenAiImages.gallery = gal.map(image => ({
                  ...image,
                  isnew: false
                }));
               }
      },
      updateStartUploadGenAi:(state,action)=>{
        state.isStartUpdateGenAiImage=action.payload
      },
      addIsOpenFeatureModal:(state,action)=>{
        state.isOpenFeature= action.payload
      },
      addUploadPercentage:(state,action)=>{
        state.uploadPercentage= action.payload
      },
      resetUploadPercentage:(state,action)=>{
        state.uploadPercentage=0
      },
      updateGenAiAttribute: (state, action) => {
        const { updateData, key } = action.payload;
      
        const genAttriIndex = state.genAi_attributes.findIndex(item =>
          Object.keys(item).includes(key)
        );
      
        if (genAttriIndex !== -1) {
          const keyAttri = state.genAi_attributes[genAttriIndex][key];
          const updateOption = updateData.option as EachOption[];
      
          if (keyAttri) {
            // Update other attributes
            keyAttri.priority = updateData.priority;
            keyAttri.status = updateData.status;
            keyAttri.is_featured = updateData.is_featured;
            keyAttri.type = updateData.type;
      
            // Handle option array update
            if (!keyAttri.option) {
              // If no existing options, set the new options
              keyAttri.option = updateOption;
            } else {
              // Merge and update options
             
              keyAttri.option=updateOption
            
            }
          }
        }
      },
       addShowAttributeonModal:(state,action)=>{
        state.showAttributeonModal=action.payload
       },
       resetShowAttributeonModal:(state)=>{
        state.showAttributeonModal={}
       }
      
}
}
)

export const {addGenAiAttributes,
   resetGenAiAttributes,
   addPrompvalue,
   updatePromptValue,
   resetPrompValue,
   startGenAiImage,stopGenAiImage,
   addGeneratedPrompt,
   resetGeneratedPrompt,
   fetchGenAiImage ,
   addProjectGenAiImages,
   resetProjectGenAiImages,
   addProjectPrompTbasedImage,
   updateGenAiImageFromFolder,
   updateProjectPrompTbasedImage,
   updateStartUploadGenAi,
   updateGenAiGallery,
   resetProjectGenAiImagesValue,
   addIsOpenFeatureModal,
   addUploadPercentage,
  resetUploadPercentage,
  updateGenAiAttribute,
  addShowAttributeonModal,
  resetShowAttributeonModal
   
  }=GenAiAttributesSlice.actions;
export const getGenAiAttributes=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.genAi_attributes
export const getPromptValues=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.promptvalue
export const getGenAiImage=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.isStartGenAiImage
export const getGeneratedPrompt=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.generatedPrompt
export const getFetchGenAi=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.refetchGenAiImage
export const getIsStartUploadGenAi=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.isStartUpdateGenAiImage


export const getProjectGenAiImage=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.projectGenAiImages
export const getIsOpenModal=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.isOpenFeature


export const getUploadPercentage=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.uploadPercentage
export const getShowAttributeModal=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.showAttributeonModal


export default GenAiAttributesSlice.reducer