// import React, { useEffect, useRef, useState } from "react";
// import { Form } from "react-bootstrap";
// import { EachOption } from "../../../../../Model/genAiAttributes/GenAiAttributes";
// import { Prev } from "react-bootstrap/esm/PageItem";
// import UploadImageS3 from "./UploadImageS3";
import React, { useEffect, useRef, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { EachOption } from '../../../../../Model/genAiAttributes/GenAiAttributes'
import { Prev } from 'react-bootstrap/esm/PageItem'
import UploadImageS3 from './UploadImageS3'


 type Props={
  title:string,
    item:EachOption,
    attritype:string,
    updateOptionData:(data:EachOption)=>void
 }
const OptionForm = ({item,attritype,updateOptionData,title}:Props) => {

  const [imageFile, setImageFile] = useState<File | null>(null); // State to store the selected file
  const [thumbnailUrl, setThumbnailUrl] = useState<string>(""); // State to store the URL of the thumbnail
  const [attriType, setAttriType] = useState<string | null>(attritype);
  const [isUpoad, setIsUpload] = useState<boolean>(false);
  const [imageName, setImageName] = useState<string | null>(null);


  const [updateOption, setUpdateOption] = useState<EachOption>({
    title: "",
    image: "",
    slug: "",
    isActive: false,
  });
   
   const [spin, setSpin]= useState<boolean>(false)
   const isUpdate= useRef(false)
    //console.log("attriType",attriType)
    

  // updare attribute type
  useEffect(() => {
    if (attritype) {
      setAttriType(attritype);
    }
  }, [attritype]);

  const isOptions = useRef(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const vvalues = e.target.value;
   
  };

const handleImageChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
       
        setUpdateOption({
            ...updateOption, // Correctly spread the existing state
            image:e.target.value
        })
      }

const handleChangeSlug=(e:React.ChangeEvent<HTMLInputElement>)=>{
       // console.log(e.target.value)
        setUpdateOption({
            ...updateOption, // Correctly spread the existing state
            slug:e.target.value
        })
        isUpdate.current=true
      }

const handleChangeActive=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setUpdateOption({
            ...updateOption, // Correctly spread the existing state
            isActive:e.target.checked
        })
        isUpdate.current=true
      }
      ;


      const handleUploadImage=(e:React.ChangeEvent<HTMLInputElement>)=>{
         setSpin(true)
        const file = e.target.files?.[0];
       // console.log("File", file) 
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
               
                 setThumbnailUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
            setImageFile(file); 
            setIsUpload(true)
            setImageName(file.name)
        }
      }


useEffect(()=>{
        if(item &&
             item.title &&
             isOptions.current
        ){
            isOptions.current= false
            setUpdateOption({
                title:item.title,
                image:item.image,
                slug:item.slug,
                isActive:item.isActive
            })
        }
      },[])


   const handleresetUploadImage=(url:string)=>{
        setIsUpload(false)
        setImageName(null)
        setImageFile(null)
        setUpdateOption({
          ...updateOption, // Correctly spread the existing state
          image:url
      })
      setSpin(false)
      isUpdate.current=true

      }
       useEffect(()=>{
        if(updateOption && isUpdate.current ){
          isUpdate.current=false
           updateOptionData(updateOption)
        }
      },[updateOption])

  // console.log("updateOption", updateOption);
  // return (
  //   <div className="row pb-4 pt-2 g-2 border-top bg-secondary">
  //     <Form.Group className="col-6" controlId="formOptions">
  //       <Form.Label>Title</Form.Label>
  //       <Form.Control
  //         type="text"
  //         name="title"
  //         value={updateOption.title}
  //         //onChange={handleChange}
  //         placeholder="Comma separated values"
      // const handleImageChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
       
      //   setUpdateOption({
      //       ...updateOption, // Correctly spread the existing state
      //       image:e.target.value
      //   })
      // }
      // const handleChangeSlug=(e:React.ChangeEvent<HTMLInputElement>)=>{
      //  // console.log(e.target.value)
      //   setUpdateOption({
      //       ...updateOption, // Correctly spread the existing state
      //       slug:e.target.value
      //   })
      //   isUpdate.current=true
      // }

      // const handleChangeActive=(e:React.ChangeEvent<HTMLInputElement>)=>{
      //   setUpdateOption({
      //       ...updateOption, // Correctly spread the existing state
      //       isActive:e.target.checked
      //   })
      //   isUpdate.current=true
      // }


      // const handleUploadImage=(e:React.ChangeEvent<HTMLInputElement>)=>{
      //    setSpin(true)
      //   const file = e.target.files?.[0];
      //  // console.log("File", file) 
      //   if (file) {
      //       const reader = new FileReader();
      //       reader.onloadend = () => {
               
      //            setThumbnailUrl(reader.result as string);
      //       };
      //       reader.readAsDataURL(file);
      //       setImageFile(file); 
      //       setIsUpload(true)
      //       setImageName(file.name)
      //   }
      // }

      // useEffect(()=>{
      //   if(item &&
      //        item.title &&
      //        isOptions.current
      //   ){
      //       isOptions.current= false
      //       setUpdateOption({
      //           title:item.title,
      //           image:item.image,
      //           slug:item.slug,
      //           isActive:item.isActive
      //       })
      //   }
      // },[])


      // const handleresetUploadImage=(url:string)=>{
      //   setIsUpload(false)
      //   setImageName(null)
      //   setImageFile(null)
      //   setUpdateOption({
      //     ...updateOption, // Correctly spread the existing state
      //     image:url
      // })
      // setSpin(false)
      // isUpdate.current=true

      // }

     // console.log("updateOption",updateOption)
      // onupdate data send data to main state
      // useEffect(()=>{
      //   if(updateOption && isUpdate.current ){
      //     isUpdate.current=false
      //      updateOptionData(updateOption)
      //   }
      // },[updateOption])
  return (
    <>

    <div className="row pb-4 pt-2 g-2 border-top bg-secondary">
<Form.Group  className="col-6" controlId="formOptions">
            <Form.Label>Title</Form.Label>
            <Form.Control 
              type="text" 
              name="title" 
             value={updateOption.title} 
              onChange={handleChange} 
              placeholder="Comma separated values" 
            />
          </Form.Group>
<Form.Group  className="col-6" controlId="formOptions">
            <Form.Label>Image path</Form.Label>
            <Form.Control 
              type="text" 
              name="image" 
              value={updateOption.image} 
              onChange={handleImageChange} 
              placeholder="image path" 
            />
          </Form.Group>
          
          <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload Image  <span>{updateOption.title}</span></Form.Label>
         { spin &&
         <Spinner animation="border" variant="info" />}
        <Form.Control type="file" 
        onChange={handleUploadImage}
        />
      </Form.Group>

      <Form.Group controlId="formOptions" className="col-6">
        <Form.Label>Slug</Form.Label>
        <Form.Control
          type="text"
          name="slug"
          value={updateOption.slug}
          onChange={handleChangeSlug}
          placeholder="slug"
        />
      </Form.Group>

      <Form.Group  className="col-6" controlId="formOptions">
        <Form.Label>Image path</Form.Label>
        <Form.Control
          type="text"
          name="image"
          value={updateOption.image}
          onChange={handleImageChange}
          placeholder="image path"
        />
      </Form.Group>

      <Form.Group className="col-8"  controlId="formFile">
        <Form.Label>
          Upload Image <span>{updateOption.title}</span>
        </Form.Label>
        <Form.Control type="file" onChange={handleUploadImage} />
      </Form.Group>


<Form.Group className="col-4" controlId="formActive">
  <Form.Label>Status</Form.Label>
  <Form.Check 
    type="switch"
    id="custom-switch"
    label="Active / Inactive"
    name="isActive"
    checked={updateOption.isActive}
    onChange={handleChangeActive}
  />
</Form.Group>

      {isUpoad && attriType && imageName && imageFile && (
        <UploadImageS3
          attriType={attritype}
          imageName={imageName}
          file={imageFile}
          resetUploadS3={handleresetUploadImage}
        />
      )}
    </div>
    </>
  );
};

export default OptionForm
