import React from 'react'
import { useDispatch } from 'react-redux'
import { addCanvasGrid, addShowNearestLine, addShowNearestPoint, addShowNearestPolygon } from '../../../../../slice/canvas/canvasOption/CanvasOptionSlice'

const CanvasOption = () => {
    const [isCanvasOption, setIsCanvasOption] = React.useState(false)
    const[isGrid, setisGrid]=React.useState(true)
    const[isshowPolygon, setisshowPolygon]=React.useState(true)
    const[isshowPoint, setisshowPoint]=React.useState(true)
    const[isshowLine, setisshowLine]=React.useState(true)
    const dispatch= useDispatch()

    const handleCanvasOption = () => {
        setIsCanvasOption(!isCanvasOption)
    }


    const handleGrid=(value:boolean)=>{
        console.log(value)
        setisGrid(value)
        dispatch(addCanvasGrid(value))
    }

    const handleShowPolygon=(value:boolean)=>{
        setisshowPolygon(value)
        dispatch(addShowNearestPolygon(value))
    }

    const handleShowPoint=(value:boolean)=>{
        setisshowPoint(value)
        dispatch(addShowNearestPoint(value))
    }

    const handleShowLine=(value:boolean)=>{
        setisshowLine(value)
        dispatch(addShowNearestLine(value))
    }   
  return (
    <>
     <div
          className=" rounded-circle brightness"
          onClick={handleCanvasOption}
        >
         <i className="bi bi-wrench-adjustable-circle-fill"></i>
        </div>

        

{isCanvasOption && (
    <div className="card display-mode-sec">
      <div className="card-body">
        

       
        <div className="d-flex align-items-center justify-content-between my-3">
          <span>Show Canvas Grid</span>
          <label className="showLabels relative border rounded-pill">
            <input
              type="checkbox"
              className="peer sr-only"
              checked={isGrid}
              onChange={(e) => handleGrid(e.target.checked)}
            />
            <div className="optionContainer"></div>
            <div
              className={`option ${
                isGrid ? "off" : "on"
              } text-primary`}
            >
              <i className="bi bi-eye"></i>
            </div>
            <div
              className={`option ${
                isGrid ? "on" : "off"
              } text-primary`}
            >
              <i className="bi bi-eye-slash"></i>
            </div>
          </label>
        </div>

        <div className="d-flex align-items-center justify-content-between my-3">
          <span>Show polygon on canvas</span>
          <label className="showLabels relative border rounded-pill">
            <input
              type="checkbox"
              className="peer sr-only"
              checked={isshowPolygon}
              onChange={(e) => handleShowPolygon(e.target.checked)}
            />
            <div className="optionContainer"></div>
            <div
              className={`option ${
                isshowPolygon ? "off" : "on"
              } text-primary`}
            >
              <i className="bi bi-eye"></i>
            </div>
            <div
              className={`option ${
                isshowPolygon ? "on" : "off"
              } text-primary`}
            >
              <i className="bi bi-eye-slash"></i>
            </div>
          </label>
        </div>


        <div className="d-flex align-items-center justify-content-between my-3">
          <span>Show line on canvas</span>
          <label className="showLabels relative border rounded-pill">
            <input
              type="checkbox"
              className="peer sr-only"
              checked={isshowLine}
              onChange={(e) => handleShowLine(e.target.checked)}
            />
            <div className="optionContainer"></div>
            <div
              className={`option ${
                isshowLine ? "off" : "on"
              } text-primary`}
            >
              <i className="bi bi-eye"></i>
            </div>
            <div
              className={`option ${
                isshowLine ? "on" : "off"
              } text-primary`}
            >
              <i className="bi bi-eye-slash"></i>
            </div>
          </label>
        </div>

        
      </div>
    </div>
  )}
        
    </>
  )
}

export default CanvasOption

{/* <i class="bi bi-wrench-adjustable-circle-fill"></i> */}