import React, { useEffect, useState } from 'react'
import { OptionJDetails } from '../../../../../Model/genAiAttributes/GenAiAttributes';
import { useSelector } from 'react-redux';
import { getGenAiAttributes } from '../../../../../slice/genAi/GenAiAttributesSlice';
import { GenAiAttributesModel } from '../../../../../Model/genAiAttributes/GenAiAttributes';
import { useNavigate } from 'react-router-dom';

const GenAiSideBarSegment = () => {

     const navigator = useNavigate( )   
    const [allGenAttrSegments, setAllGenAttrSegments] = useState<GenAiAttributesModel | null>(null);    
    const getGenAiAttributess= useSelector(getGenAiAttributes)

    useEffect(() => {
        if (getGenAiAttributess && getGenAiAttributess.length > 0) {
          const allAtt = getGenAiAttributess[0] as GenAiAttributesModel; // Explicitly cast to GenAiAttributesModel
        
          const segmentAttributes: GenAiAttributesModel = {};
    
          Object.keys(allAtt).forEach((key) => {
            const attribute = allAtt[key] as OptionJDetails; // Access attribute directly
             if (attribute.type === 'segment') {
              segmentAttributes[key] = attribute; // Correctly typed attribute
            }
          });
    
         
         setAllGenAttrSegments(segmentAttributes);
        }
      }, [getGenAiAttributess]);

      const handleSegment=(title: string)=>{
        console.log("title",title)
        navigator(`/dashboard-page/gen-ai/segment/${title}`);
      }
  return (
    <>
    <>
        <div>
        <i className="bx bx-chevron-down dropicon"></i>
              <ul className="submenu">
                {allGenAttrSegments &&
                  Object.keys(allGenAttrSegments).map((key, index) => {

                    return (
                        <li
                        // className={activeSubmenuIndex[3] === index + 1 ? "active" : ""}
                         onClick={() => handleSegment(allGenAttrSegments[key].key??"")}
                        >
                        {allGenAttrSegments[key].title}
                        </li>   
                    );
                  })}
                  
               
              
              </ul>

        </div>

            
           
    </>
    </>
  )
}

export default GenAiSideBarSegment