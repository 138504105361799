import { configureStore } from "@reduxjs/toolkit";
import UserReducer  from "../slice/user/UserSlice";

import UserProjectReducer from "../slice/user/UserProject"

import LoadingReducer from "../slice/loading/LoadingSlice"

import ApiModelDataReducer from "../slice/modelData/ModelDataSlice"

import MaterialReducer from "../slice/materialSlice/MaterialSlice"
import MaterialBrandReducer from "../slice/materialSlice/MaterialBrandSlice"
import MaterialcategoryReducer from "../slice/materialSlice/MaterialCategorySlice"
import MaterialStyleReducer from "../slice/materialSlice/MaterialStyleSlice"

import UserJobSlice from "../slice/userJobSlice/UserJobSlice";
import JobSlice from "../slice/Admin/JobSlice/JobSlice";
import userScreenResolution from "../slice/userScreenSlice/userScreenResolution";

import SegmentSlice from "../slice/segment/SegmentSlice";
import MasterArraySlice from "../slice/canvas/masterArray/MasterArraySlice";

import TabSlice from "../slice/tabControl/TabControlSlice"
import MediaSlice from "../slice/media/MediaSlice"


import SvgImageSlice from "../slice/svdImageSlice/SvgImageSlice";
import DimensionSlice from "../slice/canvas/masterArray/DimensionSlice";

import GroupSlice from "../slice/canvas/groupSlice.tsx/GroupSlice"
import CanvasControlSlice from "../slice/canvas/masterArray/CanvasControlSlice";

import ToastSlice from "../slice/messageToast/ToastSlice";

import SwatchOnCanvasSlice from "../slice/canvas/masterArray/SwatchOnCanvas";
import WebSocketSlice from "../slice/webSocketSlice/WebSocketSlice";

import GenAiAttributesSlice from "../slice/genAi/GenAiAttributesSlice";

import UpdateValueSlice from "../slice/canvas/updatevalue/UpdateValueSlice";
import SegregatedSlice from "../slice/segegratedSlice/SegregatedSlice";
import ReplaceSwatchSlice from "../slice/replaceSwatch/ReplaceSwatchSlice";

import ProjectCommentSlice from "../slice/projectCommet/ProjectCommentSlice";
import SamModelSlice from "../slice/samModel/SamModelSlice";

 
import SwatchesSlice from "../slice/swatches/SwatchSlices";

import ToolActiveSlice from "../slice/toolActive/ToolActiveSlice"; 

import ImageDepthSlice from "../slice/imageDepthSlice/ImageDepthSlice"; 
import ProjectSlice from "../slice/Admin/projectSlice/ProjectSlice";
import AllUSerSlice from "../slice/Admin/allUserSlice/AllUSerSlice";

import SearchMaterial from "../slice/materialSlice/SearchMaterial";
import SettingPathSlice from "../slice/settingPathSlice/SettingPathSlice";

import GenAiFeatureSlice from "../slice/genAi/featureSlice/GenAiFeatureSlice";
import userStore from "./UserStore";
import adminStore from "./AdminStore";

import CanvasOptionSlice from "../slice/canvas/canvasOption/CanvasOptionSlice";

import segDetectModelSlice from "../slice/segDetectModelSlice/SegDetectModelSlice";
const store = configureStore({
    reducer: {
       
        user: UserReducer,
        userProjects: UserProjectReducer,
        userJob: UserJobSlice,
        canvasDimension: DimensionSlice,
        segment: SegmentSlice,
        imageDepth: ImageDepthSlice,
        masterArray: MasterArraySlice,
        segregatedSegment: SegregatedSlice,
        svgImage: SvgImageSlice,
        groupSlice: GroupSlice,
        swatchOnCanvas: SwatchOnCanvasSlice,
        canvasControl: CanvasControlSlice,
        samModelSlice: SamModelSlice,

        genai_attributes: GenAiAttributesSlice,
        genAiFeature:GenAiFeatureSlice,

        replaceSwatch: ReplaceSwatchSlice,
        toastSlice: ToastSlice,
        loading: LoadingReducer,
        webSocket: WebSocketSlice,
        apiModel: ApiModelDataReducer,
        projectComment: ProjectCommentSlice,
        swatches: SwatchesSlice,
        toolActive: ToolActiveSlice,
        material: MaterialReducer,
        materialBrand: MaterialBrandReducer,
        materialCategory: MaterialcategoryReducer,
        materialStyle: MaterialStyleReducer,
        searchMaterial: SearchMaterial,
        jobs: JobSlice,
        projects: ProjectSlice,
        allUSers: AllUSerSlice,
        windowResolution: userScreenResolution,
        tabControl: TabSlice,
        media: MediaSlice,
        updateValue: UpdateValueSlice,

        settingPath: SettingPathSlice, // setting path slice

        canvasOption: CanvasOptionSlice,
        segDetectModel: segDetectModelSlice,
        
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export { userStore, adminStore };
