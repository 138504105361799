import React, { useEffect, useState } from "react";
import "../LeftSections.scss";
import { getMasterArray, updateCurrenSwatchActive } from "../../../../slice/canvas/masterArray/MasterArraySlice";
import { useDispatch, useSelector } from "react-redux";
import { Swatch } from "../../../../Model/Job/SamModel";

import { getSegregateSegment, updateSwatchData } from '../../../../slice/segegratedSlice/SegregatedSlice'
import { JobSegmentModel } from '../../../../Model/Job/JobModel'
import { AllreadyExistSwatchModel, SvgImageModel } from '../../../../Model/svgModel/SvgModel'
import { getUserLogin } from '../../../../slice/user/UserSlice'
import { Dropdown } from "react-bootstrap";
// import { updateAlreadyExistSwatch } from "../../../../slice/svdImageSlice/SvgImageSlice";
import DeleteCurrentSwatch from "./deleteCurrentSwatch/DeleteCurrentSwatch";
import { startLoading } from "../../../../slice/loading/LoadingSlice";
import OptionCurrentSwatch from "./optionCurrentSwatch/OptionCurrentSwatch";

const s3path = process.env.REACT_APP_S3BucketMaterial;

type Props = {
  groupName: string;
};
const CurrentSelectedSwatch = ({ groupName }: Props) => {
  const path = `${s3path}/liv/materials`;
  const [segratedSegment, setSegregatedSegment] = useState<JobSegmentModel[]>(
    []
  );
  const getMasterArrays = useSelector(getMasterArray);
  const getSegregateSegments = useSelector(getSegregateSegment);
  const [overallSwatch, setOverAllSwatch] = useState<Swatch[]>([]);
  const [userId, setUserId]= useState<number|null>()
  const getUserLogins= useSelector(getUserLogin)
  const dispatch= useDispatch()
  const [subGroupName, setSubGroupName]= useState<string|null>(null)
  useEffect(() => {
    if (getSegregateSegments &&
       getSegregateSegments.length > 0) {
      setSegregatedSegment(getSegregateSegments);
    }
  }, [getSegregateSegments]);

  // set userId
  useEffect(()=>{
    if(getUserLogins &&
       getUserLogins[0]?.id){
      setUserId( getUserLogins[0]?.id)
    }
  },[getUserLogins])



  useEffect(() => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0 &&
      groupName
    ) {
      
      const segGroup = getMasterArrays.allSeg.find(
        (item) => item.name == groupName
      );
      if (
        segGroup &&
        segGroup.overAllSwatch &&
        segGroup.overAllSwatch.length > 0
      ) {
        setOverAllSwatch(segGroup.overAllSwatch);
      } else {
        setOverAllSwatch([]);
      }
    } else {
      setOverAllSwatch([]);
    }
  }, [getMasterArrays, groupName]);


  const handleAllReadySwatch=(data:Swatch)=>{
    
      
        dispatch(startLoading())
     // update overAll swatch
   dispatch(updateCurrenSwatchActive({
    groupName:groupName,
    subGroupName:"",
    swatchId:data.swatch_id
   }))
   if(getMasterArrays &&
     getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length>0 ){
    const segGroup = getMasterArrays?.allSeg.find(
      (item) => item.name == groupName
    );
    let allVal:AllreadyExistSwatchModel[]=[]
      if(segGroup && 
        segGroup.segmentation &&
        segGroup.segmentation.length){
          segGroup.segmentation.map(item=>{
            Object.keys(item).forEach(grp=>{
              const subGrp= item[grp]
              if(subGrp && subGrp.length>0){
                subGrp.map(chd=>{
                  const segName=chd.details?.label
                  const annot= chd.details?.annotation;
                  const coord= chd.details?.bb_annotation_int;
                  const userSwatches= chd.designer?.filter(item=>item.user_id==userId)
                    if(userSwatches && userSwatches.length>0){
                     const designSwatch= userSwatches[0].swatch
                     if(designSwatch && designSwatch.length>0){
                        const swatchs= designSwatch.filter(item=>item.swatch_id==data.swatch_id)
                         if(swatchs && swatchs.length>0){
                          
                           const datas={
                            swatchUrl:swatchs[0].swatch_seg_image,
                              segname:segName??"",
                              annoatation:annot??[],
                              coOrdinate:coord??[],
                              swatchId:data.swatch_id,
                              isActive:!data.isActive
                            
                           }
                           allVal.push(datas)
                         }
                     }
                    }

                })
              }
            })
          })
        }
        if(allVal.length>0){

//  dispatch(updateAlreadyExistSwatch(allVal))

          // update in segregate slice
          dispatch(updateSwatchData({
            allSwatchs:allVal
          }))  
        }
   }
    

  }

  return (
    <>
      <div className="selected-items p-2">
        <h6>Current Selected Area</h6>
        <div className="row g-2">
          {overallSwatch && overallSwatch.length > 0 ? (
            overallSwatch.map((item, index) => {
              let isChecked = false;
              const swatchId = item.swatch_id;
              
              return (
                <div className="col-2" key={index}>
                  <div className="palletsbox">
                    {item.isActive && (
                      <span className="pallets-check"
                      key={swatchId}
                      >
                        {" "}
                        <i className="bi bi-check-lg"></i>{" "}
                      </span>
                    )}
                    <img src={`${path}/${item?.photo}`} alt="" 
                     onClick={()=>handleAllReadySwatch(item)}
                    />
                   
                      {/* delete button to delete current swatch */}
                     {item.swatch_id &&
                      <OptionCurrentSwatch
                      swatchId={item.swatch_id}
                      swatchData={item}
                      />}
                    
                  </div>
                </div>
              );
            })
          ) : (
            <div>No swatches available</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentSelectedSwatch;
