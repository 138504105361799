import React, { useEffect, useRef } from 'react'
import { GetAllS3Folder, GetS3BucketFolder } from '../../../../../api/folderApi/FolderApi'
import { useDispatch } from 'react-redux'
import { updateFolder } from '../../../../../slice/media/MediaSlice'


type Props={
    path:string
    resetFolder:(data:boolean)=>void
}
const GetFolder = ({path,resetFolder}:Props,) => {
    const dispatch= useDispatch()
    const isApi= useRef(true)
    useEffect(()=>{
        if(path!=null && isApi.current){
            isApi.current= false
            searchFolder(path) 
        }
    },[path])

    const searchFolder=async(path:string)=>{
        try{
  const response= await GetS3BucketFolder(path)
  
    if(response && response.status==200){
        const allData= response.data
        dispatch(updateFolder({
            allData:allData
        }))
    }
    resetFolder(true)
        }catch(err){
            console.log("err",err)
            resetFolder(false)
        }
    }
  return (
    <div>
        
    </div>
  )
}

export default GetFolder