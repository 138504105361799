import React, { useEffect, useRef } from 'react'

import { useDispatch } from 'react-redux'
import { uploadGenAiAttributeImages } from '../../../../../api/genAi/GenAiAPi'
import axios from 'axios'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'


type Props={
    attriType:string,
    imageName:string ,
    file:File,
    resetUploadS3:(url:string)=>void

}
const UploadImageS3 = ({attriType,imageName,file,resetUploadS3}:Props) => {

      const dispatch= useDispatch()
      const isApi= useRef(true)

      useEffect(()=>{
        if(attriType &&
            imageName &&
            file  &&
            isApi.current     
        ){
            isApi.current= false
            
            
            uploadS3Image(attriType,imageName,file)
        }
      },[attriType,imageName,file])

    const uploadS3Image=async(attriType:string,imageName:string,file:File)=>{
      const formData= new FormData;
      
      formData.append('image',file)
      formData.append('imageName',imageName)
      formData.append('attriType',attriType)

    try{
      const response = await uploadGenAiAttributeImages(formData)
       
          if(response && response.status===200){
             isApi.current= true
            
             resetUploadS3(response?.data)
          } 
    }catch(err){

      if(axios.isAxiosError(err)){
            
        dispatch(addMessage({
            isShow:true,
            mess: err.response?.data.message,
            toastType:"Error"
           }))
    }
        isApi.current= true
             resetUploadS3("")
           //  alert(" error in upload image s3")
    }

    }
  return (
    <>
    </>
  )
}

export default UploadImageS3