import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../../slice/messageToast/ToastSlice";
import {
  addWebSocketMessage,
  getWebSocketMessage,
  getWebSocketStatus,
  stopWebSocket,
} from "../../slice/webSocketSlice/WebSocketSlice";
import {
  addProjectPrompTbasedImage,
  fetchGenAiImage,
  getFetchGenAi,
  getGeneratedPrompt,
  getPromptValues,
  resetGeneratedPrompt,
  resetPrompValue,
  updateStartUploadGenAi,
} from "../../slice/genAi/GenAiAttributesSlice";

const StartWebSocket = () => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const pingCountRef = useRef<number>(0); // Ref to track ping count
  const maxPingCount = 10; // Max ping count before closing connection
  const dispatch = useDispatch();
  const lastMessageRef = useRef<string | null>(null);
  const [prompt, setPrompt] = useState<string | null>(null);
  const getWebSocketStatuss = useSelector(getWebSocketStatus);
  const getWebSocketMessages = useSelector(getWebSocketMessage);
  const getGeneratedPrompts = useSelector(getGeneratedPrompt);

  // set the promt value

  const isEmptyObject = (obj: object) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    if (getGeneratedPrompts && !isEmptyObject(getGeneratedPrompts)) {
      Object.keys(getGeneratedPrompts).forEach((key) => {
        const value = getGeneratedPrompts[key] as string;
        if (key == "prompt1") {
          setPrompt(value);
        }
      });
    } else {
      setPrompt(null);
    }
  }, [getGeneratedPrompts]);
  // Restart web socket if closed
  useEffect(() => {
    if (getWebSocketMessages === "WebSocket connection closed.") {
      setIsConnected(true);
    }
  }, [getWebSocketMessages]);

  // Start or stop connection based on status
  useEffect(() => {
    if (getWebSocketStatuss) {
      // console.log("wen socket slice value",getWebSocketStatuss)
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [getWebSocketStatuss]);

  // Main WebSocket logic
  useEffect(() => {
    console.log("socket", socket);
    // Automatically initiate the WebSocket connection when the component mounts
    if (!socket && isConnected) {
      const ws = new WebSocket(`wss://api.dzinly.org/ws`);
      console.log("ws ->", ws);

      ws.onopen = function () {
        console.log("WebSocket connection established.");
        const message = "WebSocket connection established.";
        setIsConnected(true);
        setError(null);
        dispatch(
          addMessage({
            isShow: true,

            mess: message,
            toastType: "success",
          })
        );
      };

      ws.onmessage = function (event) {
        console.log("event ---->", event);
        const triggerMessage = event.data;
        console.log("triggerMessage",triggerMessage)
        // Handle non-ping messages
        // if (triggerMessage !== lastMessageRef.current) {
        //   const parts = triggerMessage.split(": ");

        //   const key = parts[0];
        //   const value = parts[1];
        //   dispatch(
        //     addMessage({
        //       isShow: true,
        //       mess: key,
        //       toastType: "success",
        //     })
        //   );
        //   pingCountRef.current = 0; // Reset ping count on non-ping messages
        // }

       // lastMessageRef.current = triggerMessage;

        // console.log("Received message event triggered:", event.data);

        // Ping-Pong handling
        // if (triggerMessage === "ping") {
        //   pingCountRef.current += 1; // Increment the ping counter

        //   // Respond to ping with pong
        //   ws.send("pong");

        //   if (triggerMessage !== lastMessageRef.current) {
        //     dispatch(
        //       addMessage({
        //         isShow: true,
        //         mess: "Sent pong in response to server ping",
        //         toastType: "success",
        //       })
        //     );

        //     // Check if ping count reaches max and disconnect
        //     if (pingCountRef.current >= maxPingCount) {
        //       console.log(
        //         `Ping count reached ${maxPingCount}. Closing connection.`
        //       );
        //       setSocket(null);
        //       ws.close(); // Close WebSocket after 10 pings with no other interactions
        //     }
        //   }
        // }

        // after completing fetch data from s3 bucket
        // if (triggerMessage) {
        //   console.log("triggerMessage ", triggerMessage);

        //   const parts = triggerMessage.split(": ");
        //   //  console.log("parts ", parts);
        //   const key = parts[0]; // "Uploaded Canny image to S3. URL"
        //   const value = parts[1];
        //   console.log("key ", key);
        //   console.log("value ", value);
        //   if (key.includes("Uploaded")) {
        //     const segments = value.split("/");
        //     console.log("segments ", segments);
        //     const fileNameWithExtension = segments.pop();
        //     console.log("fileNameWithExtension ", fileNameWithExtension);
        //     const imageName = fileNameWithExtension.split(".")[0];
        //     console.log("imageName ", imageName);
        //     dispatch(
        //       addProjectPrompTbasedImage({
        //         prompt: prompt,
        //         imagePath: fileNameWithExtension,
        //         name: imageName,
        //       })
        //     );
        //   } else if (key == "Image generation process completed for prompt") {
        //     // console.log("now completed image generateion", key)

        //     dispatch(
        //       addMessage({
        //         isShow: true,
        //         mess: key,
        //         toastType: "success",
        //       })
        //     );

        //     dispatch(resetGeneratedPrompt());
        //     dispatch(updateStartUploadGenAi(true));
        //     dispatch(stopWebSocket());
        //   }
        // }
      };

      ws.onerror = function (err) {
        console.error("WebSocket error:", err);
        const message = "WebSocket error";
        dispatch(
          addMessage({
            isShow: true,
            mess: message,
            toastType: "Error",
          })
        );
        setError("WebSocket connection failed."); // Set error state
        setSocket(null);
        ws.close(); // Close the connection on error
      };

      ws.onclose = function () {
        console.log("WebSocket connection closed.");

        const mess = "WebSocket connection closed.";
        dispatch(addWebSocketMessage(mess));
        dispatch(
          addMessage({
            isShow: true,
            mess: mess,
            toastType: "success",
          })
        );

        dispatch(stopWebSocket());
        dispatch(fetchGenAiImage(true));
        setIsConnected(false);
        setSocket(null);

        console.log("WebSocket connection closed.");
      };

      setSocket(ws);
    }

    // Clean up on component unmount
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  return <></>;
};

export default StartWebSocket;

// if(axios.isAxiosError(err)){

//   dispatch(addMessage({
//       isShow:true,
//       mess: err.response?.data.message,
//       toastType:"Error"
//      }))
// }
