import React, { useEffect, useState } from 'react'
import { CatBrandStyleSwatchModel } from '../../../../Model/material/CatBrandStyleSwatchModel'
import DoorSwatch from './doorSwatch/DoorSwatch'

import { useDispatch, useSelector } from 'react-redux'
import WallSwatch from './wallSwatch/WallSwatch'
import { addWallSwatches, addDoorSwatches, getDoorSwatches, getGarageSwatches, getGutterSwatches, getRoofSwatches, getTrimSwatches, getWallSwatches, getWindowSwatches, getIsRefetchDoor, getIsRefetchWindow, getIsRefetchRoof, getIsRefetchGarage, getIsRefetchGutter, getIsRefetchWall, getIsRefetchTrim, updateRefetchDoor, updateRefetchWall, updateRefetchWindow, updateRefetchRoof, updateRefetchGarage, updateRefetchGutter, updateRefetchTrim } from '../../../../slice/swatches/SwatchSlices'
import WindowSwatch from './windowSwatch/WindowSwatch'
import RoofSwatch from './roofSwatch/RoofSwatch'
import GarageSwatch from './garageSwatch/GarageSwatch'
import GutterSwatch from './gutterSwatch/GutterSwatch'
import TrimSwatch from './trimSwatch/TrimSwatch'


const GetAllSwatchImage = () => {

    const doorSwatches= useSelector(getDoorSwatches)
    const windowSwatches= useSelector(getWindowSwatches)        
    const roofSwatches= useSelector(getRoofSwatches)
    const trimSwatches= useSelector(getTrimSwatches)
    const garageSwatches= useSelector(getGarageSwatches)
    const gutterSwatches= useSelector(getGutterSwatches)
    const wallSwatches= useSelector(getWallSwatches)
  
    const[isDoor, setIsDoor]=useState(false)
    const[isWindow, setIsWindow]=useState(false)
    const[isRoof, setIsRoof]=useState(false)
    const[isTrim, setIsTrim]=useState(false)
    const[isGarage, setIsGarage]=useState(false)
    const[isGutter, setIsGutter]=useState(false)
    const[isWall, setIsWall]=useState(false)
    const dispatch= useDispatch()


    const getIsRefetchDoors= useSelector(getIsRefetchDoor)
    const getIsRefetchWindows= useSelector(getIsRefetchWindow)
    const getIsRefetchRoofs= useSelector(getIsRefetchRoof)
    const getIsRefetchGarages= useSelector(getIsRefetchGarage)
    const getIsRefetchGutters= useSelector(getIsRefetchGutter)
    const getIsRefetchWalls= useSelector(getIsRefetchWall)
    const getIsRefetchTrims= useSelector(getIsRefetchTrim)


// fetching door swatches
    useEffect(()=>{
           if(getIsRefetchDoors){
            setIsDoor(true)
           }else{
            setIsDoor(false)
           }
    },[getIsRefetchDoors])


    // fetching window swatches
    useEffect(()=>{
        if(getIsRefetchWindows){
            setIsWindow(true)
        }else{
            setIsWindow(false)
        }
    },[getIsRefetchWindows])

    // fetching roof swatches
    useEffect(()=>{
        if(getIsRefetchRoofs){
            setIsRoof(true)
        }else{
            setIsRoof(false)
        }
    },[getIsRefetchRoofs])   



    // fetching trim swatches
    useEffect(()=>{
        if(getIsRefetchTrims){
            setIsTrim(true)
        }else{
            setIsTrim(false)
        }
    },[getIsRefetchTrims])  
    
    
    // fetching garage swatches
    useEffect(()=>{
        if(getIsRefetchGarages){
            setIsGarage(true)
        }else{
            setIsGarage(false)
        }
    },[getIsRefetchGarages])

    // fetching gutter swatches
    useEffect(()=>{
        if(getIsRefetchGutters){
            setIsGutter(true)
        }else{
            setIsGutter(false)
        }
    },[getIsRefetchGutters])
 

    // fetching wall swatches
    useEffect(()=>{
        if(getIsRefetchWalls ){
            setIsWall(true)
        }else{
            setIsWall(false)
        }
    },[getIsRefetchWalls])

  

  const handleDoorSwatches=()=>{
    dispatch(updateRefetchDoor(false))
    setIsDoor(false)
  }

  const handeWallSwatches=(data:CatBrandStyleSwatchModel[])=>{
    dispatch(addWallSwatches(data))
    setIsWall(false)
    dispatch(updateRefetchWall(false))
  }
  const handleWindowSwatches=()=>{
    setIsWindow(false)
    dispatch(updateRefetchWindow(false))
    }

    const handleRoofSwatches=()=>{
        setIsRoof(false)
        dispatch(updateRefetchRoof(false))
    }


    const handleGarageSwatches=()=>{    
        setIsGarage(false)
        dispatch(updateRefetchGarage(false))
    }

    const handleGutterSwatches=()=>{
        setIsGutter(false)
        dispatch(updateRefetchGutter(false))
    }



    const handleTrimSwatches=()=>{
        setIsTrim(false)
        dispatch(updateRefetchTrim(false))
    }
  return (
    <>
    


{isDoor &&
 <DoorSwatch
resetswatchDoor={handleDoorSwatches}
/>}

{isWall &&
 <WallSwatch
swatchWall={handeWallSwatches}
/>}

{isWindow &&
 <WindowSwatch
resetswatchDoor={handleWindowSwatches}
/>}

{isRoof &&
 <RoofSwatch
resetswatchRoof={handleRoofSwatches}
/>}

{ isGarage &&
 <GarageSwatch
resetswatchGarage={handleGarageSwatches}
/>}

{isGutter &&
 <GutterSwatch
resetswatchGutter={handleGutterSwatches}
/>      }


{isTrim &&
 <TrimSwatch
resetswatchTrim={handleTrimSwatches}
/>}

    </>
  )
}

export default GetAllSwatchImage