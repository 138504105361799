import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import ReAnnoatationModal from "../modal/ReAnnoatationModal";
import { getCanvasSize } from "../../../../slice/userScreenSlice/userScreenResolution";
import {
  getJobBase64,
  getSelectedSegment,
  getUserJobBase64,
} from "../../../../slice/userJobSlice/UserJobSlice";
import {
  getMasterArray,
  getReAnnotationSegment,
  updateAnnotationPoint,
} from "../../../../slice/canvas/masterArray/MasterArraySlice";
import MagnifierCanvas from "./MagnifierCanvas";
import { PointModel } from "../../../../Model/masterArray/MasterArrayModel";
import "./Mangnifier.css";
import {
  startLoading,
  stopLoading,
} from "../../../../slice/loading/LoadingSlice";
import { StartUpateMasterArrayModel } from "../../../../Model/masterArray/UpdateIndexModel";
import {
  changeSegmentTab,
  switchToOutline,
} from "../../../../slice/tabControl/TabControlSlice";
import {
  getSegregateSegment,
  updateAnnoatationInSegegtated,
} from "../../../../slice/segegratedSlice/SegregatedSlice";
import AnnotationUpdateApi from "../../masterDataAnotationApi/AnnotationUpdateApi";
import { SegmentDetails } from "../../../../Model/Job/SamModel";
import {
  getToolActiveNumber,
  UpdateToolActive,
} from "../../../../slice/toolActive/ToolActiveSlice";

import { JobSegmentModel, Segment } from "../../../../Model/Job/JobModel";
import { getAllSegment } from "../../../../slice/segment/SegmentSlice";
import { is } from "@react-three/fiber/dist/declarations/src/core/utils";
import { all } from "axios";
import { Points } from "@react-three/drei";
import { addApiMessage } from "../../../../slice/messageToast/ToastSlice";
import { CanvasImage } from "../../canvasUtil/SetImageAsBackground";
import { NewCollectPoints } from "../../canvasUtil/addSegmentCanvasUtil/CreatePolygonHover";
import { checkMouseInsidePolygon } from "../../canvasUtil/CheckMousePointInsidePolygon";

import { hideAllPolygon, hideAllPolygonsExcept, hideReAnnotatedPolygon, showPolygon } from "../../canvasUtil/addSegmentCanvasUtil/ShowAnnotationNewSegment";
import { drawLines, RemoveGridLine } from "../../canvasUtil/addSegmentCanvasUtil/DrawMouseLine";
import { collectPoints } from "../../canvasUtil/CreatePolygon";
import { getCanvasGrid, getMouseTolerance, getShowNearestLine, getShowNearestPoint, getShowNearestPolygon, getZoomCanvas, resetZoom, updateZoomValue } from "../../../../slice/canvas/canvasOption/CanvasOptionSlice";
import { getCachedBase64Image } from "../../canvasUtil/ConvertImageToBAse64";
import { ZoomCanvas } from "../../canvasUtil/ZoomCanvas";

interface CustomCircleOptions extends fabric.ICircleOptions {
  id?: number;
  className?: string;
}




interface NearestLine{
  start: fabric.Point;
  end: fabric.Point;
}
const ReAnnotationCanvas = () => {
  const navigate = useNavigate();
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const canvasElementRef = useRef<HTMLCanvasElement | null>(null);
   const containerRef = useRef(null);
  const getUserJobBase64s = useSelector(getUserJobBase64);
  const [scaleX, setScalex] = useState<number | null | undefined>();
  const [scaleY, setScaley] = useState<number | null | undefined>();

  const getMasterArrays = useSelector(getMasterArray);
  const getSegregateSegments = useSelector(getSegregateSegment);
  const getReAnnotationSegments = useSelector(getReAnnotationSegment);

  const [allpoints, setAllPoints] = useState<number[]>([]);
  const allPointsRef = useRef<number[]>([]);
  const [lastPOintId, setLastPOintId] = useState<number[]>([]);
  const lastPOintIdRef = useRef<number[]>([]);
  const [top, setTop] = useState<number | null | undefined>();
  const [left, setLeft] = useState<number | null | undefined>();
  const [isShowDistance, setIsShowDistance] = useState<boolean>(false);
  const [mousePoint, setMousePoint] = useState<number[]>([]);
  const isEditPolyname = useRef<string | null>(null);
 
  const [segName, setsegName] = useState<string | null>(null);
  const [segShortName, setSegShortName] = useState<string | null>(null);
  const [groupName, setGroupName] = useState<string | null>(null);
  const [childGroupName, setChildGroupName] = useState<string | null>(null);
  const getSelectedSegments = useSelector(getSelectedSegment);
  const getToolActiveNumbers = useSelector(getToolActiveNumber);
  const getAllSegments = useSelector(getAllSegment);

  const getCanvasGrids = useSelector(getCanvasGrid);
  const getShowNearestPolygons = useSelector(getShowNearestPolygon);

  const getShowNearestPoints = useSelector(getShowNearestPoint);
  
  const getShowNearestLines = useSelector(getShowNearestLine);

  const getMouseTolerances= useSelector(getMouseTolerance);
 
   const getZoomCanvass= useSelector(getZoomCanvas);
  const isCanvasGrid = useRef(true);
  const isShowPolygon = useRef(true);
  const isShowPoint = useRef(true);

  useEffect(() => {
    isFirst.current = true;
  },[])

  //set the show grid

  useEffect(() => {
    if(getCanvasGrids){
      isCanvasGrid.current=true
    }else{
      isCanvasGrid.current=false
    }
  },[getCanvasGrids])

  // set the show nearest polygon
  useEffect(() => {
    if(getShowNearestPolygons){
      isShowPolygon.current=true
    }else{
      isShowPolygon.current=false
    }
  },[getShowNearestPolygons])


  const isApi = useRef(true);
  let polygonMode: boolean = true;
  const dispatch = useDispatch();
  let pointArray: { x: number; y: number }[] = [];
  let lineArray: fabric.Line[] = new Array();
  const isFillPolygon = useRef<boolean>(false);

  //let activeLine: fabric.Line | undefined|null;
  let activeLine: fabric.Line | null = null;
  const lineArrays: fabric.Line[] = [];
  const pointArrays: fabric.Circle[] = [];
  let hoverCircle: fabric.Circle | null = null;
  let activeShape: fabric.Polygon | undefined = undefined;
  const [jobId, setJobId] = useState<number | null>(null);
  const currentPolygon = useRef<string|undefined>("")
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isUpdatemasterdata, setIsUpdatemasterdata] = useState<boolean>(false);
  const [annotation, setAnnotation] = useState<number[]>([]);
  const [currentDistance, setCurrentDistance] = useState<number>(0);
  const getJobBase64s= useSelector(getJobBase64)

  const COLORS = {
    startPoint: "#FF0000", // Red for the start point
    edgePoint: "#FF1493", // Deep pink for edge points
    edgeCircle: "#FF1493", // Pink circle for edge points
    snapCircle: "#000000", // Black for the snap circle
    suggestCircle: "#FF6347", // Tomato for suggestive points
    polygonFill: "rgba(0, 255, 0, 0.3)",
    polygonStroke: "#008000", // Green for the polygon's border
    verticePoint: "#FFFF00", // Yellow for VerticePoints
    verticeCircle: "#FFFF00", // Yellow circle for VerticePoints
    closedVerticePoint: "#0000FF", // Blue for closed vertices
    closedVerticeCircle: "#0000FF", // Blue circle for closed vertices
  };
  let min = 99;
  let max = 999999;
      
    const isPolygon= useRef(true);
    useEffect(()=>{
      isPolygon.current=true
    },[])

  // update the first point
  const isFirst = useRef(true);
  useEffect(() => {
    if (
      allpoints &&
      allpoints.length > 0 &&
      canvasRef.current &&
      isFirst.current &&
      segName
    ) {
      
      isFirst.current = false;
    
      allPointsRef.current = allpoints;
     
      const circle = new fabric.Circle({
        name: "firstpoint",
        radius: 3,
        fill: "transparent",
        stroke: "black",
        strokeWidth: 2,
        left: allpoints[0],
        top: allpoints[1],
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: "center",
        originY: "center",
        visible: true,
      } as CustomCircleOptions);

      const hoverCircle = new fabric.Circle({
        name: "finalPoint",
        radius: 15,
        fill: "transparent",
        stroke: COLORS.startPoint,
        strokeWidth: 1,
        left: allpoints[0]-15,
        top: allpoints[1]-15,
        selectable: false,
        hasBorders: false,
        hasControls: false,
       
        visible: true,
        strokeDashArray: [5, 5],
      } as CustomCircleOptions);
      canvasRef.current.add(hoverCircle);
      canvasRef.current.add(circle);

      canvasRef.current.renderAll();
      hideReAnnotatedPolygon(canvasRef,segName)
     // canvasRef.current.hoverCursor = "none";
    }
  }, [allpoints, canvasRef,segName]);


  // update id  wrt to ref
  useEffect(() => {
    if (lastPOintId && lastPOintId.length > 0) {
      lastPOintIdRef.current = lastPOintId;
    }
  }, [lastPOintId]);

  // get the new segment name
  useEffect(() => {
    if (getSelectedSegments && getSelectedSegments.length > 0) {
      const selected = getSelectedSegments[0];
      const grpseg = selected.details;
      if (
        grpseg &&
        grpseg.seg_short &&
        grpseg.label &&
        grpseg.seg_type &&
        grpseg.group
      ) {
        setSegShortName(grpseg.seg_short);
        setsegName(grpseg.label);
        setGroupName(grpseg.seg_type);
        setChildGroupName(grpseg.group);
        isEditPolyname.current = grpseg.label;

      }
    }
  }, [getSelectedSegments]);

     // Adjust canvas dimensions to fit the container
       const resizeCanvas = (fabricCanvas: fabric.Canvas) => {
         const container = containerRef.current;
         if (container) {
           const { offsetWidth, offsetHeight } = container;
     
           fabricCanvas.setWidth(offsetWidth);
           fabricCanvas.setHeight(offsetHeight);
           fabricCanvas.renderAll();



              // Adjust image dimensions
      const backgroundImage = fabricCanvas.backgroundImage as fabric.Image;
      if (backgroundImage) {
        backgroundImage.scaleToWidth(offsetWidth);
        backgroundImage.scaleToHeight(offsetHeight);
        fabricCanvas.requestRenderAll();
      }

         }
       };

  // create canvas
  const isCanvas = useRef(true);
  const MIN_SNAP_DISTANCE = 15; // Minimum distance to snap to points
  const MAX_SUGGEST_DISTANCE = MIN_SNAP_DISTANCE * 2; // Maximum distance for edge points suggestions
  const verticePoints = useRef<fabric.Circle[]>([]); // Array to store VerticePoint objects
  const verticeRange = useRef<fabric.Circle[]>([]); // Array to store VerticePoint objects
  useEffect(() => {
    if (canvasElementRef.current &&
       isCanvas.current) {
      isCanvas.current = false;
      // Initialize Fabric canvas
      const rightSection = document.querySelector(
        ".canvas-right"
      ) as HTMLElement;
      const canvas = new fabric.Canvas(canvasElementRef.current, {
        backgroundColor: "#f0f0f0", // Canvas background
      });

      const dimension = new fabric.Group([], {
        selectable: false,
        name: "dimension",
        visible: true,
        hasBorders: false,
        hasControls: false,
      });
     
 
        // Set initial canvas size
       resizeCanvas(canvas);
      canvas.add(dimension);

       // Set custom cursor
       const customCursor = createCustomCursor();
       canvas.defaultCursor = `url(${customCursor})18 18, crosshair`;
       canvas.hoverCursor = `url(${customCursor})18 18, crosshair`;
       canvas.moveCursor = `url(${customCursor})18 18, crosshair`;
    canvas.selection = false;

     
      canvas.requestRenderAll();
      canvasRef.current = canvas;
      canvas.on("mouse:down", (event) => {
        // console.log("event",event)
        handleMouseDown(event);
      });

      canvas.on("mouse:up", (event) => {
        handleMouseUp(event);
      });
      canvas.on("mouse:move", (event) => {
        handleMouseMove(event); 
      
        
      });
      canvas.on("mouse:over", (event) => {
        handleMouseOver(event);
      });

      canvas.on("mouse:wheel", (event) => {
        handleMouseWheel(event);
      });
      //  canvas.on('mouse:out', (event) => {
      //   handleMouseOut(event); // Add mouse move event to show the dynamic point/line
      // });
      window.addEventListener('keydown',(event)=>{handleKeyDown(event)});



      return () => {
        // Clean up the canvas when the component unmounts
        if (canvasRef.current) {
          canvasRef.current.off("mouse:down", handleMouseDown);
          canvasRef.current.off("mouse:up", handleMouseUp);
          canvasRef.current.off("mouse:move", handleMouseMove);
          canvasRef.current.off("mouse:wheel", handleMouseWheel);
          window.addEventListener('keydown',(event)=>{handleKeyDown(event)});


        }
      };
    } else {
      isCanvas.current = true;
    }
  }, []);


  const createCustomCursor = () => {
    const cursorCanvas = document.createElement('canvas');
    cursorCanvas.width = 40;
    cursorCanvas.height = 40;
    const ctx = cursorCanvas.getContext('2d');

    if (ctx) {
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.lineWidth = 2;
     ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
      // Draw horizontal line
      ctx.beginPath();
      ctx.moveTo(0, 20);
      ctx.lineTo(40, 20);
      ctx.stroke();

      // Draw vertical line
      ctx.beginPath();
      ctx.moveTo(20, 0);
      ctx.lineTo(20, 40);
      ctx.stroke();
    }

    return cursorCanvas.toDataURL('image/png');
  };

    // update all point on canavs
     // taking data from segregated data to make polygon
      useEffect(() => {
        if (
          canvasRef.current &&
          getSegregateSegments &&
          getSegregateSegments.length > 0  &&
          scaleX &&
          scaleY  &&
          segName &&
          getSegregateSegments.length > 0    
        ) {
          // dispatch(stopSegregation());
          let editPolyName=segName

             isPolygon.current=false
          getSegregateSegments.forEach((segmentModel: JobSegmentModel) => {
            // Iterate through each key in the JobSegmentModel object
            Object.keys(segmentModel).forEach((key) => {
              const jobDetail = segmentModel[key] as Segment; // Access Segment by key
              const annotation = jobDetail?.details?.annotation;
              const cordinate = jobDetail?.details?.bb_annotation_int;
              const segName = key;
              const textName = jobDetail?.details?.seg_short ?? "";
              const groupName = jobDetail?.details?.seg_type ?? "";
              const subGroupName = jobDetail?.details?.group ?? "";
              const childName = jobDetail?.details?.seg_short ?? "";
              const segColor = getAllSegments.find(
                (item) => item.name === groupName
              );
              const color = segColor?.color_code;
    
              if (annotation && cordinate && segName && color) {
                NewCollectPoints(
                   annotation,
                  segName,
                  cordinate,
                  groupName,
                  subGroupName,
                  childName,
                  textName,
                  color,
                  scaleX,
                  scaleY,
                  canvasRef,
                  editPolyName,
                  )

              }
            });
          });
        }
      }, [canvasRef.current, getSegregateSegments, scaleX, scaleY,segName]);
    
      const handleKeyDown=(event: KeyboardEvent)=>{
        //  console.log("event constrol zzz",event)
          if (event.ctrlKey && event.key === 'z') {
           // console.log("undo")
            undoLastAction();
          }
        }
      
  

  useEffect(() => {
    if (mousePoint.length > 0 &&
       allpoints && 
       allpoints.length == 0) {
      setIsShowDistance(true);
      showDistanceCordinate([], mousePoint, false);
    } else if (
      allpoints &&
      allpoints.length > 0 &&
      mousePoint &&
      mousePoint.length > 0
    ) {
      const pointLength = allpoints.length;
      const points = [allpoints[pointLength - 2], allpoints[pointLength - 1]];
      showDistanceCordinate(points, mousePoint, true);
    }
  }, [allpoints, mousePoint, canvasRef.current, isShowDistance]);

  // handle mouse move
  const handleMouseMove = (options: fabric.IEvent) => {
    // console.log("canvasref.cuu", canvasRef.current)
   
    showMagnifier(options)
    const pointerDraws = options.pointer;
    const pointerCanvas = canvasRef.current?.getPointer(options.e);


    const pointer = options.pointer;

    handleZoom(options);
    if (
      pointerDraws &&
      pointerCanvas &&
      pointer &&
      canvasRef.current &&
      canvasRef.current.width &&
      canvasRef.current?.height &&
      pointerCanvas
    ) {
    
      showDrawLine(pointerCanvas);
     
      setMousePoint([pointerDraws?.x ?? 0, pointerDraws?.y ?? 0]);


     
          const fabricPoint = new fabric.Point(pointerCanvas.x, pointerCanvas.y);
          //hide all show all dotted polygon
          if(isShowPolygon.current){
            if(fabricPoint){
              checkMouseInsidePolygon(fabricPoint, canvasRef, false, hideAnnotation, showAnnotation, isFillPolygon); 
  
             }
          }else{
            hideAllPolygon(canvasRef, segName??"");
          }
           
       
     
    }
  };


  const showDrawLine = (pointerDraws: PointModel) => {
   // console.log("isCanvasGrid",isCanvasGrid)
    if(isCanvasGrid.current){
     // console.log("isCanvasGrid---->",isCanvasGrid)
      drawLines(pointerDraws.x ?? 0, pointerDraws.y ?? 0, canvasRef);
    }else{
      RemoveGridLine(canvasRef);
    }
  
  }
  const hideAnnotation=(segName:string)=>{
    hideAllPolygonsExcept(segName, canvasRef,isEditPolyname)
    
  }

  const isColor = useRef(false);
  const showAnnotation=(segName:string,color:string, pointer:fabric.Point)=>{
    const mousePoint = [pointer.x, pointer.y];
    showPolygon(canvasRef,segName,mousePoint[0],mousePoint[1],isEditPolyname)
  }

  const showDistanceCordinate = (
    allpoints: number[],
    mousePoint: number[],
    isShowDistance: boolean
  ) => {
    if (canvasRef.current && mousePoint) {
      const canvasWidth= canvasRef.current.width ?? 0;

      const canvasObjects = canvasRef.current.getObjects();
      if (canvasObjects) {

        // Find the "dimension" group (if necessary)
        const matchingGroup = canvasObjects.find(
          (grp) => grp.name === "dimension"
        ) as fabric.Group | undefined;
        let existingText = canvasRef.current
          .getObjects()
          .find((obj) => obj.name === "showDim") as fabric.Text | undefined;

        // If the text doesn't exist, create it and add it to the canvas
        if (!existingText) {
          existingText = new fabric.Text(``, {
            left: canvasWidth-400, // Adjust position to avoid cursor
            top: 10, // Adjust position to avoid cursor // Adjust position to avoid cursor
            name: "showDim",
            fontFamily: "Arial",
            fontSize: 12, // Smaller font size for crisp appearance
            fill: "white", // Text color
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black background with slight opacity
            selectable: false, // Disable selection of the text
            evented: false, // Ensure text is not selectable
            visible: true,
          });

          // Add the new text to the canvas
          canvasRef.current.add(existingText);
          canvasRef.current.requestRenderAll();
        }

        // Update the text content and position dynamically
        if (!isShowDistance) {
          existingText.set({
            left: canvasWidth-400, // Adjust position to avoid cursor
            top: 10, // Adjust position to avoid cursor
            text: `x:(${mousePoint[0].toFixed(2)}, y:${mousePoint[1].toFixed(2)})\ndistance: 0`, 
          });
        } else if (isShowDistance) {
          const distanceX = allpoints[0] - mousePoint[0];
          const distanceY = allpoints[1] - mousePoint[1];
          const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
          existingText.set({
            left: canvasWidth-400, // Adjust position to avoid cursor
            top: 10, // Adjust position to avoid cursor
            text: `x:(${mousePoint[0].toFixed(2)}, y:${mousePoint[1].toFixed(2)})\ndistance: ${distance}`,
          });
        }

        // Update the canvas rendering after modifying the text
        existingText.setCoords(); // Ensure the internal coordinates are updated

        // Ensure the text object is brought to the front
        canvasRef.current.bringToFront(existingText);
        canvasRef.current.requestRenderAll(); // Render the canvas
      }
    }
  };

  // ad image on canavas
  useEffect(() => {
    if (getJobBase64s &&
      getJobBase64s.imagePath && 
      canvasRef.current) {
       
        const base64Image= getCachedBase64Image(getJobBase64s.imagePath )
      
         if(base64Image){
           
           CanvasImage(base64Image, canvasRef, setScalex, setScaley, setTop, setLeft);
         }
    }
  }, [getJobBase64s]);

  const handleMouseDown = (options: fabric.IEvent) => {

    const zoom = canvasRef.current?.getZoom() ?? 1;
   
    let mouseP: { x: number; y: number } = { x: 0, y: 0 };
    if(zoom!=1  &&
       options.absolutePointer &&
       options.pointer){
      mouseP = {
        x: (options.absolutePointer.x ?? 0) ,
        y: (options.absolutePointer.y ?? 0)
      }
    }else if(options.pointer){
      mouseP = {
        x: (options?.pointer.x ?? 0) ,
        y: (options?.pointer.y ?? 0) 
      }
    }
  
    const pointer = canvasRef.current?.getPointer(options.e);
    const existingLines = canvasRef?.current?.getObjects('line').filter(line => line.name === 'highlightLine');
   const existingImaginaryCircle = canvasRef.current?.getObjects('circle').filter(circle => circle.name === 'imaginaryPoint');
    if (pointer && allPointsRef.current && allPointsRef.current.length > 0) {
      const point1 = allPointsRef.current[0];
      const point2 = allPointsRef.current[1];

      const top = pointer.y;
      const left = pointer.x;

      const tolerance = 10; // Adjust this tolerance value as needed

      if (
        Math.abs(top - point2) < tolerance &&
        Math.abs(point1 - left) < tolerance
      ) {
        setIsShowModal(true);
        polygonMode = false;
        
      }
    }

    if(pointer &&
      existingImaginaryCircle &&
      existingLines &&
       existingImaginaryCircle.length>0  &&
       canvasRef.current&&
       isShowPolygon.current 
       
     ){
      
      const existingPoint=existingImaginaryCircle[0]
      const existingPointValue = {
       x: (existingPoint.left ?? 0) ,
       y: (existingPoint.top ?? 0) 
     };
    
    
       addPoint(existingPointValue, currentDistance,options);
   }
  else if (polygonMode &&
    canvasRef.current && mouseP) {
     
     addPoint(mouseP, currentDistance,options);

    }
  };
 const zoomRef = useRef<number|null>(null);
  const handleMouseWheel = (event: fabric.IEvent) => {
    //console.log("mouse Wheel",event)
    const deltaE = event.e as WheelEvent;
   
    if (deltaE && canvasRef.current) {
      const delta = deltaE.deltaY;
      let zoom = canvasRef.current.getZoom();

      zoom *= 0.999 ** delta;

      if (zoom > 20) zoom = 20; // Set maximum zoom level
      if (zoom < 0.01) zoom = 0.01; // Set minimum zoom level

      ZoomCanvas(canvasRef, zoom,canvasZoom);
      // const center = canvasRef.current.getCenter();
      // canvasRef.current.zoomToPoint(
      //   { x: center.left, y: center.top },
      //   zoom
      // );
     // console.log("zoom",zoom)
      // zoomRef.current = zoom;
      // deltaE.preventDefault();
      // deltaE.stopPropagation();
      // Adjust annotation points based on zoom level
     // adjustAnnotationPoints(zoom);
    }
  };
// update on tool zoom
  const canvasZoom=(data:number)=>{
    const value = Math.floor(data * 100);;

    dispatch(updateZoomValue(value))
  }

  // handle zoom through the icon
  useEffect(() => {
    if(getZoomCanvass!=0 && 
      canvasRef.current){

      ZoomCanvas(canvasRef, getZoomCanvass,canvasZoom);
    }
  },[getZoomCanvass,canvasRef])


  


  const handleMouseUp = (options: fabric.IEvent) => {
    drawPolygon();
  };
  const [previousPolygon, setPreviousPolygon] = useState<fabric.Group | null>(null);

  const handleMouseOver = (options: fabric.IEvent) => {
   
    const points= options.e as MouseEvent
    const pointX= points.offsetX
    const pointY= points.offsetY
    const targetPoint={
      x:pointX,
      y:pointY
    }

    const targetnames = options.target?.name;

    if (targetnames && points) {
      currentPolygon.current=targetnames
     ;
      toggleVerticeVisibility(targetPoint, targetnames);
    }
  };

 


 

  // Handle mouse out to remove the hover circle
  const handleMouseOut = (options: fabric.IEvent) => {
    // Remove the hover circle if it exists
    if (hoverCircle && canvasRef.current) {
      canvasRef.current.remove(hoverCircle);
      hoverCircle = null; // Reset the hoverCircle
      canvasRef.current.renderAll();
    }
  };

  const drawPolygon = () => {
    polygonMode = true;
  };


  const addPoint = (options: PointModel,currentDistance:number, optionfs:fabric.IEvent) => {
    
   // console.log("corrected mouse point",options)
  let currentPoint = options;
    if (!options) return;
  
    const random = Math.floor(Math.random() * (max - min + 1)) + min;
    const id = new Date().getTime() + random;
    setLastPOintId((prev) => [...prev, id]);
    const circle = new fabric.Circle({
      radius: 1,
      fill: "yellow",
      stroke: "rgb(255, 1, 154)",
      strokeWidth: 3,
      id: id,
      className: "point",
      left: currentPoint!.x-1,
      top: currentPoint!.y-1,
     name:"polygonPoint",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      visible:true
    } as CustomCircleOptions);

    if (!circle.left || !circle.top) return;

    if (pointArrays.length === 0) {
      circle.set({ fill: "#000000" }); // First point is black
    } else {
      circle.set({ fill: "#ff0000" }); // Other points can be a different color (e.g., red)
    }
    pointArray.push({ x: circle.left, y: circle.top });
    const points: [number, number, number, number] = [
      circle.left+3,
      circle.top+3,
      circle.left+3,
      circle.top+3,

    ];

    if (activeShape) {
      canvasRef.current?.remove(activeShape);
      const pos = canvasRef.current?.getPointer(optionfs.e);

      if (pos) {
        const points = activeShape?.get("points") ?? [];
        points.push(new fabric.Point(pos.x, pos.y)); // Use fabric.Point
        activeShape.set({ points: points });
        var polygon = new fabric.Polygon(points, {
          stroke: "rgb(7 239 253)",
          strokeWidth: 2,
          fill: "transparent",
          opacity: 0.9,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          name: "polygon",
        });
        canvasRef.current?.remove(activeShape);
        canvasRef.current?.add(polygon);

        activeShape = polygon;

        canvasRef.current?.renderAll();
      }
    } else {
      const x = currentPoint?.x ?? 0; // Ensure x is a number, use 0 if undefined
      const y = currentPoint?.y ?? 0; // Ensure y is a number, use 0 if undefined
      const newPolyPoints = [{ x, y }];
      const polygon = new fabric.Polygon(newPolyPoints, {
        stroke: "#333333",
        strokeWidth: 1,
        fill: "#cccccc",
        opacity: 0.3,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        name: "poly",
      });
      activeShape = polygon;
      canvasRef.current?.add(polygon);
    }
    // activeLine = line;
    // console.log("poinst",points)
    setAllPoints((prev) => [...prev, points[0], points[1]]);
    pointArrays.push(circle);
    // lineArray.push(line);
    //canvasRef.current?.add(line);
    canvasRef.current?.add(circle);
    //console.log("canvasRef.current====>",canvasRef.current)
  };

  const [isCompletedPolygon, setIsCompletedPolygon] = useState(false);

  const handleCloseModal = () => {
    isFirst.current = true;
    polygonMode = true;
    setAllPoints([]);
    setLastPOintId([])
    allPointsRef.current = [];
    lastPOintIdRef.current = [];
    activeShape = undefined;
    pointArray = [];
    setIsShowModal(false);
    //console.log("canRef.current--->",canvasRef.current)
    const getAllObjects = canvasRef.current?.getObjects();  
    //console.log("canRef.current--->",getAllObjects)
    if (getAllObjects) {
      getAllObjects.forEach((item) => {
        //console.log("item", item);
  
        if (
          item.name === "polygonPoint" ||
          item.name === "polygon" ||
          item.name === "finalPoint" ||
          item.name === "firstpoint"
        ) {
          canvasRef.current?.remove(item);
        }
      });
      canvasRef.current?.requestRenderAll();
    }
    
    dispatch(switchToOutline("segment"));
    dispatch(changeSegmentTab("segment"));
   // console.log("canRef.current--->", getAllObjects);
    
  };



  const handleOk = () => {
    setIsShowModal(false);
    dispatch(addApiMessage("updating annotation point"));
     isFirst.current = true;
    // setAllReAnnoatationPoints.push(pointArrays)
    convertToBoundingBox(allpoints);
  };

  function convertToBoundingBox(flattenedPoints: number[]) {
    if (scaleX && scaleY) {
      let newFlattenedPoints = [];
      const points = flattenedPoints;

      for (let i = 0; i < points.length; i += 2) {
        const x = points[i] / (scaleX ?? 1);
        const y = points[i + 1] / (scaleY ?? 1);
        newFlattenedPoints.push(
          parseFloat(x.toFixed(2)),
          parseFloat(y.toFixed(2))
        );
      }

      if (
        newFlattenedPoints.length > 0 &&
        segName &&
        childGroupName &&
        segShortName &&
        groupName
      ) {
        setIsUpdatemasterdata(true);
        setAnnotation(newFlattenedPoints);
      }
    }
  }

  useEffect(() => {
    if (getMasterArrays && getMasterArrays.jobId) {
      setJobId(getMasterArrays.jobId);
    } else {
      setJobId(null);
    }
  }, [getMasterArrays]);


  const handlehoverLayer = () => {
    dispatch(switchToOutline("segment"));
    dispatch(changeSegmentTab("segment"));
    
  };

  // add maginier kens
  const handleZoom = (options: fabric.IEvent) => {
    const canvas = canvasRef.current; // Store the reference in a variable
    if (!canvas || canvas.width === undefined || canvas.height === undefined)
      return; // Check if canvasRef.current is null or dimensions are undefined

    const pointer = canvas.getPointer(options.e);

    const magnifierSize = 100; // Size of the magnifier area

    const existingMagnifier = canvas.getObjects().find(obj => obj.name === "magnifier");
    if (existingMagnifier) {
      canvas.remove(existingMagnifier);
    }
    const magnifiedImage = new fabric.Image(canvas.toDataURL(), {
      left: pointer.x - magnifierSize / 2,
      top: pointer.y - magnifierSize / 2,
      scaleX: magnifierSize / canvas.width,
      scaleY: magnifierSize / canvas.height,
      selectable: false,
      evented: false,
      name: "magnifier",
    });

    canvas.add(magnifiedImage);
    canvas.renderAll();
  };

  const handleResetAnotation = (data: SegmentDetails) => {
    setAnnotation([]);
    setIsUpdatemasterdata(false);
    dispatch(stopLoading());

    const masterValue = {
      segName: segName,
      childgroupName: childGroupName,
      seg_short: segShortName,
      groupName: groupName,
      masterData: data,
    };

    dispatch(startLoading());
    // update in segretaed  Array
    dispatch(updateAnnoatationInSegegtated(masterValue));

    dispatch(switchToOutline("segment"));
    dispatch(changeSegmentTab("segment"));
  };
  
  const handleFailResetAnnotation = () => {
    setAnnotation([]);
    setIsUpdatemasterdata(false);
    dispatch(stopLoading());
    dispatch(resetZoom());  // res zoom value
    disposeCanvas()
    dispatch(switchToOutline("segment"));
    dispatch(changeSegmentTab("segment"));
  };

  //  call Undo from tool
  useEffect(() => {
    if (getToolActiveNumbers == 3) {
     // console.log("called undo");
      undoLastAction();
      dispatch(UpdateToolActive(0));
    }
  }, [getToolActiveNumbers]);

  // undo Action
  const undoLastAction = () => {
    const idLength = lastPOintIdRef.current.length;
    if (
      allpoints.length > 0 &&
      canvasRef.current &&
      lastPOintIdRef.current &&
      idLength
    ) {
      const getPolygonObject = canvasRef.current
        .getObjects()
        .filter((item) => item.name === "polygon") as fabric.Polygon[];
      const getLastPoint = canvasRef.current
        .getObjects()
        .filter(
          (item: CustomCircleOptions) =>
            item?.id === lastPOintIdRef.current[idLength - 1]
        );
     // console.log("getLastPoint", getLastPoint);
      if (
        getPolygonObject &&
        getPolygonObject.length > 0 &&
        getLastPoint.length > 0
      ) {
        // Remove the last point circle
        canvasRef.current.remove(getLastPoint[0]);

        const points = getPolygonObject[0].points;
        if (points && points.length > 0) {
          points.pop(); // Remove the last point

          // Update the polygon with the new points array
          getPolygonObject[0].set({ points });

          // Redraw polygon to refresh connections
          getPolygonObject[0].dirty = true;
          canvasRef.current.renderAll();

          // Optional: Update your points array (allpoints) if needed for further undo actions
          allpoints.pop(); // Assuming allpoints tracks the polygon point coordinates

         // console.log("Updated Polygon Points:", points);
          // update the id
          // Update the lastPOintId after deletion
          setLastPOintId((prev) => {
            const updatedIds = [...prev]; // Create a copy of the previous state
            updatedIds.pop(); // Remove the last ID
            lastPOintIdRef.current = updatedIds; // Update the ref
            return updatedIds; // Return the updated state
          });
        }
      }
    }
  };

  // Function to show magnifier
  const showMagnifier = (options: fabric.IEvent) => {
    const canvas = canvasRef.current as fabric.Canvas; // Type assertion to fabric.Canvas
    if (!canvas || canvas.width === undefined || canvas.height === undefined)
      return;

    const pointer = canvas.getPointer(options.e); // Get pointer position

    // Create a new magnified image based on the current canvas
    const zoomedCanvas = document.createElement("canvas");

    zoomedCanvas.width = 140; // Size of the zoomed area
    zoomedCanvas.height = 140; // Size of the zoomed area
    const zoomedContext = zoomedCanvas.getContext("2d");
    // Draw the zoomed area onto the new canvas
    zoomedContext?.drawImage(
      canvas.getElement(),
      pointer.x - 70, // Center the zoomed area
      pointer.y - 70, // Center the zoomed area
      140, // Width of the zoomed area
       140, // Height of the zoomed area
      0,
      0,
      140,
      140
    );

    // Create a fabric image from the zoomed canvas
    const canvasWidth = canvasRef.current?.width ?? 0;
    const magnifiedImage = new fabric.Image(zoomedCanvas, {
      left: canvasWidth-150, // Position it over the magnifier
      top: 5, // Position it over the magnifier

      selectable: false,
      evented: false,
      name: "magnifiedImage", // Name for the magnified image
    });
    // Remove previous magnified images to prevent multiple layers
    const existingMagnifiedImage = canvas
      .getObjects()
      .find((obj) => obj.name === "magnifiedImage");
    if (existingMagnifiedImage) {
      canvas.remove(existingMagnifiedImage); // Remove the existing magnified image
    }

    // Add the new magnified image to the canvas
    canvas.add(magnifiedImage);
  };

  const toggleVerticeVisibility = (pointer:PointModel, targetName:string) => {
    verticePoints.current.forEach((vertice) => {
      
      if(vertice.name?.includes(targetName)){
       // console.log("vertice", vertice);
        const distance = calculateDistance(pointer, new fabric.Point(vertice.left ?? 0, vertice.top ?? 0));
      
        if (distance <= MAX_SUGGEST_DISTANCE) {
        //  console.log("MAX_SUGGEST_DISTANCE", MAX_SUGGEST_DISTANCE);
          vertice.set({ visible: true });
        } else {
          vertice.set({ visible: false });
        }
      }
      
    });
    canvasRef.current?.renderAll();
  };

  const calculateDistance = (point1: PointModel, point2: PointModel) => {
    const dx = point1.x - point2.x;
  const dy = point1.y - point2.y;
  return Math.sqrt(dx * dx + dy * dy);
  };

 


  const disposeCanvas = () => {
    const canvasElements = document.querySelectorAll('canvas');
    canvasElements.forEach((canvas) => {
      canvas.remove();
    });
  };
 
  

  return (
    <>
      <div className="editor-canvas position-relative"
       ref={containerRef}
       style={{
         height: "100vh",
         overflow: "hidden",
         transformOrigin: "top left",
       }}
      >
        <div className="re-anno-section" onClick={handlehoverLayer}>
          <button className=" d-flex  align-items-center btn  rounded-pill custom-back-button border-0 ">
            <span className="fs-4">
              <i className="bi bi-arrow-left-short pe-1"></i>
            </span>
            {getReAnnotationSegments.name}-{getReAnnotationSegments.segName}
          </button>
        </div>

        <canvas ref={canvasElementRef} className="dzi-van"></canvas>
      </div>

      <ReAnnoatationModal
        isShow={isShowModal}
        onClose={handleCloseModal}
        ok={handleOk}
      />

      {isUpdatemasterdata && annotation && childGroupName && (
        <AnnotationUpdateApi
          segmentationInt={annotation}
          segName={childGroupName}
          resetAnnotation={handleResetAnotation}
          resetFailAnnotation={handleFailResetAnnotation}
        />
      )}
    </>
  );
};

export default ReAnnotationCanvas;

