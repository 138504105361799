import { NameModel } from "../../../../../Model/material/MaterialModel";
import { SegmentModel } from "../../../../../Model/segment/SegmentModel";

export const SearchCategoryBasedOnSegment=(
    allSegment:SegmentModel[],
    groupName:string,
    allCategoryData:NameModel[],
    searchedCategories:(data:NameModel[])=>void
)=>{

    const getSelectedsegment = allSegment.find(
        (item) => item.name == groupName
      );

      if (
        getSelectedsegment &&
        getSelectedsegment.categories &&
        getSelectedsegment.categories.length > 0
      ) {
        let selectedCat: NameModel[] = [];
        const allcat = getSelectedsegment.categories;
        if (Array.isArray(allcat)) {
          allcat.forEach((category) => {
            const cat = allCategoryData.find((item) => item.title == category);

            selectedCat.push(cat ?? {});
          });
        }
        if (selectedCat.length > 0) {
            searchedCategories(selectedCat)
        }else{
            searchedCategories([])
        }
      }

}