import React, { useEffect, useRef } from 'react'
import { UploadSegDetectModel } from '../../../../api/segDetectModelApi/SegDetectModelApi'
import axios from 'axios'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { useDispatch } from 'react-redux'


type Props={
    uploadfile: File | undefined
    name: string
    resetUploadFile: (data:string)=>void
}
const UpLoadZipFile = ({uploadfile,resetUploadFile, name}:Props) => {
    const dispatch= useDispatch()   
    const isApiCall= useRef<boolean>(true)
    useEffect(() => {
        if(uploadfile && isApiCall.current){
            uploadZipIntoS3(uploadfile)
            isApiCall.current=false
        }
    },[uploadfile])

    const uploadZipIntoS3 = async (file: File) => {
        try{
            if(!file){
                return
            }
            const noSpacesStr = name.replace(/\s+/g, '');
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', noSpacesStr);
            const response = await UploadSegDetectModel(formData);
           
             if(response.status===200 && response.data){
                resetUploadFile(response.data.message)
             }

            
        }
        catch(err){
            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
                   resetUploadFile("")
                  
            }
           
       
        }finally {
            isApiCall.current = true
           
          }
        }
    
  return (
    <>
    </>
  )
}

export default UpLoadZipFile