import { configureStore } from "@reduxjs/toolkit";

import SettingPathSlice from "../slice/settingPathSlice/SettingPathSlice";

const adminStore = configureStore({
    reducer: {
        settingPath: SettingPathSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default adminStore;
export type AdminRootState = ReturnType<typeof adminStore.getState>;
