import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';
import {  getJobBase64, getUserJobBase64 } from '../../../slice/userJobSlice/UserJobSlice';
import { changeSegmentTab, getSwitchCanvas, getTabControl } from '../../../slice/tabControl/TabControlSlice';
import "../../../page/canvas/canvasview.scss"
import { getCachedBase64Image } from '../canvasUtil/ConvertImageToBAse64';
import { CanvasImage } from '../canvasUtil/SetImageAsBackground';

const OriginalCanvas = () => {
    const canvasRef = useRef<fabric.Canvas | null>(null);
    const canvasElementRefs = useRef<HTMLCanvasElement | null>(null);
    const rightSectionRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef(null);
    const getUserJobBase64s = useSelector(getUserJobBase64);
    const [scaleX, setScalex] = useState<number | null | undefined>();
    const [scaleY, setScaley] = useState<number | null | undefined>();
    const [top, setTop] = useState<number | null | undefined>();
    const [left, setLeft] = useState<number | null | undefined>();
    const getJobBase64s= useSelector(getJobBase64)
   // const [annotationPoint, setAnnotationPoint] = useState<PointAnnotation[]>([]);
    const isCanvas = useRef(true);
  
    const dispatch = useDispatch();
    const getTabControls = useSelector(getTabControl);
    const getSwitchCanvass = useSelector(getSwitchCanvas);
  
    // Clean up canvas on unmount
    useEffect(() => {
      return () => {
        if (canvasRef.current) {
          canvasRef.current.dispose();
          canvasRef.current = null;
        }
      };
    }, []);
  
    // Handle canvas disposal on switch
    useEffect(() => {
      if (getSwitchCanvass === "segment" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('segment'));
      }
      else  if (getSwitchCanvass === "outline" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('outline'));
      }
      else if (getSwitchCanvass === "dimension" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('dimension'));
      }
      else if (getSwitchCanvass === "genAiImage" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('genAiImage'));
        
      }
      else if (getSwitchCanvass === "comments" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('comments'));
      }
    }, [getSwitchCanvass, dispatch]);
  
    // Initialize the canvas when the tab is set to "outline"
    useEffect(() => {
      if (getTabControls === "compare" && 
        !canvasRef.current && 
        canvasElementRefs.current) {
        isCanvas.current = true;
        generateCanvas();
      }
    }, [getTabControls, canvasRef]);
  
    const generateCanvas = () => {
      const rightSection = rightSectionRef.current;
      const canvas = new fabric.Canvas(canvasElementRefs.current, {
        width: rightSection == null ? window.innerWidth : window.innerWidth - rightSection.offsetWidth,
        height: window.innerHeight,
        backgroundColor: '#f0f0f0',
      });
  
      canvas.requestRenderAll();
      canvasRef.current = canvas;
    }
  
    // Load the background image based on the job base64
    useEffect(() => {
      if (getJobBase64s && 
        getJobBase64s.imagePath &&
        canvasRef.current && 
        getTabControls === "compare") {
      
          const base64Image= getCachedBase64Image(getJobBase64s.imagePath )
          // console.log("base64Image",base64Image)
            if(base64Image){
              
              CanvasImage(base64Image, canvasRef, setScalex, setScaley, setTop, setLeft);
            }
      }
    }, [getJobBase64s, getTabControls]);
  
  return (
    <div className='editor-canvas position-relative'
    ref={containerRef}
    style={{
      height: "100vh",
      overflow: "hidden",
      transformOrigin: "top left",
    }}>
         <canvas ref={canvasElementRefs}  className='original'/>
    </div>
  )
}

export default OriginalCanvas