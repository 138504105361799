import { fabric } from "fabric";

export const hideAnnotationOnCanvas = (
  targetData: string,
  canvasRef: React.RefObject<fabric.Canvas>,
  isFillPolygon: React.MutableRefObject<boolean>
) => {
  const targetname = targetData;
  const allObjects = canvasRef.current?.getObjects();
  if (allObjects && allObjects.length > 0 && targetData != null) {
    allObjects.forEach((currentObject) => {
      if (currentObject instanceof fabric.Group) {
        const object = currentObject as fabric.Group;
        if (object.name != targetname && object.name != "imageGroup") {
          const curentObj = object?.getObjects();
          if (curentObj && curentObj.length > 0) {
            curentObj.forEach((obj) => {
              if (obj.visible) {
                obj.set({
                  visible: false,
                });
              }
              const opa = isFillPolygon.current ? 0.5 : 10;
              if (obj.opacity === opa) {
                obj.set({
                  opacity: 0.1,
                  visible: false,
                });
              }

              canvasRef.current?.requestRenderAll();
            });
          }
        }
      }
    });
  }
};