import React from 'react'
import GenAiFeatureHome from '../../../../module/admin/adminGenAi/genAiFeature/GenAiFeatureHome'

const AdminGenAiFeaturePage = () => {
  return (
    <>
      <GenAiFeatureHome/>
    </>
  )
}

export default AdminGenAiFeaturePage