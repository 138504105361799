import React from 'react'
import MyAccounts from '../../module/myaccountpage/MyAccounts'

const AccountPage = () => {
  return (
    <div>
      <MyAccounts/>
    </div>
  )
}

export default AccountPage
