import React, { useEffect, useRef, useState } from 'react'
import { JobModel } from '../../../Model/Job/JobModel'
import { addUserJob } from '../../../api/jobs/JobApi'
import axios from 'axios'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import { useDispatch } from 'react-redux'
import { updateNewJobId } from '../../../slice/user/UserProject'


type Props={
    projectId:number,
    resetCreateJob:(data:number)=>void
}
const CreateJobInToDb = ({projectId,resetCreateJob}:Props) => {
   const dispatch= useDispatch()   
   const [ isCreatJob, setIsCreateJob]= useState<boolean>(false)
   const isApi= useRef(true)
    

   const [ jobData, setJobData]= useState<any>()

   useEffect(()=>{
    if( projectId && isApi.current){
        const data:JobModel={
            project_id: projectId,
            job_json:"",
          image:"" ,
          compressImage:"",
          job_view: "Front",
          isArch: false,
          base_64:"",
          seg_labels_count:{},
          dimension_pixel:[],
          segments:{},
         
        }
        isApi.current=false
        handleCreateJob(data)
    }
   },[projectId])

    const handleCreateJob=async(data:JobModel)=>{

        try{
            const response= await addUserJob(data);
            if(response && response.status==200){
               // console.log("response  job",response.data)
                isApi.current=true
                dispatch(updateNewJobId(response.data.id))
                resetCreateJob(response.data.id)
            }

        }
       
        catch(err){

            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))

                   isApi.current=true
                   resetCreateJob(0)
            }
           
        }
    }
  return (
    <>
    </>
  )
}

export default CreateJobInToDb