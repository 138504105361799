import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSearchSwatch, getIsFilter, getSearchBrandIds, getSearchCatId, getSearchStyleId, updateIsSawatchSearching } from '../../../../../../slice/materialSlice/SearchMaterial'
import { getGroupName } from '../../../../../../slice/toolActive/ToolActiveSlice'
import SearchSwatchCat from './SearchSwatchCat'
import { handleImageScale } from '../../../../../../page/samModel/new/helper/scaleHelper'
import SearchSwatchCatBrnd from './SearchSwatchCatBrnd'
import SearchSwatchCatBrndStyle from './SearchSwatchCatBrndStyle'
import { is } from '@react-three/fiber/dist/declarations/src/core/utils'

const SearchSwatchBased = () => {
    const[ isCatSearch, setIsCatSearch]= useState<boolean>(false)
    const[ isBrandSearch, setIsBrandSearch]= useState<boolean>(false)
    const[ isStyleSearch, setIsStyleSearch]= useState<boolean>(false)
 
       const dispatch= useDispatch()
      const getSearchCatIds= useSelector(getSearchCatId) 
      const getGroupNames= useSelector(getGroupName)  
      
     const getAllSearchSwatchs= useSelector(getAllSearchSwatch)
     const getSearchBrandIdss= useSelector(getSearchBrandIds)
     const getSearchStyleIds= useSelector(getSearchStyleId)
     const getIsFilters = useSelector(getIsFilter);
     const isSwatchCat= useRef(true)


     useEffect(() => {
      if(!getIsFilters){
       isSwatchCat.current=true
      }  
    }, [getIsFilters]);
    
      useEffect(()=>{
        if(getSearchCatIds && 
            getGroupNames &&
             isSwatchCat.current
          ){
            isSwatchCat.current=false
          setIsCatSearch(true)
        }
      },[getSearchCatIds, getGroupNames])

      const handleResetSearch=()=>{
       dispatch( updateIsSawatchSearching(false))
        setIsCatSearch(false)
      }


      // start searching  swatch  based on category , brands
      useEffect(()=>{
        if(getSearchCatIds && 
          getAllSearchSwatchs && 
          getAllSearchSwatchs.length>0 &&
          getAllSearchSwatchs[0].brand &&
          getAllSearchSwatchs[0].brand.length>0 &&
          getSearchBrandIdss){
             if(getAllSearchSwatchs &&
              getAllSearchSwatchs[0].brand &&
              getSearchBrandIdss.length== getAllSearchSwatchs[0].brand.length){
                setIsBrandSearch(true)
          dispatch(updateIsSawatchSearching(true))
             }

        }
      },[ getAllSearchSwatchs,getSearchBrandIdss])

      const handleResetSearchcatBrand=()=>{
        dispatch( updateIsSawatchSearching(false))
        setIsBrandSearch(false)
      }



       // start searching  swatch  based on category , brands and styles
       useEffect(()=>{
        if(getSearchCatIds && 
          getAllSearchSwatchs && 
          getAllSearchSwatchs.length>0 &&
          getAllSearchSwatchs[0].brand &&
          getAllSearchSwatchs[0].brand.length>0 &&
          getAllSearchSwatchs[0].style &&
          getAllSearchSwatchs[0].style.length>0&&
          getSearchBrandIdss &&
          getSearchStyleIds){
             if(getAllSearchSwatchs &&
              getAllSearchSwatchs[0].brand &&
              getAllSearchSwatchs[0].style &&
              getSearchBrandIdss.length== getAllSearchSwatchs[0].brand.length &&
              getSearchStyleIds.length== getAllSearchSwatchs[0].style.length
            ){
                setIsStyleSearch(true)
          dispatch(updateIsSawatchSearching(true))
             }

        }
      },[ getAllSearchSwatchs,getSearchBrandIdss,getSearchStyleIds])


      const handleResetSearchcatBrandStyle=()=>{
        dispatch( updateIsSawatchSearching(false))
        setIsStyleSearch(false)
      }
  return (

    <>
    {isCatSearch &&
    getSearchCatIds &&
     getGroupNames &&
        <SearchSwatchCat
        segType={getGroupNames}
        catData={getSearchCatIds}
        resetSerachCatSwatch={handleResetSearch}
        />
    }

    
  {/* search based on category and brands */}

    {isBrandSearch &&
    getSearchCatIds.id &&
    <SearchSwatchCatBrnd
    catId={getSearchCatIds.id}
    brands={getSearchBrandIdss}
    segType={getGroupNames}
    resetSearchCatBrand={handleResetSearchcatBrand}

    />}


    {/*  search swatches based on category , brands, and styles */}
    {isStyleSearch &&
    getSearchCatIds.id &&
    <SearchSwatchCatBrndStyle
    segType={getGroupNames}
    catId={getSearchCatIds.id}
    brands={getSearchBrandIdss}
    styles={getSearchStyleIds}
    resetSearchCatBrandStyle={handleResetSearchcatBrandStyle}
    /> 
}
    </>



  )
}

export default SearchSwatchBased