import { fabric } from "fabric";
import { CanvasControlModel } from "../../../Model/canvasControlModel/CanvasControlModel";

interface PointModel {
  x: number;
  y: number;
}
interface CustomGroupOptions extends fabric.IGroupOptions {
  groupName?: string;
  subGroupName?: string;
  childGroupName?: string; // Example for an object
}

export const checkMouseInsidePolygon = (
  pointer: fabric.Point,
  canvasRef: React.RefObject<fabric.Canvas>,
  isColor: boolean,
  hideAnnotation: (name: string) => void,
  showAnnotation: (
    name: string,
    color: string,
    pointer: fabric.Point
    
  ) => void,
  isFillPolygon: React.MutableRefObject<boolean>
) => {
  const allObjects = canvasRef.current?.getObjects();
  if (allObjects && allObjects.length > 0) {
    allObjects.forEach((item: fabric.Object) => {
      const groupObject = item as fabric.Group;
      if (groupObject.type === "group" && groupObject.name !== "imageGroup") {
        const selectgroupObject = groupObject.getObjects();
       
        if (selectgroupObject) {
          const currentPoly = selectgroupObject[0];
          if (currentPoly instanceof fabric.Polygon) {
            const vertices = currentPoly.get("points");
            const checkvalue = isPointInPolygon(pointer, vertices || []);
            
            if (checkvalue) {
              hideAnnotation(currentPoly.name ?? "");
              showAnnotation(
                currentPoly.name ?? "",
                currentPoly.stroke?.toString() ?? "",
                pointer
              
              );
            }
          }
        }
      }
    });
  }
};

export const isPointInPolygon = (
  point: { x: number; y: number },
  vertices: { x: number; y: number }[]
) => {
  let inside = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const xi = vertices[i].x,
      yi = vertices[i].y;
    const xj = vertices[j].x,
      yj = vertices[j].y;

    const intersect =
      yi > point.y !== yj > point.y &&
      point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const GetGroupObject = (
  pointer: fabric.Point,
  canvasRef: React.RefObject<fabric.Canvas>,
  isComment: boolean,
  canvasControl:(data: CanvasControlModel)=>void
) => {
  const allObjects = canvasRef.current?.getObjects();
  if (allObjects && allObjects.length > 0) {
    allObjects.forEach((item: fabric.Object) => {
      const groupObject = item as fabric.Group 
      if (groupObject.type === "group" && groupObject.name !== "imageGroup") {
        const selectgroupObject = groupObject.getObjects();
        const currentGroup = groupObject as CustomGroupOptions;
       // console.log("currentGroup",currentGroup)
        if (selectgroupObject) {
          const currentPoly = selectgroupObject[0];
          if (currentPoly instanceof fabric.Polygon) {
            const vertices = currentPoly.get("points");
            const checkvalue = isPointInPolygon(pointer, vertices || []);
             if(checkvalue){
             
              const data = {
                      groupName: currentGroup?.groupName,
                      subGroupName: currentGroup?.subGroupName,
                      segShortName: currentGroup?.childGroupName,
                      segName: currentGroup?.name,
                      isMainComment: isComment,
                      isUserIcon: isComment,
                      isEditComment: isComment,
                      x: pointer.x,
                      y: pointer.y,
                    };
                  canvasControl(data)
          }
        }
      }
    }
    });
  }
}