import React, { useState } from 'react'
type Props = {
    value:number
    updateMaterialStatus: (data: number) => void; // Ensure this accepts a number
}
const SelectedBoolean = ({updateMaterialStatus,value}:Props) => {
    const [status, setStatus] = useState<number>(value);
  
    const updateStatus = (data: number) => { // Ensure this accepts a number
        setStatus(data);
        updateMaterialStatus(data); // Call your update function here
    };
  return (
    <>
    
    <div className="form-check form-switch">
        <input 
            className="form-check-input" 
            type="checkbox" 
            role="switch" 
            id="flexSwitchCheckDefault" 
            checked={status === 1} // Check if status is active
            onChange={(e) => updateStatus(status === 1 ? 0 : 1)} // Toggle status
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            {status === 1 ? "Active" : "Inactive"} 
            {/* // Display current status */}
        </label>
    </div>
    </>
  )
}

export default SelectedBoolean