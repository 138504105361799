
import axios from "axios";


// const serverApi = import.meta.env.SERVER_URL
const serverApi =process.env.REACT_APP_SERVER_URL

const databaserServer="http://localhost:5000"

// getAll  wall swatches
export function GetAllWallSwatches() {
    const data={
        page:1,
        limit:200
    }
    return axios.post(`${serverApi}/material-wall`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}

// getAll  window swatches
export function GetAllWindowSwatches() {
    const data={
        page:1,
        limit:100
    }
    return axios.post(`${serverApi}/material-window`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}
// getAll  door swatches
export function GetAllDoorSwatches() {
    const data={
        page:1,
        limit:200
    }
    return axios.post(`${serverApi}/material-door`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}
// getAll  garage door swatches
export function GetAllGarageDoorSwatches() {
    const data={
        page:1,
        limit:20
    }
    return axios.post(`${serverApi}/material-garage`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}

// getAll  gutter swatches
export function GetAllGutterSwatches() {
    const data={
        page:1,
        limit:20
    }
        return axios.post(`${serverApi}/material-gutter`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}
// getAll  roof swatches
export function GetAllRoofSwatches() {
    const data={
        page:1,
        limit:20
    }
        return axios.post(`${serverApi}/material-roof`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}
// getAll  trim     swatches
export function GetAllTrimSwatches() {
    const data={
        page:1,
        limit:20
    }
        return axios.post(`${serverApi}/material-trim`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}

