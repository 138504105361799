import { fabric } from "fabric";


export const CanvasImage = (
  imageBase64: string,
  canvasRef: React.RefObject<fabric.Canvas>,
  setScalex: React.Dispatch<React.SetStateAction<number | null | undefined>>,
  setScaley: React.Dispatch<React.SetStateAction<number | null | undefined>>,
  setTop: React.Dispatch<React.SetStateAction<number | null | undefined>>,
  setLeft: React.Dispatch<React.SetStateAction<number | null | undefined>>
) => {
 // const encodedImage = "data:image/png;base64," + imageBase64;
const encodedImage = imageBase64;

  const decodedImage = atob(encodedImage.split(",")[1]);
  const uint8Array = new Uint8Array(decodedImage.length);
  for (let i = 0; i < decodedImage.length; i++) {
    uint8Array[i] = decodedImage.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: "image/png" });
  const imageUrl = URL.createObjectURL(blob);
  const canvas = canvasRef.current;
 
  fabric.Image.fromURL(
    imageUrl,
    (img: fabric.Image) => {
      const canvas = canvasRef.current;
      if (canvas && canvas.width && canvas.height && img.width && img.height) {
        const imgAspectRatio = img.width / img.height;
        const canvasAspectRatio = canvas.width / canvas.height;
        let scale;

        if (canvasAspectRatio > imgAspectRatio) {
          scale = canvas.height / img.height;
        } else {
          scale = canvas.width / img.width;
        }
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        img.scaleToWidth(canvasWidth);
        img.scaleToHeight(canvasHeight);
      

        canvas.setBackgroundImage(
          img,
          canvas.requestRenderAll.bind(canvas),
          {
            scaleX: canvasWidth / img.width,
            scaleY: canvasHeight / img.height,
          //   left: (canvas.width - img.width * scale) / 2, // Center the image horizontally
          // top: (canvas.height - img.height * scale) / 2,
            originX: "left",
            originY: "top",
          }
        );

       // canvas.add(img);
      //  canvas.sendToBack(img);
          setScalex(canvasWidth / 1023)
          setScaley(canvasHeight / 592)
          
       // setScalex(0.9364613880742912);
        //setScaley(1.08445);
        setTop(img.width);
       setLeft(img.height);
        canvas.requestRenderAll();
          
      }
    },
    { crossOrigin: "anonymous" }
  );

  
};

// fabric.Image.fromURL(
  //   imageUrl,
  //   (img: fabric.Image) => {
  //     const canvas = canvasRef.current;
  //     if (canvas && canvas.width && canvas.height && img.width && img.height) {
  //       const imgAspectRatio = img.width / img.height;
  //       const canvasAspectRatio = canvas.width / canvas.height;
  //       let scale;
  //       let left = 0;
  //       let top = 0;
  
  //       if (canvasAspectRatio > imgAspectRatio) {
  //         scale = canvas.height / img.height;
  //         left = (canvas.width - img.width * scale) / 2; // Center horizontally
  //       } else {
  //         scale = canvas.width / img.width;
  //         top = (canvas.height - img.height * scale) / 2; // Center vertically
  //       }
  
  //       img.scale(scale); // Scale the image proportionally
  //       img.set({
  //         left: left, // Center the image horizontally
  //         top: top, // Center the image vertically
  //         selectable: false, // Make it non-draggable
  //         hasControls: false, // Disable scaling and rotation
  //         evented: false, // Disable drag events
  //       });
  
  //       canvas.setBackgroundImage(
  //         img,
  //         canvas.requestRenderAll.bind(canvas),
  //         {
  //           scaleX: scale,
  //           scaleY: scale,
  //           originX: "left",
  //           originY: "top",
  //         }
  //       );
  
  //       canvas.add(img);
  //       canvas.sendToBack(img);
  //       console.log("canvas.height", canvas.height);
  //       console.log("canvas.width", canvas.width);
  //       console.log("image width", img.width);
  //       console.log("image height", img.height);
  //       console.log("scale y", scale);
  //       console.log("scale x", scale);
  //       setScalex(scale);
  //       setScaley(scale);
  //       setTop(top);
  //       setLeft(left);
  //       canvas.requestRenderAll();
  //     }
  //   },
  //   { crossOrigin: "anonymous" }
  // );

 // fabric.Image.fromURL(imageUrl, (img) => {
  //   if (img && img.width && img.height && canvas?.width && canvas.height) {

     
  //     // Calculate the scale to fit the image without stretching
  //     const imgAspectRatio = img.width / img.height;
  //     const canvasAspectRatio = canvas.width / canvas.height;
  //     let scale;

  //     if (canvasAspectRatio > imgAspectRatio) {
  //       scale = canvas.height / img.height;
  //     } else {
  //       scale = canvas.width / img.width;
  //     }

  //     img.scale(scale); // Scale the image
  //     img.set({
  //       left: (canvas.width - img.width * scale) / 2, // Center the image horizontally
  //       top: (canvas.height - img.height * scale) / 2, // Center the image vertically
  //       selectable: false, // Make it non-draggable
  //       hasControls: false, // Disable scaling and rotation
  //       evented: false, // Disable drag events
  //     });

    
  //     canvas.add(img); // Add the image to the canvas
  //     canvas.sendToBack(img); // Send the image to the background
  //     console.log("canvas.height", canvas.height);
  //     console.log("canvas.width", canvas.width);
  //     console.log("img.height--", img.height);
  //     console.log("img.width---", img.width);
  //     console.log("scel y", canvas.height / img.height);
  //     console.log("scel x", canvas.width / img.width);
  //     setScalex(canvas.width / img.width);
  //       setScaley(canvas.height / img.height);
     
  //     canvas.requestRenderAll(); // Render the canvas
  //   }
  // });