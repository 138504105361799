import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getIsCacheImage, getJobBase64, updateChacheImage } from '../../../../slice/userJobSlice/UserJobSlice';
import { getSettingPath } from '../../../../slice/settingPathSlice/SettingPathSlice';
import { getImagePath } from '../util/imagePathUtil/ImagePathUtil';
import { ConvertImageToBase64, getCachedBase64Image } from '../../../canavas/canvasUtil/ConvertImageToBAse64';
import { addApiMessage } from '../../../../slice/messageToast/ToastSlice';

const GetBase64Image = () => {
    const dispatch = useDispatch();
    const getJobBase64s= useSelector(getJobBase64);
    const getSettingPaths= useSelector(getSettingPath)
    const getImageChaches = useSelector(getIsCacheImage)
    const isApi= useRef(true)
    const [imagePath, setImagePath] = React.useState<string | null>(null);
    useEffect(() => {
        if(getJobBase64s && 
            getJobBase64s.imagePath &&
            getJobBase64s.projectId &&
            getJobBase64s.jobId &&
            getSettingPaths &&
            isApi.current
        ){ 
         // dispatch(addApiMessage("Fetching image"))
              getImagePath(getSettingPaths , "Project Image",projectImagePath)
                  
        }
    },[getJobBase64s,getSettingPaths])

    const projectImagePath = async (data: string) => {
        const image = `${data}${getJobBase64s.projectId}/${getJobBase64s.jobId}/${getJobBase64s.imagePath}`;
       
        if (image && getJobBase64s.imagePath) {
            setImagePath(image);
            const base64Image = await ConvertImageToBase64(image,getJobBase64s.imagePath);
               if(base64Image){
              //  console.log("Image converted to Base64 and cached:", base64Image);
                dispatch(updateChacheImage(true))
               }
           
          
        }
      };

    

  return (
    <>
    </>
  )
}

export default GetBase64Image