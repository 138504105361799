import React, { useEffect, useRef } from 'react'

import { AddCategories } from '../../../../../api/material/category/CategoryApi'
import { useDispatch } from 'react-redux'
import { addReduxCategory } from '../../../../../slice/materialSlice/MaterialCategorySlice'
import { MaterialBrandModel } from '../../../../../Model/material/MaterialBrandModel'
import { AddBrand } from '../../../../../api/material/brand/BrandApi'
import { addNewBrand } from '../../../../../slice/materialSlice/MaterialBrandSlice'
import { AddSwatch } from '../../../../../api/material/swatch/SwatchApi'
import { MaterialModel } from '../../../../../Model/material/MaterialModel'
import { updateSwatchData } from '../../../../../slice/materialSlice/MaterialSlice'
import axios from 'axios'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'


type Props={
    resetAdd:()=>void
    addSwatchData:MaterialModel
}
const AddSwatchApi = ({resetAdd,addSwatchData}:Props) => {
const dispatch= useDispatch()
     const isApi= useRef(true)
    useEffect(()=>{

      if(addSwatchData && 
         addSwatchData.title && 
         isApi.current
      ){
         isApi.current= false
         addSwatch(addSwatchData)  
      }
    },[addSwatchData])

    const addSwatch=async(data:MaterialModel)=>{

         try{
            const response= await AddSwatch(data);
            console.log("add  DB-->", response.data)
             if(response && response.status===200){
               //  console.log("add", response)
                isApi.current= true
                  dispatch(updateSwatchData({
                     data:response.data
                  }))
                resetAdd()
             }
         }catch(err){
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))

              
        }
            isApi.current= true
            resetAdd()
         }
    }
  return (
    <div></div>
  )
}

export default AddSwatchApi