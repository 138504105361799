import React, { useState, useEffect, useRef } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserLogin } from "../../../slice/user/UserSlice";
import { userModel } from "../../../Model/user/UserModel";
import { resetSVGImage } from "../../../slice/svdImageSlice/SvgImageSlice";
import ListView from "../ListView/ListView";
import { resetUploadMessage, resetIsRegenaration } from "../../../slice/modelData/ModelDataSlice";
import { resetJobData, resetSelectedSegment, resetUserJob, updateChacheImage } from "../../../slice/userJobSlice/UserJobSlice";
import { getMasterArray, resetMasterArray, updateGroupNameActive } from "../../../slice/canvas/masterArray/MasterArraySlice";
import UpdateJobHome from "../updateJob/UpdateJobHome";
import { resetSwatchOnCanvas } from "../../../slice/canvas/masterArray/SwatchOnCanvas";


import AiExteriors from "../exteriorsAi/AiExteriors";
import { stopLoading } from "../../../slice/loading/LoadingSlice";
import { getProjectGenAiImage, resetGenAiAttributes, resetGeneratedPrompt, resetProjectGenAiImages, resetPrompValue } from "../../../slice/genAi/GenAiAttributesSlice";
import { resetSegregated, resetSegregatedSegments } from "../../../slice/segegratedSlice/SegregatedSlice";
import Segments from "./segments/Segments";
import { closeCommentTab, getComment, resetCanvasComment } from "../../../slice/projectCommet/ProjectCommentSlice";
import "./LeftSections.scss";
import { UserCommentsTab } from "../commnets/UserCommentsTab";
import IndexPage from "../../../page/samModel/IndexPage";
import { changeSegmentTab, getTabControl, resetSegmentTab, switchToOutline } from "../../../slice/tabControl/TabControlSlice";
import UploadImage from "../../../page/samModel/UploadImage";
import { addGroupName, resetToolActive, UpdateToolActive } from "../../../slice/toolActive/ToolActiveSlice";
import ProjectDetails from "./projectDetails/ProjectDetails";
import { getCanvasControl, resetCanvasControl, resetCanvasControlState } from "../../../slice/canvas/masterArray/CanvasControlSlice";
import { getAllSegment, UpdateIsDisable } from "../../../slice/segment/SegmentSlice";
import { resetWebSocket, startWebSocket } from "../../../slice/webSocketSlice/WebSocketSlice";

import GetGenAiImageFromDb from "../exteriorsAi/getAttributes/GetGenAiImageFromDb";
import { resetMessage } from "../../../slice/messageToast/ToastSlice";
import FabricToolHome from "../fabricTool/FabricToolHome";
import MessageToast from "../../messageToast/MesageToast";
import { resetCanvasOption } from "../../../slice/canvas/canvasOption/CanvasOptionSlice";
import { clearCache } from "../../canavas/canvasUtil/ConvertImageToBAse64";






const LeftSections = () => {
  const [activeMainTab, setActiveMainTab] = useState<string>("details");  // State to track active tab
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isGenAiImage, setIsGenAiImages] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const getUserLogins = useSelector(getUserLogin) as userModel[];
 

  const getMasterArrays= useSelector(getMasterArray)

  const getProjectGenAiImages= useSelector(getProjectGenAiImage)

  const getTabControls= useSelector(getTabControl)
    
  const isMasterArray= useRef(true)

  
  useEffect(() => {
    if (getUserLogins &&
       getUserLogins.length > 0) {
      setIsLogin(true);
      if (getUserLogins[0].role) {
        setUserRole(getUserLogins[0].role);
      }
    } else {
      setIsLogin(false);
    }
  }, [getUserLogins]);

  const handleCustomerProfile = () => {

    clearCache()
    updateSegmentIsDisable()// update the segment isDisable
    dispatch(resetSVGImage());
    removeProjectFromLocalStorage();
    dispatch(resetCanvasControlState()) // initial state to canvas control
    // dispatch(switchToOutline("segment"))
    // dispatch(changeSegmentTab("segment"))
    dispatch(resetWebSocket()) // reset the web socjet slice

    dispatch(resetPrompValue()) // reset the prompt value

    dispatch(resetProjectGenAiImages()) // reset project GeAi images


    dispatch(resetMessage())  // reset the message from toast

    dispatch(resetSegregatedSegments()) // reset the segregated slice

    dispatch(resetSegmentTab()) // reset the segment tab

    dispatch(resetCanvasOption()) // reset the canvas option
    
    dispatch(updateChacheImage(false))
    navigator(`/${userRole}/project`);

  };

  const removeProjectFromLocalStorage = () => {
    dispatch(resetSVGImage());
    localStorage.removeItem("getSegregateSegments");
    localStorage.removeItem("getUserJobDatas");
    localStorage.removeItem("getMasterArrays");
    dispatch(resetUploadMessage());
    dispatch(resetIsRegenaration());
    dispatch(resetMasterArray());
    dispatch(resetSelectedSegment());
    dispatch(resetUserJob());
    dispatch(resetSwatchOnCanvas());
    dispatch(stopLoading());
    dispatch(resetCanvasComment()) // remove project Comments
    dispatch(resetToolActive())  // update the Tool active 
    dispatch(resetCanvasControl())
    dispatch(resetGeneratedPrompt()) // reset the slice for generared prompt
  };

  const handleMainTabClick = (tab: string) => {
    dispatch(switchToOutline("segment"))
    // dispatch(changeSegmentTab("segment"))
    dispatch(UpdateToolActive(0))
    setActiveMainTab(tab);  // Set active tab based on user click
  };
  const handleGenAITabClick = (tab: string) => {
    dispatch(switchToOutline("genAiImage"))
   // dispatch(startWebSocket())  // start web socket
    dispatch(UpdateToolActive(0))
    setActiveMainTab(tab);  // Set active tab based on user click
  };

  const handleProjectDetailsTabClick=(tab:string)=>{
    
    dispatch(switchToOutline("segment"))
    setActiveMainTab(tab);
  }


  //tab control
  useEffect(() => {
    if(getTabControls=="segment"){
      setActiveMainTab("segments")
    }else if(getTabControls=="comments"){
      setActiveMainTab("comments")}
  
    else if(getTabControls=="details"){
      setActiveMainTab("details")}
    
  }, [getTabControls])
  
 
  const handleCommentClick =(tab:string)=>{
    dispatch(switchToOutline("comments"))
    dispatch(changeSegmentTab("comments"))
    setActiveMainTab(tab);
    dispatch(UpdateToolActive(2))
  }

  //update the first masterArray
  useEffect(() => {
    if (getMasterArrays && 
      getMasterArrays.allSeg && 
      getMasterArrays.allSeg.length &&
       isMasterArray.current) {
        isMasterArray.current= false
       
        dispatch(addGroupName( getMasterArrays.allSeg[0].name))
        dispatch(updateGroupNameActive({
          groupName:getMasterArrays.allSeg[0].name
        }))
      }else{
      //  isMasterArray.current=true
      }
  }, [getMasterArrays]);

  const handleSamModel=(tab:string)=>{
    setActiveMainTab(tab)
    dispatch(switchToOutline("sam-model"))
    dispatch(changeSegmentTab('sam-model'))
  }
  const getCanvasControls =useSelector(getCanvasControl)

  const updateSegmentIsDisable=()=>{
    dispatch(UpdateIsDisable())
  }

  // getGetAiImages from Dara base
  useEffect(()=>{
    if(getProjectGenAiImages && 
      getProjectGenAiImages.gallery &&
      getProjectGenAiImages.gallery.length==1){
        setIsGenAiImages(true)
      }else{
        setIsGenAiImages(false)
      }
  },[getProjectGenAiImages])
  
  const handleResetGenAiImages=()=>{
    setIsGenAiImages(false)
  }
  return (
    <>
      <div className="canvas-left-header">
      
        <div className='px-2 bg-white border-bottom d-flex align-items-center'>
          <button className="btn btn-outline-primary btn-sm d-flex align-items-center gap-1" onClick={handleCustomerProfile}>
            <i className="bi bi-arrow-left-short"></i>
            Back
          </button>
          <ul className="nav nav-underline nav-fill w-100 ms-2 border-start ps-2" id="myTab" role="tablist">
            <li className="nav-item" role="presentation" 
           onClick={() => handleProjectDetailsTabClick("details")}
            >
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Details</Tooltip>}>
                <button className={`nav-link ${activeMainTab === "details" ? "active" : ""}`} type="button">
                <i className="bi bi-person-lines-fill list-icon"></i>
                </button>
              </OverlayTrigger>
            </li>
            <li className="nav-item" role="presentation" onClick={() => handleMainTabClick("segments")}>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Segments</Tooltip>}>
                <button className={`nav-link ${activeMainTab === "segments" ? "active" : ""}`} type="button">
                  {/* SEGMENTS */}
                  <i className="bi bi-house-door list-icon"></i>
                </button>
              </OverlayTrigger>
            </li>

            <li className="nav-item" role="presentation" 
            onClick={() => handleMainTabClick("old-segment"
              
            )}>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">List View</Tooltip>}>
                <button className={`nav-link ${activeMainTab === "old-segment" ? "active" : ""}`} type="button">
                 
                  <i className="bi bi-columns-gap list-icon"></i>
                </button>
              </OverlayTrigger>
            </li>

            <li className="nav-item" role="presentation" onClick={() => handleCommentClick("comments")}>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Comments</Tooltip>}>
                <button className={`nav-link ${activeMainTab === "comments" ? "active" : ""}`} type="button">
                <i className="bi bi-chat-dots list-icon"></i>
                </button>
              </OverlayTrigger>
            </li>

            <li className="nav-item" role="presentation" onClick={() => handleGenAITabClick("combo")}>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Gen-AI</Tooltip>}>
                <button className={`nav-link ${activeMainTab === "combo" ? "active" : ""}`} type="button">
                  <i className="bi bi-columns-gap list-icon"></i>
                </button>
              </OverlayTrigger>
            </li>
            {/* <li className="nav-item" role="presentation" 
            onClick={() => handleSamModel("sam-model")}
            >
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">3d Mesh</Tooltip>}>
                <button className={`nav-link ${activeMainTab === "sam-model" ? "active" : ""}`} type="button">
                  <i className="bi bi-columns-gap list-icon"></i>
                </button>
              </OverlayTrigger>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="canvas-left-body">
      {activeMainTab === "details" && (
          <div className="tab-pane active" id="details">
            <ProjectDetails />
          </div>
        )}
        {activeMainTab === "segments" && (
          <div className="tab-pane active" id="segments">
            <Segments />
          </div>
        )}
        {activeMainTab === "old-segment" && (
          <div className="tab-pane active" id="old-segment">
            <ListView />
          </div>
        )}
        {activeMainTab === "comments" && (
          <div className="tab-pane active" id="comments">
            {/* <GenAiHome /> */}
            <UserCommentsTab/>
          </div>
        )}
        {activeMainTab === "combo" && (
          <div className="tab-pane active" id="combo">
            <AiExteriors />
          </div>
        )}
        {activeMainTab === "sam-model" && (
          <div className="tab-pane active" id="combo">
            {/* sam model  */}
           <h6>Sam Model left section</h6>
           {/* <FabricToolHome/> */}
           {/* <UploadImage/> */}
          </div>
        )}

        {/* Include any additional components you want displayed regardless of the tab */}
        <UpdateJobHome />
      </div>

     {isGenAiImage &&
      <GetGenAiImageFromDb
       resetGenAiImages={handleResetGenAiImages}
      />}

<MessageToast
          />
          
    </>
  );
};

export default LeftSections;
