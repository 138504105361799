import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenAiAttributesModel, OptionJDetails } from "../../../Model/genAiAttributes/GenAiAttributes";


interface GenAiFeatureStates {
    genAiFeatureData:OptionJDetails
   
    
}

const initialState: GenAiFeatureStates = {
 genAiFeatureData:{}

};

const GenAiFeatureSlice = createSlice({
    name: "genAiFeature",
    initialState,
    reducers: {
       addGenAiFeature:(state,action)=>{
        state.genAiFeatureData=action.payload
       },
       resetGenAiFeatureData:(state)=>{
        state.genAiFeatureData={}
       }

    }
}
)

export const {addGenAiFeature, resetGenAiFeatureData}=GenAiFeatureSlice.actions;
export const getGenAiFeatureData=  (state:{genAiFeature:GenAiFeatureStates})=>state.genAiFeature.genAiFeatureData
export default GenAiFeatureSlice.reducer