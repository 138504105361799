import React, { useState } from 'react'
import { CiSatellite1, CiViewList } from 'react-icons/ci'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const ModelSideBar = () => {
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const navigate= useNavigate()
     const handleSegDetectModel=(segActive:number)=>{
      setActiveIndex(segActive)
      navigate('/dashboard-page/seg-detect-model') 

     }
  return (
    <>
     <li
              className={activeIndex === 4 ? "active" : ""}
              role="button"
              onClick={() => handleSegDetectModel(4)}
              >
              <div className="d-flex align-items-center gap-1">
                <span className="sidebar-nav-icon">
                  <CiSatellite1 />
                </span>
                <span className="sidebar-nav-text"
                 //onClick={handleSegments}
                 >
                  Model
                </span>
              </div>
            </li>
    </>
  )
}

export default ModelSideBar