import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSegDetectModel, updateSegModal } from '../../../../slice/segDetectModelSlice/SegDetectModelSlice'
import { SegDetectModel } from '../../../../Model/segDetectModel/SegDetect'
import { MaterialModel } from '../../../../Model/material/MaterialModel'
import Loading from '../../../loading/Loading'
import DataTable from "react-data-table-component";
const SegModeltable = () => {
    const dispatch= useDispatch()
    const [modelId, setModelId] = useState<number|null>(null)
     const getSegDetectModels= useSelector(getSegDetectModel)
     const [allModels, setAllModels] = React.useState<SegDetectModel[]>([])
     const [isloading, setIsLoading]= useState<boolean>(false)
      const [isDeleteModal, setIsDeleteModal]= useState<boolean>(false)
     useEffect(() => {
      if( getSegDetectModels&&getSegDetectModels.length>0){
        setAllModels(getSegDetectModels)
      }else{
        setAllModels([])
      }
      },[getSegDetectModels])


  const handleOpenModal = () => {
    dispatch(updateSegModal(true))
  }
      

  const handleDelete = (row: SegDetectModel) => {
    if(row && row.id){
    setModelId(row?.id)
  }
  

  };
  const columns = [
    {
      name: "Id",
      selector: (row: SegDetectModel) => row.id ?? 0, // Selector returns a primitive value
      sortable: true,
    },
    {
      name: "name",
      selector: (row: SegDetectModel) => row.name ?? "",
    },

    {
      name: "Title",
      selector: (row: SegDetectModel) => row.title ?? "",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row: SegDetectModel) => row.description??"",
      sortable: true,
    },

    {
      name: "Path",
      selector: (row: SegDetectModel) => row.filePath ?? "",
      sortable: true,
    },

    // {
    //   name: "s3BucketName",
    //   selector: (row: SegDetectModel) => row.s3BucketName ?? "",
    //   sortable: true,
    // },

    {
      name: "Status",
      selector: (row: SegDetectModel) => row.isActive ?"true":"false",
      sortable: true,
    },

    {
      name: "Action",
      cell: (row: SegDetectModel) => (
        <>
          <div className="d-flex action">
            <div
              className="action-icon-table"
              // onClick={() => handleEditCategory(row)}
            >
              <i className="bi bi-pencil-square mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-copy mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-box-arrow-up-right mx-0"></i>
            </div>
            <div
              className="action-icon-table trash-icon-media"
               onClick={() => handleDelete(row)}
            >
              <i className="bi bi-trash3 mx-0"></i>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
     <div className="card mx-3">
        <div className="card-header bg-white p-2 d-flex justify-content-between align-items-center">
          
          {/* <SearchSwatches/> */}



          <button
            type="button"
            className="btn btn-primary"
            onClick={handleOpenModal}
           >
            Create new entry
          </button>
        </div>

         {isloading &&
          <Loading />
         }
        <div className="card-body p-0">
          <div className="p-0">
            {allModels && allModels.length == 0 ? (
              <Loading />
            ) : (
             

              <DataTable
                columns={columns}
                data={allModels?.map((item) => ({ ...item, key: item.id }))}
                pagination
                fixedHeader
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SegModeltable