import { SettingPathModel } from "../../../../../Model/settingPath/SettingPathModel";

const newMaterialPath: { [key: string]: string } = {};
const oldMaterialPath: { [key: string]: string } = {};


export const MaterialPath = (
    settingPath: SettingPathModel[]  
) => {
    if(settingPath.length>0){
        const materialNewItem = settingPath.find(item => item.title == "materials");
        const materialOldItem = settingPath.find(item => item.title == "old material");
        console.log("materialNewItem",materialNewItem)
        console.log("materialOldItem",materialOldItem)  
        if(materialNewItem && materialNewItem.path && materialNewItem.title){

          newMaterialPath[materialNewItem.title] = materialNewItem.path;
        }
        if(materialOldItem && materialOldItem.path && materialOldItem.title){
           oldMaterialPath[materialOldItem.title] = materialOldItem.path;
        }
    }
}

export const getMaterialPath = (title: string) => {
        if(title=="old material"){
            return oldMaterialPath[title] || null;

        }else{
            return newMaterialPath[title] || null;
        }
    
}
