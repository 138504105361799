import { fabric } from "fabric";

export const  drawLines = (
  mouseX: number,
  mouseY: number,
  canvasRef: React.RefObject<fabric.Canvas>
) => {
  const canvas = canvasRef.current;

  if (!canvas) {
    return;
  }

  // Remove previous lines
  const linesToRemove = canvas.getObjects("line");
  linesToRemove.forEach((line) => {
    canvas.remove(line);
  });

  // Create horizontal dashed line
  const horizontalLine = new fabric.Line(
    [0, mouseY, canvas.width ?? 0, mouseY],
    {
      stroke: "white",
      strokeWidth: 2,
      selectable: false,
      strokeDashArray: [8, 8],
      opacity: 0.5,
    }
  );

  // Create vertical dashed line
  const verticalLine = new fabric.Line(
    [mouseX, 0, mouseX, canvas.height ?? 0],
    {
      stroke: "white",
      strokeWidth: 2,
      selectable: false,
      strokeDashArray: [8, 8],
      opacity: 0.5,
    }
  );

  // Add lines to canvas
  canvas.add(horizontalLine);
  canvas.add(verticalLine);
  canvas.renderAll();
};

export const RemoveGridLine = (canvasRef: React.RefObject<fabric.Canvas>) => {
  const canvas = canvasRef.current;

  if (!canvas) {
    return;
  }

  // Remove previous lines
  const linesToRemove = canvas.getObjects("line");
  linesToRemove.forEach((line) => {
    canvas.remove(line);
    canvas.renderAll();
  });
}