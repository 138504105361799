import React, { useEffect, useState } from 'react'
import { SettingPathModel } from '../../../../Model/settingPath/SettingPathModel'
import UpdateSettingPath from '../api/UpdateSettingPath'
import { useDispatch } from 'react-redux'
import { startLoading } from '../../../../slice/loading/LoadingSlice'

type Props = {
  mode: string
  settingData: SettingPathModel
  isOpen: boolean
  onClose: () => void
}

const SettingPathForm = ({ isOpen, onClose, settingData, mode }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState<number|null>(0);
  const [title, setTitle] = useState<string|null>('');
  const [path, setPath] = useState<string|null>('');
  const [slug, setSlug] = useState<string|null>('');
  const [type, setType] = useState<string|null>('');
  const dispatch = useDispatch(); 
  const [status, setStatus] = useState(false);
const [updatePath, setUpdatePath] = useState<SettingPathModel|null>(null);
const [isStartUpdate, setIsStartUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (mode === "add") {
      setTitle(settingData.title || '');
      setPath(settingData.path || '');
      setSlug(settingData.slug || '');
      setType(settingData.type || '');
      setStatus(settingData.is_active || false);
    } else {
        setId(settingData.id||0)
        setTitle(settingData.title || '');
        setPath(settingData.path || '');
        setSlug(settingData.slug || '');
        setType(settingData.type || '');
        setStatus(settingData.is_active || false);
    }
  }, [mode, settingData]);

  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [isOpen]);

  const handleCloseModal = () => {
    onClose();
  }

  const handleSaveChanges = () => {
       dispatch(startLoading())
    let formData:SettingPathModel|null=null
    if(mode==="add"){
       formData = {
        title:title??"",
        path:path??"",
        slug:slug??"",
        type:path??"",
        is_active:status
      }
    }else{
     formData = {
      id:id??0,
      title:title??"",
      path:path??"",
      slug:slug??"",
      type:path??"",
      is_active:status
        
      }
    }
   
    setUpdatePath(formData)
    //console.log('Form Data:', formData);
    setIsStartUpdate(true)
 
  }


   const handleResetPath=()=>{
    setId(settingData.id||0)
        
        setTitle(null);
        setPath(null);
        setSlug(null);
        setType(null);
      setStatus(false);
    setIsStartUpdate(false)
    setUpdatePath(null)
    onClose()
   }
  return (
    <>
      <div className={`modal fade ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }} id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden={!isModalOpen}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">{mode} entry</h4>
              <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
            </div>
            <div className="modal-body p-4">
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <label className='form-label'>Title</label>
                  <input type='text' className='form-control' placeholder='Enter your title' value={title??""} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className='col-12 col-md-6'>
                  <label className='form-label'>Path</label>
                  <input type='text' className='form-control' placeholder='Enter your path' value={path??""} onChange={(e) => setPath(e.target.value)} />
                </div>
                <div className='col-12 col-md-12'>
                  <label className='form-label'>slug</label>
                  <textarea className="form-control" id="exampleFormControlTextarea1" value={slug??""} onChange={(e) => setSlug(e.target.value)}></textarea>
                </div>
                <div className='col-12 col-md-6'>
                  <label className='form-label'>Type</label>
                  <select className="form-select" aria-label="Default select example" value={type??""} onChange={(e) => setType(e.target.value)}>
                    <option value="">select type</option>
                    <option value="Projects">Projects</option>
                    <option value="System">System</option>
                  </select>
                </div>
                <div className='col-12 col-md-6'>
                  <label className='form-label'>Status</label>
                  <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={status} onChange={(e) => setStatus(e.target.checked)} />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active / Inactive</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-link text-secondary text-decoration-none" onClick={handleCloseModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save changes</button>
            </div>
          </div>
        </div>
      </div>


     {isStartUpdate &&
     updatePath &&
      <UpdateSettingPath
      updatePath={updatePath}
      resetUpdatePath={handleResetPath}
      mode={mode}
      />}
    </>
  )
}

export default SettingPathForm