import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addImageUrl, getUserImageFile, getUserNewProject } from '../../../slice/user/UserProject'
import { UploadImagePresignedS3Bucket, UploadImageS3Bucket } from '../../../api/projects/ProjectApi'
import { getUserLogin } from '../../../slice/user/UserSlice'
import Compressor from 'compressorjs';
import { addImageDepthData } from '../../../slice/imageDepthSlice/ImageDepthSlice'
type Props={
  jobId:number,
    resetUploadImage:(data:string)=>void
}

const UploadImageS3Api = ({ jobId,resetUploadImage}:Props) => {


    const getUserNewProjects= useSelector(getUserNewProject)
    const getUserImageFiles= useSelector(getUserImageFile)
      const getUserLogins= useSelector(getUserLogin)
        const dispatch= useDispatch();
        const isApi=useRef(true)
 


     useEffect(()=>{
        if(getUserNewProjects &&
             getUserNewProjects.id &&
              getUserImageFiles &&
              getUserLogins &&
              getUserLogins[0] &&
              getUserLogins[0].role &&
              isApi.current &&
              jobId
            ){

              const id=  getUserNewProjects.id
           isApi.current=false
              const formData= new FormData;

                const imgName=getUserImageFiles.name
                    const extensionOnly=imgName.split('.').pop()
                    const imagename=`image_${id}_original.${extensionOnly}`
                 // update the image depth data
                 dispatch(addImageDepthData({
                  current_image:imagename,
                  projectId:getUserNewProjects.id
                 }))

                 
               formData.append('image',getUserImageFiles)
                if( id) formData.append('id', id.toString())
                  formData.append("imagename",imagename)
             
               
                upLoadPreSignedImage(id, getUserImageFiles, imagename,getUserLogins[0].role,jobId);
         
                   
        }
     },[getUserNewProjects,getUserImageFiles,getUserLogins,jobId])
   

     const upLoadPreSignedImage = async (id: number, file: File, imagename: string, role: string,jobId:number) => {
      try {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('id', id.toString());

        formData.append('imagename', imagename);
        const response = await UploadImagePresignedS3Bucket(file, role, id.toString(),imagename, jobId.toString());
        
        if (response.status === 200 && response.data) {
          const { presignedUrl } = response.data;
          //const imageBuffer = await file.arrayBuffer();
          const uploadImage = await uploadImageUsingPresignedUrl(presignedUrl,file );
            //console.log("uploadImage",uploadImage)
          if (uploadImage) {
            dispatch(addImageUrl(imagename));
            resetUploadImage(imagename);
          }
        } else {
          throw new Error('Upload failed');
        }
      } catch (err) {
       // console.error("Error in upload Image:", err);
        resetUploadImage("fail");
      } finally {
        isApi.current = true;
      }
    };


 
    
    const uploadImageUsingPresignedUrl=async(presignedUrl: string, file:File)=> {
      try {
        const response = await fetch(presignedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type, // Adjust based on your image type
          },
          body: file,
         
        });
       
       // console.log("upload pres signed image Api", response)
        if (!response.ok) {
          throw new Error(`Failed to upload image. Status: ${response.status}`);
        }
       // console.log("Image uploaded successfully");
        return presignedUrl.split('?')[0]; // Return the URL of the uploaded image without query parameters
      } catch (err) {
        //console.error("Error uploading image", err);
        throw err;
      }
    }
  

  return (
    <></>
  )
}

export default UploadImageS3Api

// https://betadzinly.s3.us-east-2.amazonaws.com/projects/746/image_746_original.jpg
// https://betadzinly.s3.us-east-2.amazonaws.com/projects/746/GraceReformatted.jpg