import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface CanvasOptionStates {
    isCanvasGrid:boolean,
    isShowNearestPolygon:boolean,
    isShowNearestPoint:boolean,
    isShowNearestLine:boolean,
    mouseTolerance:number,
    zoomCanvas:number,
    ZoomCanvasValue:number
   

   
    
}

const initialState: CanvasOptionStates = {
    isCanvasGrid:true,
    isShowNearestPolygon:true,
    isShowNearestPoint:true,
    isShowNearestLine:true,
    mouseTolerance:5,
    zoomCanvas:1.0,
    ZoomCanvasValue:100
   

};

const CanvasOptionSlice = createSlice({
    name: "canvasOption",
    initialState,
    reducers: {
       addCanvasGrid:(state,action)=>{
           state.isCanvasGrid=action.payload
        
       },
         addShowNearestPolygon:(state,action)=>{
          state.isShowNearestPolygon=action.payload

    },
    addShowNearestPoint:(state,action)=>{
        state.isShowNearestPoint=action.payload

    },
    addShowNearestLine:(state,action)=>{
        state.isShowNearestLine=action.payload

    },
    addMouseTolerance:(state,action)=>{
        state.mouseTolerance=action.payload

    },
    resetCanvasOption:(state)=>{
        return initialState;
    },
    addZoomIn:(state,action)=>{
        const {zoominValue}=action.payload
        const zoomin=state.zoomCanvas
        state.zoomCanvas=zoomin+zoominValue
        
    },
    addZoomOut:(state,action)=>{
        const {zoomoutValue}=action.payload
        const zoomout=state.zoomCanvas
        state.zoomCanvas=zoomout-zoomoutValue
    },
updateZoomValue:(state,action)=>{
    state.ZoomCanvasValue=action.payload
},
    
    resetZoom:(state)=>{
        state.zoomCanvas=1.0
        state.ZoomCanvasValue=100
    }
    
   

    }

}
)

export const {
    addCanvasGrid,
    addShowNearestPolygon,
    addShowNearestPoint,
    addShowNearestLine,
    addMouseTolerance,
    resetCanvasOption,
    addZoomIn,
    addZoomOut,
    resetZoom,
    updateZoomValue
}=CanvasOptionSlice.actions;
export const getCanvasGrid=  (state:{canvasOption:CanvasOptionStates})=>state.canvasOption.isCanvasGrid;

export const getShowNearestPolygon=  (state:{canvasOption:CanvasOptionStates})=>state.canvasOption.isShowNearestPolygon;

export const getShowNearestPoint=  (state:{canvasOption:CanvasOptionStates})=>state.canvasOption.isShowNearestPoint;

export const getShowNearestLine=  (state:{canvasOption:CanvasOptionStates})=>state.canvasOption.isShowNearestLine;

export const getMouseTolerance=  (state:{canvasOption:CanvasOptionStates})=>state.canvasOption.mouseTolerance;  

 export const getZoomCanvas=  (state:{canvasOption:CanvasOptionStates})=>state.canvasOption.zoomCanvas;

 export const getZoomCanvasValue=  (state:{canvasOption:CanvasOptionStates})=>state.canvasOption.ZoomCanvasValue
export default CanvasOptionSlice.reducer
