import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CatBrandStyleSwatchModel } from "../../Model/material/CatBrandStyleSwatchModel";


interface SwatchesStates {
    doorSwatches:CatBrandStyleSwatchModel[]
    windowSwatches:CatBrandStyleSwatchModel[]
    roofSwatches:CatBrandStyleSwatchModel[]
    garageSwatches:CatBrandStyleSwatchModel[]
    gutterSwatches:CatBrandStyleSwatchModel[]
    wallSwatches:CatBrandStyleSwatchModel[]
    trimSwatches:CatBrandStyleSwatchModel[]
    isRefetchDoor:boolean
    isRefetchWindow:boolean
    isRefetchRoof:boolean
    isRefetchGarage:boolean
    isRefetchGutter:boolean
    isRefetchWall:boolean
    isRefetchTrim:boolean
    

   
    
}

const initialState: SwatchesStates = {
doorSwatches:[],
windowSwatches:[],
roofSwatches:[],
garageSwatches:[],
gutterSwatches:[],
wallSwatches:[],
trimSwatches:[],
isRefetchDoor:false,
isRefetchWindow:false,
isRefetchRoof:false,
isRefetchGarage:false,
isRefetchGutter:false,
isRefetchWall:false,
isRefetchTrim:false,

};

const SwatchesSlice = createSlice({
    name: "swatches",
    initialState,
    reducers: {
       addDoorSwatches:(state,action)=>{
            state.doorSwatches=action.payload
       },
       updateRefetchDoor:(state,action)=>{
        state.isRefetchDoor=action.payload
       },
       addWindowSwatches:(state,action)=>{
        state.windowSwatches=action.payload
       },
         updateRefetchWindow:(state,action)=>{
          state.isRefetchWindow=action.payload
         },
       addRoofSwatches:(state,action)=>{
        state.roofSwatches=action.payload
       },
         updateRefetchRoof:(state,action)=>{
          state.isRefetchRoof=action.payload
         },
       addGarageSwatches:(state,action)=>{
        state.garageSwatches=action.payload
        },
        updateRefetchGarage:(state,action)=>{
            state.isRefetchGarage=action.payload
           },
        addGutterSwatches:(state,action)=>{
            state.gutterSwatches=action.payload
        },
        updateRefetchGutter:(state,action)=>{
            state.isRefetchGutter=action.payload
           },
        addWallSwatches:(state,action)=>{
            state.wallSwatches=action.payload
        },  
        updateRefetchWall:(state,action)=>{
            state.isRefetchWall=action.payload
           },
        addTrimSwatches:(state,action)=>{
            state.trimSwatches=action.payload
        },
        updateRefetchTrim:(state,action)=>{
            state.isRefetchTrim=action.payload
           },
        resetDoorSwatches:(state)=>{
            state.doorSwatches=[]
            state.isRefetchDoor=false
        },  
        resetWindowSwatches:(state)=>{
            state.windowSwatches=[]
            state.isRefetchWindow=false
        },  
        resetRoofSwatches:(state)=>{
            state.roofSwatches=[]
            state.isRefetchRoof=false
        },
        resetGarageSwatches:(state)=>{
            state.garageSwatches=[]
            state.isRefetchGarage=false
        },
        resetGutterSwatches:(state)=>{
            state.gutterSwatches=[]
            state.isRefetchGutter=false
        },  
        resetWallSwatches:(state)=>{
            state.wallSwatches=[]
            state.isRefetchWall=false
        },
        resetTrimSwatches:(state)=>{
            state.trimSwatches=[]
            state.isRefetchTrim=false
        },  

    }
}
)

export const {
    addDoorSwatches,resetDoorSwatches,updateRefetchDoor,
    addWindowSwatches,resetWindowSwatches,updateRefetchWindow,
    addRoofSwatches,resetRoofSwatches,updateRefetchRoof,    
    addGarageSwatches,resetGarageSwatches,updateRefetchGarage,
    addGutterSwatches,resetGutterSwatches,updateRefetchGutter,
    addWallSwatches,resetWallSwatches,updateRefetchWall,
    addTrimSwatches,resetTrimSwatches,updateRefetchTrim
}=SwatchesSlice.actions;
export const getDoorSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.doorSwatches;
export const getWindowSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.windowSwatches;
export const getRoofSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.roofSwatches;
export const getGarageSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.garageSwatches;
export const getGutterSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.gutterSwatches;
export const getWallSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.wallSwatches;
export const getTrimSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.trimSwatches;


export const getIsRefetchDoor=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchDoor;
export const getIsRefetchWindow=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchWindow;
export const getIsRefetchRoof=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchRoof;
export const getIsRefetchGarage=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchGarage;
export const getIsRefetchGutter=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchGutter;
export const getIsRefetchWall=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchWall;
export const getIsRefetchTrim=  (state:{swatches:SwatchesStates})=>state.swatches.isRefetchTrim;

export default SwatchesSlice.reducer