import React from 'react'
import GenAiSegmentHome from '../../../../module/admin/adminGenAi/genAiSegment/GenAiSegmentHome'

const AdminGenAiSegmentPage = () => {
  return (
    <>

      <GenAiSegmentHome/>
    </>
  )
}

export default AdminGenAiSegmentPage