import React, { useEffect, useState } from 'react'
import GetAllProject from './showProject/GetAllProject'
import ShowListOfProject from './showProject/ShowListOfProject'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLogin } from '../../slice/user/UserSlice'
import { userModel } from '../../Model/user/UserModel'
import { getLoading } from '../../slice/loading/LoadingSlice'
import Loading from '../loading/Loading'
import GetScreenResolution from '../getUserScreenResolution/GetUserScreenResolution'
import WebSocketComponent from '../webSocket/StartWebSocket'
import RefreshHome from '../../Refresh/RefreshHome'
import ProjectPageRefresh from '../../Refresh/ProjectPageRefresh'
import Header from '../header/Header'
import GetAlgeniAttributes from '../ImageView/exteriorsAi/getAttributes/GetAlgeniAttributes'
import GetAllMaterialsHome from '../getAllMaterials/GetAllMaterialsHome'
import { addReloadProject, getIsReloadProject, getUserProject } from '../../slice/user/UserProject'
import MessageToast from '../messageToast/MesageToast'
import GetAllSettingPath from '../admin/settingPath/api/GetAllSettingPath'
import { GetUserRole_ID } from '../auth/util/GetUserRole_ID'

const ProjectHome = () => {


  const [isuserProjectApi, setIsUserProjectApi] = useState<boolean>(false)
  const getUserLogins = useSelector(getUserLogin) as userModel[]
  const getUserProjects= useSelector(getUserProject)
  const [isProject, setIsProject]= useState<boolean>(false)
  const getIsReloadProjects= useSelector(getIsReloadProject)
  const dispatch= useDispatch()
  useEffect(() => {
    if (getUserLogins && getUserLogins.length > 0) {
      if (getUserLogins[0] && 
        getUserLogins[0].projects && getUserLogins[0].projects.length > 0) {
        localStorage.removeItem("getMasterArrays")
        localStorage.removeItem("getSegregateSegments")
        GetUserRole_ID(getUserLogins)
      }
    }
  }, [getUserLogins])
  
  // allow the get all project 
  useEffect(()=>{
    if(getUserProjects && 
      getUserProjects.length==0){
        setIsProject(true)
    }
  },[getUserProjects])

  // allow to reload the project
useEffect(()=>{
  if(getIsReloadProjects){
    setIsProject(true)
  }
},[getIsReloadProjects])

   const handleResetProject=()=>{
    setIsProject(false)
    dispatch(addReloadProject(false))
   }

  const getLoadings= useSelector(getLoading)
  const [isLoading, setisLoading]= useState<boolean>(false)
  useEffect(()=>{
   console.log("getLoadings",getLoadings)
   if(getLoadings){
     setisLoading(true)
   }else{
    setisLoading(false)
   }
 },[getLoadings])


 const handleResetSettingPath=()=>{

 }
  return (

    <>
    
     {isProject &&
      <GetAllProject
      resetGetAllProject={handleResetProject}
       />}
      
    {isLoading && <Loading/>}
      <ShowListOfProject />

      <GetScreenResolution/>
 
 
      <ProjectPageRefresh/> 

      <GetAlgeniAttributes/>

      <GetAllMaterialsHome/>

      
<GetAllSettingPath
resetSettingPath={handleResetSettingPath}
/>

      <MessageToast/>
     
    </>
  )
}


export default ProjectHome
