import { fabric } from "fabric";

interface PointModel {
  x: number;
  y: number;
}

interface CustomGroupOptions extends fabric.IGroupOptions {
  groupName?: string;
  subGroupName?: string;
  childGroupName?: string;
}

export const collectPoints = (
  annotation: number[],
  segName: string,
  coordinates: number[],
  groupName: string,
  subGroupName: string,
  childName: string,
  textName: string,
  color: string,
  scaleX: number,
  scaleY: number,
  canvasRef: React.RefObject<fabric.Canvas>,
  isFillPolygon: boolean,
  top: number,
  left: number,

) => {
  if (annotation && scaleX && scaleY) {
    const point: PointModel[] = [];
    let polyName = segName;
    for (let i = 0; i < annotation.length; i += 2) {
      const x = annotation[i] * scaleX;
      const y = annotation[i + 1] * scaleY;
      point.push({ x, y });
    }

    if (point && point.length > 0) {
      makePolygon(
        point,
        coordinates,
        polyName,
        groupName,
        subGroupName,
        childName,
        textName,
        color,
        scaleX,
        scaleY,
        canvasRef,
        isFillPolygon,
        top,
        left,
       
      );
    }
  }
};

export const makePolygon = (
  point: PointModel[],
  coordinate: number[],
  polyName: string,
  groupName: string,
  subGroupName: string,
  childName: string,
  textName: string,
  color: string,
  scaleX: number,
  scaleY: number,
  canvasRef: React.RefObject<fabric.Canvas>,
  isFillPolygon: boolean,
  top: number,
  left: number,
 
) => {
  if (
    point &&
    point.length > 0 &&
    coordinate &&
    polyName &&
    scaleX &&
    scaleY &&
    canvasRef.current
  ) {
    const allObjects = canvasRef.current?.getObjects();
    const currentObject = allObjects.find((item) => item.name == polyName);
    // Delete the existing object if found
    if (currentObject) {
      canvasRef.current.remove(currentObject);
    }

    const text = new fabric.Text(textName, {
      left: coordinate[0] * scaleX,
      top: coordinate[1] * scaleY,
      fontFamily: "Arial",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Black background with slight opacity
      fontSize: 18,
      fill: "#fff",
      selectable: true,
      
      visible: false,
    });

    const polygon = new fabric.Polygon(point, {
      name: polyName,
      left: coordinate[0] * scaleX,
      top: coordinate[1] * scaleY,
      fill: isFillPolygon ? color : "transparent",
      originX: "left",
      originY: "top",
      hasRotatingPoint: false,
      hasBorders: false,
      hasControls: false,
      stroke: color,
      strokeWidth: 2,
      opacity: 0.1,
      visible: false,
      lockMovementX: true,
      lockMovementY: true,
    });

    const options: CustomGroupOptions = {
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      hasRotatingPoint: false,
      hasBorders: false,
      hasControls: false,
      groupName: groupName,
      subGroupName: subGroupName,
      childGroupName: childName, // Your custom property
      subTargetCheck: true,
      name: polyName,
    };
    const group = new fabric.Group([polygon, text], options);
   

    canvasRef.current?.add(group);

    canvasRef.current?.requestRenderAll();
  }
};

