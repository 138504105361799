
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { JobSegmentationModel } from '../../../../../Model/masterArray/MasterArrayModel';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { getSegmentInfo } from '../../../../../slice/userJobSlice/UserJobSlice';

import { getMasterArray, updateAnnotationPoint } from '../../../../../slice/canvas/masterArray/MasterArraySlice';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { updateAnnoatationInSegegtated } from '../../../../../slice/segegratedSlice/SegregatedSlice';
import { ISkewModel } from '../../../../../Model/Job/SamModel';
import { getSettingPath } from '../../../../../slice/settingPathSlice/SettingPathSlice';
import { getImagePath } from '../../util/imagePathUtil/ImagePathUtil';

const urlpath= process.env.REACT_APP_Project_Image
type Props = {
  grpName:string,
  subGroupName:string,
  childName:string
  isInfo: boolean;
  handleClose: () => void;
};


const Information: React.FC<Props> = ({ isInfo,grpName,subGroupName,childName,handleClose }) => {
  const [eachSegmentData, setEachSegmentData] = useState<JobSegmentationModel | null>(null);
  const [ isEdit, setIsEdit]= useState<boolean>(false)
   const [editKey, setEditKey]= useState<string|null>(null)
   const [copySkew, setCopySkew]= useState<ISkewModel|null>(null)
   const [annotation, setAnnotation]= useState<number[]>([])
   const [bbInt, setBbInt]= useState<number[]>([])
   const [bbFloat, setBbFloat]= useState<number[]>([])
   const [shortSeg, setshortSeg]= useState<string|null>(null)
   const getSettingPaths= useSelector(getSettingPath)
 
   const[imagePath, setImagePath]= useState<string|null>(null)
  
   const dispatch= useDispatch()
  const [inputValue, setInputValue] = useState<string>('');
  
  const [copied, setCopied] = useState<{ [key: string]: boolean }>({
    annotation: false,
    boundingBox: false,
    seg_type: false,
    annotation_type: false,
    label: false,
    confidence: false,
    seg_name: false,
    seg_short: false,
    perimeter_pixel: false,
    perimeter_feet: false,
    annotation_area_pixel: false,
    annotation_area_sqft: false,
    bb_area_pixel: false,
    bb_area_sqft: false,
    imageUrl: false,
    skew_value: false,
    angle: false,

  });

  useEffect(()=>{
    if(getSettingPaths && getSettingPaths.length>0){
      getImagePath(getSettingPaths,"Project Image",setImagePath)
      
    }
   },[getSettingPaths])
  
  // const getSegmentInfos = useSelector(getSegmentInfo);
 const getMasterArrays = useSelector(getMasterArray)
  const [imageUrl, setImageUrl]= useState<string|null>(null)
 useEffect(()=>{

  if( getMasterArrays &&
     getMasterArrays.image && 
     getMasterArrays.project_id&&
     getMasterArrays.jobId &&
      imagePath
     ){
   const image= `${imagePath}${getMasterArrays.project_id}/${getMasterArrays.jobId}/${getMasterArrays.image}`
  // console.log("image",image)
    setImageUrl(image)
  }
 },[getMasterArrays, imagePath])


 // when change in master Array then change the setEachSegmentData value
 useEffect(()=>{
  if(getMasterArrays &&
    getMasterArrays.allSeg &&
    getMasterArrays.allSeg.length>0 &&
    grpName &&subGroupName&&childName){
      const  groupValue=  getMasterArrays.allSeg.find(item=>item.name===grpName);
      if(groupValue &&
       groupValue.segmentation &&
       groupValue.segmentation.length>0
       ){
         const childGroupIndex=  groupValue.segmentation.findIndex(item=>
           Object.keys(item).includes(subGroupName))

           if(childGroupIndex!=-1){
             const subChildGroupValue= groupValue.segmentation[childGroupIndex][subGroupName] as JobSegmentationModel[]
         
                if(subChildGroupValue &&subChildGroupValue.length){
                  subChildGroupValue.map(child=>{
                     const name= child.segName
                     if(child.segName===childName){
                      setEachSegmentData(child)
                     }
                  })
                
                
                }
           }
       }

    }
 },[getMasterArrays,grpName,subGroupName,childName])

 



  

  const handleCopy = (type: string) => {
    const textToCopy = document.querySelector(`.${type}-data-text`)?.textContent;
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopied((prev) => ({ ...prev, [type]: true }));
          setTimeout(() => setCopied((prev) => ({ ...prev, [type]: false })), 2000);  // Reset tooltip after 2 seconds
        })
        .catch(() => {
          setCopied((prev) => ({ ...prev, [type]: false }));  // Optionally handle errors here
        });
    }
  };
  const handleImageCopy = () => {
    if (imageUrl) {
      navigator.clipboard.writeText(imageUrl)
        .then(() => {
          setCopied((prev) => ({ ...prev, imageUrl: true }));
          setTimeout(() => setCopied((prev) => ({ ...prev, imageUrl: false })), 2000);
        })
        .catch(() => {
          setCopied((prev) => ({ ...prev, imageUrl: false }));
        });
    }
  };
     
  const handleEdit=(data:any)=>{
    setEditKey(data)
   
    setIsEdit(true)

  }


  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
    const inputVal =event.target.value
    const numberArray: number[] = inputVal.split(',').map(num => parseFloat(num.trim()));
    
      if(editKey=="annotation"){
        setAnnotation(numberArray)
         if(eachSegmentData){
          setEachSegmentData(prev=>({
            ...prev,
            details:{
              ...prev?.details,
              annotation:numberArray
            }
          }))
         }
        
      } 
      else  if(editKey=="bb_annotation_float"){
        if(eachSegmentData){
          setEachSegmentData(prev=>({
            ...prev,
            details:{
              ...prev?.details,
              bb_annotation_float:numberArray
            }
          }))
         }
      }
      else if(editKey=="bb_annotation_int"){
        setBbInt(numberArray)
        if(eachSegmentData){
          setEachSegmentData(prev=>({
            ...prev,
            details:{
              ...prev?.details,
              bb_annotation_int:numberArray
            }
          }))
         }
  }
      else if(editKey=="perimeter_pixel" && inputVal){
       // setBbInt(numberArray)
       const val=parseInt(inputVal)
        if(eachSegmentData){
          setEachSegmentData(prev=>({
            ...prev,
            details:{
              ...prev?.details,
              perimeter_pixel:val
            }
          }))
         }
  }
      else if(editKey=="bb_dimension_pixel" && inputVal){
       // setBbInt(numberArray)
       const stringArray = inputVal.split(",");
        if(eachSegmentData){
          setEachSegmentData(prev=>({
            ...prev,
            details:{
              ...prev?.details,
              bb_dimension_pixel:stringArray
            }
          }))
         }
  }
}
   
   const hanldeSubmitEdit=(key:string)=>{
   
        const data={
          segName:childName,
           masterData:eachSegmentData?.details,
            childgroupName:subGroupName ,
            seg_short:eachSegmentData?.details?.seg_short,
            groupName:grpName,
        }
        
         // update in segregated data Array
         dispatch(updateAnnoatationInSegegtated(data))
      //  dispatch(updateAnnotationPoint(data))
         
        setIsEdit(false)
      }
     
   
      const handleSkew=(data:ISkewModel)=>{
        setCopySkew(data)
      }
  return (
    
    // <Modal show={isInfo} onHide={handleClose}>
    <div className="container">
      <div className='row'>
        <div className="col-12 col-md-4">
        {imageUrl && (
        <div className="seg-data rounded border shadow sticky-top">
            <div className='d-flex align-items-center justify-content-between p-3 py-2 border-bottom'>
              <h6 className='pe-2 m-0'>Image</h6>
              <div className="d-flex gap-1">
                <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        className="custom-tooltip"
                        id={`tooltip-imageUrl`}
                      >
                        {copied.imageUrl ? "Copied!" : "Copy"}
                      </Tooltip>
                    }
                  >
                  <i
                    className="bi bi-copy text-primary ms-2"
                    onClick={handleImageCopy}
                    role="button"
                  ></i>
                </OverlayTrigger>
              </div>
            </div>

            <div className="p-3">
              <input
                  type="text"
                  className="form-control bg-white" // Add margin-top for spacing
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={imageUrl} // Set the input value to the image URL
                  readOnly // Make the input read-only if you want to prevent editing
              />

                {/* <div className="d-flex align-items-center mt-2">
                  <span className="me-2">Copy the Image Url:</span>
                  
                </div> */}
                <img className='rounded mt-3' src={imageUrl} alt="" />
            </div>
            
          </div>
          )}
          
            
         
        </div>
         
          
        <div className="col-12 col-md-8">
  {eachSegmentData && eachSegmentData.details && (
    <div className='row g-3'>
      {Object.entries(eachSegmentData.details).map(([key, value]) => {
        if (key !== "skew_value") {
          return (
            <div key={key} className='col-6'>
              <div className="seg-data rounded border shadow">
                <div className='d-flex align-items-center justify-content-between p-3 py-2 border-bottom'>
                  <h6 className='pe-2 m-0'>{key.replace('_', ' ').toUpperCase()}</h6>
                  <div className="d-flex gap-1">
                    <i className="bi bi-pencil-square text-primary" onClick={() => handleEdit(key)}></i>
                    <OverlayTrigger 
                      placement="top" 
                      overlay={<Tooltip className='custom-tooltip' id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>}>
                      <i className="bi bi-copy text-primary" onClick={() => handleCopy(key)}></i>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="p-3 py-2">
                  <p className={`${key}-data-text m-0 text-truncate`}>
                    {Array.isArray(value) ? value.join(', ') : value}
                  </p>

                  {isEdit && editKey === key && (
                    <div className="mb-2 mt-3">
                      <label htmlFor="exampleTextArea" className="form-label fw-semibold">
                        Enter {editKey}
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleTextArea"
                        rows={3} // Number of rows to define the height
                        value={inputValue}
                        onChange={handleChange}
                      />
                      <button className='btn btn-primary btn-sm mt-2' onClick={() => hanldeSubmitEdit(key)}>Submit</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        } else if (key === "skew_value" && 
          eachSegmentData.details) {
          const valuess = eachSegmentData.details[key] as ISkewModel
          return (
            <div key={key} className='col-6'>
              <div className="seg-data rounded border shadow">
                <div className='d-flex align-items-center justify-content-between p-3 py-2 border-bottom'>
                  <h6 className='pe-2 m-0'>{key.replace('_', ' ').toUpperCase()}</h6>
                  <div className="d-flex gap-1">
                    <i className="bi bi-pencil-square text-primary" onClick={() => handleEdit(key)}></i>
                    <OverlayTrigger 
                      placement="top" 
                      overlay={<Tooltip className='custom-tooltip' id={`tooltip-${key}`}>{copied[key] ? 'Copied!' : 'Copy'}</Tooltip>}>
                      <i className="bi bi-copy text-primary" 
                      onClick={() => handleSkew(valuess)}
                      ></i>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="p-3 py-2">
                  <p className={`${key}-data-text m-0 text-truncate`}>
                    {valuess && valuess.skew_x && 
                    `Skew X: ${valuess.skew_x}`
                    }
                  </p>
                  <p className={`${key}-data-text m-0 text-truncate`}>
                    {valuess && valuess.skew_y && 
                    `Skew y: ${valuess.skew_y}`
                    }
                  </p>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  )}
</div>
      </div>
    </div>
      // {/* <Modal.Footer>
      //   <button className='btn btn-tranparent border-primary text-primary' onClick={handleClose}>
      //     Close
      //   </button>
      // </Modal.Footer> */}
    // </Modal>
  );
}

export default Information;



