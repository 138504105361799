import axios from "axios";
import { ProjectModel, UpdateProjectModel, UploadImageModel } from "../../Model/project/ProjectModel";

import apiCilent from "../interceptor/ApiCilent"
 const serverApi =process.env.REACT_APP_SERVER_URL
// const serverApi getUserLogin="https://ztrbpzguq1.execute-api.us-east-2.amazonaws.com/dev"

export function GetallProject() {

   // return axios.get(`${serverApi}/projects`,)
    return axios.get(`${serverApi}/customer/project`,)
        .then(response => {
            // console.log("project", response)
            return response
        }).catch(error => {
            throw error;
        });


}


export function userProject(projectList:[], role:string){
    return apiCilent.post(`/${role}/project`,projectList)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}

export function userCreateProject(projectList:ProjectModel,email:string,role:string){
    const data={
        projectList:projectList,
        email:email,
        role:role
    }
    return apiCilent.post(`/${role}/create-project`,data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}


export function UpdateProjectData(data:UpdateProjectModel){

    const data1={
        id:data.id,
        email:data.email,
        image:data.image,
        jobId:data.jobId,
        compressImage:data.compressImage
    }

    return apiCilent.post(`/${data.role}/update-image-project`,data1)
    .then(response => {
        // console.log("update project", response)
        return response
    }).catch(error => {
        throw error;
    });
}

export function UploadImagePresignedS3Bucket(data:File,role:string, projectId:string,imagename:string,jobId:string){
    const dataValue={
        fileName: imagename, 
        fileType: data.type,
         projectId:projectId,
            jobId:jobId
    }
    return apiCilent.post(`/${role}/project/pre-signed-uploadImage`,dataValue)
    .then(response => {
        // console.log("upload image", response)
        return response
    }).catch(error => {
        throw error;
    });
}
//   const dispatch= useDispatch()
export function UploadImageS3Bucket(data: FormData, role:string) {

    // const dispatch= useDispatch()

    return apiCilent.post(`/${role}/project/uploadImage`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'Authorization': `Bearer ${role}`
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (percentCompleted) {
            console.log(`Upload Progress: ${percentCompleted}%`);

           // dispatch(addUploadMessage(`Uploading Image  ${percentCompleted}%`))

          }
        }
      }
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Upload failed:", error.response.data);
        throw new Error(error.response.data.message || "Upload failed");
      }
      console.error("Upload error:", error);
      throw error;
    });
  }
export function UploadImageS3Bucket1(imageData:UploadImageModel){
   
    return apiCilent.post(`/${imageData.role}/project/uploadImage2`,imageData,{
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        //   'Authorization': `Bearer ${imageData.role}`
        // }
      })
    .then(response => {
        // console.log("upload image", response)
        return response
    }).catch(error => {
        throw error;
    });
}

// update job id into project
export function UpdateJobInProject(projectId:number, jobId:number){
    const data={
        projectId:projectId,
        jobId:jobId
    }
    return apiCilent.post(`/project/update-job`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}


// delete project by Id 
export function ProjectDeleteById(projectId:number){
    const data={
        projectId:projectId,
       
    }
    return apiCilent.post(`/project/delete`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}


export function ProjectEdit(projectData:ProjectModel){ 
    const data={
        projectData
    } 
    return apiCilent.post(`/project/edit`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
 }