import { MaterialBrandModel } from "../../../../../../Model/material/MaterialBrandModel";
import { NameModel } from "../../../../../../Model/material/MaterialModel";
import { MaterialStyleModel } from "../../../../../../Model/material/MaterialStyleModel";


export const GetBrandscategoryIdBased = (
    catId: number,
     brands: MaterialBrandModel[]):NameModel[] => {
    let brandName:NameModel[]=[];
    brands.map((brand)=>{
        if(brand.material_category_id===catId){
            brandName.push({
                id:brand.id,
                title:brand.title
            })
        }
       
    })
    return brandName;

}
export const GetStyleBrandIdcategoryIdBased = (
    
    brandId:number,
     syles: MaterialStyleModel[]):NameModel[] => {
    let styleName:NameModel[]=[];
    syles.map((style)=>{
        if(style.material_brand_id===brandId ){
            styleName.push({
                id:style.id,
                title:style.title
            })
        }
       
    })
    return styleName;

}