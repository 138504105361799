import { JobSegmentationModel, MasterModel } from "../../../../../Model/masterArray/MasterArrayModel";

export const updateGroupSegment = (
    groupname: string,
    childGroupName: string,
    getMasterArrays: MasterModel
): JobSegmentationModel[] |undefined => {
    if (getMasterArrays &&
        getMasterArrays.allSeg &&
        getMasterArrays.allSeg.length > 0) {

        const groupValue = getMasterArrays.allSeg.find(item => item.name === groupname);
        if (groupValue &&
            groupValue.segmentation &&
            groupValue.segmentation.length > 0) {
            const childGroupIndex = groupValue.segmentation.findIndex(item =>
                Object.keys(item).includes(childGroupName));

            if (childGroupIndex != -1) {
                const subChildGroupValue = groupValue.segmentation[childGroupIndex][childGroupName];
                return subChildGroupValue;
            }
        }
    }
}