import React, { useEffect, useRef, useState } from 'react'
import UploadImageS3Api from './UploadImageS3Api'
import UploadCompressImage from './UploadCompressImage'
import { original } from '@reduxjs/toolkit'
import CreatePalletFolder from './CreatePalletFolder'


export interface responseImage{
    original:string
    compress:string
}
type Props={
  jobId:number
    resetUploadImage:(data:string)=>void
    isUploadImageApi:boolean
    resetUploadHomeImage:(data:responseImage)=>void
}
const UploadImageHome = ({isUploadImageApi,resetUploadImage,resetUploadHomeImage,jobId}:Props) => {
     const [isImageOriginal, setIsImageOriginal]= useState<Boolean>(false)
     const [isImageCompress, setIsImageCompress]= useState<Boolean>(false)
     const [originalImage, setOriginalImage]= useState<string|null>()
     const [compressImage, setcompressImage]= useState<string|null>()
     const [isPalletCreated, setIsPalletCreated]= useState<boolean>(false)
    
    const isApi= useRef(true)
    useEffect(()=>{
        if(isUploadImageApi &&
          isApi.current &&
           jobId){
            isApi.current=false
            
            setIsImageOriginal(true)
        }
    },[isUploadImageApi])

    const handleResetUploadImage=(data:string)=>{
        setIsImageOriginal(false)
        if(data==="fail"){
            resetUploadImage("fail")
            isApi.current=true
        }else{
            
            setOriginalImage(data)
      setIsImageCompress(true)
        }
       
    }


    const handleResetCompressImage=(data:string)=>{

        setIsImageCompress(false)
        if(data==="fail"){
            resetUploadImage("fail")
          //  isApi.current=true
        }else{
            setcompressImage(data)
            setIsPalletCreated(true)
            // const mess={
            //     original:originalImage||"",
            //     compress:data||""
            // }
            // setOriginalImage(null)
            // if(mess){
               // resetUploadHomeImage(mess)
              
            }
           // isApi.current=true
        }
    



    const handleResetPalletFolder=(mess:boolean)=>{
        setIsPalletCreated(false)

          if(mess){
            const value={
                original:originalImage||"",
                 compress:compressImage||""
            }
           resetUploadHomeImage(value)
          // setIsSaveEmbedding(true)
          }else{
            resetUploadImage("fail")
          }
          isApi.current=true
       
    }
  return (
    <div>
        {/* original image upload */}
         {isImageOriginal &&
         jobId &&
        <UploadImageS3Api
        jobId={jobId}
        resetUploadImage={handleResetUploadImage}
       
        />
        }
        
{/*             upload compressed image */}
        {isImageCompress &&
        jobId &&
        <UploadCompressImage
        jobId={jobId}
        resetUploadcompressImage={handleResetCompressImage}
        />
        }

            {/*  created Pallet folder name */}
           {isPalletCreated &&
            <CreatePalletFolder
            isPalletCreated={isPalletCreated}
            resetPalletFolder={handleResetPalletFolder}
            />}


    </div>
  )
}

export default UploadImageHome