import React, { useEffect, useRef, useState } from 'react'
import { GetUsersJob } from '../../../api/jobs/JobApi'
import { useDispatch } from 'react-redux'
import { addJobData } from '../../../slice/userJobSlice/UserJobSlice'
import { JobModel } from '../../../Model/Job/JobModel'
import { SegMentGroupModel } from '../../../Model/masterArray/MasterArrayModel'
import GetMasterGroup from './GetMasterGroup'
import { stopLoading } from '../../../slice/loading/LoadingSlice'


type Props={
    resetJob:(data:string)=>void
    jobId:number
}
const GetUserJob = ({resetJob,jobId}:Props) => {
           const dispatch= useDispatch()
            const isApi=useRef(true)
            const [jobData, setJobData]= useState<JobModel|null>(null)

  useEffect(()=>{
    if(jobId &&
        isApi.current
    ){
        isApi.current= false
        userJob(jobId)
    }
  },[jobId])
    const userJob=async (jobId:number)=>{

        try{
            const response= await GetUsersJob(jobId)
             // console.log("user job",response)
            if(response && response.status===200){
               
               //console.log("user job",response.data)
               setJobData(response.data)
             //  resetJob("success")
              
               

            }
        } catch(err){

            resetJob("fail")
        }
        

    }


   const handleResetData=()=>{
    dispatch(stopLoading())
    isApi.current=true
     dispatch(addJobData(jobData))
     setJobData(null)
     
     resetJob("success")
   }
    

  //  console.log("jobData",jobData)
  return (
    <>
        
    {/*  create */}
        {
        jobData!=null &&
          <GetMasterGroup
          jobData={jobData}
          resetMasterGroup={handleResetData}
          />
         
        }
    </>
  )
}

export default GetUserJob