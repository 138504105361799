import React, { useEffect } from 'react'
import AuthRefresh from './AuthRefresh'
import RefreshCanvas from './RefreshCanvas'
import ProjectPageRefresh from './ProjectPageRefresh'
import CommentRefesh from './commentRefresh/CommentRefesh'
import { switchToOutline } from '../slice/tabControl/TabControlSlice'
import { changeSegmentTab } from '../slice/tabControl/TabControlSlice'
import { useDispatch } from 'react-redux'

const RefreshHome = () => {
    const dispatch= useDispatch()

  useEffect(()=>{
    dispatch(changeSegmentTab("details"))
    dispatch(switchToOutline("segment"))
  },[])
  //console.log("refreshed worked")
  return (
    <div>
        
        <AuthRefresh/>
        <RefreshCanvas/>
          {/*  refresh comment */}
     <CommentRefesh />
        <ProjectPageRefresh/>
    </div>
  )
}

export default RefreshHome