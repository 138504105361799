import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addJobData, getCurrentTransImage,  getSelectedSegment, getUserJobBase64, getUserJobData, updateBase64 } from '../slice/userJobSlice/UserJobSlice'
import { addMasterArray, getMasterArray } from '../slice/canvas/masterArray/MasterArraySlice'
import { loadEnvFile } from 'process'
import { addSegment, getAllSegment } from '../slice/segment/SegmentSlice'
import AuthRefresh from './AuthRefresh'
import RefreshHome from './RefreshHome'
import RefreshMaterialHome from './material/RefreshMaterialHome'
import ProjectPageRefresh from './ProjectPageRefresh'
import GetBase64FromApi from '../module/projectPage/base64Image/GetBase64FromApi'
import { getSegregateSegment, segegratedJobSegment, startSegregation } from '../slice/segegratedSlice/SegregatedSlice'
import GetAllSettingPath from '../module/admin/settingPath/api/GetAllSettingPath'

const RefreshCanvas = () => {
  
      const getSelectedSegments= useSelector(getSelectedSegment)

      const getSegregateSegments= useSelector(getSegregateSegment)
      const getUserJobBase64s= useSelector(getUserJobBase64)
        const getMasterArrays= useSelector(getMasterArray)
        const getAllSegments= useSelector(getAllSegment)
        const isApiImage= useRef(true)
        const isSegment= useRef(true)
        const isMasterArray= useRef(true)
        const isSegegratedData= useRef(true)
        const isnoApiImage= useRef(true)
        const isnoSegment= useRef(true)
        const isnoMasterArray= useRef(true)
        const isnoSegegratedData= useRef(true)
        const [projectId, setProjectId] = useState<number | null>(null)
        const [jobId, setJobId] = useState<number | null | undefined>(null)
        const [isJobImage, setIsJobImage] = useState<boolean>(false)
        const  [imageUrl, setImageUrl] = useState<string | null>()
      const dispatch= useDispatch()

// user Job Image
    useEffect(()=>{
        if((getUserJobBase64s=="" &&
            isnoApiImage.current  &&
            getMasterArrays.jobId &&
            getMasterArrays.project_id &&
            getMasterArrays.image
        )){
            setJobId(getMasterArrays.jobId)
            setProjectId(getMasterArrays.project_id)
            setIsJobImage(true)
            setImageUrl(getMasterArrays.image)
            isnoApiImage.current= false
           
        }
      
    },[getUserJobBase64s,getMasterArrays])

    //
    useEffect(()=>{
       
        if((getSegregateSegments.length===0 &&
            isnoSegegratedData.current)){
            isnoSegegratedData.current= true
            const segData= localStorage.getItem("getSegregateSegments")
            if(segData){
                const segDataParse= JSON.parse(segData)
                console.log("refresh")
                dispatch(segegratedJobSegment(segDataParse))
            }
          
        }
       else  if(getSegregateSegments && 
        getSegregateSegments.length>0 &&
        isSegegratedData.current ){
            isSegegratedData.current= false
            localStorage.setItem("getSegregateSegments",JSON.stringify(getSegregateSegments))
        }
    },[getSegregateSegments])

    //selected selectedSegment
    useEffect(()=>{
        if((getSelectedSegments.length===0)){
            const segData= localStorage.getItem("getSelectedSegments")
            if(segData){
                const segDataParse= JSON.parse(segData)
               // dispatch(segegratedJobSegment(segDataParse))
            }
          
        }
       else  if(getSelectedSegments.length>0){
            localStorage.setItem("getSelectedSegments",JSON.stringify(getSelectedSegments))
        }
    },[getSelectedSegments])

    // master Array
    useEffect(()=>{

        
        if(getMasterArrays && getMasterArrays.jobId===undefined && isnoMasterArray.current ){
            
            const mastAray= localStorage.getItem("getMasterArrays")
            if(mastAray ){
                isnoMasterArray.current= false
                const masterArray= JSON.parse(mastAray)
               
                dispatch(addMasterArray(masterArray))
                dispatch(startSegregation())
            }
        }else if (getMasterArrays && getMasterArrays.jobId && isMasterArray.current){
            isMasterArray.current= false
            localStorage.setItem("getMasterArrays", JSON.stringify(getMasterArrays))
        }
    },[getMasterArrays])



    //add segements

    useEffect(()=>{
        if(getAllSegments && getAllSegments.length===0 && isnoSegment.current){
            isnoSegment.current= false
             const allsegment=localStorage.getItem("getAllSegments")
             if(allsegment){
                const allSeg= JSON.parse(allsegment)
                dispatch(addSegment(allSeg))
             }

        }else if(getAllSegments &&getAllSegments.length>0 && isSegment.current){
            isSegment.current= false
            localStorage.setItem('getAllSegments',JSON.stringify(getAllSegments))
        }
    },[getAllSegments])

    const handleResetImageUrl = (mess: boolean) => {
        setJobId(null)
    
        setIsJobImage(false)
        setJobId(null)
        setImageUrl(null)
        setProjectId(null)
    }


    const handleResetsettingPath = () => {}
  return (
    <div>
         < ProjectPageRefresh/>
          <RefreshMaterialHome/>

            {/* get base64 through Api */}
       {isJobImage &&
       imageUrl &&
       projectId &&
       jobId &&
        <GetBase64FromApi
        jobId={jobId}
        url={imageUrl}
        projectId={projectId}
        resetCallApi={handleResetImageUrl}
        />} 

        <GetAllSettingPath
        resetSettingPath={handleResetsettingPath}
        />
    </div>
  )
}

export default RefreshCanvas