import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSearchSwatch } from '../../../../../slice/materialSlice/SearchMaterial'
import { CatBrandStyleSwatchModel } from '../../../../../Model/material/CatBrandStyleSwatchModel'
import { JobSegmentationModel } from '../../../../../Model/masterArray/MasterArrayModel'
import { getSelectedSegment } from '../../../../../slice/userJobSlice/UserJobSlice'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'
import { addSvgImage, addSvgOption, getSvgOption } from '../../../../../slice/svdImageSlice/SvgImageSlice'
import ReplaceSwatchHome from '../../replaceSwatch/ReplaceSwatchHome'
import { stopLoading } from '../../../../../slice/loading/LoadingSlice'
import { SelectedGroupModel } from '../../../../../Model/svgModel/SvgModel'
import InformationSwatch from '../../selectSwatch/InformationSwatch'
import { SearchMaterialModal } from '../../../../../Model/material/SearchMaterial'
const s3path= process.env.REACT_APP_S3BucketMaterial

const SearchEdSwatchImage = () => {
    const dispatch= useDispatch()
    const path =`${s3path}/liv/materials`;
    const [selctedSwatch, setSelectedSwatch]= useState< CatBrandStyleSwatchModel|null>()
      const [palletUrl, setPalletUrl] = useState<string|null>(null); // Base64 string for the image
      const [activeIndex, setActiveIndex] = useState<number|null>(null); // Active index for the image
     const [groupSegmentData, setGroupSegmentData]= useState<JobSegmentationModel[]>([])
       const [isApi, setIsApi]= useState<boolean>(false)
         
  const getSvgOptions= useSelector(getSvgOption)
       
    const getAllSearchSwatchs= useSelector(getAllSearchSwatch)
        const getSelectedSegments= useSelector(getSelectedSegment)

    // update the selected segment for swatch
      useEffect(()=>{
        if(getSelectedSegments && getSelectedSegments.length>0){
          setGroupSegmentData(getSelectedSegments)
        }
      },[getSelectedSegments])


    const handleSwatch=(data:CatBrandStyleSwatchModel, index: number)=>{
        if(data.is_featured){
          handleSwatchFeature(data)
    
        }else{
          //console.log("no feature")
          setSelectedSwatch(data)
           const swatchUrl=`${path}/${data.photo}`
       
          setPalletUrl(swatchUrl)
          setActiveIndex(index); // Set the active index here
          if(groupSegmentData && groupSegmentData.length==0){
           dispatch(addMessage({
             isShow:true,
             mess:"Please select the segment",
              toastType:"Error"
            }))
            // alert('Please select the segment')
          }else {
           dispatch(addSvgOption({
             isOpenModal:true,
             svgModelName:""
            }))
          }
        }
           
       
      
      }

       const handleSwatchFeature=(data:CatBrandStyleSwatchModel)=>{
          //console.log("yes feature")
          if(data && data.bucket_path && data.photo){
            setSelectedSwatch(data)
            const swatchUrl=data.bucket_path=="default"?`${path}/${data.photo}`:data.bucket_path
           setPalletUrl(swatchUrl)
           if(groupSegmentData && groupSegmentData.length==0){
            
            dispatch(addMessage({
              isShow:true,
              mess:"Please select the segment",
               toastType:"Error"
             }))
            
           }else {
            dispatch(addSvgOption({
              isOpenModal:true,
              svgModelName:""
             }))
           }
          }
        }


        
          const handleResetSwatch=(mess:boolean)=>{
            setSelectedSwatch(null)
            setPalletUrl(null)
            setIsApi(false)
            dispatch(stopLoading())
            if (mess) {
            } else if (!mess) {
            
              // alert('Error on swatch image')
              dispatch(addMessage({
                isShow:true,
                mess:"Error on swatch image",
                 toastType:"Error"
               }))
            }
          }


          // open opencv
          useEffect(()=>{
          
            if(getSvgOptions.svgModelName==="openCv" &&
             !getSvgOptions.isOpenModal &&!isApi &&
              palletUrl){
                 // console.log("setIsApi is true")
                  setIsApi(true)
                }
               
            
            
          },[getSvgOptions,palletUrl, isApi])

          useEffect(()=>{
          
            if(getSvgOptions.svgModelName==="reactSvg" && 
              groupSegmentData && 
              groupSegmentData.length>0 && palletUrl){
              reactSvg(groupSegmentData,palletUrl)
            }
          
            
          },[getSvgOptions,groupSegmentData,palletUrl])

          
          const reactSvg=(groupSegmentData:JobSegmentationModel[],swatchUrl:string)=>{
            const allSelected:SelectedGroupModel[]=[]
            if(groupSegmentData && groupSegmentData.length>0 && swatchUrl){
                    groupSegmentData.map(item=>{
                            if(item.segName && item.details?.annotation && item.details.bb_annotation_int){
                                const data={
                                    segname:item.segName,
                                    annoatation:item.details?.annotation ??[],
                                    coOrdinate:item.details?.bb_annotation_int??[]
                                }
                                allSelected.push(data)
                            }
                    })
                 
              if(allSelected && allSelected.length>0){
                dispatch(addSvgImage({
                    swatchUrl:swatchUrl,
                    selectedGroup:allSelected
                   }))
              }
                   
               }
          } 
          
          const [show, setShow] = useState(false);
          const handleClose = () => setShow(false);

          const handleCloseSwatch=()=>{
            setShow(false)  
            setSelectedSwatch(null)
          }

          const handleShow = (data:CatBrandStyleSwatchModel) =>{
              console.log("data", data)
            //setShow(true);
             setSelectedSwatch(data)
           
           } 

           
  return (
    <>
     <div className="row g-2">
{  getAllSearchSwatchs && 
     getAllSearchSwatchs.length > 0 &&
     getAllSearchSwatchs[0]?.Swatches &&
         <span>{getAllSearchSwatchs[0]?.Swatches.length}</span>
         }
     {getAllSearchSwatchs && 
     getAllSearchSwatchs.length > 0 &&
     getAllSearchSwatchs[0]?.Swatches &&
     getAllSearchSwatchs[0].Swatches.length === 0 ? (
      <span>No swatch</span>
     ) : (
      getAllSearchSwatchs[0]?.Swatches?.slice(0, 50).map((item, index) => {
        return (
          <div className="col-4" key={`${item.id}-${index}`}>
            <div 
            className={`palletsbox ${activeIndex === index ? "active" : ""}`} // Add active class conditionally
            >
              <div className="palletsbox__header">
                
                <div>
                  <img src={`${path}/${item?.photo}`} alt="swatch" 
                     onClick={() => handleSwatch(item, index)}
                  />
                  <svg
                    className="checkicon md"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    ></circle>
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    ></path>
                  </svg>
                  
                </div>
                <span
                  role="button"
                  className="pallets-info"
                  onClick={()=>handleShow(item)}// Show Offcanvas
                >
                  <i className="bi bi-info"
                 onClick={()=>handleShow(item)}
                  ></i>
                </span>
                {/* <h6 className='small'>{item?.title}</h6> */}
              </div>
              
            </div>
          </div>
        );
      })
     )}
    
     </div>

     { palletUrl!=null &&
     isApi &&
     selctedSwatch  &&
     <ReplaceSwatchHome
     selctedSwatch={selctedSwatch}
      palletUrl={palletUrl}
      resetSwatch={handleResetSwatch}
      />}

{/* {show &&
     selctedSwatch &&
      <InformationSwatch
      show={show}
      selctedSwatch={selctedSwatch}
      onclose={handleCloseSwatch}
      />} */}
   
   
    </>
  )
}

export default SearchEdSwatchImage