import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSearchStyleName } from '../../../../../../slice/materialSlice/MaterialStyleSlice'
import { NameModel } from '../../../../../../Model/material/MaterialModel'
import { addSearchStyleId, addStyleSearchSwatch, getIsFilter, getSearchCatId, updateIsSawatchSearching, updateIsStyleSearching } from '../../../../../../slice/materialSlice/SearchMaterial'
import { getGroupName } from '../../../../../../slice/toolActive/ToolActiveSlice'
import { getMasterArray } from '../../../../../../slice/canvas/masterArray/MasterArraySlice'
import { GetCetegoryColor } from '../util/getCategoryColor'


const s3path= process.env.REACT_APP_S3BucketMaterial
type Props = {
  addSelectedStyle: (brands: NameModel[]) => void;
}
const StyleSearch = ({addSelectedStyle}:Props) => {


    const dispatch= useDispatch()
    const [color, setColor] = React.useState<string | null | undefined>(null);
    const [styles, setStyles]= useState<NameModel[]>([])
    const getMasterArrays= useSelector(getMasterArray)
    const getIsFilters = useSelector(getIsFilter);

// update filter when close
    useEffect(() => {
      if(!getIsFilters){
        setStyles([]);
      }  
    }, [getIsFilters]);
    // set the color
    useEffect(() => {
      if( getMasterArrays && getMasterArrays.allSeg && getMasterArrays.allSeg.length>0){
        GetCetegoryColor(getMasterArrays.allSeg,setColor)
      }
    },[getMasterArrays])

    const path =`${s3path}/liv/materials`;
      const getSearchCatIds= useSelector(getSearchCatId)
    
         const getGroupNames= useSelector(getGroupName)

      
   const getSearchStyleNames= useSelector(getSearchStyleName)
    const handleStyle=(data:NameModel)=>{
     
           if(data && 
                data.id && 
                data.title){
                dispatch(addSearchStyleId({
                  id:data.id
                 }))
        
                 setStyles([...styles, data])
                 if (styles.some(style => style.id === data.id)) {
                  const sty = styles.filter(style => style.id !== data.id);
                  setStyles(sty);
                  addSelectedStyle(sty);
                } else {
                  addSelectedStyle([...styles, data]);
                  setStyles([...styles, data]);
                }
                 dispatch(updateIsStyleSearching(false))
        
               }
              
            
    }
    

       // update the brand in redux 
        useEffect(()=>{
          if(getSearchCatIds && 
            styles &&
            styles.length>0 &&
            getGroupNames
          ){
            dispatch(addStyleSearchSwatch({
              type:getGroupNames,
              catId:getSearchCatIds.id,
              styles:styles
            }))
    
           
          }
        },[styles,getSearchCatIds, getGroupNames])
  return (
    <>
     {/* <div className="row g-2">
     {getSearchStyleNames && 
     getSearchStyleNames.length==0?(
      <span>No style</span>
     ): (
      getSearchStyleNames.map((item, index)=>{
        return(
          <div className="col-4" key={`${item.id}-${index}`}>
          <div className="palletsbox ">
            <div className="palletsbox__header">
            <i className="bi bi-palette"></i>
              <h6>{item?.title}</h6>
           
            </div>
    
            
            
          </div>
          
        </div>
        )
      })
     
     )
      
     }
     </div> */}

<div className="d-flex flex-wrap align-items-center gap-2">
                     { getSearchStyleNames &&
                     getSearchStyleNames.length>0 &&
                     getSearchStyleNames.map(item=>{
                      let selectedStyle:string="";
                         if(styles && styles.length>0){
                             styles.map(style=>{
                              if(style.id===item.id){
                                selectedStyle=style.title??""
                              }
                             })
                         }
                      return (
                        <span className=" rounded-pill p-2 select-brand"
                        key={item.id}
                        style={{
                          border: `1px solid ${color}`,
                          borderRadius: "6px",
                      
                          backgroundColor: item.title===selectedStyle ? `${color}50` : "transparent",
                          transition: "background-color 0.3s ease",
                      }}
                      onClick={()=>handleStyle(item)}
                        >
                        {/* <input className="form-check-input me-1" type="checkbox" id="firstCheckbox" 
                         onChange={()=>handleStyle(item)}
                        /> */}
                        <label className="form-check-label" htmlFor="firstCheckbox"
                         onClick={()=>handleStyle(item)}
                        >{item.title}</label>
                      </span>
                      )
                     })
                     }
                </div>
    </>
  )
}

export default StyleSearch