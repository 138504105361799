import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addBrandName, getAllBrandName } from '../../../../../slice/materialSlice/MaterialBrandSlice';
import { GetBrandNames } from '../../../../../api/material/brand/BrandApi';
import { addCategoryName, getCategoryName } from '../../../../../slice/materialSlice/MaterialCategorySlice';
import { GetCategoriesName } from '../../../../../api/material/category/CategoryApi';

const GetCategoryName = () => {
  const dispatch= useDispatch()
   const isApi= useRef(true)
  const getCategoryNames= useSelector(getCategoryName)
   useEffect(()=>{
    if(getCategoryNames &&
        getCategoryNames.length==0 &&
        isApi.current
    ){
        isApi.current= false
        getCategoiesName()
    }else if(getCategoryNames &&
        getCategoryNames.length==0){
        isApi.current= true

    }
   },[getCategoryNames])

    const getCategoiesName= async()=>{
        try{
            const response= await GetCategoriesName();
            
           // console.log("get all style  Name", response)
            if(response && response.status==200 ){
                  isApi.current= true
                  
                  dispatch(addCategoryName(response.data))
            }

        }catch(err){
            isApi.current= true
        }
    }
  return (
    <></>
  )
}

export default GetCategoryName