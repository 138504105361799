import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import {  getAddSegmentGroup } from '../../../../slice/canvas/groupSlice.tsx/GroupSlice';
import { getMasterArray, updateAnnotationPoint } from '../../../../slice/canvas/masterArray/MasterArraySlice';
import {  getJobBase64, getUserJobBase64 } from '../../../../slice/userJobSlice/UserJobSlice';

import { changeSegmentTab, getSwitchCanvas, getTabControl, switchToOutline } from '../../../../slice/tabControl/TabControlSlice';
import { getSegregateSegment, updateAnnoatationInSegegtated } from '../../../../slice/segegratedSlice/SegregatedSlice';
import AnnotationUpdateApi from '../../masterDataAnotationApi/AnnotationUpdateApi';
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice';
import { SegmentDetails } from '../../../../Model/Job/SamModel';
import AddGroupModel from '../addNewSegment/AddGroupModel';
import { getToolActiveNumber, UpdateToolActive } from '../../../../slice/toolActive/ToolActiveSlice';
import { getShowNearestPolygon } from '../../../../slice/canvas/canvasOption/CanvasOptionSlice';
import { getCanvasGrid } from '../../../../slice/canvas/canvasOption/CanvasOptionSlice';
import { getCachedBase64Image } from '../../canvasUtil/ConvertImageToBAse64';
import { CanvasImage } from '../../canvasUtil/SetImageAsBackground';
import { hideAllPolygon, hideAllPolygonsExcept, hideReAnnotatedPolygon, showPolygon } from '../../canvasUtil/addSegmentCanvasUtil/ShowAnnotationNewSegment';
import { PointModel } from '../../../../Model/masterArray/MasterArrayModel';
import { drawLines, RemoveGridLine } from '../../canvasUtil/addSegmentCanvasUtil/DrawMouseLine';
import { checkMouseInsidePolygon } from '../../canvasUtil/CheckMousePointInsidePolygon';
import { ShowMagnifier } from '../../canvasUtil/ShowMagnifier';



interface CustomCircleOptions extends fabric.ICircleOptions {
  id?: number;
  className?: string;
}

interface CustomFabricObject extends fabric.Object {
  id?: string | number;
}
const NewSegmentCanvas = () => {
    const canvasRef = useRef<fabric.Canvas|null>(null);
    const canvasElementRef = useRef<HTMLCanvasElement | null>(null);
    const isCanvas= useRef(true)
    const getUserJobBase64s = useSelector(getUserJobBase64);
   const dispatch= useDispatch()
   const navigate = useNavigate()
    const containerRef = useRef(null);
    const [scaleX, setScalex] = useState<number|null|undefined>();
    const [scaleY, setScaley] = useState<number|null|undefined>();
    const [allpoints, setAllPoints]= useState<number[]>([])
    const [isShowDistance, setIsShowDistance]= useState<boolean>(false)
    const [mousePoint, setMousePoint]= useState<number[]>([])
    const allPointsRef = useRef<number[]>([]);
    const [undoStack, setUndoStack] = useState<any[]>([]); 
   const [lastPOintId,setLastPOintId]= useState<number[]>([])
   const lastPOintIdRef= useRef<number[]>([])
   const [top, setTop] = useState<number | null | undefined>();
   const [left, setLeft] = useState<number | null | undefined>();
    let polygonMode: boolean = true;
    let min = 99;
    let max = 999999;
    let pointArray: { x: number; y: number }[] = []
    let activeShape: fabric.Polygon | undefined = undefined;
    const lineArrays: fabric.Line[] = [];
    const pointArrays: fabric.Circle[] = [];
    const isCanvasGrid = useRef(true);
    const isShowPolygon = useRef(true);
    const isFillPolygon = useRef<boolean>(false);
    const [currentDistance, setCurrentDistance] = useState<number>(0);
    const[ isShowModal, setIsShowModal]= useState<boolean>(false)
  const getSegregateSegments = useSelector(getSegregateSegment) 
  const getAddSegmentGroups= useSelector(getAddSegmentGroup)
  const [segName, setsegName]= useState<string|null>(null)
  const [segShortName, setSegShortName]= useState<string|null>(null)
  const [groupName, setGroupName]= useState<string|null>(null)
  const [childGroupName, setChildGroupName]= useState<string|null>(null)
  const getMasterArrays= useSelector(getMasterArray)
  const [jobId, setJobId]= useState<number|null>(null)
  const getTabControls = useSelector(getTabControl);
  const getSwitchCanvass = useSelector(getSwitchCanvas);
 
  const getToolActiveNumbers= useSelector(getToolActiveNumber)
  const [ isUpdatemasterdata, setIsUpdatemasterdata]= useState<boolean>(false)
  const [annotation, setAnnotation]= useState<number[]>([])
  const getCanvasGrids = useSelector(getCanvasGrid);
  const getShowNearestPolygons = useSelector(getShowNearestPolygon);
  const currentPolygon = useRef<string|undefined>("")
  const getJobBase64s= useSelector(getJobBase64)
  const isEditPolyname = useRef<string>("");
 

  const COLORS = {
    startPoint: "#FF0000", // Red for the start point
    edgePoint: "#FF1493", // Deep pink for edge points
    edgeCircle: "#FF1493", // Pink circle for edge points
    snapCircle: "#000000", // Black for the snap circle
    suggestCircle: "#FF6347", // Tomato for suggestive points
    polygonFill: "rgba(0, 255, 0, 0.3)",
    polygonStroke: "#008000", // Green for the polygon's border
    verticePoint: "#FFFF00", // Yellow for VerticePoints
    verticeCircle: "#FFFF00", // Yellow circle for VerticePoints
    closedVerticePoint: "#0000FF", // Blue for closed vertices
    closedVerticeCircle: "#0000FF", // Blue circle for closed vertices
  };
 
  const MIN_SNAP_DISTANCE = 15; // Minimum distance to snap to points
  const MAX_SUGGEST_DISTANCE = MIN_SNAP_DISTANCE * 2; // Maximum distance for edge points suggestions
  const verticePoints = useRef<fabric.Circle[]>([]); // Array to store VerticePoint objects


  //set the show grid

  useEffect(() => {
    if(getCanvasGrids){
      isCanvasGrid.current=true
    }else{
      isCanvasGrid.current=false
    }
  },[getCanvasGrids])

  // set the show nearest polygon
  useEffect(() => {
    if(getShowNearestPolygons){
      isShowPolygon.current=true
    }else{
      isShowPolygon.current=false
    }
  },[getShowNearestPolygons])

  // update the first point
  const isFirst= useRef(true)
  useEffect(()=>{
    if(allpoints && 
      allpoints.length>0 &&
       canvasRef.current &&
       isFirst.current){
        isFirst.current= false
       
        allPointsRef.current=allpoints
    
      // Create a new circle if none exists
      const circle = new fabric.Circle({
        name: 'firstpoint',
        radius:3 ,
        fill: "transparent",
        stroke: "black",
        strokeWidth: 2,
        left: allpoints[0],
        top: allpoints[1],
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        visible: true
      } as CustomCircleOptions)
     
      const hoverCircle = new fabric.Circle({
        name: "finalPoint",
        radius: 15,
        fill: "transparent",
        stroke: COLORS.startPoint,
        strokeWidth: 1,
        left: allpoints[0]-15,
        top: allpoints[1]-15,
        selectable: false,
        hasBorders: false,
        hasControls: false,
       
        visible: true,
        strokeDashArray: [5, 5],
      } as CustomCircleOptions)
      canvasRef.current.add(hoverCircle);
      canvasRef.current.add(circle);
    
      canvasRef.current.renderAll();

      //hideReAnnotatedPolygon(canvasRef,segName)
     // canvasRef.current.hoverCursor = "none";
    }
  },[allpoints,canvasRef])


  // update id  wrt to ref
  useEffect(()=>{
    if(lastPOintId && lastPOintId.length>0){
      lastPOintIdRef.current= lastPOintId
    }
  }
  ,[lastPOintId])


  // get the new segment name 
  useEffect(()=>{
   
    if(getSegregateSegments &&
       getSegregateSegments.length>0 &&
       getAddSegmentGroups &&
       getSwitchCanvass==="newSegment"){
          const childGroupName=getAddSegmentGroups.childGroupName
          const groupName=getAddSegmentGroups.groupName
          const childGroups = getSegregateSegments.filter(item => {
            return Object.keys(item).some((key) => item[key].details?.seg_type === groupName);
          });
  
               if(childGroups.length>0 && childGroupName && groupName){
                 const childobj= childGroups.filter(item=>{
                 return Object.keys(item).some(key=>item[key].details?.seg_name===childGroupName)
                 })
                 if(childobj){
                  const seg_short= childobj[0]?.[childGroupName].details?.seg_short
                  if(seg_short){
                    const newSegShort= (seg_short.replace(/[0-9]/g, "")+(childGroups.length+1))
                    
                    console.log("groupName+(childGroups.length+1)--->",groupName+(childGroups.length+1))
                    setSegShortName(newSegShort)
                    setsegName(groupName+(childGroups.length+1))
                    setGroupName(groupName)
                    setChildGroupName(childGroupName)
                    
                  }
                 
                 }
               
               }
         
    } 

  },[getSegregateSegments,getAddSegmentGroups,getSwitchCanvass ])

  // get job id
  useEffect(()=>{
    if(getMasterArrays && getMasterArrays.jobId){
        setJobId(getMasterArrays.jobId)
    }else{
        setJobId(null)
    }
 },[getMasterArrays])

     // Adjust canvas dimensions to fit the container
           const resizeCanvas = (fabricCanvas: fabric.Canvas) => {
             const container = containerRef.current;
             if (container) {
               const { offsetWidth, offsetHeight } = container;
         
               fabricCanvas.setWidth(offsetWidth);
               fabricCanvas.setHeight(offsetHeight);
               fabricCanvas.renderAll();

               const backgroundImage = fabricCanvas.backgroundImage as fabric.Image;
               if (backgroundImage) {
                 backgroundImage.scaleToWidth(offsetWidth);
                 backgroundImage.scaleToHeight(offsetHeight);
                 fabricCanvas.requestRenderAll();
               }
             }
           };
    
       
  // create canvas 
    useEffect(() => {
      if (canvasElementRef.current && 
        isCanvas.current) {
        isCanvas.current= false
        // Initialize Fabric canvas
        const rightSection = document.querySelector('.canvas-right') as HTMLElement;
        const canvas = new fabric.Canvas(canvasElementRef.current, {
          // width:rightSection==null? window.innerWidth: window.innerWidth - rightSection.offsetWidth, // Adjust width
          // height: window.innerHeight,
          backgroundColor: '#f0f0f0', // Canvas background
        });

        const dimension = new fabric.Group([], { 
          selectable: false, 
          name: 'dimension',
          visible: true ,
          hasBorders: false,
            hasControls: false,
        });
        
        // Set initial canvas size
        resizeCanvas(canvas);

        canvas.add(dimension);

              // Set custom cursor
    const customCursor = createCustomCursor();
    canvas.defaultCursor = `url(${customCursor}) 16 16, crosshair`;
    canvas.hoverCursor = `url(${customCursor}) 16 16, crosshair`;
    canvas.moveCursor = `url(${customCursor}) 16 16, crosshair`;
    canvas.selection = false;

      canvas.requestRenderAll();
        canvasRef.current = canvas;
        canvas.on('mouse:down', (event) => {
          // console.log("event",event)
          handleMouseDown(event);
        });
        
        canvas.on('mouse:up',
          (event) => {
            handleMouseUp(event);
         });

         canvas.on('mouse:move', (event) => {
          handleMouseMove(event); // Add mouse move event to show the dynamic point/line
        });
         canvas.on('mouse:over', (event) => {
          handleMouseOver(event); // Add mouse move event to show the dynamic point/line
        });

           window.addEventListener('keydown',(event)=>{handleKeyDown(event)});


        return () => {
          // Clean up the canvas when the component unmounts
          if (canvasRef.current) {
            canvasRef.current.off('mouse:down', handleMouseDown);
            canvasRef.current.off('mouse:up', handleMouseUp);
            canvasRef.current.off('mouse:move', handleMouseMove);
           // canvasRef.current.off("mouse:wheel", handleMouseWheel);
            window.addEventListener('keydown',(event)=>{handleKeyDown(event)});
  
          }
        
        }
        
      } else{
        isCanvas.current= true
      }
    }, []);

    const createCustomCursor = () => {
      const cursorCanvas = document.createElement('canvas');
      cursorCanvas.width = 40;
      cursorCanvas.height = 40;
      const ctx = cursorCanvas.getContext('2d');
  
      if (ctx) {
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.lineWidth = 2;
       ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
        // Draw horizontal line
        ctx.beginPath();
        ctx.moveTo(0, 20);
        ctx.lineTo(40, 20);
        ctx.stroke();
  
        // Draw vertical line
        ctx.beginPath();
        ctx.moveTo(20, 0);
        ctx.lineTo(20, 40);
        ctx.stroke();
      }
  
      return cursorCanvas.toDataURL('image/png');
    };
  

    //   // ad image on canavas
    useEffect(() => {
      if (getJobBase64s &&
        getJobBase64s.imagePath && 
        canvasRef.current) {
         
          const base64Image= getCachedBase64Image(getJobBase64s.imagePath )
         
           if(base64Image){
             
             CanvasImage(base64Image, canvasRef, setScalex, setScaley, setTop, setLeft);
           }
      }
    }, [getUserJobBase64s]);
  

      
      const handleKeyDown=(event: KeyboardEvent)=>{
        //  console.log("event constrol zzz",event)
          if (event.ctrlKey && event.key === 'z') {
           // console.log("undo")
            undoLastAction();
          }
        }
      
      useEffect(()=>{
      
    
        if(mousePoint.length>0  &&
           allpoints &&
            allpoints.length==0){
           setIsShowDistance(true)
          showDistanceCordinate([],mousePoint,false)
        }else if(allpoints && allpoints.length>0 &&
          mousePoint &&mousePoint.length>0){
            const pointLength= allpoints.length
           const points=[allpoints[pointLength-2],allpoints[pointLength-1 ]]
            showDistanceCordinate(points,mousePoint,true)
        }
       },[allpoints, mousePoint, canvasRef.current, isShowDistance])
    
  
         // handle mouse move
    const handleMouseMove = (options: fabric.IEvent) => {
      
      //showMagnifier(options)
      ShowMagnifier(options,canvasRef)
      const pointerDraws = options.pointer;
    const pointerCanvas = canvasRef.current?.getPointer(options.e);


    const pointer = options.pointer;

    handleZoom(options);
    if (
      pointerDraws &&
      pointerCanvas &&
      pointer &&
      canvasRef.current &&
      canvasRef.current.width &&
      canvasRef.current?.height &&
      pointerCanvas
    ) {
    
      showDrawLine(pointerCanvas);
     
      setMousePoint([pointerDraws?.x ?? 0, pointerDraws?.y ?? 0]);


     
          const fabricPoint = new fabric.Point(pointerCanvas.x, pointerCanvas.y);
          //hide all show all dotted polygon
          if(isShowPolygon.current){
            if(fabricPoint){
              checkMouseInsidePolygon(fabricPoint, canvasRef, false, hideAnnotation, showAnnotation, isFillPolygon); 
  
             }
          }else{
            hideAllPolygon(canvasRef, segName??"");
          }
           
       
     
    }
     
     
    };
    
    const showDrawLine = (pointerDraws: PointModel) => {
      // console.log("isCanvasGrid",isCanvasGrid)
       if(isCanvasGrid.current){
        // console.log("isCanvasGrid---->",isCanvasGrid)
         drawLines(pointerDraws.x ?? 0, pointerDraws.y ?? 0, canvasRef);
       }else{
         RemoveGridLine(canvasRef);
       }
     
     }

     const hideAnnotation=(segName:string)=>{
      hideAllPolygonsExcept(segName, canvasRef,isEditPolyname??"")
      
    }
  
    const isColor = useRef(false);
    const showAnnotation=(segName:string,color:string, pointer:fabric.Point)=>{
      const mousePoint = [pointer.x, pointer.y];
      showPolygon(canvasRef,segName,mousePoint[0],mousePoint[1],isEditPolyname)
    }

      // add maginier kens
  const handleZoom = (options: fabric.IEvent) => {
    const canvas = canvasRef.current; // Store the reference in a variable
    if (!canvas || canvas.width === undefined || canvas.height === undefined)
      return; // Check if canvasRef.current is null or dimensions are undefined

    const pointer = canvas.getPointer(options.e);

    const magnifierSize = 100; // Size of the magnifier area

    const existingMagnifier = canvas.getObjects().find(obj => obj.name === "magnifier");
    if (existingMagnifier) {
      canvas.remove(existingMagnifier);
    }
    const magnifiedImage = new fabric.Image(canvas.toDataURL(), {
      left: pointer.x - magnifierSize / 2,
      top: pointer.y - magnifierSize / 2,
      scaleX: magnifierSize / canvas.width,
      scaleY: magnifierSize / canvas.height,
      selectable: false,
      evented: false,
      name: "magnifier",
    });

    canvas.add(magnifiedImage);
    canvas.renderAll();
  };
  

      const showDistanceCordinate=(allpoints:number[],mousePoint:number[],isShowDistance:boolean)=>{
     
        if (canvasRef.current && mousePoint) {
          const canvasObjects = canvasRef.current.getObjects();
          if (canvasObjects) {
            // Find the "dimension" group (if necessary)
            const matchingGroup = canvasObjects.find(grp => grp.name === "dimension") as fabric.Group | undefined;
            let existingText = canvasRef.current.getObjects().find(obj => obj.name === 'showDim') as fabric.Text | undefined;
      
            // If the text doesn't exist, create it and add it to the canvas
            if (!existingText) {
              existingText = new fabric.Text(``, {
                left: mousePoint[0] + 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                name: 'showDim',
                fontFamily: 'Arial',
                fontSize: 12,         // Smaller font size for crisp appearance
                fill: 'white',       // Text color
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black background with slight opacity
                selectable: false,  // Disable selection of the text
                evented: false,     // Ensure text is not selectable
                visible: true,
              });
      
              // Add the new text to the canvas
              canvasRef.current.add(existingText);
              canvasRef.current.requestRenderAll();
            }
      
            // Update the text content and position dynamically
            if(!isShowDistance){
              
              existingText.set({
                left: mousePoint[0]+ 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                text: `(${mousePoint[0].toFixed(2)}, ${mousePoint[1].toFixed(2)})`,  // Update the displayed coordinates
              });
            }
            else if (isShowDistance ){
              const distanceX=allpoints[0]-mousePoint[0]
              const distanceY=allpoints[1]-mousePoint[1]
              const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
              existingText.set({
                left: mousePoint[0]+ 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                text: `${distance.toFixed(2)}`,  // Update the displayed coordinates
              });
            }
           
      
            // Update the canvas rendering after modifying the text
            existingText.setCoords();  // Ensure the internal coordinates are updated
      
            // Ensure the text object is brought to the front
            canvasRef.current.bringToFront(existingText);
            canvasRef.current.requestRenderAll();  // Render the canvas
          }
        }
      }

      
      const handleMouseDown = (options: fabric.IEvent) => {

        const zoom = canvasRef.current?.getZoom() ?? 1;
       
        let mouseP: { x: number; y: number } = { x: 0, y: 0 };
        if(zoom!=1  &&
           options.absolutePointer &&
           options.pointer){
          mouseP = {
            x: (options.absolutePointer.x ?? 0) ,
            y: (options.absolutePointer.y ?? 0)
          }
        }else if(options.pointer){
          mouseP = {
            x: (options?.pointer.x ?? 0) ,
            y: (options?.pointer.y ?? 0) 
          }
        }
      
        const pointer = canvasRef.current?.getPointer(options.e);
        const existingLines = canvasRef?.current?.getObjects('line').filter(line => line.name === 'highlightLine');
       const existingImaginaryCircle = canvasRef.current?.getObjects('circle').filter(circle => circle.name === 'imaginaryPoint');
        if (pointer && allPointsRef.current && allPointsRef.current.length > 0) {
          const point1 = allPointsRef.current[0];
          const point2 = allPointsRef.current[1];
    
          const top = pointer.y;
          const left = pointer.x;
    
          const tolerance = 10; // Adjust this tolerance value as needed
    
          if (
            Math.abs(top - point2) < tolerance &&
            Math.abs(point1 - left) < tolerance
          ) {
            setIsShowModal(true);
            polygonMode = false;
            
          }
        }
    
        if(pointer &&
          existingImaginaryCircle &&
          existingLines &&
           existingImaginaryCircle.length>0  &&
           canvasRef.current&&
           isShowPolygon.current 
           
         ){
          
          const existingPoint=existingImaginaryCircle[0]
          const existingPointValue = {
           x: (existingPoint.left ?? 0) ,
           y: (existingPoint.top ?? 0) 
         };
        
        
           addPoint(existingPointValue, currentDistance,options);
       }
      else if (polygonMode &&
        canvasRef.current && mouseP) {
         
         addPoint(mouseP, currentDistance,options);
    
        }
      };

 
     const handleMouseUp = (options:fabric.IEvent) => {
       // // console.log("options",options)
       // console.log("mouse up")
      drawPolygon()
       
     };
 
 
     const drawPolygon = () => {
 
       polygonMode = true;
       // pointArray = new Array();
       // lineArray = new Array();
     
      
     };
 
     const addPoint = (options: PointModel,currentDistance:number,optionfs: fabric.IEvent) => {
      let currentPoint = options;
      if (!options) return;
    
       const random = Math.floor(Math.random() * (max - min + 1)) + min;
       const id = new Date().getTime() + random;
       setLastPOintId(prev => [...prev, id]);
       const circle = new fabric.Circle({
         radius: 1,
         fill: '#000000',
         stroke: 'rgb(255, 1, 154)',
         strokeWidth: 3,
         id:id,
         className:'point',
         left: currentPoint!.x-1,
      top: currentPoint!.y-1,
         selectable: false,
         hasBorders: false,
         hasControls: false,
         originX: 'center',
         originY: 'center',
       }  as CustomCircleOptions);
   
      
       if (!circle.left || !circle.top) return;
   
       if (pointArrays.length === 0) {
         circle.set({ fill: '#000000' }); // First point is black
       } else {
         circle.set({ fill: '#ff0000' }); // Other points can be a different color (e.g., red)
       }
        pointArray.push({x:circle.left,y:circle.top})
        const points: [number, number, number,number] = [
          circle.left+3,
          circle.top+3,
          circle.left+3,
          circle.top+3,
        ];
     
         
   
       if ( activeShape ) {
         canvasRef.current?.remove(activeShape);
         const pos = canvasRef.current?.getPointer(optionfs.e);
         if (pos) {
           const points = activeShape?.get('points') ?? [];
           points.push(new fabric.Point(pos.x, pos.y)); // Use fabric.Point
           activeShape.set({ points: points });
           var polygon = new fabric.Polygon(points,{
             stroke:'rgb(7 239 253)',
               strokeWidth:2,
               fill: 'transparent',
               opacity: 0.9,
               selectable: false,
               hasBorders: false,
               hasControls: false,
               evented: false,
               name:"polygon"
           });
          canvasRef.current?.remove(activeShape);
            canvasRef.current?.add(polygon);
             
            activeShape = polygon;
         
           canvasRef.current?.renderAll();
         }
         
        
       } else {
        const x = currentPoint?.x ?? 0; // Ensure x is a number, use 0 if undefined
      const y = currentPoint?.y ?? 0; // Ensure y is a number, use 0 if undefined
         
         const newPolyPoints = [{ x, y }];
         const polygon = new fabric.Polygon(newPolyPoints,{
           stroke:'#333333',
           strokeWidth:1,
           fill: '#cccccc',
           opacity: 0.3,
           selectable: false,
           hasBorders: false,
           hasControls: false,
           evented: false,
           name:"poly"
       });
       activeShape=polygon
        canvasRef.current?.add(polygon)
       }

         setAllPoints((prev) => [
           ...prev,           
           points[0],       
           points[1],
         ]);
       pointArrays.push(circle);
      // lineArray.push(line);
       //canvasRef.current?.add(line);
       canvasRef.current?.add(circle);
       //console.log("canvasRef.current====>",canvasRef.current)
     };


     const handleMouseOver = (options: fabric.IEvent) => {
      
      const points= options.e as MouseEvent
    const pointX= points.offsetX
    const pointY= points.offsetY
    const targetPoint={
      x:pointX,
      y:pointY
    }

    const targetnames = options.target?.name;

    if (targetnames && points) {
      currentPolygon.current=targetnames
     ;
      toggleVerticeVisibility(targetPoint, targetnames);
    }
      // // Get the pointer location
      // const pointer = canvasRef.current?.getPointer(options.e);
     
      // if (pointer ) {
      //   const target = options.target as CustomFabricObject;
      //   const top = pointer.y; 
      //   const left = pointer.x;
        
      //   if (target &&
      //     target.name &&target.name==="firstpoint" &&
      //      canvasRef.current && top && left) {
      //     const targetId = target?.id || 0;
      //    // console.log("traget",target.name)
      //     // Check if the target matches an object on the canvas
      //     const canvasObjects = canvasRef.current.getObjects();
    
      //     // Find the matching object by ID
      //     const matchingObject = canvasObjects?.find((obj: CustomFabricObject) => obj.name === "finalPoint");

          
      //     if (matchingObject) {
        
      //         // Move the existing circle to the new position (with some tolerance)
      //         const tolerance = 30; // Adjust this tolerance value as needed
      //         const circleTop = matchingObject.top || 0;
      //         const circleLeft = matchingObject.left || 0;
    
      //         if (Math.abs(circleTop - top) > tolerance || Math.abs(circleLeft - left) > tolerance) {
      //           // Update the position of the existing circle
      //           matchingObject.set({
                
      
      //             stroke: "red",
      //             left: left,
      //             top: top,
      //             visible:true
      //           });
      //           canvasRef.current.renderAll();
      //         }
           
              
      //     } 
          
      //   }else if(canvasRef.current 
      //     && target &&
      //     target.name!="firstpoint"
      //   ) {
      //     const canvasObjects = canvasRef.current.getObjects();
    
      //     const matchingObject = canvasObjects?.find((obj: CustomFabricObject) => obj.name === "finalPoint");
           
      //     if (matchingObject) {
        

      //         matchingObject.set({
              
    
      //           stroke: "red",
      //           left: left,
      //           top: top,
      //           visible:false
      //         });
      //         canvasRef.current.renderAll();
            
         
            
      //   } 
      //   }
      // }
    };
    
   
    const toggleVerticeVisibility = (pointer:PointModel, targetName:string) => {
      verticePoints.current.forEach((vertice) => {
        
        if(vertice.name?.includes(targetName)){
         // console.log("vertice", vertice);
          const distance = calculateDistance(pointer, new fabric.Point(vertice.left ?? 0, vertice.top ?? 0));
        
          if (distance <= MAX_SUGGEST_DISTANCE) {
          //  console.log("MAX_SUGGEST_DISTANCE", MAX_SUGGEST_DISTANCE);
            vertice.set({ visible: true });
          } else {
            vertice.set({ visible: false });
          }
        }
        
      });
      canvasRef.current?.renderAll();
    };


    const calculateDistance = (point1: PointModel, point2: PointModel) => {
      const dx = point1.x - point2.x;
    const dy = point1.y - point2.y;
    return Math.sqrt(dx * dx + dy * dy);
    };
     const handleCloseModal=()=>{
      setIsShowModal(false)
      
    }
    const handleOk=(groupName:string, childGroupName:string, shortName:string)=>{
    
      setIsShowModal(false)
     // setAllReAnnoatationPoints.push(pointArrays)
     convertToBoundingBox(allpoints,groupName, childGroupName,shortName)
    }
   
    const  convertToBoundingBox=(flattenedPoints:number[],groupName:string, childGroupName:string, shortName:string)=> {
      
      if(scaleX && scaleY){
       
       let  newFlattenedPoints=[]
        const points = flattenedPoints;
  
         
        for (let i = 0; i < points.length; i += 2) {
          const x = points[i]/(scaleX??1);
          const y = points[i + 1]/(scaleY??1);
          newFlattenedPoints.push(parseFloat(x.toFixed(2)),parseFloat(y.toFixed(2)))
         
        }
    
       if( newFlattenedPoints.length>0 &&
        childGroupName &&
        shortName  && 
        segName &&
        groupName ){
          setSegShortName(shortName)
                    setsegName(segName)
                    setGroupName(groupName)
                    setChildGroupName(childGroupName)
          setIsUpdatemasterdata(true)
             setAnnotation(newFlattenedPoints)

        }
      
   
    }
  }

  const handleResetAnotation=(data:SegmentDetails)=>{
    setAnnotation([])
    setIsUpdatemasterdata(false)
    dispatch(stopLoading())

    const masterValue={
      segName:segName,
      childgroupName:childGroupName,
      seg_short:segShortName,
      groupName:groupName,
      masterData:data
    }
   // console.log("masterValue",masterValue)
    dispatch(startLoading())
    // update in segretaed  Array
    dispatch(updateAnnoatationInSegegtated(masterValue))
       
        dispatch(switchToOutline("segment"))
         dispatch(changeSegmentTab('segment'))
  }
  const handlehoverLayer=()=>{
    dispatch(UpdateToolActive(0))
    dispatch(switchToOutline("segment"))
    dispatch(changeSegmentTab('segment'))
    // navigate(`/project/view/${jobId}`)
     
  }

  const handleFailResetAnnotation=()=>{
    setAnnotation([])
    setIsUpdatemasterdata(false)
    dispatch(stopLoading())

    dispatch(switchToOutline("segment"))
    dispatch(changeSegmentTab('segment'))
  }

  //  call Undo from tool
  useEffect(()=>{
    if(getToolActiveNumbers==3){
      undoLastAction()
      dispatch(UpdateToolActive(0))
    }
  },[getToolActiveNumbers])
  
  // undo Action
  const undoLastAction = () => {
    // console.log("allpoints",allpoints)
    const idLength=lastPOintIdRef.current.length
    if (allpoints.length > 0 && 
      canvasRef.current && 
      lastPOintIdRef.current &&
      idLength
    ) {
      const getPolygonObject = canvasRef.current.getObjects().filter(item => item.name === "polygon") as fabric.Polygon[];
      const getLastPoint = canvasRef.current.getObjects().filter((item: CustomCircleOptions) => item?.id === lastPOintIdRef.current[idLength-1]);
     // console.log("getLastPoint", getLastPoint);
      if (getPolygonObject && getPolygonObject.length > 0 && getLastPoint.length > 0) {
        // Remove the last point circle
        canvasRef.current.remove(getLastPoint[0]);

       
        const points = getPolygonObject[0].points;
        if (points && points.length > 0) {
          points.pop(); // Remove the last point
  
          // Update the polygon with the new points array
          getPolygonObject[0].set({ points });
          
          // Redraw polygon to refresh connections
          getPolygonObject[0].dirty = true;
          canvasRef.current.renderAll();
          
          // Optional: Update your points array (allpoints) if needed for further undo actions
          allpoints.pop(); // Assuming allpoints tracks the polygon point coordinates
          
        //  console.log("Updated Polygon Points:", points);
           // update the id
          // Update the lastPOintId after deletion
          setLastPOintId(prev => {
            const updatedIds = [...prev]; // Create a copy of the previous state
            updatedIds.pop(); // Remove the last ID
            lastPOintIdRef.current = updatedIds; // Update the ref
            return updatedIds; // Return the updated state
        });
        }
      }
    }
  };


 // Function to show magnifier


  
  return (
    <>
<div  className='position-relative'
 ref={containerRef}
 style={{
   height: "100vh",
   overflow: "hidden",
   transformOrigin: "top left",
 }}
>
<div className='re-anno-section'  onClick={handlehoverLayer}
>
      <button className='d-flex align-items-center btn rounded-pill custom-back-button border-0'> 
      <span className='fs-4'>
      <i className="bi bi-arrow-left-short pe-1"></i>
      
      </span>
      <span >
        Annotation Canvas
      </span>
     
      
        {/* {groupName}-{childGroupName}-{segShortName} */}
   </button> 
     {/* Add a button to trigger undo */}
   
     
      </div>
   
<canvas ref={canvasElementRef}  className='dzi-van'/>

{/* confirmation model */}
{isShowModal &&
  <AddGroupModel
  selectedSegment={true}
  isSegmentModal={isShowModal}
  handleClose={handleCloseModal}
  ok={handleOk}
  />
}





</div>

{isUpdatemasterdata &&
annotation &&
childGroupName &&
<AnnotationUpdateApi
segmentationInt={annotation}
segName={childGroupName}
resetAnnotation={handleResetAnotation}
resetFailAnnotation={handleFailResetAnnotation}
/>}


    </>
  )
}

export default NewSegmentCanvas