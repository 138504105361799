import React, { useState } from 'react'
import LeftSection from '../../../leftSection/LeftSection'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'

import SearchAdd from '../../../dasboardpages/components/pageContent/SearchAdd'
import PageContentHeader from '../../../dasboardpages/components/pageContent/PageContentHeader'
import Sidebar from '../../../dasboardpages/components/Sidebar'
import Header from '../../../header/Header'
import SwatchTable from './swatchTable/SwatchTable'

const AdminSwatchesHome = () => {
  const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('Swatch') 
 
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Library') 
  const [breadCum3, setbreadCum3] =useState<string>('Swatch') 
 
  
  
  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };
  return (
    <>
    <Header/>
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
        {/* content header */}
        
         <PageContentHeader
         firstHeader={firstHeader}
         secondHeader={secondHeader}
         breadCum1={breadCum1}
         breadCum2={breadCum2}
         breadCum3={breadCum3}
         
          />
        

        {/* <SearchAdd/> */}

          {/* table */}
         
          <SwatchTable/>



         
    </div>
    </>




  )
}

export default AdminSwatchesHome