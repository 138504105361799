import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSettingPath } from '../../../slice/settingPathSlice/SettingPathSlice'
import { SettingPathModel } from '../../../Model/settingPath/SettingPathModel'
import { Ellipse } from 'fabric/fabric-impl'
import Loading from '../../loading/Loading'
import { getLoading, stopLoading } from '../../../slice/loading/LoadingSlice'
import DataTable from 'react-data-table-component';
import SettingPathForm from './settingPathForm/SettingPathForm'
const SettingPathTable = () => {

  const dispatch=useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allSettingPath, setAllSettingPath] = useState<SettingPathModel[]>([])
  const getSettingPaths= useSelector(getSettingPath)
  const getLoadings = useSelector(getLoading);
  const [mode, setMode]= useState<string|null>(null)
  const [isEditPath, setIsEditPath] = useState<SettingPathModel|null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
    
    // update all setting path from the redux
    useEffect(() => {
     
       if( getSettingPaths&&
        getSettingPaths.length>0){
         setAllSettingPath(getSettingPaths)
       }else{
          setAllSettingPath([])
       }
    }, [getSettingPaths] )


    const handleEditSettingPath = (settingPath: SettingPathModel) => {
      setIsEditPath(settingPath) 
      setIsOpen(true) 
      setMode("edit")
    }


    const handleCloseModal=()=>{

        dispatch(stopLoading())
      setIsOpen(false)
      setIsEditPath(null)
      setMode(null)
    }


     const handleAddPath=()=>{
      setIsEditPath({
        title:"",
        path:"",
        type:"",
        is_active:false
      })
      setIsOpen(true)
      setMode("add")
     }
      const columns = [
        {
          name: "Id",
           selector: (row: SettingPathModel) => row.id??0, // Selector returns a primitive value
          sortable: true,
        },
        {
          name: "Title",
           selector: (row: SettingPathModel) => row.title??"",
          sortable: true,
        },
        {
          name: "Path",
           selector: (row: SettingPathModel) => row.path??"",
          sortable: true,
        },
        {
          name: "Slug",
           selector: (row: SettingPathModel) => row.slug??"",
          sortable: true,
        },
        {
          name: "Type",
           selector: (row: SettingPathModel) => row.type??"",
          sortable: true,
        },
        
        {
          name: "Status",
           selector: (row: SettingPathModel) => row.is_active?"true":"false",
          sortable: true,
        },
        
        {
          name: "Action",
          cell: (row: SettingPathModel) => {
            return(
              <div className="d-flex action">
                     <div className="action-icon-table"
                      onClick={() => handleEditSettingPath(row)}
                      >
                       <i className="bi bi-pencil-square mx-0"></i>
                     </div>
                     <div className="action-icon-table">
                       <i className="bi bi-copy mx-0"></i>
                     </div>
                     <div className="action-icon-table">
                       <i className="bi bi-box-arrow-up-right mx-0"></i>
                     </div>
                     <div className="action-icon-table trash-icon-media" 
                     //onClick={() => handleDelete(record)}
                     >
                       <i className="bi bi-trash3 mx-0"></i>
                     </div>
                   </div>
            )
          }
        },
      ];
  return (
    <>
      <div className='card mx-3'>
        <div className='card-header bg-white p-2 d-flex justify-content-between align-items-center'>
          <input
            type="search"
            className="form-control w-auto"
            placeholder="Search"
          />
          <button type='button' className='btn btn-primary'  onClick={handleAddPath}>Create new entry</button>
        </div>
        
        <div className='card-body p-0'>
          {isLoading && <Loading />}
          <div className="p-0">
            {allSettingPath.length === 0 ? (
              <Loading />
            ) : (
           

              <DataTable 
              columns={columns} 
              data={allSettingPath.map((item) => ({ ...item, key: item.id }))}
              pagination
              fixedHeader
            />

            )}
          </div>
         

        </div>
      </div>
      <div>
        <div className=" brand-title">
          <p>{allSettingPath.length} entries Found</p>
        </div>
      </div>

{
     isEditPath &&
     isOpen &&
     mode &&
      <SettingPathForm
      settingData={isEditPath}
      isOpen={isOpen}
      onClose={handleCloseModal}
      mode={mode}
      /> }


{getLoadings &&
<Loading/>}
    </>
  )
}

export default SettingPathTable