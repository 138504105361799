import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaterialModel, SearchSwatchModel, SwatchDocModel, SwatchesModel } from "../../Model/material/MaterialModel";
import { SearchPageModel } from "../../Model/material/MaterialBrandModel";


interface MaterialStates {
    material:MaterialModel[]
    allSwatches:SwatchesModel[]
    isDownloadSwatch:boolean,
    searchSwatch:SearchPageModel,
    swatchResponse:string,
    swatchDoc:SwatchDocModel,
    searchSwatchData:MaterialModel[]
    isSearch:boolean
    dynamicSearchSwatch:SearchSwatchModel
   
    
}

const initialState: MaterialStates = {
    material:[],
    allSwatches:[],
    isDownloadSwatch:false,
    searchSwatch:{},
    swatchResponse:"",
    swatchDoc:{},
    searchSwatchData:[],
    isSearch:false,
    dynamicSearchSwatch:{}

};

const MaterialSlice = createSlice({
    name: "material",
    initialState,
    reducers: {
        reSetMaterial:(state)=>{
           return initialState
        },
        addMaterial: (state, action) => {
            const mat = state.material;
            
            if (mat.length === 0) {
              
                state.material = action.payload;
            } else {
                
                state.material = [...mat, ...action.payload];
            }
        },
       addSwathes:(state,action)=>{
        state.allSwatches=action.payload
       },
       updateSwatchData:(state,action)=>{
        const { data}=action.payload
        const index= state.material.findIndex((swatch)=>swatch.id===data.id)
             if(index!==-1){
                 state.material[index]=data
             }else{
                    state.material.push(data)
             }
       },
       addIsDownloadSwatch:(state,action)=>{
        state.isDownloadSwatch= action.payload
    },
    addSwatchResponse:(state,action)=>{
    state.swatchResponse=action.payload
    },
    addSearchSwatch:(state,action)=>{
        const { page,pageSize}=action.payload
        state.searchSwatch.page=page
        state.searchSwatch.pageSize=pageSize
    },
    addSwatchDoc:(state,action)=>{
        const {totalDocuments,totalPages,currentPage,pageSize}=action.payload;
        state.swatchDoc.currentPage=currentPage
        state.swatchDoc.pageSize=pageSize
        state.swatchDoc.totalDocuments=totalDocuments
        state.swatchDoc.totalPages=totalPages
       },
       addSearchSwatchData:(state,action)=>{
        state.searchSwatchData= action.payload
       },

       resetSearchSwatchData:(state)=>{
        state.searchSwatchData=[]
       },
       updateIsSearch:(state,action)=>{
        state.isSearch= action.payload
       },
       addDynamicCat:(state,action)=>{
          const {catId}=action.payload
          state.dynamicSearchSwatch.catId= catId
       },
       addDynamicBrand:(state,action)=>{
          const {brandId}=action.payload
          state.dynamicSearchSwatch.brandId= brandId
       },
       addDynamicStyle:(state,action)=>{
          const {styleId}=action.payload
          state.dynamicSearchSwatch.styleId= styleId
       }, resetDynamicSwatchSearch:(state)=>{
        state.dynamicSearchSwatch={}
       }
       
       
    }
}
)

export const {
    reSetMaterial,
    addMaterial ,
    addSwathes,
    addSwatchDoc,
    addSearchSwatch,
    addSwatchResponse,
    addIsDownloadSwatch,
    addSearchSwatchData,
    resetSearchSwatchData,
    updateIsSearch,
    addDynamicCat,
    addDynamicBrand,
    addDynamicStyle,
    resetDynamicSwatchSearch,
    updateSwatchData

}=MaterialSlice.actions;
export const getMaterial=  (state:{material:MaterialStates})=>state.material.material;
// export const getAllSwatches=  (state:{material:MaterialStates})=>state.material.allSwatches;
export const getAllSwatches = (state: { material: MaterialStates }): SwatchesModel[]=> {
    return state.material.allSwatches|| [];
}
export const getIsDownloadSwatch = (state: { material: MaterialStates }): boolean=> {
    return state.material.isDownloadSwatch|| false;
}
export const getSwatchResponse = (state: { material: MaterialStates }): string=> {
    return state.material.swatchResponse|| "";
}
export const getSwatchDoc = (state: { material: MaterialStates }): SwatchDocModel=> {
    return state.material.swatchDoc|| {};
}
export const getSearchSwatch = (state: { material: MaterialStates }): SearchPageModel=> {
    return state.material.searchSwatch|| {};
}


export const getSearchSwatchData = (state: { material: MaterialStates }):MaterialModel[] => {
    return state.material.searchSwatchData|| [];
}
export const getIsSearch = (state: { material: MaterialStates }):boolean => {
    return state.material.isSearch|| false;
}


export default MaterialSlice.reducer