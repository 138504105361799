import axios from "axios";
import apiCilent from "../interceptor/ApiCilent"
import { JobModel, UpdateJobModel, UpdateJobModels } from "../../Model/Job/JobModel";



 const serverApi =process.env.REACT_APP_SERVER_URL


// add job into job collection
export function addUserJob(data:JobModel){
    return apiCilent.post(`/job/add`,data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}


// update job into job collection
export function UpdateUserJob(data:UpdateJobModels){
    return apiCilent.post(`/job-update`,data)
    .then(response => {
      
        return response
    }).catch(error => {
        throw error;
    });
}

// get user Job
export function GetUsersJob(jobId:number){
    const data={
        jobId:jobId
    }
    return apiCilent.post(`/job/get`,data)
    .then(response => {
        
        return response
    }).catch(error => {
        throw error;
    });
}


// get All jobs
export function GetJobsAll(page:number, limit:number){
  
    const data={
        page:page,
        limit:limit
    }
    
    return apiCilent.post(`/admin/job/get-all`,data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}

export function UpdateBase64(base64Image:string, jobId:number){
 
    const data={
        jobId:jobId,
        base_64:base64Image
    }
    return apiCilent.post(`/job-update-base64`, data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}

export function UpdateSortedLayer( jobId:number,sortedLayer:string[]){
 
    const data={
        jobId:jobId,
        sortedLayer:sortedLayer
    }
    return apiCilent.post(`/job/update-segment-layer`, data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}


// delete job by id

export function DeleteJobById( jobId:number){
 
    const data={
        jobId:jobId,
        
    }
    return apiCilent.post(`/project/delete-job`, data)
    .then(response => {
       
        return response
    }).catch(error => {
        throw error;
    });
}
