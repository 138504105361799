import React from 'react'
import SegDetectModelHome from '../../module/admin/segDetectModel/SegDetectModelHome'

const SegDetectModelPage = () => {
  return (
    <>
    <SegDetectModelHome />
    </>
  )
}

export default SegDetectModelPage