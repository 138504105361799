import { fabric } from "fabric";

export const ShowMagnifier = (
    options: fabric.IEvent,
    canvasRef: React.RefObject<fabric.Canvas>,
) => {
    const canvas = canvasRef.current as fabric.Canvas; // Type assertion to fabric.Canvas
    if (!canvas || canvas.width === undefined || canvas.height === undefined)
      return;
  
    const pointer = canvas.getPointer(options.e); // Get pointer position
  
    // Create a new magnified image based on the current canvas
    const zoomedCanvas = document.createElement("canvas");
  
    zoomedCanvas.width = 140; // Size of the zoomed area
    zoomedCanvas.height = 140; // Size of the zoomed area
    const zoomedContext = zoomedCanvas.getContext("2d");
    // Draw the zoomed area onto the new canvas
    zoomedContext?.drawImage(
      canvas.getElement(),
      pointer.x - 70, // Center the zoomed area
      pointer.y - 70, // Center the zoomed area
      140, // Width of the zoomed area
       140, // Height of the zoomed area
      0,
      0,
      140,
      140
    );
  
    // Create a fabric image from the zoomed canvas
    const canvasWidth = canvasRef.current?.width ?? 0;
    const magnifiedImage = new fabric.Image(zoomedCanvas, {
      left: canvasWidth-150, // Position it over the magnifier
      top: 5, // Position it over the magnifier
  
      selectable: false,
      evented: false,
      name: "magnifiedImage", // Name for the magnified image
    });
    // Remove previous magnified images to prevent multiple layers
    const existingMagnifiedImage = canvas
      .getObjects()
      .find((obj) => obj.name === "magnifiedImage");
    if (existingMagnifiedImage) {
      canvas.remove(existingMagnifiedImage); // Remove the existing magnified image
    }
  
    // Add the new magnified image to the canvas
    canvas.add(magnifiedImage);
  };
  