import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSegregateSegment } from '../../../../../slice/segegratedSlice/SegregatedSlice'
import { getMasterArray } from '../../../../../slice/canvas/masterArray/MasterArraySlice'
import { get } from 'http'
import { ReactSVG } from 'react-svg'
import { getGroupName } from '../../../../../slice/toolActive/ToolActiveSlice'
import { getUserLogin } from '../../../../../slice/user/UserSlice'
import { getAllSegment } from '../../../../../slice/segment/SegmentSlice'
import { SegmentModel } from '../../../../../Model/segment/SegmentModel'
import { MasterArrayModel, MasterModel } from '../../../../../Model/masterArray/MasterArrayModel'
import SegmentDetails from '../../projectDetails/SegmentDetails'
import "../../projectDetails/ProjectDetails.scss"
const SegmentFiliter = () => {
    const [markActive, setMarkActive] = useState<string|null>(null)
    const getMasterArrays= useSelector(getMasterArray) 
    const getGroupNames= useSelector(getGroupName)
    const [filterSegment, setFilterSegment] = useState<SegmentModel[] | MasterArrayModel[]>([]) // Change model type based on login status
    const getUserLogins= useSelector(getUserLogin)
    const getAllSegments= useSelector(getAllSegment)

    // update the active state of the segment
    useEffect(() => {
        if(getGroupNames){
            setMarkActive(getGroupNames)
        }
        else{
            setMarkActive(null)
        }
    }, [getGroupNames])

    useEffect(() => {
        if (getUserLogins &&
           getUserLogins[0]?.id &&
           getMasterArrays &&
            getMasterArrays?.allSeg &&
           getMasterArrays?.allSeg?.length > 0) {
            if (getMasterArrays?.allSeg?.length > 0) {
                setFilterSegment(getMasterArrays.allSeg); // Use MasterModel when logged in
            }
        } else {
            if (getAllSegments?.length > 0) {
                setFilterSegment(getAllSegments); // Use SegmentModel otherwise
            }
        }
    }, [getUserLogins, getAllSegments, getMasterArrays]);

    return (
        <>
     {/* <div className="total-seg-sec d-flex flex-wrap align-items-center gap-1"> */}
     {
            filterSegment &&
            filterSegment.length > 0 &&
            filterSegment.map((item, index) => {
                const path = "https://dzinlystrapi.s3.us-east-2.amazonaws.com";

                return (
                   
                    <div className="seg-progress-details rounded-pill" key={`${item.name}-${index}`}
                    style={{
                                    border: `1px solid ${item.color_code}`,
                                    borderRadius: "6px",
                                    // color:"white",
                                    backgroundColor: item.isActive ? `${item.color_code}50` : "transparent",
                                    transition: "background-color 0.3s ease",
                                }}
                    >
                    <span className="seg-color-code"
                    style={{ backgroundColor: `${item.color_code}` }}
                    ></span>
                    <p>
                       {item.name} 
                    </p>
                  </div>
                )
            })
        }
     {/* </div> */}
       
        </>
    )
}

export default SegmentFiliter

 // <div className={`select-seg-list  ${item.name === markActive ? 'active' : ''}`}>
                    //     <button className='btn d-flex gap-2 align-items-center'
                    //         style={{
                    //             border: `1px solid ${item.color_code}`,
                    //             borderRadius: "6px",
                    //             // color:"white",
                    //             backgroundColor: item.isActive ? `${item.color_code}50` : "transparent",
                    //             transition: "background-color 0.3s ease",
                    //         }}
                    //     >
                    //         <span className="icon">
                    //             <ReactSVG
                    //                 src={`${item.icon}`}
                    //                 beforeInjection={(svg) => {
                    //                     svg.setAttribute("style", "width: 100%; height: 100%; border-radius: 4px;");
                    //                 }}
                    //                 onError={(error) => {
                    //                     console.error("Error loading SVG:", error);
                    //                 }}
                    //             />
                    //         </span>
                    //         {item.name}
                    //     </button>
                    // </div>