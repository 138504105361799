import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { GetSettingPathData } from '../../../../api/settingPathApi/SetttingPathApi';
import { addMessage } from '../../../../slice/messageToast/ToastSlice';
import axios from 'axios';
import { addSettingPath } from '../../../../slice/settingPathSlice/SettingPathSlice';
import { MaterialPath } from '../../../ImageView/LeftSections/util/materialPath/MaterialPath';

type Props = {
    resetSettingPath:()=>void
}

const GetAllSettingPath = ({resetSettingPath}:Props) => {

   const dispatch = useDispatch();
   const isApi= useRef(true)

    useEffect(() => {
        if(isApi.current){
            isApi.current= false
            getPathSetting()
        }
    },[])
   const getPathSetting = async () => {
    try {
      const response = await GetSettingPathData()
      if(response.status === 200){
       // console.log("gett setting Pathh--",response.data)
       MaterialPath(response.data)  // update the material path in ts file
       
           dispatch(addSettingPath({pathData:response.data}))
                isApi.current = true
                resetSettingPath()
      }
      
    } catch (err) {
      
        if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
               isApi.current = true
               resetSettingPath()
              
        }
       
   }
}

  return (
    <>
    </>
  )
}

export default GetAllSettingPath