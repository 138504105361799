import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTargetAddSegment } from '../../../../../slice/canvas/updatevalue/UpdateValueSlice';
import { Popover, Overlay } from "react-bootstrap";
import "../deleteCurrentSwatch/DeleteCurrentSwatch.scss";
import { Swatch } from '../../../../../Model/Job/SamModel';

type Props = {
  swatchId: number;
  swatchData:Swatch
};

const OptionCurrentSwatch = ({ swatchId ,swatchData}: Props) => {
  const [groupName, setGroupName] = useState<string | null>(null);
  const [groupSubName, setGroupSubName] = useState<string | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const getTargetAddSegments = useSelector(getTargetAddSegment);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      getTargetAddSegments &&
      getTargetAddSegments.groupName &&
      getTargetAddSegments.subGroupName
    ) {
      setGroupName(getTargetAddSegments.groupName);
      setGroupSubName(getTargetAddSegments.subGroupName);
    }
  }, [getTargetAddSegments]);



  // Hide popover when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (target && !target.contains(event.target as Node)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopover, target]);

  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setTarget(event.currentTarget);
    setShowPopover((prev) => !prev);
  };

  return (
    <>
      <div className="pallet-infomation-icon">
        <i
          className="bi bi-three-dots-vertical"
          onClick={togglePopover}
          aria-expanded={showPopover}
        ></i>
        <Overlay
          show={showPopover}
          target={target}
          placement="bottom"
          containerPadding={20}
        >
          <Popover className="popover-select-area" id={`popover-${swatchId}`}>
     
            <div className="pallet-info">
              <h6 className="font-weight-bold">Segments</h6>
              <ul className="seg-pallet-act m-0">
              {swatchData && swatchData.segGroup &&
               swatchData.segGroup.map((item, index) => {
                return(

                  <li>
                  {item}
                  <span>
                    <i className="bi bi-trash3" aria-label="Delete WL3"></i> |{" "}
                    <i className="bi bi-arrow-repeat" aria-label="Refresh WL3"></i>
                  </span>
                </li>
              
                )
              })
            }
             
              </ul>
              <ul className="seg-pallet-actions p-0 m-0">
                <li className="global-action">
                  <i
                    className="bi bi-trash3"
                    aria-label="Delete All"
                    title="Delete All"
                  ></i>
                  All Delete
                </li>
                <li className="global-action">
                  <i
                    className="bi bi-star"
                    aria-label="Add or Remove Favorites"
                    title="Add or Remove Favorites"
                  ></i>
                  Add/Remove Fav
                </li>
              </ul>
            </div>
          </Popover>
        </Overlay>
      </div>
    </>
  );
}

export default OptionCurrentSwatch

{/* <Popover className="popover-select-area" id={`popover-${swatchId}`}>
<div className="pallet-info">
  <h6 className="font-weight-bold">Segments</h6>
  <ul className="seg-pallet-act m-0">
    <li>
      WL3
      <span>
        <i className="bi bi-trash3" aria-label="Delete WL3"></i> |{" "}
        <i className="bi bi-arrow-repeat" aria-label="Refresh WL3"></i>
      </span>
    </li>
    <li>
      WL6
      <span>
        <i className="bi bi-trash3" aria-label="Delete WL6"></i> |{" "}
        <i className="bi bi-arrow-repeat" aria-label="Refresh WL6"></i>
      </span>
    </li>
  </ul>
  <ul className="seg-pallet-actions p-0 m-0">
    <li className="global-action">
      <i
        className="bi bi-trash3"
        aria-label="Delete All"
        title="Delete All"
      ></i>
      All Delete
    </li>
    <li className="global-action">
      <i
        className="bi bi-star"
        aria-label="Add or Remove Favorites"
        title="Add or Remove Favorites"
      ></i>
      Add/Remove Fav
    </li>
  </ul>
</div>
</Popover> */}