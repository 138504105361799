import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateBase64 } from '../../../slice/userJobSlice/UserJobSlice'
import { GetBase64Image } from '../../../api/modelApi/ModelApi'
import { startLoading, stopLoading } from '../../../slice/loading/LoadingSlice'
import { addApiMessage, addMessage } from '../../../slice/messageToast/ToastSlice'
import { error } from 'console'
import { getSettingPath } from '../../../slice/settingPathSlice/SettingPathSlice'
const backendUrl =process.env.REACT_APP_BACKEND_URL
 const urlpath= process.env.REACT_APP_Project_Image
type Props={
  jobId:number,
    url:string, 
    projectId:number
    resetCallApi:(data:boolean)=> void
}
const GetBase64FromApi = ({url,projectId,jobId,resetCallApi}:Props) => {
    const isApi= useRef(true)
    const dispatch= useDispatch()
    const getSettingPaths= useSelector(getSettingPath)
    const [path, setPath]= useState<string|null>(null)

    useEffect(()=>{
        if(url && 
            isApi.current && 
            projectId &&
            jobId &&
            getSettingPaths &&
            getSettingPaths.length>0
          ){
            isApi.current=false
            const projectPath= getSettingPaths.filter((data)=>data.title==="Project Image")
             const paths= projectPath[0].path

              if(paths){
                
                const imageUrl=`${paths}${projectId}/${jobId}/${url}`
                 

                  callAIModel(imageUrl)  
                }
               
                
           
        }
    },[url,projectId,jobId,getSettingPaths])


    const callAIModel=  async (url:string)=>{
     
    try{
   const response= await GetBase64Image(url);
     //console.log("base 64 response",response)
     if(response && response.status==200){
      
      dispatch(updateBase64({
        base64:response.data.img_base64}))
      isApi.current=true
      resetCallApi(true)
      dispatch(addApiMessage(""))
      dispatch(stopLoading())
     }
     
    }catch(err:unknown){
        // alert("Error in model Api")
       // console.log("base 64 error", err)
        dispatch(addMessage({
          isShow:true,
          mess:"Error in model Api",
           toastType:"Error"
         }))
        dispatch(stopLoading())
        resetCallApi(false)
    }
}
  return (
    <div>
        
    </div>
  )
}

export default GetBase64FromApi

// dispatch(updateBase64(modelData.data.Base64))