import React from "react";
import Header from "../header/Header";
import "./SettingPage.scss";
const SettingPage = () => {
  return (
    <>
      <Header />
      <div className="d-flex align-items-center justify-content-center vh-100 profile-page">
        <div className="container">
          <div>
            <h4>My Profile</h4>

            <div className="card bg-secondary shadow" >
              <div className="card-body d-flex align-items-center justify-content-between">
                <div className="user-info">
                  <div className="d-flex align-items-center">
                    <div className="icon-container text-center bg-white ">
                      <i className="bi bi-person"></i>
                    </div>
                    <div className="user-details">
                      <h5 className="card-title">Vijendra Jat</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">
                        Web Designer
                      </h6>
                      <p className="card-text">
                        Ashadeep Rainbow, Jagatpura, Jaipur
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <button className="btn border rounded-pill bg-white">
                    <i className="bi bi-pencil-square"></i> Edit
                  </button>
                </div>
              </div>
            </div>

            <div className="card my-4 bg-secondary shadow">
              {/* <div className="card-header bg-white">Personal Information</div> */}
              <div className="card-header bg-white d-flex align-items-center">
                <div className="me-auto">
                 <h5 className="m-0">Personal Information</h5>
                </div>
                <button className="btn border rounded-pill">
                  <i className="bi bi-pencil-square"></i> Edit
                </button>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="mb-3 col-6">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder=" first name..."
                      />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder=" last name..."
                      />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder=" email..."
                      />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="phone" className="form-label">
                        Phone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        placeholder=" phone number..."
                      />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="phone" className="form-label">
                        Company
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        placeholder=" Company number..."
                      />
                    </div>
                  </div>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="card my-4 bg-secondary shadow">
              <div className="card-header bg-white d-flex align-items-center">
                <div className="me-auto">
                <h5 className="m-0">Reset Password</h5>
                  </div>
                <button className="btn border rounded-pill">
                  <i className="bi bi-pencil-square"></i> Edit
                </button>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    {/* <h4 className="text-center pb-5">Reset Password</h4> */}
                    <div className="mb-3 col-6">
                      <label htmlFor="currentPassword" className="form-label">
                        Current Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="currentPassword"
                        placeholder=" Current password..."
                      />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="newPassword" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        placeholder=" New password..."
                      />
                    </div>
                    <div className="mb-3 col-6">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        placeholder="Confirm new password..."
                      />
                    </div>
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary">
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* <div className=" d-flex rounded bg-white">
      {/* Sidebar Tabs */}

          {/* <ul className="nav nav-tabs flex-column border-end profile-tab" id="myTab" role="tablist" style={{ minWidth: "200px"}}>
        {/* Profile Tab */}
          {/* <li className="nav-item" role="presentation">
            <button
              className="nav-link active text-start"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true">
              My Profile
            </button>
          </li>

          {/* Reset Password Tab */}
          {/* <li className="nav-item" role="presentation">
            <button
              className="nav-link text-start"
              id="password-tab"
              data-bs-toggle="tab"
              data-bs-target="#resetPassword"
              type="button"
              role="tab"
              aria-controls="resetPassword"
              aria-selected="false">
              Reset Password
            </button>
          </li> */}
          {/* </ul>  */}

          {/* Tab Content */}
          {/* <div className="tab-content flex-grow-1 p-4" id="myTabContent"> */}
          {/* Profile Form */}
          {/* <div
          className="tab-pane fade show active"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <form>
            <h4 className="text-center pb-5 ">Personal Information</h4>
            <div className="row">
              <div className="mb-3 col-6">
                <label htmlFor="firstName" className="form-label">First Name</label>
                <input type="text" className="form-control" id="firstName" placeholder=" first name..." />
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="lastName" className="form-label">Last Name</label>
                <input type="text" className="form-control" id="lastName" placeholder=" last name..." />
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" placeholder=" email..." />
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input type="tel" className="form-control" id="phone" placeholder=" phone number..." />
              </div>
              <div className="mb-3 col-6">
                <label htmlFor="phone" className="form-label">Company</label>
                <input type="tel" className="form-control" id="phone" placeholder=" Company number..." />
              </div>
            </div>
            <div className="text-end">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
          </form>
        </div> */}

          {/* Reset Password Form */}
          {/* <div
          className="tab-pane fade"
          id="resetPassword"
          role="tabpanel"
          aria-labelledby="password-tab"
        >
          <form>
            <h4 className="text-center pb-5">Reset Password</h4>
            <div className="mb-3">
              <label htmlFor="currentPassword" className="form-label">Current Password</label>
              <input type="password" className="form-control" id="currentPassword" placeholder=" Current password..." />
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">New Password</label>
              <input type="password" className="form-control" id="newPassword" placeholder=" New password..." />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
              <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm new password..." />
            </div>
            <div className="text-end">
              <button type="submit" className="btn btn-primary">Reset Password</button>
            </div>
          </form>
        </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default SettingPage;
