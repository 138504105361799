import { userModel } from "../../../Model/user/UserModel";


export const GetUserRole_ID = (userData: userModel[]):number => {

  if (userData && userData[0]?.id && userData[0]?.name && userData[0]?.role) {
    return(userData[0]?.id);
    
  }
  return 0;
};

