import React, { useEffect } from 'react'
import { DeleteFolderS3Bucket } from '../../../../api/folderApi/FolderApi';
import { addMessage } from '../../../../slice/messageToast/ToastSlice';
import axios from 'axios';
import { useDispatch } from 'react-redux';


type Props={
    projectId:number
    resetDeleteFolder:()=>void
}
const DeleteFolder = ({projectId,resetDeleteFolder}:Props) => {

    const dispatch= useDispatch()
    const isApi= React.useRef(true)

    useEffect(()=>{
        if(projectId && isApi.current){
            isApi.current=false
            deleteFolder(projectId)
        }
    },[projectId])

    const deleteFolder = async (projectId: number) => {
   try{
    const response = await DeleteFolderS3Bucket(projectId)
    console.log("delete folder", response)
        if(response && response.status===200){
            dispatch(addMessage({
                isShow:true,
                mess: "Folder deleted successfully",
                toastType:"success"
               }))
        }
        isApi.current=true
        resetDeleteFolder()
    }catch(err){
        if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))

               isApi.current=true
               resetDeleteFolder()
        }
    }
   }
    
  return (
    <></>
  )
}

export default DeleteFolder