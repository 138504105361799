import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { JobSegmentationModel } from '../../../../Model/masterArray/MasterArrayModel';
import "./EachSeg.scss";
import Action from './action/Action';
import './GroupSeg.css'
import { useDispatch, useSelector } from 'react-redux';
import { getMasterArray, getUpdateIndexing, resetisDeleteSegFromcanvas, startSegHoverEffect, stopSegHoverEffect, updateIndexing } from '../../../../slice/canvas/masterArray/MasterArraySlice';
import { addSelectedSegment, } from '../../../../slice/userJobSlice/UserJobSlice';
import { getCanvasControl, getIsSelectedCanvas, resetCanvasControl, updateisSeletedCanvas } from '../../../../slice/canvas/masterArray/CanvasControlSlice';
import { getUpdateValueStatus, stopUpdateValue } from '../../../../slice/canvas/updatevalue/UpdateValueSlice';
import { getChildName } from '../../../../slice/toolActive/ToolActiveSlice';
import { updateGroupSegment } from './util/GetChildGroup';

type Props={
    allSegDetail:JobSegmentationModel[]
    groupName:string
}
const GroupSegment = ({allSegDetail,groupName}:Props) => {
    const [allgroupSegment, setAllGroupSegement]= useState<JobSegmentationModel[]>([])
    const [selectedTab, setSelectedTab] = useState(0);
   const [eachSegName, setEachSegName]=useState<string|null>()
   const [eachSeg, setEachSeg]=useState<JobSegmentationModel|null>(null)
   const dispatch= useDispatch()
   const getCanvasControls= useSelector(getCanvasControl)
   
   const [groupNames, setGroupNames]= useState<string|null>(null)
   const [childGroupName, setChildGroupName]= useState<string|null>(null)
   const getMasterArrays= useSelector(getMasterArray)
   const getIsSelectedCanvass= useSelector(getIsSelectedCanvas)
  


   // Function to handle tab click
    const handleTabClick = (allData:JobSegmentationModel) => {
       console.log("allData--->",allData)
      setEachSegName(allData.name)
      setEachSeg(allData)
      dispatch(addSelectedSegment([allData]))
       
      // setGroupNames()
      // setChildGroupName()
      // setsegName()

    };
    const allMemoChildGroup = useMemo(() => {
      if (allSegDetail && 
          allSegDetail.length > 0 && 
          getMasterArrays && 
          getMasterArrays.allSeg && 
          getMasterArrays.allSeg.length > 0 && 
          groupName) {
        const grpName = groupName.replace(/[0-9]/g, "");
        setGroupNames(grpName);
        setChildGroupName(groupName);
   const  allChdGrp=updateGroupSegment(grpName, groupName, getMasterArrays);
   setAllGroupSegement(allChdGrp??[])
        return allChdGrp
      }
      return [];
    }, [allSegDetail, groupName, getMasterArrays]);

   //console.log("allMemoChildGroup--->",allMemoChildGroup)
    // useEffect(()=>{
    //     if(allSegDetail && 
    //       allSegDetail.length>0 && 
    //       getMasterArrays &&
    //       getMasterArrays.allSeg &&
    //       getMasterArrays.allSeg.length>0 &&
    //       groupName){
           
    //         const grpName = groupName.replace(/[0-9]/g, "");
    //          setGroupNames(grpName)
    //         setChildGroupName(groupName)
    //        const allChildGroup= updateGroupSegment(grpName,groupName,getMasterArrays)
    //        setAllGroupSegement(allChildGroup??[])
    //     } 
        
    // },[allSegDetail,groupName,getMasterArrays])

    //Also if the change in master Image then update the value
    // useEffect(()=>{
    //   if(getMasterArrays &&
    //     getMasterArrays.allSeg && 
    //     getMasterArrays.allSeg.length>0 &&
    //     groupNames &&
    //     childGroupName 
    //   ){
    //      if(groupNames===groupNames && childGroupName ){
    //     const allChildGrp=updateGroupSegment(groupNames ,childGroupName,getMasterArrays)
    //     setAllGroupSegement(allChildGrp??[])
    //     }

    // },[getMasterArrays,groupNames,childGroupName])


    const handLeMouseHover=(data:JobSegmentationModel)=>{
     
       dispatch(startSegHoverEffect({
        seg:[data]
  
       }))
    }


    const handleMouseOut=()=>{
      dispatch(stopSegHoverEffect())
    }

    // first auto selected 
  
    // onselct the canavas update tje group segment
      useEffect(()=>{
        
        if(getCanvasControls &&
           getCanvasControls.segName &&
           getCanvasControls.segShortName &&
            getCanvasControls.groupName &&
            getCanvasControls.subGroupName &&
           
           getIsSelectedCanvass
          ){

            if(groupNames=== getCanvasControls.groupName &&
              childGroupName== getCanvasControls.subGroupName){
            
               const echSegarray= allMemoChildGroup?.find(item=>item.segName=== getCanvasControls.segName)
                
               if(echSegarray){
                setEachSeg(echSegarray)
                setEachSegName( getCanvasControls.segShortName)
              
                dispatch(addSelectedSegment([echSegarray]))
                //dispatch(updateisSeletedCanvas())
               }

              }else{
                const allChildGroup=updateGroupSegment(getCanvasControls.groupName , getCanvasControls.subGroupName,getMasterArrays)
                if(allChildGroup && allChildGroup.length>0){
                  const echSegarray= allChildGroup.find(item=>item.segName=== getCanvasControls.segName)
                  
                if(echSegarray){
                 setEachSeg(echSegarray)
                 setEachSegName( getCanvasControls.segShortName)
               
                 dispatch(addSelectedSegment([echSegarray]))
                 //dispatch(updateisSeletedCanvas())
                }
                }
               
                 
              }
            
            
           }
      },[getCanvasControls,getIsSelectedCanvass])


        

         
      // // delete segment from canavs and update into Db and update indexing
      // const getUpdateIndexings= useSelector(getUpdateIndexing)
      // const getStartIndexings= useSelector(getStartIndexing)
      // useEffect(()=>{
      
      //    // delete data from canvas
      //   if(getUpdateIndexings &&
      //     getUpdateIndexings.childname &&
      //     getUpdateIndexings.seg_short &&
      //     getUpdateIndexings.isDeleteSegFromcanvas
      //   ){
      //      dispatch(resetisDeleteSegFromcanvas())
      //    dispatch(deleteSegmentFromCanvas({
      //       childName:getUpdateIndexings.childname,
      //       childShortName:getUpdateIndexings.seg_short
      //     }))


      //   }

      //   // update indexing
      //   if(getUpdateIndexings &&
      //     getUpdateIndexings.groupName &&
      //     getUpdateIndexings.subGroupName &&
      //     getStartIndexings
      //   ){
          
      //     //stop IsStarttIndexingIn Job Slice
      //     dispatch(stopIsStartIndexing())
      //     //indexing master Array
      //     dispatch(updateIndexing({
      //       groupName:getUpdateIndexings.groupName,
      //       subGroupName:getUpdateIndexings.subGroupName
      //     }))
      //   }
      // },[getUpdateIndexings,getStartIndexings])

      // after update data into the DB
      const getUpdateValueStatuss= useSelector(getUpdateValueStatus)
      useEffect(()=>{
        if(getUpdateValueStatuss &&
          groupNames &&
          childGroupName
        ){
         const allChildGroup= updateGroupSegment(groupNames,childGroupName,getMasterArrays)
         setAllGroupSegement(allChildGroup??[])
           dispatch( stopUpdateValue())
        }
      },[getUpdateValueStatuss, groupNames,childGroupName])
  return (
    <>

            <div className='segment-tab-menu'>

            <div className="tab-navigation ">
      <Swiper
        spaceBetween={0}
        slidesPerView={'auto'}
        freeMode={true}
        slideToClickedSlide={true}
        centeredSlides={false}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        // onSlideChange={() => console.log('slide change')}
       // onSwiper={(swiper) => console.log(swiper)}
      >
        {allgroupSegment && allgroupSegment.length > 0 ?
        (
          allgroupSegment.map((item, index) => {
            // Check if the current item is the selected tab
            return (
              <SwiperSlide
                key={item.name}
                className={`tab-button ${eachSegName === item.name ? 'active' : ''}`}
                style={{
                  width: 'auto',
                  //backgroundColor: eachSegName === item.name ? 'lightblue' : 'white',
                }}
                onMouseEnter={()=>handLeMouseHover(item)}
                onMouseLeave={handleMouseOut}
                onClick={() => handleTabClick(item)} // Handle click
              >
                {item.name} {/* Display the segment name */}
              </SwiperSlide>
            );
          })
        ):(
          <>No segment</>
        )
        
        }
      </Swiper>

      <div className='seg-pallet-bg'>
      {/* image class "seg-pallet-bg" */}
        {/* Tab panel content */}
        {eachSeg &&
          eachSeg.name==eachSegName &&(
            <div className='py-2'>
          
            <Action
                    allSegDetail={eachSeg}
                    groupName={groupName}
                    />
          </div>
        
          )
        }
       
      </div>
    </div>
              
              
                    

          </div>
        
   
      

    </>
  )
}

export default GroupSegment

  