import React, { useEffect, useRef } from 'react'
import { SettingPathModel } from '../../../../Model/settingPath/SettingPathModel'
import { useDispatch } from 'react-redux'
import { AddSettingPathData, UpdateSettingPathData } from '../../../../api/settingPathApi/SetttingPathApi'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import axios from 'axios'
import { addUpdateSettingPath } from '../../../../slice/settingPathSlice/SettingPathSlice'

type Props={
    updatePath:SettingPathModel,
    resetUpdatePath:()=>void
    mode:string
}
const UpdateSettingPath = ({updatePath,resetUpdatePath,mode}:Props) => {

    const dispatch=useDispatch()
    const isApi= useRef(true)

    useEffect(()=>{
        if(updatePath && isApi.current && mode){
            isApi.current= false
            callUpdateApi(updatePath)
        }
    },[updatePath,mode])
    const callUpdateApi= async(data:SettingPathModel)=>{
        try{
            if(mode=="add"){
                
                const response= await AddSettingPathData(data)
                
                if(response.status==200){

                    dispatch(addUpdateSettingPath({
                        pathData:response.data
                    }))
                  isApi.current= false
                  resetUpdatePath()
                }
            }else{

                const updateresponse= await UpdateSettingPathData(data)
               
                if(updateresponse.status==200){

                    
                    dispatch(addUpdateSettingPath({
                        pathData:data

                    }))
                  isApi.current= false
                  resetUpdatePath()
                }
            }
          
        }catch(err){

            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
                   
                  
            }
            isApi.current= false
            resetUpdatePath()
        }
    }
  return (
    <></>
  )
}

export default UpdateSettingPath