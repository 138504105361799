import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SegDetectModel } from "../../Model/segDetectModel/SegDetect";


interface SegDetectModelStates {
       segDetectModels:SegDetectModel[],
       isSegModel:boolean
   
    
}

const initialState: SegDetectModelStates = {
    segDetectModels:[],
    isSegModel:false    

};

const SegDetectModelSlice = createSlice({
    name: "segDetectModel",
    initialState,
    reducers: {
       addSegDetectModel:(state,action)=>{
        state.segDetectModels= action.payload
       },
       resetSegeDetectModel:(state)=>{
        state.segDetectModels=[]
       },
        updateSegDetectModel: (state, action) => { 
             const {modelData}=action.payload
                const index= state.segDetectModels.findIndex((model)=>model.id===modelData.id)
                if(index!==-1){
                    state.segDetectModels[index]=modelData
                }else{
                    state.segDetectModels.push(modelData)
                }

    },
    updateSegModal:(state,action)=>{
        state.isSegModel=action.payload
    }
}
}
)

export const {addSegDetectModel, 
    resetSegeDetectModel,
    updateSegDetectModel,
    updateSegModal
}=SegDetectModelSlice.actions;

export const getSegDetectModel=  (state:{segDetectModel:SegDetectModelStates})=>state.segDetectModel.segDetectModels;

export const getSegModelStatus=  (state:{segDetectModel:SegDetectModelStates})=>state.segDetectModel.isSegModel;    
export default SegDetectModelSlice.reducer