import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';

const FabricToolcanvas = () => {
  

  return (
    <>
      <h4>Fabric Tool Canvas</h4>
      
    </>
  );
}

export default FabricToolcanvas;