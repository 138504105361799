import React, { useEffect, useState } from 'react'
import GetMaterials from './GetMaterials'

import GetAllMaterialCaterogy from './GetAllMaterialCaterogy'

import { useSelector } from 'react-redux'
import {  getIsDownloadSwatch, getMaterial } from '../../slice/materialSlice/MaterialSlice'
import GetAllMaetrial_brand_style from './style/GetAllMaetrial_brand_style'
import GetMaterialBrand from './brand/GetMaterialBrand'

import GetAllSegments from '../brandPage/Segment/api/GetAllSegments'
import { getIsDownloadCategory } from '../../slice/materialSlice/MaterialCategorySlice'
import { getIsDownloadBrand } from '../../slice/materialSlice/MaterialBrandSlice'
import { getIsDownloadStyle } from '../../slice/materialSlice/MaterialStyleSlice'

const GetAllMaterialsHome = () => {
  const [ isCategory, setIsCategory]= useState<boolean>(false)
  const [ isBrand, setIsBrand]= useState<boolean>(false)
  const [ isStyle, setIsStyle]= useState<boolean>(false)
  const [ isSwatch, setIsSwatch]= useState<boolean>(false)
       const getIsDownloadCategorys= useSelector(getIsDownloadCategory)
       const getIsDownloadBrands= useSelector(getIsDownloadBrand)
       const getIsDownloadStyles= useSelector(getIsDownloadStyle)
       const getIsDownloadSwatchs= useSelector(getIsDownloadSwatch)


       // update download category
       useEffect(()=>{
        if(getIsDownloadCategorys){
          setIsCategory(getIsDownloadCategorys)
        }else{
          setIsCategory(false)
        }
       },[getIsDownloadCategorys])


       // update download Brand
       useEffect(()=>{
        if(getIsDownloadBrands){
          setIsBrand(getIsDownloadBrands)
        }else{
          setIsBrand(false)
        }
       },[getIsDownloadBrands])


       // update download style
       useEffect(()=>{
        if(getIsDownloadStyles){
          setIsStyle(getIsDownloadStyles)
        }else{
          setIsStyle(false)
        }
       },[getIsDownloadStyles])


       // update download swatch

       useEffect(()=>{
        if(getIsDownloadSwatchs){
          setIsSwatch(getIsDownloadSwatchs)
        }else{
          setIsSwatch(false)
        }
       },[getIsDownloadSwatchs])


  return (
    <div>

        {/*  all category */}
     {  isCategory &&
      <GetAllMaterialCaterogy/>}


       {/*  allBrand */}
      { isBrand &&
      <GetMaterialBrand/>}


        {/*  all style with brand and category */}
       {isStyle &&
        <GetAllMaetrial_brand_style/>}


      {/* get ALL material */}
      {isSwatch &&
       <GetMaterials/>}

        

        {/* all Segment */}
        <GetAllSegments/> 
    </div>
  )
}

export default GetAllMaterialsHome