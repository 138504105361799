import React from 'react'
import SettingPage from '../../module/settingsPage/SettingPage'

const Settings = () => {
  return (
    <>
    <SettingPage/>
    </>
  )
}

export default Settings
