import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { getToast, resetMessage } from '../../slice/messageToast/ToastSlice';
import { useNavigate } from 'react-router-dom';
import { resetSegregatedSegments } from '../../slice/segegratedSlice/SegregatedSlice';
import { resetGeneratedPrompt, resetProjectGenAiImages } from '../../slice/genAi/GenAiAttributesSlice';
import { resetWebSocket } from '../../slice/webSocketSlice/WebSocketSlice';
import { changeSegmentTab } from '../../slice/tabControl/TabControlSlice';
import { resetSVGImage } from '../../slice/svdImageSlice/SvgImageSlice';
import { resetCanvasControl, resetCanvasControlState } from '../../slice/canvas/masterArray/CanvasControlSlice';
import { switchToOutline } from '../../slice/tabControl/TabControlSlice';
import { resetUserJob } from '../../slice/userJobSlice/UserJobSlice';
import { resetIsRegenaration } from '../../slice/modelData/ModelDataSlice';
import { resetPrompValue } from '../../slice/genAi/GenAiAttributesSlice';
import { resetUploadMessage } from '../../slice/modelData/ModelDataSlice';
import { resetMasterArray } from '../../slice/canvas/masterArray/MasterArraySlice';
import { resetSelectedSegment } from '../../slice/userJobSlice/UserJobSlice';
import { resetSwatchOnCanvas } from '../../slice/canvas/masterArray/SwatchOnCanvas';
import { stopLoading } from '../../slice/loading/LoadingSlice';
import { resetCanvasComment } from '../../slice/projectCommet/ProjectCommentSlice';
import { resetToolActive } from '../../slice/toolActive/ToolActiveSlice';
import { resetUser } from '../../slice/user/UserSlice';
import "./MesageToast.scss";


const MessageToast = () => {
  const [show, setShow] = useState(true);
  const[ toastMessage, setToastMessage]=useState<string|null>(null)
  const [toastType, setToastType] = useState<string|null>(null); // Can be 'success' or 'error'
  const [messTime , setMessTime] = useState<number>(5000)
  const getToasts= useSelector(getToast)
   const dispatch= useDispatch()
   const navigate= useNavigate()
   useEffect(() => {
    if (getToasts.isShow &&
       getToasts.message &&
       getToasts.toastType!=""
      ) {
      
      if (getToasts.message === "Invalid or expired token") {
        setShow(getToasts.isShow);
        setToastMessage(getToasts.message);
        setToastType(getToasts.toastType??"")
        setMessTime(9000); 
        handleCustomerProfile()
        setTimeout(() => {
          dispatch(stopLoading());
          navigate("/auth/login")
        }, 6000); 
      } else {
        setShow(getToasts.isShow);
        setToastMessage(getToasts.message);
        setToastType(getToasts.toastType??"")
        setMessTime(getToasts.toastType != "success" ? 6000 : 5000);
      }
    } else {
      setShow(false);
      setToastMessage(null);
      setToastType(null);
    }
  }, [getToasts]);

  // close toast component
  useEffect(()=>{
    if(!show){
       dispatch(resetMessage())
    }
  },[show])
  
  const handleCustomerProfile = () => {
    //updateSegmentIsDisable()// update the segment isDisable
    dispatch(resetSVGImage());
    dispatch(resetUser())
    removeProjectFromLocalStorage();
    dispatch(resetCanvasControlState()) // initial state to canvas control
    // dispatch(switchToOutline("segment"))
    // dispatch(changeSegmentTab("segment"))
    dispatch(resetWebSocket()) // reset the web socjet slice

    dispatch(resetPrompValue()) // reset the prompt value

    dispatch(resetProjectGenAiImages()) // reset project GeAi images


    dispatch(resetMessage())  // reset the message from toast

    dispatch(resetSegregatedSegments()) // reset the segregated slice
    //  navigator(`/${userRole}/project`);

  };

  const removeProjectFromLocalStorage = () => {
    dispatch(resetSVGImage());
    localStorage.removeItem("getSegregateSegments");
    localStorage.removeItem("getUserJobDatas");
    localStorage.removeItem("getMasterArrays");
    localStorage.removeItem("getUserLogins");
    dispatch(resetUploadMessage());
    dispatch(resetIsRegenaration());
    dispatch(resetMasterArray());
    dispatch(resetSelectedSegment());
    dispatch(resetUserJob());
    dispatch(resetSwatchOnCanvas());
    dispatch(stopLoading());
    dispatch(resetCanvasComment()) // remove project Comments
    dispatch(resetToolActive())  // update the Tool active 
    dispatch(resetCanvasControl())
    dispatch(resetGeneratedPrompt()) // reset the slice for generared prompt
   
  };
  return (
    <>
     {show &&
      <ToastContainer position="bottom-start" className="p-3">
      <Toast 
      onClose={() => setShow(false)} 
      show={show} delay={messTime} autohide
        //  bg={toastType != 'success' ? 'danger' : ''}
      >
        <Toast.Header className='toast-header'>
          <svg className="bd-placeholder-img rounded me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
    <rect width="100%" height="100%" fill={toastType === 'success' ? '#11b823' : 'red'}></rect> {/* Change fill color based on toastType */}
  </svg>
          <strong className="me-auto" >Alert</strong>
          {/* <strong className="me-auto">{toastType === 'success' ? 'Success' : 'Error'}</strong> */}
          
        </Toast.Header>
        <Toast.Body
       
        >{toastMessage}</Toast.Body>
      </Toast>
    </ToastContainer>}
    </>
   
  );
};

export default MessageToast;
