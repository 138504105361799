import React, { useEffect, useRef } from 'react'
import { MaterialCategoryModel } from '../../../../../Model/material/MaterialCategoryModel'
import { AddCategories, UpdateCategories } from '../../../../../api/material/category/CategoryApi'
import { useDispatch } from 'react-redux'
import { updateCategory } from '../../../../../slice/materialSlice/MaterialCategorySlice'
import { MaterialBrandModel } from '../../../../../Model/material/MaterialBrandModel'
import { UpdateBrand } from '../../../../../api/material/brand/BrandApi'
import { updateEditBrand } from '../../../../../slice/materialSlice/MaterialBrandSlice'


type Props={
    resetUpdate:(data:string)=>void,
    editBrand:MaterialBrandModel
}
const UpdateBrandApi = ({resetUpdate,editBrand}:Props) => {
     const dispatch= useDispatch()
    const isApi= useRef(true)
    useEffect(()=>{
      if(editBrand && 
          editBrand.id &&
          isApi.current
         ){
         callUpdateBrand(editBrand)
         isApi.current=false
      }
    },[editBrand])

    const callUpdateBrand=async(data:MaterialBrandModel)=>{

         try{
            const response= await UpdateBrand(data);
             if(response && response.status===200){
                
                isApi.current=true;
                dispatch(updateEditBrand({
                  data:response.data
                }))
                resetUpdate("success")
             }
         }catch(err){
          isApi.current=true;
            resetUpdate("fail")
         }
    }
  return (
    <div></div>
  )
}

export default UpdateBrandApi