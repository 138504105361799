import React, { useEffect, useState } from 'react'
import { MaterialModel, NameModel } from '../../../../../../Model/material/MaterialModel'
import InputMaterial from './InputMaterial'
import { useDispatch, useSelector } from 'react-redux'
import { getCategoryName, getMaterialCategorys } from '../../../../../../slice/materialSlice/MaterialCategorySlice'
import { getAllBrandName, getMaterialBrand } from '../../../../../../slice/materialSlice/MaterialBrandSlice'
import { getMaterialStyle, getStyleName } from '../../../../../../slice/materialSlice/MaterialStyleSlice'
import UploadSwatchImage from './UploadSwatchImage'
import { getAllSegment } from '../../../../../../slice/segment/SegmentSlice'
import { GetBrandscategoryIdBased, GetStyleBrandIdcategoryIdBased } from '../../searchSwatchs/util/Searchbased'
import SelectedBoolean from './SelectedBoolean'
import AddSwatchApi from '../../api/AddSwatchApi'
import { GetUserRole_ID } from '../../../../../auth/util/GetUserRole_ID'
import { getUserLogin } from '../../../../../../slice/user/UserSlice'
import { updateRefetchDoor, updateRefetchWindow, updateRefetchGarage, updateRefetchGutter, updateRefetchWall, updateRefetchTrim, updateRefetchRoof } from '../../../../../../slice/swatches/SwatchSlices'
import SearchStyleBrandBased from '../../searchSwatchs/searchStyle/SearchStyleBrandBased'
import { Spin } from 'antd'

type Props = {
    isModalOpen: boolean
    onClose: () => void
    type: string  // add | edit
}
const AddEditSwatch = ({isModalOpen,onClose, type}:Props) => {
  const dispatch = useDispatch()
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imageName, setImageName] = useState<string>("");
    const[ isUpload, setIsUpload]= useState<boolean>(false)
    const [thumbnailUrl, setThumbnailUrl] = useState<string>(""); // State to store the URL of the thumbnail
     const [path, setPath] = useState<string>("");
     const [isSave, setIsSave] = useState<boolean>(false);
    const getCategoryNames= useSelector(getCategoryName)
       const[optionBrands, setOptionBrands]= useState<NameModel[]>([])
       const[optionStyles, setOptionStyles]= useState<NameModel[]>([])
       const [isGetStyle, setIsGetStyle] = useState<boolean>(false);
      const[isAdd, setIsAdd]= useState<boolean>(false)
       const[brandId, setBrandId]= useState<number[]>([])
      
       const [auto, setAuto] = React.useState(false);
       const [percent, setPercent] = React.useState(-50);
    const getMaterialBrands= useSelector(getMaterialBrand)
    const getMaterialStyles= useSelector(getMaterialStyle)
    const getAllSegments= useSelector(getAllSegment)
    const getUserLogins= useSelector(getUserLogin)


     const mergedPercent = auto ? 'auto' : percent;
    const userId=GetUserRole_ID(getUserLogins)

    const [addMaterial, setAddMaterial] = useState<MaterialModel>({
        title: '',
        photo_svg: '',
        id: 0,
        user_id: userId??0,
        role_id: 0,
        material_category_id: 0,
        material_brand_id: 0,
        material_brand_style_id: 0,
        description: '',
        photo: '',
        is_admin: 0,
        finish_needed: 0,
        suggested_swatches: [],
        manufacturer_request: 0,
        manufacturer_request_note: null,
        status: 0,
        created: new Date().toDateString(),
        modified: new Date().toDateString(),
        bucket_path: 'default',
        is_featured: false,
        related_materials: [],
        segment: [],
        style: [],
        color: '',
        material_type_id: 0,
        material_style: undefined,
      });
      const handleSegment = (field: keyof MaterialModel, values: string[]) => {
        let allSeg: string[] = addMaterial.segment || [];
        values.forEach((value) => {
          refetchSwatch(value) // refetch swatch
          if (allSeg.includes(value)) {
            allSeg = allSeg.filter((item) => item !== value);
          } else {
            allSeg.push(value);
          }
        });
        setAddMaterial({ ...addMaterial, [field]: allSeg });
      };
      
      const handleCategoryChange=(key:string,value:number)=>{
        //console.log(key,value)
        setAddMaterial({...addMaterial,[key]:value})
        const allBrands=GetBrandscategoryIdBased(value,getMaterialBrands)
          if(allBrands && allBrands.length>0){
            setOptionBrands(allBrands)
           
          }
      }

      const handleBrandChange=(key:string,value:number)=>{
         console.log(key,value)
        setAddMaterial({...addMaterial,[key]:value})
        const allStyles=GetStyleBrandIdcategoryIdBased(value,getMaterialStyles)
        console.log("all styles--->",allStyles)
          if(allStyles && allStyles.length==0){
            setIsGetStyle(true)
            setBrandId([value])
          } 
        else if(allStyles && allStyles.length>0){
            setOptionStyles(allStyles)
          }
      }
      const handleStyleChange=(key:string,value:number)=>{
       // console.log(key,value)
        setAddMaterial({...addMaterial,[key]:value})
      }

      const handleImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const ddff=event.target.files;
    if(ddff && ddff.length>0){
      const file = ddff[0]; // Get the selected file
      if (!file) return;
  
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the image file and thumbnail URL
        
        setThumbnailUrl(reader.result as string); // Set the thumbnail URL
      };
      reader.readAsDataURL(file); 
      setImageFile(file);
     // setIsUpload(true)
      setPath("material")
      setImageName(file.name) 
      setAddMaterial({...addMaterial,photo:file.name})
     
       
    }
   
      }

     
      const handleImagePath=(data:string)=>{
       // console.log("image path",data)
        setIsUpload(false)
        const filename = data.split('/material/')[1];
        setAddMaterial({...addMaterial,photo:filename})
        setIsAdd(true)
      }
  const handleCloseModal = () => {

    setAddMaterial({
        title: '',
        photo_svg: '',
        id: 0,
        user_id: 0,
        role_id: 0,
        material_category_id: 0,
        material_brand_id: 0,
        material_brand_style_id: 0,
        description: '',
        photo: '',
        is_admin: 0,
        finish_needed: 0,
        suggested_swatches: [],
        manufacturer_request: 0,
        manufacturer_request_note: null,
        status: 0,
        created: new Date().toDateString(),
        modified: new Date().toDateString(),
        bucket_path: 'default',
        is_featured: false,
        related_materials: [],
        segment: [],
        style: [],
        color: '',
        material_type_id: 0,
        material_style: undefined,
        new_bucket: 1
      });
      setIsAdd(false)
    onClose();
  }
  const handleSaveChanges = () => {
    if(addMaterial.photo!=""){
      setIsUpload(true)
      setIsSave(true)
    }
    else{
      alert("Please  select image")
    }
   
   
  }


  const refetchSwatch=(title:string)=>{
     if(title=="Door"){
         dispatch(updateRefetchDoor(true))
     }
     if(title=="Window"){
         dispatch(updateRefetchWindow(true))
     }
      if(title=="Roof"){
          dispatch(updateRefetchRoof(true))
      }
      if(title=="Garage"){
          dispatch(updateRefetchGarage(true))
      }
      if(title=="Gutter"){
          dispatch(updateRefetchGutter(true))
      }
      if(title=="Wall"){
          dispatch(updateRefetchWall(true))
      }
      if(title=="Trim"){
          dispatch(updateRefetchTrim(true))
      }

  }

     const handleResetStyle=(data:NameModel[])=>{
        setIsGetStyle(false)
        setBrandId([])
        let allSty:NameModel[]=[]
         if(data && data.length>0){
         
          data.map((item)=>{
            allSty.push({
              id:item.id,
              title:item.title
            })
          })
         }
        setOptionStyles(allSty)
     }
  return (

    <>
    <div className={`modal fade ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }} id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden={!isModalOpen}>
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="exampleModalLabel">{type}  </h4>
          <button type="button" className="btn-close"
           onClick={handleCloseModal} 
           aria-label="Close"></button>
        </div>
        <div className="modal-body p-4">
          <div className='row g-2'>
          <div className='col-12 col-md-6'>
  <label className='form-label'>Segment</label>
  <select
    className="form-select"
    aria-label="Default select example"
    value={addMaterial.segment}
    onChange={(e) => handleSegment('segment', Array.from(e.target.selectedOptions, option => option.value))}
    multiple
  >
    <option value="">Select type</option>
    {getAllSegments && getAllSegments.map((item) => (
      <option key={item.id} value={item.name}>{item.name}</option>
    ))}
  </select>
</div>
            <div className='col-12 col-md-6'>

            <label className='form-label'>Category</label>
              
              <select className="form-select" aria-label="Default select example"
                value={addMaterial.material_category_id}
                onChange={(e) => handleCategoryChange('material_category_id', parseInt(e.target.value))}
                >
                <option value="">Select type</option>
                {getCategoryNames && getCategoryNames.map((item) => (
                    <option key={item.id} value={item.id}>{item.title}</option>
                ))}
                </select>
              
            </div>
            
          </div>
          <div className='row g-2'>
            <div className='col-12 col-md-6'>
            <label className='form-label'>Brand</label>
            <select className="form-select" aria-label="Default select example"
                value={addMaterial.material_brand_id}
                onChange={(e) => handleBrandChange('material_brand_id', parseInt(e.target.value))}
                >
                <option value="">Select type</option>
                {optionBrands && optionBrands.map((item) => (
                    <option key={item.id} value={item.id}>{item.title}</option>
                ))}
                </select>
            </div>
            <div className='col-12 col-md-6'>
            
            <label className='form-label'>Style</label>
                  <select className="form-select" aria-label="Default select example"
                value={addMaterial.material_brand_style_id}
                onChange={(e) => handleStyleChange('material_brand_style_id', parseInt(e.target.value))}
                >
                <option value="">Select type</option>
                {optionStyles && optionStyles.map((item) => (
                    <option key={item.id} value={item.id}>{item.title}</option>
                ))}
                
                </select>
                { isGetStyle &&
                <Spin size="small" />}
                
            </div>
            
          </div>
         

            <div className='row g-2'>
               
                <div className='col-12 col-md-6'>
                <label className='form-label'>Title</label>
              <InputMaterial
                title='title'
                titleValue={addMaterial?.title ?? ''}
                updateTitle={(data) => setAddMaterial({ ...addMaterial, title: data??"" })}
              />
                
                </div>
                <div className='col-12 col-md-6'>
                <label className='form-label'>Description</label>
                <InputMaterial
                title='title'
                titleValue={addMaterial?.description ?? ''}
                updateTitle={(data) => setAddMaterial({ ...addMaterial, description: data??"" })}
              />
                </div>
        </div>
            <div className='row g-2'>
                <div className='col-12 col-md-6'>
                <label className='form-label'>is_admin</label>
                <SelectedBoolean
                 value={0}
                updateMaterialStatus={(data) => setAddMaterial({ ...addMaterial, is_admin: data })}
                />
                </div>
                <div className='col-12 col-md-6'>
                <label className='form-label'>new Bucket</label>
                <SelectedBoolean
                value={1}
                updateMaterialStatus={(data) => setAddMaterial({ ...addMaterial, new_bucket: data })}
                />
                </div>
              
             </div>
             <div className='row g-2'>
                <div className='col-12 col-md-6'>
                <label className='form-label'>Status</label>
                <SelectedBoolean
                 value={0}
                updateMaterialStatus={(data) => setAddMaterial({ ...addMaterial, status: data })}
                />
                </div>
                <div className='col-12 col-md-6'>
                <label className='form-label'>Is featured</label>
                <SelectedBoolean
                 value={0}
                updateMaterialStatus={(data) => setAddMaterial({ ...addMaterial, is_featured: data==1?true:false })}
                />
                </div>
        </div>
             <div className='row g-2'>
                <div className='col-12 col-md-6'>
                <label className='form-label'>Finish needed</label>
                <SelectedBoolean
                 value={0}
                updateMaterialStatus={(data) => setAddMaterial({ ...addMaterial, finish_needed: data })}
                />
                </div>
               
        </div>
            <div className='row g-2'>
                
                <div className='col-12 col-md-6'>
                <label className='form-label'>Upload image</label>
                 <input type='file'
                        className='form-control'
                        placeholder='Enter your path'
                        // value={inputvalue} 
                        onChange={handleImage}
                        />
                </div>
                {/* <div className="spinner-border text-info" role="status">
  <span className="visually-hidden">Loading...</span> */}
             </div>
                {thumbnailUrl && (
                      <img
                        src={thumbnailUrl}
                        alt="Thumbnail"
                        style={{ maxWidth: "100%", marginTop: "10px" }}
                      />
                    )}
        </div>
         
        <div className="modal-footer">
          <button type="button" className="btn btn-link text-secondary text-decoration-none" onClick={handleCloseModal}>Close</button>
          {isSave ? (
    <button type="button" className="btn btn-primary0" disabled>
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Saving...
    </button>
  ) : (
    <button type="button" className="btn btn-primary0" onClick={handleSaveChanges}>
      Save changes
    </button>
  )}
        </div>
      </div>
    </div>
  </div>
   
  

  {
    isUpload &&
    imageFile &&
    <UploadSwatchImage
    path={path}
    imageFile={imageFile}
    imageName={imageName}
    imagePath={handleImagePath}
    />
  }


  {/* add swatch DB */}
 { isAdd &&
 addMaterial.photo!="" &&
 <AddSwatchApi
 addSwatchData={addMaterial}
 resetAdd={handleCloseModal}
 />  } 


 {/* get style id and title is not found in redux*/}

 {isGetStyle &&
     <SearchStyleBrandBased
     brandId={brandId}
     resetSearchStyle={handleResetStyle}
     />
 }
    </>
    
  )
}

export default AddEditSwatch