import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsCacheImage,
  getJobBase64,
  getSelectedSegment,
  getUserJobBase64,
  updateChacheImage,
} from "../../../slice/userJobSlice/UserJobSlice";

import {
  getSegregateSegment,
  updateAnnoatationInSegegtated,
} from "../../../slice/segegratedSlice/SegregatedSlice";
import { PointModel } from "../../../Model/masterArray/MasterArrayModel";
import {
  changeSegmentTab,
  getSwitchCanvas,
  getTabControl,
  switchToOutline,
} from "../../../slice/tabControl/TabControlSlice";
import { Transform } from "fabric/fabric-impl";
import AnnotationUpdateApi from "../masterDataAnotationApi/AnnotationUpdateApi";
import { stopLoading } from "../../../slice/loading/LoadingSlice";
import { SegmentDetails } from "../../../Model/Job/SamModel";
import { startLoading } from "../../../slice/loading/LoadingSlice";

import { getCachedBase64Image } from "../canvasUtil/ConvertImageToBAse64";
import { CanvasImage } from "../canvasUtil/SetImageAsBackground";


interface EditablePolygon extends fabric.Polygon {
  edit?: boolean;
  points?: fabric.Point[];
  controls: { [key: string]: fabric.Control };
}

interface CustomControl extends fabric.Control {
  cornerColor?: string;
  cornerStrokeColor?: string;
  cornerSize?: number;
}
// Define a type for controls with dynamic keys
interface PolygonControls {
  [key: string]: fabric.Control;
}
const EditAnnoatationCanvas = () => {
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const canvasEditRef = useRef<HTMLCanvasElement | null>(null);
  const containerRef = useRef(null);

  const [deletIconPoint, setDeleteIconPoint] = useState<PointModel | null>();
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [scaleX, setScalex] = useState<number | null>();
  const [scaleY, setScaley] = useState<number | null>();

  const [segName, setsegName] = useState<string | null>(null);
  const [points, setPoints] = useState<PointModel[]>([]);
  const pointsRef = useRef<PointModel[]>([]);
  const [segShortName, setSegShortName] = useState<string | null>(null);
  const [groupName, setGroupName] = useState<string | null>(null);
  const [childGroupName, setChildGroupName] = useState<string | null>(null);
  const getSelectedSegments = useSelector(getSelectedSegment);
  const [isStartEdit, setIsStartEdit] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [updatePoint, setUpdatePoint] = useState(true);
  const [annotation, setAnnotation] = useState<number[]>([]);
  const [cordinate, setCordinate] = useState<number[]>([]);

  const [top, setTop] = useState<number | null | undefined>();
  const [left, setLeft] = useState<number | null | undefined>();

   const isCanvasImage= useRef(true)
  const [isUpdatemasterdata, setIsUpdatemasterdata] = useState<boolean>(false);
  const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(
    null
  );
  const getTabControls = useSelector(getTabControl);
  const getSwitchCanvass = useSelector(getSwitchCanvas);

  const getJobBase64s= useSelector(getJobBase64)

  
    // Adjust canvas dimensions to fit the container
    const resizeCanvas = (fabricCanvas: fabric.Canvas) => {
      const container = containerRef.current;
      if (container) {
        const { offsetWidth, offsetHeight } = container;
  
        fabricCanvas.setWidth(offsetWidth);
        fabricCanvas.setHeight(offsetHeight);
        fabricCanvas.renderAll();


        const backgroundImage = fabricCanvas.backgroundImage as fabric.Image;
        // if (backgroundImage) {
        //   backgroundImage.scaleToWidth(offsetWidth);
        //   backgroundImage.scaleToHeight(offsetHeight);
        //   fabricCanvas.requestRenderAll();
        // }
      }
    };
    useEffect(() => {
      if (getTabControls === "editAnnotation" && 
        !canvasRef.current && 
        canvasEditRef.current) {
        
        generateCanvas();
      }
    }, [getTabControls, canvasRef]);


    const generateCanvas=()=>{
  
      // Initialize Fabric canvas
      const rightSection = document.querySelector('.canvas-right') as HTMLElement| null;
      
      if (!rightSection) {
        console.warn('Element with class .canvas-right not found');
      }
      const canvas = new fabric.Canvas(canvasEditRef.current, {
     //  width:958, // Adjust width
     //    height:642 ,
      width:rightSection==null? window.innerWidth: window.innerWidth - rightSection.offsetWidth, // Adjust width
          height: window.innerHeight,
        backgroundColor: '#282828', // Canvas background
      });
     

     
       // Set initial canvas size
       resizeCanvas(canvas);
     
       // Resize the canvas when the window resizes
      const handleResize = () => resizeCanvas(canvas);
       window.addEventListener("resize", handleResize);
     
      // Set the canvasRef to the Fabric.js canvas instance
      
      
      canvas.requestRenderAll();
      canvasRef.current = canvas;
      canvas.on("mouse:down", (event) => {
        handleMouseDown(event);
      });

      canvas.on("mouse:over", (event) => {
        handleMouseOver(event); // Add mouse move event to show the dynamic point/line
      });

      canvas.on("mouse:dblclick", (event) => {
        handleDBClick(event);
      });
     
         return () => {
           canvas.off("mouse:over", handleMouseOver);
          
           canvas.off("mouse:down", handleMouseDown);
           canvas.off("mouse:dblclick", handleDBClick);
         };
       };

    // update the image on canvas
    useEffect(()=>{
      if( 
        
         getJobBase64s &&
           getJobBase64s.imagePath &&
           canvasRef.current  &&
           isCanvasImage.current 
        ){
          isCanvasImage.current=false
  
   const base64Image= getCachedBase64Image(getJobBase64s.imagePath )
 
    if(base64Image){
     
      CanvasImage(base64Image, canvasRef, setScalex, setScaley, setTop, setLeft);
      isCanvasImage.current =false
  

    }
      } 
    },[getJobBase64s, canvasRef])

  // creat polygon
  useEffect(() => {
    if (
      !isCanvasImage.current &&
      cordinate &&
      annotation &&
      segName &&
      scaleX  &&
      scaleY 
     
    ) {
      const point: PointModel[] = [];
      let i;
      for (i = 0; i < annotation.length; i += 2) {
        const x = annotation[i] * (scaleX ?? 1);
        const y = annotation[i + 1] * (scaleY ?? 1);
        point.push({ x, y });
      }
     // console.log("scaleY",scaleY)
      //console.log("scaleX",scaleX)
      if (point && point.length > 0) {
        pointsRef.current = point;
        
        setPoints(point);
        // makePolygon(point,cordinate,segName)
      }
    }
  }, [cordinate, annotation, segName, canvasRef, scaleX, scaleY,isCanvasImage]);


  

  useEffect(() => {
    if (getSelectedSegments &&
       getSelectedSegments.length > 0 &&
       !isCanvasImage.current
      
      ) {
      const selected = getSelectedSegments[0];
      const grpseg = selected.details;
      if (
        grpseg &&
        grpseg.seg_short &&
        grpseg.label &&
        grpseg.seg_type &&
        grpseg.group &&
        grpseg.annotation &&
        grpseg.bb_annotation_int 
       
      ) {
        setSegShortName(grpseg.seg_short);
        setsegName(grpseg.label);
        setGroupName(grpseg.seg_type);
        setChildGroupName(grpseg.group);
        setAnnotation(grpseg.annotation);
        setCordinate(grpseg.bb_annotation_int);
      }
    }
  }, [getSelectedSegments]);

 

  // handleMouse over
  const handleMouseOver = (event: fabric.IEvent) => {
    // console.log("event", event.target)
     console.log("cabavas", canvasRef.current)
  };



  // return to main canavs
  const handlehoverLayer = () => {
    isCanvasImage.current=true
    dispatch(switchToOutline("segment"));
    dispatch(changeSegmentTab("segment"));
    disposeCanvas()
   
  };
  
  const disposeCanvas = () => {
    const canvasElements = document.querySelectorAll('canvas');
    canvasElements.forEach((canvas) => {
      canvas.remove();
    });
  };

  // double click
  const handleDBClick = (event: fabric.IEvent) => {
    // console.log("double click",event)
    setDeleteIconPoint(null);

    const canvasPoint = canvasRef.current?.getObjects();
    const canvas = canvasRef.current;
    const targetPoints = event.pointer;
    if (
      targetPoints &&
      targetPoints.x &&
      targetPoints.y &&
      canvasPoint &&
      canvas
    ) {
      //console.log("canvasPoint",canvasPoint)

      const points = canvasPoint[0] as EditablePolygon;
      const allpoints = points.points;

      // Reset all corner colors to their initial state
      if (points.controls) {
        Object.keys(points.controls).forEach((key: string) => {
          const control = points.controls[key] as CustomControl;
          if (control) {
            control.render = (ctx, left, top, styleOverride, fabricObject) => {
              const size = control?.cornerSize || 6; // Default corner size
              ctx.beginPath();
              ctx.arc(left, top, size / 2, 0, Math.PI * 2, false);
              ctx.fillStyle = control.cornerColor || "rgb(255 1 154)"; // Use control's corner color
              ctx.fill();
              if (control.cornerStrokeColor) {
                ctx.strokeStyle = control.cornerStrokeColor;
                ctx.lineWidth = 2;
                ctx.stroke();
              }
            };
          }
        });
      }
      const width = canvas.width; // Canvas width in pixels
      const height = canvas.height;
      if (allpoints && allpoints?.length > 0 && width && height) {
        const index = findClosestPoint(
          allpoints,
          targetPoints.x,
          targetPoints.y
        );
        const xPos = Math.min(Math.max(targetPoints.x, 0), width - 740);
        const yPos = Math.min(Math.max(targetPoints.y, 0), height - 250);
        // console.log("index", index)
        if (index != null) {
          setDeleteIconPoint({
            x: xPos,
            y: yPos,
          });
          setIsDelete(true);
          setSelectedPointIndex(index);
          // Change the color of the closest point
          changePointColor(index, points);
        }
      }
    }
  };
  
  useEffect(() => {
    if (
      points &&
      points.length > 0 &&
      segName &&
      cordinate &&
      canvasRef.current &&
      scaleX != null &&
      scaleY != null &&
      top &&
      left 
    ) {
      const allObjects = canvasRef.current?.getObjects();
      const currentObject = allObjects.find((item) => item.name == segName);
      // Delete the existing object if found
      if (currentObject) {
        canvasRef.current.remove(currentObject);
      }
      var polygon = new fabric.Polygon(points, {
        left: cordinate[0] * scaleX,
        top: cordinate[1] * scaleY,
        fill: "transparent",
        strokeWidth: 2,
        stroke: "rgb(7 239 253)",
        scaleX: 1,
        scaleY: 1,
        objectCaching: false,
        transparentCorners: false,
        cornerColor: "red",
        cornerStyle: "circle",
        selectable: false,
        name: segName,
      });
      // setPolygon(polygon)
     // canvasRef.current.viewportTransform = [1, 0, 0, 1, 0, 0];
      canvasRef.current.add(polygon);
      canvasRef.current.bringForward(polygon);
      canvasRef.current.requestRenderAll();
      setUpdatePoint(false);
      setIsStartEdit(true);
    }
  }, [points, canvasRef, cordinate, segName, scaleX, scaleY]);

  //call edit function
  useEffect(() => {
    if (isStartEdit && canvasRef.current) {
     Edit();
    }
  }, [isStartEdit, canvasRef]);

  const Edit = () => {
    if (canvasRef.current) {
      const objects = canvasRef.current.getObjects();
      console.log("objects", objects);
      if (objects.length > 0) {
        let poly = objects[0] as EditablePolygon;
        canvasRef.current.setActiveObject(poly);
        poly.edit = !poly.edit;

        if (poly.edit && poly.points) {
          const lastControl = poly.points.length - 1;
          poly.lockMovementX = true;
          poly.lockMovementY = true;
          poly.cornerStyle = "circle";
          // poly.cornerColor = 'red';
          poly.cornerColor = "rgb(255 1 154)";
          poly.cornerSize = 7;
          // poly.cornerStrokeWidth = 3; // This property might not exist in the Fabric.js version you are using
          poly.cornerStrokeColor = "rgb(7 239 253)";

          // Type the controls assignment properly
          poly.controls = poly?.points.reduce<PolygonControls>(
            (acc, point, index) => {
              // console.log("index",index)
              const controlKey = `p${index}`;
              acc[controlKey] = new fabric.Control({
                cursorStyle: "pointer",
                positionHandler: polygonPositionHandler.bind({
                  pointIndex: index,
                }),
                actionHandler: anchorWrapper(index, actionHandlers).bind({
                  pointIndex: index,
                }),
                actionName: "modifyPolygon",
              }) as CustomControl;
              return acc;
            },
            {}
          );
        } else {
          poly.cornerColor = "black";
          poly.cornerStyle = "rect";
          poly.controls = fabric.Object.prototype.controls;
        }
        poly.hasBorders = !poly.edit;
        canvasRef.current.requestRenderAll();
      }
    }
  };

  const actionHandlers = function (
    eventData: MouseEvent,
    transform: Transform,
    x: number,
    y: number
  ): boolean {
    // console.log("actionHandlers");
    const polygon = transform.target as fabric.Polygon;
    // console.log("actionHandlers tarnsfrom.target",transform);
    if (!polygon || !polygon.points) {
      console.warn("Polygon or its points are undefined.");
      return false;
    }

    const corner = transform.corner;
    //  console.log("corner",corner)
    const currentControl = parseInt(transform.corner?.substring(1), 10); //
    if (isNaN(currentControl) || !polygon.points[currentControl]) {
      console.warn("Invalid corner or control mapping:", transform.corner);
      return false;
    }
    //console.log("currentControl",currentControl)
    const mouseLocalPosition = polygon.toLocalPoint(
      new fabric.Point(x, y),
      "center",
      "center"
    );
    const polygonBaseSize = getObjectSizeWithStroke(polygon);
    //console.log("polygonBaseSize", polygonBaseSize);
    const size = polygon._getTransformedDimensions(0, 0);

    const finalPointPosition = new fabric.Point(
      (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
        polygon.pathOffset.x,
      (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y
    );

    // Prevent overlapping with other points
    // console.log("polygon oints", polygon.points);
    if (isOverlappingWithOtherPoints(finalPointPosition, polygon.points)) {
      return false;
    }

    //polygon.points[currentControl] = finalPointPosition;
    // Update the specific point in the points array
    polygon.points[currentControl] = finalPointPosition; // Only update the selected point
    polygon.setCoords();
    canvasRef.current?.renderAll();

    pointsRef.current = polygon.points;
    polygon.setCoords();
    canvasRef.current?.renderAll();
    //  console.log("finalPointPosition ",finalPointPosition)
    //  console.log("added same ",polygon.points)
    return true;
  };

  const isOverlappingWithOtherPoints = (
    newPoint: fabric.Point,
    points: fabric.Point[]
  ) => {
    //console.log("fabric.Point",newPoint)
    const overlapThreshold = 0; // Set a threshold for detecting overlap (e.g., 10px)
    for (const point of points) {
      //console.log("point",point)
      const val = newPoint.distanceFrom(point);
      // console.log("val disoa",val)
      if (newPoint.x == point.x && newPoint.y == point.y) {
        return true;
      }
      // if (newPoint.distanceFrom(point) < overlapThreshold) {
      //   return true;
      // }
    }
    return false;
  };

  const polygonPositionHandler = function (
    this: { pointIndex: number },
    dim: { x: number; y: number },
    finalMatrix: any,
    fabricObject: EditablePolygon
  ): fabric.Point {
    const point = fabricObject.points?.[this.pointIndex];
    //console.log("fabricObject --polygonPositionHandler",fabricObject)
    if (!point) return new fabric.Point(0, 0);

    // Adjust the position calculation
    const x = point.x - (fabricObject.pathOffset?.x ?? 0);
    const y = point.y - (fabricObject.pathOffset?.y ?? 0);

    return fabric.util.transformPoint(
      new fabric.Point(x, y),
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas?.viewportTransform ?? [],
        fabricObject.calcTransformMatrix()
      )
    );
  };

  const anchorWrapper = function (
    anchorIndex: number,
    fn: (
      eventData: MouseEvent,
      transform: Transform,
      x: number,
      y: number
    ) => boolean
  ) {
    return function (
      eventData: MouseEvent,
      transform: Transform,
      x: number,
      y: number
    ) {
      const fabricObject = transform.target as fabric.Polygon;
      if (
        fabricObject &&
        fabricObject.points &&
        fabricObject.points[anchorIndex]
      ) {
        const polygonBaseSize = getObjectSizeWithStroke(fabricObject);

        // Adjust to correctly calculate the relative point
        const mouseLocalPosition = fabricObject.toLocalPoint(
          new fabric.Point(x, y),
          "center",
          "center"
        );
        const size = fabricObject._getTransformedDimensions(0, 0);

        // Calculate relative position
        const finalPointPosition = new fabric.Point(
          (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
            (fabricObject.pathOffset?.x ?? 0),
          (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
            (fabricObject.pathOffset?.y ?? 0)
        );

        fabricObject.points[anchorIndex] = finalPointPosition; // Update the point
        fabricObject.setCoords();
        canvasRef.current?.renderAll();

        // Ensure the object stays in place visually
        fabricObject.left = fabricObject.left ?? 0;
        fabricObject.top = fabricObject.top ?? 0;

        return fn(eventData, transform, x, y);
      }
      // console.warn("Polygon points are undefined.");
      return false;
    };
  };

  // Modify actionHandlers to correctly handle the IEvent parameter

  // Define getObjectSizeWithStroke function with appropriate types
  function getObjectSizeWithStroke(object: fabric.Object): fabric.Point {
    const scaleX = object.scaleX || 1;
    const scaleY = object.scaleY || 1;
    const strokeWidth = object.strokeWidth || 0;

    const stroke = new fabric.Point(
      object.strokeUniform ? strokeWidth / scaleX : strokeWidth,
      object.strokeUniform ? strokeWidth / scaleY : strokeWidth
    );

    return new fabric.Point(
      (object.width || 0) * scaleX + stroke.x,
      (object.height || 0) * scaleY + stroke.y
    );
  }

  //
  // const isPointInsidePolygon = (point: fabric.Point, polygon: fabric.Polygon) => {
  //   const { left, top, scaleX, scaleY } = polygon;
  //   const transformedPoints = polygon.points.map(p => ({
  //     x: left + p.x * scaleX,
  //     y: top + p.y * scaleY,
  //   }));

  //   let inside = false;
  //   for (let i = 0, j = transformedPoints.length - 1; i < transformedPoints.length; j = i++) {
  //     const xi = transformedPoints[i].x, yi = transformedPoints[i].y;
  //     const xj = transformedPoints[j].x, yj = transformedPoints[j].y;

  //     const intersect = ((yi > point.y) !== (yj > point.y)) &&
  //       (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
  //     if (intersect) inside = !inside;
  //   }

  //   return inside;
  // };

  const handleMouseDown = (event: fabric.IEvent) => {
    if (canvasRef.current) {
      const pointer = canvasRef.current.getPointer(event.e);
      const polygon = canvasRef.current.getActiveObject();

      if (polygon?.type === "polygon") {
       // console.log("Clicked inside polygon:", polygon);
      } else {
        const objects = canvasRef.current.getObjects();
        const polygon = objects.find(
          (obj) => obj.type === "polygon"
        ) as fabric.Polygon;
      }
      canvasRef.current.renderAll();
    }
  };

 
  const handleSaveAnnotation = () => {
    const polygonCanvas = canvasRef.current;
    if (polygonCanvas && groupName && childGroupName && segShortName) {
     
      const polyObject = polygonCanvas.getObjects();
      const points = polyObject[0] as EditablePolygon;
      const allpoints = points.points;
      let tempPoints: number[] = [];
      if (allpoints && allpoints.length > 0) {
        allpoints.map((item) => {
          tempPoints.push(item.x);
          tempPoints.push(item.y);
        });
      }
      if (tempPoints && tempPoints.length > 0) {
        convertToBoundingBox(
          tempPoints,
          groupName,
          childGroupName,
          segShortName
        );
      }
    }
  };

  const convertToBoundingBox = (
    flattenedPoints: number[],
    groupName: string,
    childGroupName: string,
    shortName: string
  ) => {
    if (scaleX && scaleY) {
      let newFlattenedPoints = [];
      const points = flattenedPoints;

      for (let i = 0; i < points.length; i += 2) {
        const x = points[i] / (scaleX ?? 1);
        const y = points[i + 1] / (scaleY ?? 1);
        newFlattenedPoints.push(
          parseFloat(x.toFixed(2)),
          parseFloat(y.toFixed(2))
        );
      }

      if (
        newFlattenedPoints.length > 0 &&
        childGroupName &&
        shortName &&
        groupName &&
        segName
      ) {
        //console.log("newFlattenedPoints",newFlattenedPoints)
        setSegShortName(shortName);
        setsegName(segName);
        setGroupName(groupName);
        setChildGroupName(childGroupName);
        setIsUpdatemasterdata(true);
        setAnnotation(newFlattenedPoints);
      }
    }
  };

  const handleResetAnotation = (data: SegmentDetails) => {
    setSegShortName(null);
    setsegName(null);
    setGroupName(null);
    setAnnotation([]);
    setCordinate([]);
    setChildGroupName(null);
    setIsUpdatemasterdata(false);
    dispatch(stopLoading());

    const masterValue = {
      segName: segName,
      childgroupName: childGroupName,
      seg_short: segShortName,
      groupName: groupName,
      masterData: data,
    };
    // console.log("masterValue",masterValue)
    dispatch(startLoading());
    // update in segretaed  Array
    dispatch(updateAnnoatationInSegegtated(masterValue));

    dispatch(switchToOutline("segment"));
    dispatch(changeSegmentTab("segment"));
  };

  const handleFailResetAnnotation = () => {
    setSegShortName(null);
    setsegName(null);
    setGroupName(null);
    setAnnotation([]);
    setCordinate([]);
    setChildGroupName(null);
   
    setIsUpdatemasterdata(false);
    dispatch(stopLoading());

    dispatch(switchToOutline("segment"));
    dispatch(changeSegmentTab("segment"));
  };

  const findClosestPoint = (
    polygon: PointModel[],
    x: number,
    y: number
  ): number | null => {
    if (!polygon) return null;
    const distances = polygon.map((point) =>
      Math.hypot(point.x - x, point.y - y)
    );
    const minDistance = Math.min(...distances);
    //console.log("minDistance",minDistance)
    const index = distances.indexOf(minDistance);
    return minDistance < 10 ? index : null; // Threshold of 10 pixels for click proximity
  };

  const changePointColor = (index: number, polygon: EditablePolygon) => {
    if (canvasRef.current && polygon.points) {
      const canvas = canvasRef.current;

      const controlKey = `p${index}`;
      const control = polygon.controls[controlKey] as CustomControl;

      if (control) {
        // Add custom rendering logic
        control.render = (ctx, left, top, styleOverride, fabricObject) => {
          const size = control?.cornerSize || 8; // Default corner size
          ctx.beginPath();
          ctx.arc(left, top, size / 2, 0, Math.PI * 2, false);
          ctx.fillStyle = control.cornerColor || "red"; // Use control's corner color
          ctx.fill();
          if (control.cornerStrokeColor) {
            ctx.strokeStyle = control.cornerStrokeColor;
            ctx.lineWidth = 2;
            ctx.stroke();
          }
        };

        // Refresh the canvas
        canvas.requestRenderAll();
      }
    }
  };

  const handleDelete = () => {
    if (canvasRef.current && selectedPointIndex !== null) {
      const canvas = canvasRef.current;
      const polygon = canvas.getObjects("polygon")[0] as fabric.Polygon;
      if (polygon.points) {
        polygon.points.splice(selectedPointIndex, 1);
        polygon.dirty = true;
        canvas.renderAll();
      }
      setIsDelete(false); // Hide delete dialog
    }
  };

  const handleCancel = () => {
    setIsDelete(false);
    setDeleteIconPoint(null);
  };

  return (
    <>
      <div
        className="editor-canvas position-relative"
        ref={containerRef}
        style={{
          height: "100vh",
          overflow: "hidden",
          transformOrigin: "top left",
        }}>
        <div className="re-anno-section" onClick={handlehoverLayer}>
          <button className="d-flex align-items-center btn rounded-pill custom-back-button border-0">
            <span className="fs-4">
              <i className="bi bi-arrow-left-short pe-1"></i>
            </span>
            {segShortName}-{childGroupName}
          </button>
        </div>

        
       <div className="position-absolute z-3 save-anno-btn">
        <button className="btn border btn-secondary shadow rounded-pill fs-6 border-primary" onClick={handleSaveAnnotation}>save </button>
        </div>
        <canvas ref={canvasEditRef} className="dzi-van"></canvas>
        

        {isDelete && (
          <div
            style={{
              position: "absolute",
              left: `${deletIconPoint?.x}px`,
              top: `${deletIconPoint?.y}px`,
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}>
            <button
              onClick={handleDelete}
              style={{
                padding: "5px 10px",
                marginRight: "5px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}>
              Delete
            </button>
            <button
              onClick={handleCancel}
              style={{
                padding: "5px 10px",
                backgroundColor: "#ccc",
                color: "#333",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}>
              Cancel
            </button>
          </div>
        )}
      </div>

      {isUpdatemasterdata && annotation && childGroupName && (
        <AnnotationUpdateApi
          segmentationInt={annotation}
          segName={childGroupName}
          resetAnnotation={handleResetAnotation}
          resetFailAnnotation={handleFailResetAnnotation}
        />
      )}
    </>
  );
};

export default EditAnnoatationCanvas;


 // ad image on canavs
  // useEffect(() => {
  //   if (getUserJobBase64s && canvasRef.current) {
  //     const image = getUserJobBase64s;
  //     const encodedImage = "data:image/png;base64," + image;
  //     //const encodedImage =  image;
  //     const decodedImage = atob(encodedImage.split(",")[1]);
  //     const uint8Array = new Uint8Array(decodedImage.length);
  //     for (let i = 0; i < decodedImage.length; i++) {
  //       uint8Array[i] = decodedImage.charCodeAt(i);
  //     }
  //     const blob = new Blob([uint8Array], { type: "image/png" });
  //     const imageUrl = URL.createObjectURL(blob);

  //     fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
  //       const canvas = canvasRef.current;
  //       if (
  //         canvas &&
  //         canvas.width &&
  //         canvas.height &&
  //         img.width &&
  //         img.height
  //       ) {
  //         const canvasWidth = canvas.width;
  //         const canvasHeight = canvas.height;

  //         img.scaleToWidth(canvasWidth);
  //         img.scaleToHeight(canvasHeight);

  //         canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas), {
  //           scaleX: canvasWidth / img.width,
  //           scaleY: canvasHeight / img.height,
  //           originX: "left",
  //           originY: "top",
  //         });
  //         setScalex(canvasWidth / img.width);
  //         setScaley(canvasHeight / img.height);
  //         console.log("canvasWidth / img.width--x", canvasWidth / img.width);
  //         console.log(
  //           "canvasHeight / img.height--y",
  //           canvasHeight / img.height
  //         );

  //         canvas.requestRenderAll();
  //       }
  //     });
  //   }
  // }, [getUserJobBase64s]);
