import React, { useState } from "react";
import { SegDetectModel } from "../../../../Model/segDetectModel/SegDetect";
import UpLoadZipFile from "../api/UpLoadZipFile";
import AddModelDB from "../api/AddModelDB";


type Props={
    isOpen:boolean
    onClose:()=>void
}
const AddSegDetectModel = ({isOpen, onClose}:Props) => {

    const [addModel, setAddModel] = React.useState<SegDetectModel>()
    const [uploadfile, setUploadFile] = React.useState<File>()
    const [isUploaded, setIsUploaded] = React.useState<boolean>(false)
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
     const [isAddDb, setIsAddDb] = React.useState<boolean>(false)

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddModel({...addModel, name: e.target.value})
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddModel({...addModel, title: e.target.value})
    }
     

    const handleZipFile = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const file = e.target.files?.[0];  
    setUploadFile(file)
    setIsUploaded(true)
    }

    const handleResetZipFile = (path:string) => {
        setAddModel({...addModel, filePath:path})
    setUploadFile(undefined)
    setIsUploaded(false)
    }


    const validateInputs = () => {
        const newErrors: { [key: string]: string } = {};
        if (!addModel?.name) newErrors.name = "Name is required";
        if (!addModel?.title) newErrors.title = "Title is required";
        if (!addModel?.description) newErrors.description = "Description is required";
        if (!addModel?.filePath) newErrors.filePath = "Upload zip file is required";
        if (addModel?.isActive === undefined) newErrors.isActive = "Status is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length;
      };

    const handleAddModel = () => {
      const dataerror=  validateInputs()
      
    if (dataerror === 0) {
     
      setIsAddDb(true)
    } 
  };

  const handleResetAddModel=()=>{
    setAddModel(undefined)
    setIsAddDb(false)
    setUploadFile(undefined)
    setIsUploaded(false)
    onClose()
  }
  return (
    <>
      <div
         className={`modal fade ${isOpen ? 'show' : ''}`}
         style={{ display: isOpen ? 'block' : 'none' }}
                aria-labelledby="exampleModalLabel"
                aria-hidden={!isOpen}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Add New Model
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="row g-2">
                <div className="col-12 col-md-6">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your model name"
                    onChange={(e) => {handleNameChange(e)}}
                  />
                   { errors&&
                   errors?.name &&
                    <div className="text-danger">{errors?.name}</div>}
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your model title"
                    onChange={(e) => {handleTitleChange(e)}}
                  />
                   {errors.title && <div className="text-danger">{errors.title}</div>}
                
                </div>
                <div className="col-12 col-md-12">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    onChange={(e) => {setAddModel({...addModel, description: e.target.value})}}
                  ></textarea>
                   {errors.description && <div className="text-danger">{errors.description}</div>}
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Upload Zip</label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Enter your title"
                    onChange={(e) => {handleZipFile(e)}}
                  />
                   {errors.filePath && <div className="text-danger">{errors.filePath}</div>}
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Status</label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      onChange={(e) => {setAddModel({...addModel, isActive: e.target.checked})}}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Active / Inactive
                    </label>
                  </div>
                  {errors.isActive && <div className="text-danger">{errors.isActive}</div>}
                </div>
              </div>
             
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link text-secondary text-decoration-none"
                onClick={onClose}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary"
               onClick={handleAddModel}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

     { isUploaded &&
     addModel?.name &&
     <UpLoadZipFile
        name={addModel?.name}
        uploadfile={uploadfile}
        resetUploadFile={handleResetZipFile}
      />}

      { isAddDb &&
      addModel &&
       <AddModelDB
         addModel={addModel}
            resetAddModel={handleResetAddModel}
       />
      }
    </>
  );
};

export default AddSegDetectModel;
