import React, { useEffect, useState } from 'react'
import Header from '../../header/Header'
import Sidebar from '../../dasboardpages/components/Sidebar'
import PageContentHeader from '../../dasboardpages/components/pageContent/PageContentHeader'
import SearchAdd from '../../dasboardpages/components/pageContent/SearchAdd'
import SettingPathTable from './SettingPathTable'
import GetAllSettingPath from './api/GetAllSettingPath'
import { useSelector } from 'react-redux'
import { getSettingPath } from '../../../slice/settingPathSlice/SettingPathSlice'
import SettingPathForm from './settingPathForm/SettingPathForm'

const SetttingPathHome = () => {
      const [isActive, setIsActive] = useState(false); // State to track whether the div is active
      const [firstHeader, setFirstHeader] = useState<string>(''); 
      const [secondHeader, setSecondHeader] =useState<string>('Setting Path') 
     
      const [breadCum1, setbreadCum1] =useState<string>('Home') 
      const [breadCum2, setbreadCum2] =useState<string>('Setting Path') 
      const [breadCum3, setbreadCum3] =useState<string>('') 
       const[isSearch,setIsSearch]=useState<boolean>(false)
       const getSettingPaths= useSelector(getSettingPath)
      const toggleActiveClass = () => {
        setIsActive(!isActive); // Toggle the isActive state
      };

       // reset Setting Path search
       useEffect(() => {
             if(getSettingPaths.length==0){
               setIsSearch(true)
             }  
       }, 
       [ ]);

      const handleResetSettingPath = () => {
        setIsSearch(false)
      }
  return (
    
    <>

<Header/>
<div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
        {/* content header */}
        
         <PageContentHeader
         firstHeader={firstHeader}
         secondHeader={secondHeader}
         breadCum1={breadCum1}
         breadCum2={breadCum2}
         breadCum3={breadCum3}
         
          />
        
        

      {/* <SettingPathForm/> */}

          {/* table */}
         
        <SettingPathTable/>



         
    </div>

    
    {/* get all Setting Path */}
   { isSearch  &&
   <GetAllSettingPath
    resetSettingPath={handleResetSettingPath}
    />}
  </>
    
    
  )
}

export default SetttingPathHome