import { useDisclosure } from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { NameModel } from '../../../../../../Model/material/MaterialModel'
import { GetBrandscategoryIdBased } from '../../../../../../api/material/swatch/SwatchApi'
import { addSearchBrandName } from '../../../../../../slice/materialSlice/MaterialBrandSlice'
import { addMessage } from '../../../../../../slice/messageToast/ToastSlice'

type Props={
    resetSearch:()=>void
    catId:NameModel
}
const SearchBrand = ({resetSearch,catId}:Props) => {
   const dispatch=useDispatch()
   const isApi= useRef(true)


   useEffect(()=>{
    if(catId && isApi.current){
        isApi.current=false
       
        getBrandBasedOnCat(catId.id??0)
    }
   }, [catId]) // added 'catId' as a dependency

   const getBrandBasedOnCat=async(catId:number)=>{
    try{
            // const response= await GetBrandscategoryIdBased(catId)
            // if(response  && response.status==200){
              
            //     dispatch(addSearchBrandName(response.data))
            //     isApi.current=true
            //     resetSearch()
            // }
    }catch(err){
        if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }

        
        isApi.current=true
        resetSearch()
    }
   }


  return (
    <></>
  )
}

export default SearchBrand