import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux'
import "../../../page/canvas/canvasview.scss"
import {  getJobBase64, getUserJobBase64 } from '../../../slice/userJobSlice/UserJobSlice';
import { changeSegmentTab, getSwitchCanvas, getTabControl, switchToOutline } from '../../../slice/tabControl/TabControlSlice';
import { JobSegmentModel, Segment } from '../../../Model/Job/JobModel';
import { PointModel } from '../../../Model/masterArray/MasterArrayModel';
import { getSegregateSegment } from '../../../slice/segegratedSlice/SegregatedSlice';
import { getAllSegment } from '../../../slice/segment/SegmentSlice';
import { UpdateToolActive } from '../../../slice/toolActive/ToolActiveSlice';
import { getCachedBase64Image } from '../canvasUtil/ConvertImageToBAse64';
import { CanvasImage } from '../canvasUtil/SetImageAsBackground';

interface PointAnnotation {
  x: number,
  y: number
}

interface CustomGroupOptions extends fabric.IGroupOptions {
  groupName?: string;
  subGroupName?: string;
  childGroupName?: string // Example for an object
}

const OutLineCanvas = () => {

  const canvasRef = useRef<fabric.Canvas | null>(null);
  const canvasElementRefs = useRef<HTMLCanvasElement | null>(null);
  const rightSectionRef = useRef<HTMLDivElement | null>(null);

  const getUserJobBase64s = useSelector(getUserJobBase64);
  const [scaleX, setScalex] = useState<number | null | undefined>();
  const [scaleY, setScaley] = useState<number | null | undefined>();
  const getSegregateSegments = useSelector(getSegregateSegment);
  const [annotationPoint, setAnnotationPoint] = useState<PointAnnotation[]>([]);
  const isCanvas = useRef(true);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const getTabControls = useSelector(getTabControl);
  const getSwitchCanvass = useSelector(getSwitchCanvas);
  const getJobBase64s= useSelector(getJobBase64)
 const getAllSegments = useSelector(getAllSegment)
 const [top, setTop] = useState<number | null | undefined>();
 const [left, setLeft] = useState<number | null | undefined>();

  // Clean up canvas on unmount
  useEffect(() => {
    return () => {
      if (canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;
      }
    };
  }, []);

  // Handle canvas disposal on switch
  useEffect(() => {
    if (getSwitchCanvass === "segment" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('segment'));
    } 
    else if (getSwitchCanvass === "dimension" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('dimension'));
    }
     else if (getSwitchCanvass === "compare" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('compare'));
    }
    else if (getSwitchCanvass === "genAiImage" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('genAiImage'));
    }
    else if (getSwitchCanvass === "comments" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('comments'));
    }
  }, [getSwitchCanvass, dispatch]);

  // Initialize the canvas when the tab is set to "outline"
  useEffect(() => {
    if (getTabControls === "outline" && 
      !canvasRef.current && 
      canvasElementRefs.current) {
      isCanvas.current = true;
      generateCanvas();
    }
  }, [getTabControls, canvasRef]);

  const generateCanvas = () => {
    const rightSection = rightSectionRef.current;
    const canvas = new fabric.Canvas(canvasElementRefs.current, {
      // width: rightSection == null ? window.innerWidth : window.innerWidth - rightSection.offsetWidth,
      // height: window.innerHeight,
      backgroundColor: '#f0f0f0'
    
    });
   
    
     // Set initial canvas size
    resizeCanvas(canvas);

    canvas.requestRenderAll();
    canvasRef.current = canvas;
  }
  // Adjust canvas dimensions to fit the container
  const resizeCanvas = (fabricCanvas: fabric.Canvas) => {
    const container = containerRef.current;
    if (container) {
      const { offsetWidth, offsetHeight } = container;

      fabricCanvas.setWidth(offsetWidth);
      fabricCanvas.setHeight(offsetHeight);
      fabricCanvas.renderAll();



         // Adjust image dimensions
 const backgroundImage = fabricCanvas.backgroundImage as fabric.Image;
 if (backgroundImage) {
   backgroundImage.scaleToWidth(offsetWidth);
   backgroundImage.scaleToHeight(offsetHeight);
   fabricCanvas.requestRenderAll();
 }

    }
  };
  // Load the background image based on the job base64
  useEffect(() => {
    if (getJobBase64s &&
      getJobBase64s.imagePath && 
      canvasRef.current) {
        //isCanvasImage.current=false
        // const imagePaths= path+getJobBase64s.projectId+"/"+getJobBase64s.jobId+"/"+getJobBase64s.imagePath
        const base64Image= getCachedBase64Image(getJobBase64s.imagePath )
       // console.log("base64Image",base64Image)
         if(base64Image){
           
           CanvasImage(base64Image, canvasRef, setScalex, setScaley, setTop, setLeft);
         }
    }
  }, [getJobBase64s]);

  // Handle Segregate Segments and draw polygons
  useEffect(() => {
    if (canvasRef.current && 
      getSegregateSegments && 
      getSegregateSegments.length > 0 &&
      getAllSegments &&
      getAllSegments.length > 0) {
      getSegregateSegments.forEach((segmentModel: JobSegmentModel) => {
        Object.keys(segmentModel).forEach((key) => {
          const jobDetail = segmentModel[key] as Segment;
          const annotation = jobDetail?.details?.annotation;
          const cordinate = jobDetail?.details?.bb_annotation_int;
          const segName = key;
          const groupName = jobDetail?.details?.seg_type ?? "";
          const subGroupName = jobDetail?.details?.group ?? "";
          const childName = jobDetail?.details?.seg_short ?? "";
          const segColor= getAllSegments.find(item=>item.name===groupName)
          const color=segColor?.color_code
          if (annotation && cordinate && segName && color) {
            collectPoints(annotation, segName, cordinate, groupName, subGroupName, childName,color);
          }
        });
      });
    }
  }, [canvasRef.current, getSegregateSegments, scaleX, scaleY,getAllSegments]);

  const collectPoints = (annotation: number[], segName: string, coordinates: number[], groupName: string, subGroupName: string, childName: string,color:string) => {
    if (annotation && scaleX && scaleY) {
      const points: PointModel[] = [];
      let i;
      for (i = 0; i < annotation.length; i += 2) {
        const x = annotation[i] * scaleX;
        const y = annotation[i + 1] * scaleY;
        points.push({ x, y });
      }
      if (points && points.length > 0) {
        makePolygon(points, coordinates, segName, groupName, subGroupName, childName,color);
      }
    }
  }

  const makePolygon = (points: PointModel[], coordinates: number[], polyName: string, groupName: string, subGroupName: string, childName: string,color:string ) => {
    if (points && points.length > 0 && coordinates && polyName && scaleX && scaleY && canvasRef.current) {
      const text = new fabric.Text(polyName, {
        left: coordinates[0] * scaleX,
        top: coordinates[1] * scaleY,
        fontFamily: 'Arial',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        fontSize: 18,
        fill: color,
        selectable: true,
        visible: true,
      });

      const polygon = new fabric.Polygon(points, {
        left: Math.round(coordinates[0] * scaleX),  // Rounding to prevent subpixel rendering
        top: Math.round(coordinates[1] * scaleY),
        fill: "transparent",
        originX: "left",
        originY: "top",
        hasRotatingPoint: false,
        hasBorders: false,
        hasControls: false,
        stroke: color,
       
        strokeWidth: 2,
        opacity: 10,
        lockMovementX: true,
        lockMovementY: true,
      });

      const options: CustomGroupOptions = {
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
        hasRotatingPoint: false,
        hasBorders: false,
        hasControls: false,
        groupName: groupName,
        subGroupName: subGroupName,
        childGroupName: childName,
        subTargetCheck: true,
        name: polyName
      };

      const group = new fabric.Group([polygon, text], options);
      canvasRef.current.add(group);
      canvasRef.current.requestRenderAll();
    }
  }

       // return to main canavs
       const handlehoverLayer = () => {
        dispatch(UpdateToolActive(0))
        dispatch(switchToOutline("segment"))
        dispatch(changeSegmentTab("segment"));
       
      };
  return (
    <>
         <div className="editor-canvas position-relative"
       ref={containerRef}
       style={{
         height: "100vh",
         overflow: "hidden",
         transformOrigin: "top left",
       }}
      >
      <div className="re-anno-section"
       onClick={handlehoverLayer}
       >
          <button className=" d-flex  align-items-center btn  rounded-pill custom-back-button border-0 ">
            <span className="fs-4">
              <i className="bi bi-arrow-left-short pe-1"></i>
            </span>
            Outline
          </button>
        </div>
      <canvas ref={canvasElementRefs} />
      </div>
    </>
  )
}

export default OutLineCanvas;