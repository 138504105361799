import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaterialStyleModel, StyleDocModel, SwatchStyleModel } from "../../Model/material/MaterialStyleModel";
import { SearchPageModel } from "../../Model/material/MaterialBrandModel";
import { MaterialModel, NameModel } from "../../Model/material/MaterialModel";

interface MaterialStyleStates {
    allStylesName:NameModel[]
    materialStyle: MaterialStyleModel[];
    searchStyleName:MaterialStyleModel[],
    isDownloadStyle:boolean,
    searchStyle:SearchPageModel,
    styleResponse:string,
    swatchStyle: SwatchStyleModel[];
    editStyle: SwatchStyleModel;
    styleDoc:StyleDocModel
}

const initialState: MaterialStyleStates = {
    allStylesName:[],
    materialStyle: [],
    searchStyleName:[],
    isDownloadStyle:false,
    searchStyle:{},
    styleResponse:"",
    swatchStyle: [],
    editStyle: {} as SwatchStyleModel,
    styleDoc:{}
};

const MaterialStyleSlice = createSlice({
    name: "materialStyle",
    initialState,
    reducers: {
        resetStyle:(state)=>{
            return initialState
        },
        addMaterialStyle: (state, action) => {
            state.materialStyle = action.payload;
        },
        addSwatchStyle: (state, action) => {
            state.swatchStyle = action.payload;
        },
        addEditStyle: (state, action) => {
            state.editStyle = action.payload;
        },

        updateEditBrand: (state, action) => {
            const { data } = action.payload;
            const brandIndex = state.materialStyle.findIndex((item) => item.id === data.id);
            if (brandIndex !== -1) {
                state.materialStyle[brandIndex].description = data.description;
                state.materialStyle[brandIndex].material_brand_id = data.material_category_id;
                state.materialStyle[brandIndex].title = data.title;
                state.materialStyle[brandIndex].slug = data.slug;
                state.materialStyle[brandIndex].sort_order = data.sort_order;
                state.materialStyle[brandIndex].status = data.status;
            }
        },
        addNewBrand: (state, action) => {
            const { data } = action.payload;
            state.materialStyle.push(data);
        },
        resetEditBrand: (state) => {
            state.editStyle = {} as SwatchStyleModel;
        },
        deleteStyle: (state, action) => {
            const { id } = action.payload;
            const brandIndex = state.swatchStyle.findIndex((item) => item.id === id);
            if (brandIndex !== -1) {
                state.swatchStyle.splice(brandIndex, 1);
            }
        },
        addIsDownloadStyle:(state,action)=>{
            state.isDownloadStyle= action.payload
        },
        addStyleResponse:(state,action)=>{
        state.styleResponse=action.payload
        },
        addSearchStyle:(state,action)=>{
            const { page,pageSize}=action.payload
            state.searchStyle.page=page
            state.searchStyle.pageSize=pageSize
        } ,
        addStyleDoc:(state,action)=>{
            const {totalDocuments,totalPages,currentPage,pageSize}=action.payload;
            state.styleDoc.currentPage=currentPage
            state.styleDoc.pageSize=pageSize
            state.styleDoc.totalDocuments=totalDocuments
            state.styleDoc.totalPages=totalPages
           },
           addStylesNames:(state,action)=>{
            state.allStylesName=action.payload
           },
           addSearchStylesNames:(state,action)=>{
            state.searchStyleName=action.payload
           },
            resetSearchStyleName:(state)=>{
                state.searchStyleName=[]
           }
          
    },
});

export const {
    addMaterialStyle,
    addSwatchStyle,
    addEditStyle,
    updateEditBrand,
    addNewBrand,
    resetEditBrand,
    deleteStyle,
    resetStyle,
    addIsDownloadStyle,
     addStyleResponse,
     addSearchStyle,
     addStyleDoc,
     addStylesNames,
     addSearchStylesNames,
     resetSearchStyleName

} = MaterialStyleSlice.actions;

export const getMaterialStyle = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.materialStyle;
export const getSwatchStyle = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.swatchStyle;

export const getEditSwatchStyle = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.editStyle;
export const getIsDownloadStyle = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.isDownloadStyle;
export const getStyleDoc = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.styleDoc
export const getSearchStyle = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.searchStyle;

export const getStyleName = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.allStylesName;

export const getSearchStyleName = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.searchStyleName;

export default MaterialStyleSlice.reducer;
