import React, { useState } from 'react'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import SwatchBrandTable from '../../../brandPage/brand/SwatchBrandTable'
import LeftSection from '../../../leftSection/LeftSection'

import Sidebar from '../../../dasboardpages/components/Sidebar'
import PageContent from '../../../dasboardpages/components/PageContent'
import PageContentHeader from '../../../dasboardpages/components/pageContent/PageContentHeader'

import SearchAdd from '../../../dasboardpages/components/pageContent/SearchAdd'
import Header from '../../../header/Header'

const AdminBrandTable = () => {
  const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('Brand') 
 
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Library') 
  const [breadCum3, setbreadCum3] =useState<string>('Brands') 
   

  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };
  return (
<>
<Header/>
<div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
        {/* content header */}
        
        
         <PageContentHeader
         firstHeader={firstHeader}
         secondHeader={secondHeader}
         breadCum1={breadCum1}
         breadCum2={breadCum2}
         breadCum3={breadCum3}
         
          />
        

        <SearchAdd/>

          {/* table */}
          <SwatchBrandTable/>




         
    </div>

<GetAllMaterialsHome />
</>
  


//     <>
//     <div className='row'>


// <LeftSection/>
// <div className="col-9 brand-right-section">

//   <SwatchBrandTable />

// </div>




 
// </div>
// </>
  
  )
}

export default AdminBrandTable