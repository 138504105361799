import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { fabric } from 'fabric';
// import './ToolArea.css';

const ToolArea = () => {
    const [htmlInput, setHtmlInput] = useState('');
    const [renderedHtml, setRenderedHtml] = useState('');
    const [fabricCode, setFabricCode] = useState(`
// Sample Fabric.js code
const rect = new fabric.Rect({
    left: 100,
    top: 100,
    fill: 'red',
    width: 50,
    height: 50
});
canvas.add(rect);
`);


    const handleRender = () => {
        setRenderedHtml(htmlInput);
    };

    const handleRunFabricCode = () => {
        const canvas = new fabric.Canvas('canvas');
        eval(fabricCode);
    };

    
    return (
        <Container fluid className="tool-area">
            <Row>
                <Col md={6} className="input-area">
                    <h4 className="mt-4">Fabric.js Code Editor</h4>
                    <Form.Group controlId="fabricCode">
                        <Form.Label>Enter Fabric.js code:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            value={fabricCode}
                            onChange={(e) => setFabricCode(e.target.value)}
                            placeholder="Enter Fabric.js code..."
                        />
                    </Form.Group>
                    <button className="mt-3" onClick={handleRunFabricCode}>
                        Run Fabric.js Code
                    </button>
                </Col>
                <Col md={6} className="output-area">
                    <h4 className="mt-4">Fabric Tool Canvas</h4>
                    <canvas id="canvas" width={800} height={600} style={{ border: '1px solid #ccc', width: '100%' }} />
                </Col>
            </Row>
        </Container>
    )
}

export default ToolArea