import React, { useEffect, useState } from 'react'

type Props={
    title:string,
    titleValue:string,
    updateTitle:(data:string)=>void
}
const InputMaterial = ({title,titleValue,updateTitle}:Props) => {
     const [inputvalue, setInputValue] = useState<string>('')
    
     const [inputTitle, setInputTitle] = useState<string>('')
    
     useEffect(() => {
        if(title){
            setInputTitle(title)
        }
        if(titleValue){
            setInputValue(titleValue)
        }   
     }, [title,titleValue])
     

     const handleInput=(value:string)=>{
        setInputValue(value)
        updateTitle(value)
     }
  return (
    <div>
        
        <input type='text'
         className='form-control'
          placeholder='Enter your path'
           value={inputvalue} 
           onChange={(e) => handleInput(e.target.value)} 
           />
    </div>
  )
}

export default InputMaterial