import React, { useEffect, useState } from 'react';
import { GenAiAttributesModel, OptionJDetails } from '../../../../Model/genAiAttributes/GenAiAttributes';
import { useSelector } from 'react-redux';
import { getGenAiAttributes } from '../../../../slice/genAi/GenAiAttributesSlice';
import { getGenAiFeatureData } from '../../../../slice/genAi/featureSlice/GenAiFeatureSlice';
import DataTable from 'react-data-table-component';

const GenAiFeatureTable = () => {
  const [allGenAttrFeature, setAllGenAttrFeature] = useState<OptionJDetails | null>(null);
  const getGenAiFeatureDatas = useSelector(getGenAiFeatureData);

  useEffect(() => {
    if (getGenAiFeatureDatas) {
      setAllGenAttrFeature(getGenAiFeatureDatas);
    } else {
      setAllGenAttrFeature(null);
    }
  }, [getGenAiFeatureDatas]);

  // const columns = [
  //   {
  //     name: "Title",
  //     selector: (row: OptionJDetails) => row.title ?? "",
  //     sortable: true,
  //   },
  //   {
  //     name: "Status",
  //     selector: (row: OptionJDetails) => (row.status ? "Active" : "Inactive"),
  //     sortable: true,
  //   },
  //   {
  //     name: "Featured",
  //     selector: (row: OptionJDetails) => (row.is_featured ? "Yes" : "No"),
  //     sortable: true,
  //   },
  //   {
  //     name: "Type",
  //     selector: (row: OptionJDetails) => row.type ?? "",
  //     sortable: true,
  //   },
  //   {
  //     name: "Priority",
  //     selector: (row: OptionJDetails) => row.priority ?? "",
  //     sortable: true,
  //   },
  //   {
  //     name: "Options",
  //     cell: (row: OptionJDetails) => {
  //       return (
  //         <div>
  //           {row.option?.map((opt, index) => (
  //             <div key={index} style={{ marginBottom: "8px" }}>
  //               <div>
  //                 <strong>Title:</strong> {opt.title ?? "N/A"}
  //               </div>
  //               <div>
  //                 <strong>Image:</strong>{" "}
  //                 {opt.image ? (
  //                   <img
  //                     src={opt.image}
  //                     alt={opt.title || "Option Image"}
  //                     style={{ width: "50px", height: "50px", objectFit: "cover" }}
  //                   />
  //                 ) : (
  //                   "No Image"
  //                 )}
  //               </div>
  //               <div>
  //                 <strong>Slug:</strong> {opt.slug ?? "N/A"}
  //               </div>
  //               <div>
  //                 <strong>Status:</strong> {opt.isActive ? "Active" : "Inactive"}
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     name: "Action",
  //     cell: (row: OptionJDetails) => {
  //       return (
  //         <div className="d-flex action">
  //           <div className="action-icon-table">
  //             <i className="bi bi-pencil-square mx-0"></i>
  //           </div>
  //           <div className="action-icon-table">
  //             <i className="bi bi-copy mx-0"></i>
  //           </div>
  //           <div className="action-icon-table">
  //             <i className="bi bi-box-arrow-up-right mx-0"></i>
  //           </div>
  //           <div className="action-icon-table trash-icon-media">
  //             <i className="bi bi-trash3 mx-0"></i>
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  return (
    <>
      <div className='card mx-3'>
        <div className='card-header bg-white p-2 d-flex justify-content-between align-items-center'>
          <input
            type="search"
            className="form-control w-auto"
            placeholder="Search"
          />
          <button type='button' className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Create new entry</button>
        </div>

        <div className='card-body p-0'>
          {/* {allGenAttrFeature && (
            <DataTable 
              columns={columns} 
             
              pagination
             
            />
          )} */}
        </div>
      </div>
    </>
  );
}

export default GenAiFeatureTable;

