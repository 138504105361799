import { isEditable } from "@testing-library/user-event/dist/utils";
import { Color } from "antd/es/color-picker";
import { fabric } from "fabric";
import React from "react";
import { Col } from "react-bootstrap";

interface PointModel {
  x: number;
  y: number;
}


const COLORS = {
  startPoint: "#FF0000", // Red for the start point
  edgePoint: "#FF1493", // Deep pink for edge points
  edgeCircle: "#FF1493", // Pink circle for edge points
  snapCircle: "#000000", // Black for the snap circle
  suggestCircle: "#FF6347", // Tomato for suggestive points
  polygonFill: "rgba(0, 255, 0, 0.3)",
  polygonStroke: "#008000", // Green for the polygon's border
  verticePoint: "#FFFF00", // Yellow for VerticePoints
  verticeCircle: "#FFFF00", // Yellow circle for VerticePoints
  closedVerticePoint: "#0000FF", // Blue for closed vertices
  closedVerticeCircle: "#0000FF", // Blue circle for closed vertices
};

export const showPolygon = (
  canvasRef: React.RefObject<fabric.Canvas>,
  targetnames: string,
   pointX: number,
    pointY: number,
    isEditPolyname:React.RefObject<string>
  ) => {
  const allObjects = canvasRef.current?.getObjects();
  if (allObjects && allObjects.length > 0) {
    const currentObject = allObjects.find((item) => item.name === targetnames && item.name!=isEditPolyname.current);
    if (currentObject && currentObject instanceof fabric.Group) {
      const allShowObjects = currentObject.getObjects();
      allShowObjects.forEach((item) => {
        if (item instanceof fabric.Polygon ) {
          const allPoints = item.get("points");
          if(!item.visible)
          item.set({ visible: true });

        const mousepOint={x:pointX,y:pointY}
      if(allPoints && 
        allPoints.length>0 &&mousepOint){  
       const distanceValue=   findNearestLine(allPoints,mousepOint)
            if(distanceValue){
              const {nearestLineStart,nearestLineEnd,minDistance}=distanceValue
              if(nearestLineStart && nearestLineEnd ){
               
                highlightLine(nearestLineStart,nearestLineEnd, canvasRef,allPoints,mousepOint)
                //suggestProximityPoints(mousepOint,allPoints,canvasRef)
              }
            }
        }   
      }  

      });
      canvasRef.current?.requestRenderAll();
    }else{
      const existingImaginaryPoints = canvasRef.current?.getObjects('circle').filter(circle => circle.name === 'imaginaryPoint');
      existingImaginaryPoints?.forEach(circle => canvasRef.current?.remove(circle));
    
    }
  }
}


export const highlightLine = (
  start: PointModel,
  end: PointModel,
  canvasRef: React.RefObject<fabric.Canvas>,
  polygonPoints: PointModel[],
  pointer: PointModel,
  
) => {
  // Remove any existing highlighted lines
  const existingLines = canvasRef.current?.getObjects("line").filter(line => line.name === "highlightLine");
  existingLines?.forEach(line => canvasRef.current?.remove(line));

  // Remove any existing imaginary points
  const existingImaginaryPoints = canvasRef.current?.getObjects("circle").filter(circle => circle.name === "imaginaryPoint");
  existingImaginaryPoints?.forEach(circle => canvasRef.current?.remove(circle));

  // Add the new highlighted line
  const line = new fabric.Line([start.x, start.y, end.x, end.y], {
    stroke: "red",
    strokeWidth: 2,
    selectable: false,
    name: "highlightLine",
    visible: true,
  });

  canvasRef.current?.add(line);
  canvasRef.current?.renderAll();
  suggestProximityPoints(pointer,polygonPoints,canvasRef)
};

export const suggestProximityPoints = (
  pointer: PointModel,
  polygonPoints: PointModel[],
  canvasRef: React.RefObject<fabric.Canvas>
 
) => {
  polygonPoints.forEach((point, index) => {
    const nextPoint = polygonPoints[(index + 1) % polygonPoints.length];
    const nearestPoint = findNearestPointOnLine(point, nextPoint, pointer);
    const distance = calculateDistance(pointer, nearestPoint);
    const existingLines = canvasRef.current?.getObjects("line").filter(line => line.name === "highlightLine");

    const MIN_SNAP_DISTANCE = 15;
    if (distance <= MIN_SNAP_DISTANCE && existingLines) {
      addImaginaryPoint(nearestPoint,canvasRef);
    }
    // else{
    //   const existingImaginaryPoints = canvasRef.current?.getObjects('circle').filter(circle => circle.name === 'imaginaryPoint');
    //   existingImaginaryPoints?.forEach(circle => canvasRef.current?.remove(circle));
    // }
  });
};


export const addImaginaryPoint=(
  point:PointModel,
    canvasRef: React.RefObject<fabric.Canvas>)=> {
  const existingImaginaryPoints = canvasRef.current?.getObjects('circle').filter(circle => circle.name === 'imaginaryPoint');
  existingImaginaryPoints?.forEach(circle => canvasRef.current?.remove(circle));

  if(canvasRef.current){
    const circle = new fabric.Circle({
      left: point.x - 3,
      top: point.y - 3,
      radius: 3,
      fill:"yellow",
      selectable: false,
      name: "imaginaryPoint",
    });

   

    canvasRef.current.add(circle);
    //canvasRef.current.add(snapCircle);
    canvasRef.current.renderAll();
  }

 // imaginaryPoints.push(circle, snapCircle);
}

export const calculateDistanceToLineSegment = (
  point: PointModel,
  lineStart: PointModel,
  lineEnd: PointModel
): number => {
  const { x: x1, y: y1 } = lineStart;
  const { x: x2, y: y2 } = lineEnd;
  const { x: px, y: py } = point;

  const A = px - x1;
  const B = py - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  const param = len_sq !== 0 ? dot / len_sq : -1;

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = px - xx;
  const dy = py - yy;
  return Math.sqrt(dx * dx + dy * dy);
};

export const findNearestLine = (
  points: PointModel[],
  mousePoint: PointModel,

) => {
  let nearestLineStart: PointModel | null = null;
  let nearestLineEnd: PointModel | null = null;
  let minDistance = 100;

  points.forEach((point, index) => {
    const nextPoint = points[(index + 1) % points.length];
    const distance = calculateDistanceToLineSegment(mousePoint, point, nextPoint);
    if (distance < minDistance) {
      minDistance = distance;
      nearestLineStart = { x: point.x, y: point.y };
      nearestLineEnd = { x: nextPoint.x, y: nextPoint.y };
    }
  });

 ;
  return { nearestLineStart, nearestLineEnd, minDistance };
};


const findNearestPointOnLine = (
  start: PointModel,
  end: PointModel,
  point: PointModel
): PointModel => {
  const { x: x1, y: y1 } = start;
  const { x: x2, y: y2 } = end;
  const { x: px, y: py } = point;

  const A = px - x1;
  const B = py - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  const param = len_sq !== 0 ? dot / len_sq : -1;

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  return { x: xx, y: yy };
};

const calculateDistance = (point1: PointModel, point2: PointModel): number => {
  const dx = point1.x - point2.x;
  const dy = point1.y - point2.y;
  return Math.sqrt(dx * dx + dy * dy);
};


export const hideAllPolygonsExcept = (
  targetnames: string,
  canvasRef: React.RefObject<fabric.Canvas>,
  isEditPolyname:React.RefObject<string>
) => {
  const allObjects = canvasRef.current?.getObjects();
  
  if (allObjects && allObjects.length > 0) {
    allObjects.forEach((item) => {
      if (item instanceof fabric.Group &&
         (item.name !== targetnames &&item.name !== isEditPolyname.current)) {
        const allShowObjects = item.getObjects();
        allShowObjects.forEach((subItem) => {
          if (subItem.visible) {
            subItem.set({ visible: false });
          }
        });
      }
    });
    canvasRef.current?.requestRenderAll();
  }
};

export const hideReAnnotatedPolygon = (
  canvasRef: React.RefObject<fabric.Canvas>,
  targetnames: string,
)=>{
const allObjects = canvasRef.current?.getObjects();
if (allObjects && allObjects.length > 0) {
  const currentObject = allObjects.find((item) => item.name === targetnames);
  if (currentObject && currentObject instanceof fabric.Group) {
    const allShowObjects = currentObject.getObjects();
    allShowObjects.forEach((item) => {
      if (item instanceof fabric.Polygon) {
        item.set({ visible: false });
      }
    });
    canvasRef.current?.requestRenderAll();
  }
}
}


export const hideAllPolygon = (
  canvasRef: React.RefObject<fabric.Canvas>,
  segName:string,
)=>{
   const allObjects = canvasRef.current?.getObjects();
    if (allObjects && allObjects.length > 0) {
      allObjects.forEach((item) => {
        if (item instanceof fabric.Group && item.name != segName ) {
          const allShowObjects = item.getObjects();
          allShowObjects.forEach((subItem) => {
            if (subItem.visible) {
              subItem.set({ visible: false });
            }
          });
        }
      });
      canvasRef.current?.requestRenderAll();
    }
}