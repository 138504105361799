import React, { useEffect, useRef } from 'react'
import { UploadSwatchPresignedS3Bucket } from '../../../../../../api/material/swatch/SwatchApi'


type Props = {
    path:string
    imageFile:File,
    imageName:string,
    imagePath:(data:string)=>void
}

const UploadSwatchImage = ({path,imageFile,imageName,imagePath}:Props) => {
    const isApi= useRef(true)


    useEffect(()=>{
        if(isApi.current &&
             path &&
              imageFile &&
               imageName){
            isApi.current=false
            upLoadPreSignedImage(imageFile, path, imageName);
               }
    },[path,imageFile,imageName,imagePath])
    const upLoadPreSignedImage = async (file: File, path: string, imageName:string) => {
        try{
            
            const response = await UploadSwatchPresignedS3Bucket(file, path, imageName);
               console.log("upload presigned swactch Image-->",response)
            if (response.status === 200 && response.data) {
                const { presignedUrl } = response.data;
                const uploadImage = await uploadImageUsingPresignedUrl(presignedUrl,file );
                imagePath(uploadImage)
                console.log("upload swactch Image-->",uploadImage)
            }
        }catch(err){
              
            imagePath("")
         console.log("Error in upload swactch Image-->",err)
        }
    }


    const uploadImageUsingPresignedUrl=async(presignedUrl: string, file:File)=> {
        try {
          const response = await fetch(presignedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': file.type, // Adjust based on your image type
            },
            body: file,
           
          });
         
         // console.log("upload pres signed image Api", response)
          if (!response.ok) {
            throw new Error(`Failed to upload image. Status: ${response.status}`);
          }
         // console.log("Image uploaded successfully");
          return presignedUrl.split('?')[0]; // Return the URL of the uploaded image without query parameters
        } catch (err) {
          //console.error("Error uploading image", err);
          throw err;
        }
      }
  return (
    <>
    </>
  )
}

export default UploadSwatchImage