import React, { useEffect, useState } from 'react'
import GetProjectDetails from './callApi/GetProjectDetails'
import { getUserProjectImageUrl } from '../../slice/user/UserProject'
import { useDispatch, useSelector } from 'react-redux'
import { getUserNewProject } from '../../slice/user/UserProject'
import PromptToAiImage from '../ImageView/exteriorsAi/promptToAiImage/PromptToAIImage'
import { PromptModel } from '../../Model/genAiAttributes/GenAiAttributes'
import { getGeneratedPrompt, getPromptValues } from '../../slice/genAi/GenAiAttributesSlice'
import { startWebSocket } from '../../slice/webSocketSlice/WebSocketSlice'


const s3BucketPath = process.env.REACT_APP_S3Bucket;

const ProjectDetailsHome = () => {
   const dispatch= useDispatch()
 
  const [imagePath, setImagePath]= useState<string|null>(null)
    const [isGenAiImage, setIsGenAiImage]= useState<boolean>(false)
     const[promptData, setPromptData]= useState<string|null>(null)

     const getUserNewProjects= useSelector(getUserNewProject)
      const getUserProjectImageUrls= useSelector(getUserProjectImageUrl)
      const getGeneratedPrompts=useSelector(getGeneratedPrompt)
   
    

       
      useEffect(()=>{
        if(getUserProjectImageUrls &&
          getUserNewProjects &&
           getUserNewProjects.id
        ){
          const image = `${s3BucketPath}/projects/${getUserNewProjects.id}/${getUserProjectImageUrls}`;
          setImagePath(image)
        }
      },[getUserProjectImageUrls,getUserNewProjects])

      useEffect(()=>{
        if(getGeneratedPrompts){
        //  setPromptData(getGeneratedPrompts)
        }
      },[getGeneratedPrompts])

  const handleResetProjectDetails = () => {
    console.log('reset project details')
    dispatch(startWebSocket());
   // setIsGenAiImage(true)
  }


  const handleResetGenAiImages = () => {  
    setIsGenAiImage(false)
    setImagePath(null)
    setPromptData(null)
  }
  return (
    <>
    {/* get project description and ,gen Ai prompt  */}
    {/* <GetProjectDetails
     resetProjectDetails={handleResetProjectDetails}
    /> */}
    
     {/* generate gen Ai image  */}
   {isGenAiImage &&
   imagePath &&
   promptData &&
    <PromptToAiImage
    imagePath={imagePath}
    promptData={promptData}
    resetImagePath={handleResetGenAiImages}
    />}
    </>
  )
}

export default ProjectDetailsHome