import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ImageView from '../../page/ImageView/ImageView';
import NewHtmlCanavasPage from '../../page/newHtmlcanvas/NewHtmlCanavasPage';
import ThreeFiberCanvasPage from '../../page/threeFiberCanvas/ThreeFiberCanvasPage';
import EditCanavsPage from '../../page/ImageView/editCanvas/EditCanavsPage';

import NewSegmentPage from '../../page/ImageView/newSegment/NewSegmentPage';


const CanvasRoutes = () => {
  return (
    <Routes>
      <Route path="/view/:id" element={<ImageView />} />

      <Route path="/edit/:id" element={<EditCanavsPage />} />

   
      <Route path="/new-segment/:id" element={< NewSegmentPage/>} />
      <Route path="/view/2D" element={<NewHtmlCanavasPage />} />
      <Route path="/view/3D" element={<ThreeFiberCanvasPage />} />
      
    </Routes>
  );
};

export default CanvasRoutes;
