import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { SearchSwatchesCat } from '../../../../../../api/material/swatch/SwatchApi'
import { NameModel } from '../../../../../../Model/material/MaterialModel'
import { addcategoryBasedSwatch, addCategorySearchSwatch } from '../../../../../../slice/materialSlice/SearchMaterial'
import axios from 'axios'
import { addMessage } from '../../../../../../slice/messageToast/ToastSlice'


type Props={
   segType:string,
    catData:NameModel,
    resetSerachCatSwatch:()=>void
}
const SearchSwatchCat = ({segType,catData,resetSerachCatSwatch}:Props) => {

    const dispatch= useDispatch()
    const isApi= useRef(true)
      useEffect(()=>{
        if(isApi.current &&
           segType &&
            catData &&
          catData.id &&
          catData.title){
           
          dispatch(addCategorySearchSwatch(
            {type:segType, 
              catData:catData}))

            getCatSwatches(catData, segType)
            isApi.current=false
        }
      },[segType,catData])

    const getCatSwatches=async(catData:NameModel,segType:string)=>{
      if( catData && catData.id){
        const catId= catData.id
        try{
           const response= await SearchSwatchesCat(catId)
           if(response.status==200){
                const data= response.data
               
                if(data && data.data){
                     dispatch(addcategoryBasedSwatch(
                        {type:segType, 
                          catId:catData.id,
                          swatches:data.data}))
                      isApi.current=true
                      
                      resetSerachCatSwatch()
                }
           }
        }catch(err){
            if(axios.isAxiosError(err)){
                
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
            }   
            isApi.current=true
            resetSerachCatSwatch()
            //console.log(err)
        }
      }
       
    }

    
  return (
    <>
        
    </>
  )
}

export default SearchSwatchCat