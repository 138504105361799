
import axios from "axios";
import { MaterialCategoryModel } from "../../../Model/material/MaterialCategoryModel";
import { MaterialModel, SearchSwatchModel } from "../../../Model/material/MaterialModel";
import apiCilent from "../../interceptor/ApiCilent"
// const serverApi = import.meta.env.SERVER_URL
const serverApi =process.env.REACT_APP_SERVER_URL

const databaserServer="http://localhost:5000"

// getAll maetrial catorgyies
export function GetAllMaterial(page:number,pageSize:number) { 
    const data={
        page:page,
        pageSize:pageSize
    }
    return axios.post(`${serverApi}/material-library/swatches`, data)
        .then(response => {
            return response
        }).catch(error => {
            console.log("error material",error)
            throw error;
        });


}

// update 
export function UpdateSwatch(data:MaterialModel){
    return axios.post(`${serverApi}/material-library/swatch/update`,data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// add
export function AddSwatch(data:MaterialModel){
    return axios.post(`${serverApi}/material-library/swatch/add`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// delete
export function DeleteSwatch(id:number){
    const data={
        id:id
    }
    return axios.post(`${serverApi}/material-library/swatch/delete`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
export function SearchSwatches(data:SearchSwatchModel){
    
    return apiCilent.post(`/material-library/search-swatch`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
export function SearchSwatchesCat(catId:number){
      const data={
            catId:catId
      }
    return apiCilent.post(`/search-swatch-category`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// get swatch based on category and brands
export function GetBrandscategoryIdBased(catId:number, brands:number[]){
      const data={
            catId:catId,
            brands:brands
      }
    return apiCilent.post(`/search-swatch-category-brands`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// get Swatch based on category , brands, swatches
export function GetStyleBrandBasedCatBasedSwatches(catId:number, brands:number[],styles:number[]){
      const data={
            catId:catId,
            brands:brands,
            styles:styles
      }
    return apiCilent.post(`/search-swatch-category-brands-styles`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}


export function UploadSwatchPresignedS3Bucket(data:File, path:string,imageName:string){
    const dataValue={
        fileType: data.type,
         path:path,
         imageName:imageName
    }
    return apiCilent.post(`/material-swatch/upload-swatch-image`,dataValue)
    .then(response => {
        // console.log("upload image", response)
        return response
    }).catch(error => {
        throw error;
    });
}


export function AddNewSwatchData(data:MaterialModel){
    const dataValue={
       materialData:data
    }
    return apiCilent.post(`/material-swatch/add-swatch`,dataValue)
    .then(response => {
        // console.log("upload image", response)
        return response
    }).catch(error => {
        throw error;
    });
}