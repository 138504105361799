import { CatBrandStyleSwatchModel } from "../../../../../Model/material/CatBrandStyleSwatchModel";

export const GetSortedSwatchImage = (
  allSwatch: CatBrandStyleSwatchModel[],
): CatBrandStyleSwatchModel[] => {
    if (allSwatch.length > 0) {
        const newBucketSwatches = allSwatch.filter(swatch => swatch.new_bucket === 1);
        const oldBucketSwatches = allSwatch.filter(swatch => swatch.new_bucket === 0);
        return [...newBucketSwatches, ...oldBucketSwatches];
      }
        
  return [];
};