import { MasterArrayModel } from "../../../../../../Model/masterArray/MasterArrayModel";

export const GetCetegoryColor = (
    allSegments: MasterArrayModel[],
    setColor:React.Dispatch<React.SetStateAction<string | null | undefined>>,
) => {
     if(allSegments && allSegments.length>0){
         allSegments.map(item=>{
            if(item.isActive){
                setColor(item.color_code)
            }

         })
     }
}