import React from 'react'
import AdminSwatchesHome from '../../../module/admin/AdminMaterial/swatches/AdminSwatchesHome'

const AdminSwatchesTablePage = () => {
  return (
    <div className="mt-5 pt-4">
        <AdminSwatchesHome/>
    </div>
  )
}

export default AdminSwatchesTablePage
