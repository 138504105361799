import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DeleteJobById } from '../../../../api/jobs/JobApi'
import axios from 'axios'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'


type Props={
 jobId:number,
 resetDeleteJob:()=>void
}
const DeleteJob = ({jobId,resetDeleteJob}:Props) => {

    const isApi= React.useRef(true)
    const dispatch= useDispatch()
    useEffect(()=>{
        if(jobId && isApi.current){
            isApi.current=false
            deleteJob(jobId)

        }
    },[jobId])


    const deleteJob = async (jobId: number) => {
        try{
            const response = await DeleteJobById(jobId)
            console.log("delete job", response)
            if(response && response.status===200){
                dispatch(addMessage({
                    isShow:true,
                    mess: "Job deleted successfully",
                    toastType:"success"
                   }))
                isApi.current=true
                resetDeleteJob()
            }        
        }catch(err){
            if(axios.isAxiosError(err)){
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
                isApi.current=true
                resetDeleteJob()
            
        }
    }
}
  return (
    <>
    </>
  )
}

export default DeleteJob