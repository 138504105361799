import React, { useEffect, useMemo, useRef, useState } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import { getIsCacheImage, getJobBase64, getUserJobBase64, updateChacheImage } from "../../../../slice/userJobSlice/UserJobSlice";
import {
  addSvgOption,
  getSvgImage,
  getSvgOption,
  getSvgPhotImage,
  resetSvgImage,
  resetSvgModel,
  resetSvgPhotImage,
} from "../../../../slice/svdImageSlice/SvgImageSlice";

import {
  getHoverGroup,
  getHoverSeg,
  getNoHoverGroup,
} from "../../../../slice/canvas/masterArray/MasterArraySlice";
import { JobSegmentModel, Segment } from "../../../../Model/Job/JobModel";
import { CanvasControlModel, PointModel } from "../../../../Model/masterArray/MasterArrayModel";
import "../../../../page/canvas/canvasview.scss";

import OptionModal from "../../LeftSections/selectSwatch/OptionModal";

import {
  getLoading,
  startLoading,
  stopLoading,
} from "../../../../slice/loading/LoadingSlice";
import {
  addCanavasControl,
  getFillPolygon,
} from "../../../../slice/canvas/masterArray/CanvasControlSlice";
import {
  changeSegmentTab,
  getSwitchCanvas,
  getTabControl,
  switchToOutline,
} from "../../../../slice/tabControl/TabControlSlice";

import {
  getDownloadStatus,
  stopDownlaod,
} from "../../../../slice/canvas/updatevalue/UpdateValueSlice";
import { addMessage, getToast } from "../../../../slice/messageToast/ToastSlice";
import {
  getDeleteSegmentHover,
  getHoverLayerSeg,
  getSegregateSegment,
  getStartSegeration,
  resetDeleteSegmenthoverLayer,
  stopSegregation,
} from "../../../../slice/segegratedSlice/SegregatedSlice";
import RightShimmer from "../rightshimmer/RightShimmer";

import {
  addComment,
  getCavasGroupComments,
  getComment,
} from "../../../../slice/projectCommet/ProjectCommentSlice";

import { getAllSegment } from "../../../../slice/segment/SegmentSlice";
import {
  getStartUploadScreenShort,
  updateStartUploadScrenShot,
} from "../../../../slice/imageDepthSlice/ImageDepthSlice";
import CalculateDepthHome from "../calculateDepth/CalculateDepthHome";
import {  showAnnotationOnCanvas } from "../../../canavas/canvasUtil/ShowAnnotation";
import { collectPoints } from "../../../canavas/canvasUtil/CreatePolygon";
import { CanvasImage } from "../../../canavas/canvasUtil/SetImageAsBackground";
import { checkMouseInsidePolygon, GetGroupObject } from "../../../canavas/canvasUtil/CheckMousePointInsidePolygon";
import {  hideAnnotationOnCanvas } from "../../../canavas/canvasUtil/HideAnnotation";
import { getSettingPath } from "../../../../slice/settingPathSlice/SettingPathSlice";
import { getImagePath } from "../../LeftSections/util/imagePathUtil/ImagePathUtil";
import { ConvertImageToBase64, getCachedBase64Image } from "../../../canavas/canvasUtil/ConvertImageToBAse64";
import { getZoomCanvas, updateZoomValue } from "../../../../slice/canvas/canvasOption/CanvasOptionSlice";
import { ZoomCanvas } from "../../../canavas/canvasUtil/ZoomCanvas";

interface PointAnnotation {
  x: number;
  y: number;
}

interface CustomGroupOptions extends fabric.IGroupOptions {
  groupName?: string;
  subGroupName?: string;
  childGroupName?: string; // Example for an object
}
const FabricCanvas: React.FC = () => {
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const canvasElementRef = useRef<HTMLCanvasElement | null>(null);
    const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  
  const getUserJobBase64s = useSelector(getUserJobBase64);
  const getSvgImages = useSelector(getSvgImage);
  const getSvgPhotImages = useSelector(getSvgPhotImage);
  const containerRef = useRef(null);
  const [scaleX, setScalex] = useState<number | null | undefined>();
  const [scaleY, setScaley] = useState<number | null | undefined>();
  const [top, setTop] = useState<number | null | undefined>();
  const [left, setLeft] = useState<number | null | undefined>();
  const getSegregateSegments = useSelector(getSegregateSegment);

  const isCanvas = useRef(true);
  const isCanvasImage = useRef(true);
  const isSegregated = useRef(true);
  const dispatch = useDispatch();
  const getStartSegerations = useSelector(getStartSegeration);
  const getSwitchCanvass = useSelector(getSwitchCanvas);
  const getTabControls = useSelector(getTabControl);
  const getDownloadStatuss = useSelector(getDownloadStatus);
  const getAllSegments = useSelector(getAllSegment);
  const getComments = useSelector(getComment);
  const getStartUploadScreenShorts = useSelector(getStartUploadScreenShort);
  const getHoverLayerSegs = useSelector(getHoverLayerSeg);
  const getFillPolygons = useSelector(getFillPolygon); // file the polygon
  const isFillPolygon = useRef<boolean>(false);
  const getIsCacheImages= useSelector(getIsCacheImage)
  const getZoomCanvass= useSelector(getZoomCanvas);

  //  const getAllreadyExistSwatchs= useSelector(getAllreadyExistSwatch)
  const isUpdateCanvas = useRef(true);
  // fill the color inside the polygon as per setting
  useEffect(() => {
    if (getFillPolygons) {
      isFillPolygon.current = false;
    } else {
      isFillPolygon.current = true;
    }
  }, [getFillPolygons]);

  // open and closing of canvas
  useEffect(() => {
    if (getSwitchCanvass === "outline" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;

      dispatch(changeSegmentTab("outline"));
      isCanvas.current = true;
      isCanvasImage.current = true;
      isSegregated.current = true;
    } else if (getSwitchCanvass === "compare" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;

      dispatch(changeSegmentTab("compare"));
      isCanvas.current = true;
      isCanvasImage.current = true;
      isSegregated.current = true;
    } else if (getSwitchCanvass === "dimension" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;

      dispatch(changeSegmentTab("dimension"));
      isCanvas.current = true;
      isCanvasImage.current = true;
      isSegregated.current = true;
    } else if (getSwitchCanvass === "genAiImage" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;

      dispatch(changeSegmentTab("genAiImage"));
      isCanvas.current = true;
      isCanvasImage.current = true;
      isSegregated.current = true;
    } else if (getSwitchCanvass === "comments" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;

      dispatch(changeSegmentTab("comments"));
      isCanvas.current = true;
      isCanvasImage.current = true;
      isSegregated.current = true;
    }
  }, [getSwitchCanvass, canvasRef]);

    

  // after switch canvas
  useEffect(() => {
    if (
      getSwitchCanvass == "segment" &&
      isCanvas.current
    ) {
      isCanvas.current = false;

      generateCanvas();
    }
  }, [getTabControls, canvasRef, getSwitchCanvass, canvasElementRef]);

  useEffect(() => {
    if (canvasElementRef.current && isCanvas.current) {
      isCanvas.current = false;
      generateCanvas();
    }
  }, []);

  
    // Adjust canvas dimensions to fit the container
    const resizeCanvas = (fabricCanvas: fabric.Canvas) => {
      const container = containerRef.current;
      if (container) {
        const { offsetWidth, offsetHeight } = container;
  
        fabricCanvas.setWidth(offsetWidth);
        fabricCanvas.setHeight(offsetHeight);
        fabricCanvas.renderAll();


         // Adjust image dimensions
      const backgroundImage = fabricCanvas.backgroundImage as fabric.Image;
      if (backgroundImage) {
        backgroundImage.scaleToWidth(offsetWidth);
        backgroundImage.scaleToHeight(offsetHeight);
        fabricCanvas.requestRenderAll();
      }
      }
    };
  

  const generateCanvas=()=>{
  
 // Initialize Fabric canvas
 const rightSection = document.querySelector('.canvas-right') as HTMLElement| null;
 
 if (!rightSection) {
   console.warn('Element with class .canvas-right not found');
 }
 const canvas = new fabric.Canvas(canvasElementRef.current, {
//  width:958, // Adjust width
//    height:642 ,
 width:rightSection==null? window.innerWidth: window.innerWidth - rightSection.offsetWidth, // Adjust width
     height: window.innerHeight,
   backgroundColor: '#282828', // Canvas background
 });


 const imgGroup = new fabric.Group([], { 
   selectable: false, 
   name: 'imageGroup',
   visible: true ,
   hasBorders: false,
     hasControls: false,
 });

  // Set initial canvas size
  resizeCanvas(canvas);

  // Resize the canvas when the window resizes
 const handleResize = () => resizeCanvas(canvas);
  window.addEventListener("resize", handleResize);

 // Set the canvasRef to the Fabric.js canvas instance
 
 canvas.add(imgGroup);
 canvas.requestRenderAll();
 canvasRef.current = canvas;
 canvas.on('mouse:over', (event) => {
   
   handleMouseOver(event);
 });
 canvas.on('mouse:out',
   (event) => {
     handleMouseOut(event);
  });

    canvas.on("mouse:down", (event) => {
      handleMouseDown(event);
    });

    canvas.on("mouse:move", (event) => {
      handleMouseMove(event);
    }
  );

  canvas.on("mouse:wheel", (event) => {
    handleMouseWheel(event);
  });
    canvas.on("mouse:up", handleMouseUp);

    return () => {
      canvas.off("mouse:over", handleMouseOver);
      canvas.off("mouse:out", handleMouseOut);
      canvas.off("mouse:up", handleMouseUp);
      canvas.off("mouse:down", handleMouseDown);
      canvas.off("mouse:move", handleMouseMove);
    };
  };

  const handleMouseDown = (event: fabric.IEvent) => {
    handleGroupClick(event);
  };

  // set background image onn canavas


   const getJobBase64s= useSelector(getJobBase64)
   const[path,setPath]= useState<string>("")
   const getSettingPaths= useSelector(getSettingPath)

   // set the image path (s3 bucket)
   useEffect(()=>{
    if(getSettingPaths && 
      getSettingPaths.length>0){
      getImagePath(getSettingPaths,"Project Image",setPath)
    }
 },[getSettingPaths])


 
  // update the image on canvas
    useEffect(()=>{
      if(getIsCacheImages && 
        path &&
         getJobBase64s &&
         getJobBase64s.jobId &&
          getJobBase64s.projectId &&
           getJobBase64s.imagePath &&
           canvasRef.current 
            
        ){
         
   const base64Image= getCachedBase64Image(getJobBase64s.imagePath )


    if(base64Image){
      //dispatch(updateChacheImage(false))
      CanvasImage(base64Image, canvasRef, setScalex, setScaley, setTop, setLeft);
       
    }
      }
    },[getIsCacheImages, path,getJobBase64s, canvasRef])

  // on mouse hover
  const handleMouseOver = (event: fabric.IEvent) => {
   
  };

  const handleMouseMove = (event: fabric.IEvent) => {
    const pointer = canvasRef.current?.getPointer(event.e);
   
    if(pointer){
      const isColor= true;
      const fabricPoint = new fabric.Point(pointer.x, pointer.y);
    checkMouseInsidePolygon(fabricPoint,canvasRef,isColor,hideAnnotation,showAnnotation,isFillPolygon)
   }
  };

    const isColor= useRef(true)
const showAnnotation=(segName:string,color:string)=>{
   // console.log("showAnnotation---",segName)
  showAnnotationOnCanvas(canvasRef,segName,color,isFillPolygon,isColor)
}


   const hideAnnotation=(segName:string)=>{
    hideAnnotationOnCanvas(segName, canvasRef, isFillPolygon);
   }

  const handleMouseOut = (event: fabric.IEvent) => {
    //  console.log("mouse out", event.target?.name)
    if (event.target) {
      // hideAnnotation(event)
      hidehoverEffectFromLeftSection();
    }
  };




  const handleMouseUp = () => {};

  // taking data from segregated data to make polygon
  useEffect(() => {
      console.log("left-->",left)
    if (
      canvasRef.current &&
      getSegregateSegments &&
      getSegregateSegments.length > 0 &&
      scaleX &&
      scaleY  &&
      top 
      
    ) {
       // console.log("start polygon---->",)
      dispatch(stopSegregation());
    let lefts=0
      getSegregateSegments.forEach((segmentModel: JobSegmentModel) => {
        // Iterate through each key in the JobSegmentModel object
        Object.keys(segmentModel).forEach((key) => {
          const jobDetail = segmentModel[key] as Segment; // Access Segment by key
          const annotation = jobDetail?.details?.annotation;
          const cordinate = jobDetail?.details?.bb_annotation_int;
          const segName = key;
          const textName = jobDetail?.details?.seg_short ?? "";
          const groupName = jobDetail?.details?.seg_type ?? "";
          const subGroupName = jobDetail?.details?.group ?? "";
          const childName = jobDetail?.details?.seg_short ?? "";
          const segColor = getAllSegments.find(
            (item) => item.name === groupName
          );
          const color = segColor?.color_code;

          if (annotation && cordinate && segName && color) {
            collectPoints(
              annotation,
              segName,
              cordinate,
              groupName,
              subGroupName,
              childName,
              textName,
              color,
              scaleX,
              scaleY,
              canvasRef,
              isFillPolygon?.current,
              top,
              lefts,
        
            );
          }
        });
      });
    }
  }, [canvasRef.current, getSegregateSegments, scaleX, scaleY,top, left]);

  // update canvas
  useEffect(() => {
    if (
      canvasRef.current &&
      getSegregateSegments &&
      getSegregateSegments.length > 0 &&
      getTabControls === "segment" &&
      getSwitchCanvass == "segment"
    ) {
      
      getSegregateSegments.forEach((segmentModel: JobSegmentModel) => {
        // Iterate through each key in the JobSegmentModel object
        Object.keys(segmentModel).forEach((key) => {
          const jobDetail = segmentModel[key] as Segment;
          if (jobDetail && jobDetail.swatch && jobDetail.details) {
            const imagePath = jobDetail?.swatch.swatch_seg_image;
            const labelName = jobDetail.details?.label;
            const isbase64 = false;
            const active = jobDetail?.swatch.isActive;
            if (imagePath && labelName && isUpdateCanvas.current) {
              console.log("isUpdateCanvas==>", isUpdateCanvas.current);
              isUpdateCanvas.current = false;
              openCvImageOnCanvas(
                imagePath,
                labelName,
                isbase64,
                active ? true : false
              );
            }
          }
        });
      });
    }
  }, [canvasRef.current, getSegregateSegments, getTabControls]);

  // handle click on canavs

  const handleGroupClick = (data: fabric.IEvent) => {
    const pointer = canvasRef.current?.getPointer(data.e);
  
    if(pointer){
      const fabricPoint = new fabric.Point(pointer.x, pointer.y);
      let isComment = false;
      GetGroupObject(fabricPoint, canvasRef,isComment,canvasControl);
    }
   
  };
  
  const canvasControl=(data: CanvasControlModel)=>{
    //console.log("data-->",data)
    dispatch(addCanavasControl(data));
    dispatch(changeSegmentTab("segment"));
    dispatch(switchToOutline("segment"));
   
  }
  // hide hover effect
  const hidehoverEffectFromLeftSection = () => {
    const allObjects = canvasRef.current?.getObjects();
    if (allObjects && allObjects.length > 0) {
      allObjects.forEach((item: fabric.Object) => {
        const groupObject = item as fabric.Group;
        if (groupObject.type === 'group' && groupObject.name != "imageGroup") {
          const selectgroupObject = groupObject.getObjects();
          if (selectgroupObject) {
            selectgroupObject.forEach((obj) => {
              const name = obj.name + "pattern";

              if (obj.visible && obj.name != name) {
                obj.set({
                  visible: false,
                });
              }
              const opa = isFillPolygon.current ? 0.5 : 10;
              if (obj.opacity === opa && obj.name != "patternPolygon") {
                obj.set({
                  opacity: 0.1,
                  visible: false,
                });
              }

              canvasRef.current?.requestRenderAll();
            });
          }
        }
      });
    }
  };
  // show hover effect on each segment
  const getHoverSegs = useSelector(getHoverSeg);
  useEffect(() => {
    if (getHoverSegs && getHoverSegs.length == 1) {
      hidehoverEffectFromLeftSection();
      const segName = getHoverSegs[0].segName;
      if (segName) {
        const convertedString = segName?.replace(/\d+/g, "");
        const segColor = getAllSegments.find(
          (item) => item.name === convertedString
        );
        const Segmentcolor = segColor?.color_code;
        const color = isFillPolygon.current ? Segmentcolor : "transparent";
        // console.log("color",color)
        const isColor= true
       showAnnotation(segName, color ?? "transparent");
        hideAnnotation(segName);
      }
    } else if (getHoverSegs && getHoverSegs.length > 0) {
      getHoverSegs.map((item) => {
        if (item.segName) {
          const convertedString = item.segName?.replace(/\d+/g, "");
          const segColor = getAllSegments.find(
            (item) => item.name === convertedString
          );
          const Segmentcolor = segColor?.color_code;
          const color = isFillPolygon.current ? Segmentcolor : "transparent";
          //console.log("color",color)

          showAnnotation(item.segName, color ?? "transparent");
        }
      });
    } else if (getHoverSegs.length == 0) {
      hidehoverEffectFromLeftSection();
    }
  }, [getHoverSegs, isFillPolygon]);

  useEffect(() => {
    if (getSvgImages && getSvgImages.length > 0 && canvasRef.current) {
      getSvgImages.forEach((item) => {
        if (
          item.swatchUrl &&
          item.selectedGroup &&
          item.selectedGroup.length > 0
        ) {
          const groupSeg = canvasRef.current?.getObjects();

          item.selectedGroup.forEach((eachSeg) => {
            const allAnnotation = eachSeg.annoatation; // Ensure correct property name
            const allPointsFormatted = getAllAnnoataionPoint(
              allAnnotation || []
            ) as PointModel[];
            const names: string = eachSeg.segname + "pattern";
            if (groupSeg) {
              // Find the group that matches the current segment name
              const matchingGroup = groupSeg.find(
                (grp) => grp.name === "imageGroup"
              ) as fabric.Group | undefined;

              if (matchingGroup) {
                const checkpatternGroup = matchingGroup.getObjects();

                if (checkpatternGroup.length > 0) {
                  checkpatternGroup.forEach((obj) => {
                    if (obj.name === names) {
                      matchingGroup.removeWithUpdate(obj);
                    } // Use removeWithUpdate to properly update the group
                  });
                }

                // const dynamicViewBoxX = 0;
                // const dynamicViewBoxY = 0;
                // const dynamicViewBoxWidth =(eachSeg.coOrdinate[2]-eachSeg.coOrdinate[0])*(scaleX??1) // The width of the viewBox
                // const dynamicViewBoxHeight = (eachSeg.coOrdinate[3]-eachSeg.coOrdinate[1])*(scaleX??1)

                // fabric.loadSVGFromString(item.swatchUrl??"", (objects, options) => {
                //   const svgObject = fabric.util.groupSVGElements(objects, options);

                //   svgObject.set({
                //     name: names, // Set the name to the current pattern name
                //     left: eachSeg.coOrdinate[0]*(scaleX??1), // Set position as needed
                //     top: eachSeg.coOrdinate[1]*(scaleY??1),

                //   });

                //   matchingGroup.addWithUpdate(svgObject); // Add the SVG object to the group
                //   canvasRef.current?.renderAll(); // Render the canvas
                // });

                fabric.Image.fromURL(
                  item.swatchUrl || "",
                  (img) => {
                    const imgElement = img.getElement();
                    if (!imgElement) {
                      return;
                    }

                    const imgWidth = img.width ?? imgElement.width;
                    const imgHeight = img.height ?? imgElement.height;

                    if (imgWidth === undefined || imgHeight === undefined) {
                      //console.error('Image dimensions are not available.');
                      return;
                    }

                    // Define the size of each pattern tile
                    const tileWidth = 100;
                    const tileHeight = 100;

                    const pattern = new fabric.Pattern({
                      source: imgElement as HTMLImageElement,
                      repeat: "repeat",
                      offsetX: 10, // Horizontal offset of the pattern
                      offsetY: 10, // Vertical offset of the pattern
                      patternTransform: [
                        tileWidth / (img.width ?? 1),
                        0,
                        0,
                        tileHeight / (img.height ?? 1),
                        0,
                        0,
                      ], // Scale the pattern tile
                    });

                    // Create and add the polygon with the pattern fill
                    const polygon = new fabric.Polygon(allPointsFormatted, {
                      fill: pattern,
                      name: names,
                      visible: true,
                    });

                    // Add the polygon to the matching group
                    matchingGroup.addWithUpdate(polygon);

                    // Re-render the canvas after adding the polygon
                    canvasRef.current?.requestRenderAll();
                  }
                  // ,{
                  //   crossOrigin: 'anonymous'
                  // }
                );
                // dispatch(resetSvgImage())
              }
            }
          });
          dispatch(stopLoading());
          dispatch(resetSvgImage());
          dispatch(resetSvgModel());
        }
      });
    }
  }, [getSvgImages]);


  const zoomRef = useRef<number|null>(null);
  const handleMouseWheel = (event: fabric.IEvent) => {
    //console.log("mouse Wheel",event)
    const deltaE = event.e as WheelEvent;
   
    if (deltaE && canvasRef.current) {
      const delta = deltaE.deltaY;
      let zoom = canvasRef.current.getZoom();

      zoom *= 0.999 ** delta;

      if (zoom > 20) zoom = 20; // Set maximum zoom level
      if (zoom < 0.01) zoom = 0.01; // Set minimum zoom level

      ZoomCanvas(canvasRef, zoom,canvasZoom);
     
    }
  };
// update on tool zoom
  const canvasZoom=(data:number)=>{
    const value = Math.floor(data * 100);;

    dispatch(updateZoomValue(value))
  }

   // handle zoom through the icon
   useEffect(() => {
    if(getZoomCanvass!=0 && 
      canvasRef.current){

      ZoomCanvas(canvasRef, getZoomCanvass,canvasZoom);
    }
  },[getZoomCanvass,canvasRef])

  // svg from front end
  useEffect(() => {
    if (getSvgPhotImages && getSvgPhotImages.length > 0 && canvasRef.current) {
      getSvgPhotImages.forEach((item) => {
        if (
          item.swatchUrl &&
          item.selectedGroup &&
          item.selectedGroup.length > 0
        ) {
          const groupSeg = canvasRef.current?.getObjects();

          item.selectedGroup.forEach((eachSeg) => {
            const allAnnotation = eachSeg.annoatation; // Ensure correct property name
            const allPointsFormatted = getAllAnnoataionPoint(
              allAnnotation || []
            ) as PointModel[];
            const names: string = eachSeg.segname + "pattern";
            if (groupSeg) {
              // Find the group that matches the current segment name
              const matchingGroup = groupSeg.find(
                (grp) => grp.name === "imageGroup"
              ) as fabric.Group | undefined;

              if (matchingGroup) {
                const checkpatternGroup = matchingGroup.getObjects();

                if (checkpatternGroup.length > 0) {
                  checkpatternGroup.forEach((obj) => {
                    if (obj.name === names) {
                      matchingGroup.removeWithUpdate(obj);
                    } // Use removeWithUpdate to properly update the group
                  });
                }

                const dynamicViewBoxX = 0;
                const dynamicViewBoxY = 0;
                const dynamicViewBoxWidth =
                  (eachSeg.coOrdinate[2] - eachSeg.coOrdinate[0]) *
                  (scaleX ?? 1); // The width of the viewBox
                const dynamicViewBoxHeight =
                  (eachSeg.coOrdinate[3] - eachSeg.coOrdinate[1]) *
                  (scaleX ?? 1);

                fabric.loadSVGFromString(
                  item.swatchUrl ?? "",
                  (objects, options) => {
                    const svgObject = fabric.util.groupSVGElements(
                      objects,
                      options
                    );

                    svgObject.set({
                      name: names, // Set the name to the current pattern name
                      left: eachSeg.coOrdinate[0] * (scaleX ?? 1), // Set position as needed
                      top: eachSeg.coOrdinate[1] * (scaleY ?? 1),
                    });

                    matchingGroup.addWithUpdate(svgObject); // Add the SVG object to the group
                    canvasRef.current?.renderAll(); // Render the canvas
                  }
                );

                dispatch(resetSvgPhotImage());
              }
            }
          });
        }
      });
    }
  }, [getSvgPhotImages]);

  const getAllAnnoataionPoint = (allPoints: number[]) => {
    if (allPoints && allPoints.length > 0 && scaleX && scaleY) {
      let i;
      const point: PointModel[] = [];
      for (i = 0; i < allPoints.length; i += 2) {
        const x = allPoints[i] * scaleX;
        const y = allPoints[i + 1] * scaleY;
        point.push({ x: x, y: y });
      }
      return point;
    }
  };
  // add segment modal

  // option for svg model modal
  const getSvgOptions = useSelector(getSvgOption);
  const [isOptionModal, setIsOptionModal] = useState<boolean>(false);

  useEffect(() => {
    // open modal
    if (getSvgOptions.isOpenModal) {
      setIsOptionModal(true);
    }

    if (getSvgOptions && getSvgOptions.SwatchImage && canvasRef.current) {
      if (getSvgOptions.SwatchImage != undefined) {
        Object.keys(getSvgOptions.SwatchImage).forEach((grp) => {
          const checkKey = grp.includes("_base64");
          console.log("svg====>");
          if (checkKey) {
            const newKey = grp.replace("_base64", "");

            const value = getSvgOptions.SwatchImage?.[grp];
            //console.log("value-->",value)
            const isBase64 = true;
            const isUpdate = true;
            if (value && newKey) {
              openCvImageOnCanvas(value, newKey, isBase64, isUpdate);
            }
          }
        });
      }
    }
  }, [getSvgOptions, canvasRef.current]);

  // add image on canvas from openCV model
  const openCvImageOnCanvas = (
    imagepath: string,
    segName: string,
    isbase64: boolean,
    isUpdate: boolean
  ) => {
    const canvas = canvasRef.current?.getObjects();
    console.log("canvas all object before", canvas);
    if (canvas && canvas.length > 0) {
      const imageGroup = canvas.find((item) => item.name === "imageGroup") as
        | fabric.Group
        | undefined;
      if (imageGroup) {
        const checkpatternGroup = imageGroup.getObjects();
        const names: string = segName + "pattern";

        // Check if an object with the same name already exists
        const existingObject = checkpatternGroup.find(
          (obj) => obj.name === names
        );
        if (existingObject) {
          imageGroup.removeWithUpdate(existingObject); // Remove existing object if found
        }

        if (isUpdate) {
          const base64Image = isbase64
            ? "data:image/png;base64," + imagepath
            : imagepath;

          fabric.Image.fromURL(
            base64Image,
            (img) => {
              const canvas = canvasRef.current;

              if (
                canvas &&
                canvas.width &&
                canvas.height &&
                img.width &&
                img.height
              ) {
                const scaleX = canvas.width / img.width;
                const scaleY = canvas.height / img.height;

                const oImg = img.set({ scaleX: scaleX, scaleY: scaleY });
                oImg.set({
                  name: names,
                  visible: true,
                });

                // Add the new image only if it doesn't already exist
                imageGroup.addWithUpdate(oImg);
                canvas.requestRenderAll();
              }
            },
            {
              crossOrigin: "anonymous", // Only needed if you load from URL, not required for Base64
            }
          );
          isUpdateCanvas.current = true;
          console.log("canvas all object", canvas);
        }
        // No need for the second check for !isUpdate since we already handle existing objects above
      }
    }
    dispatch(resetSvgModel());
    dispatch(stopLoading());
    isUpdateCanvas.current = true;
  };

  const handleCloseOptionModal = () => {
    setIsOptionModal(false);
    dispatch(resetSvgModel());
  };

  const handleChooseOption = (data: string) => {
    setIsOptionModal(false);
    dispatch(startLoading());
    dispatch(
      addSvgOption({
        isOpenModal: false,
        svgModelName: data,
      })
    );
  };

  const getToasts= useSelector(getToast)
  const getLoadings = useSelector(getLoading);
  const [isLoading, setisLoading] = useState<boolean>(false);
  useEffect(() => {
    if (getLoadings ) {
      setisLoading(true);
    } else {
      setisLoading(false);
    }
  }, [getToasts]);

  useEffect(() => {
    if (getDownloadStatuss) {
      captureScreenshot();
    }
  }, [getDownloadStatuss]);

  const captureScreenshot = () => {
    const canvas = canvasRef.current;
    console.log("gegg");
    if (!canvas) {
      console.error("Canvas element not found");
      return;
    }

    try {
      canvas.getElement().toBlob((blob) => {
        if (!blob) {
          console.error("Failed to create blob from canvas");
          return;
        }

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `canvas_screenshot_${Date.now()}.png`;
        a.click();
        URL.revokeObjectURL(url);
      });
      dispatch(stopDownlaod());
    } catch (err) {
      console.log("err", err);
      dispatch(stopDownlaod());
      console.error("Canvas is tainted and cannot be exported", err);
      alert(
        "Sorry, the screenshot cannot be captured due to a security restriction."
      );
    }
  };

  // delete the point from the canavs

  // const captureScreenshot = () => {
  //   try {
  //     const canvas = canvasRef.current;
  //     if (!canvas) {
  //       console.error("Canvas element not found");
  //       return;
  //     }

  //     // Convert to dataURL instead of using toBlob
  //     const dataURL = canvas.toDataURL({
  //       format: 'png',
  //       quality: 1,
  //       enableRetinaScaling: true
  //     });

  //     // Create download link
  //     const a = document.createElement("a");
  //     a.href = dataURL;
  //     a.download = `canvas_screenshot_${Date.now()}.png`;
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);

  //     dispatch(stopDownlaod());
  //   }catch (err) {
  //     console.error("Canvas export error:", err);
  //     dispatch(stopDownlaod());
  //     dispatch(addMessage({
  //       type: 'error',
  //       message: 'Failed to capture screenshot. Please ensure all images are loaded properly.'
  //     }));
  //   }
  // };
  const getDeleteSegmentHovers = useSelector(getDeleteSegmentHover);

  useEffect(() => {
    if (
      getDeleteSegmentHovers &&
      getDeleteSegmentHovers.childName &&
      getDeleteSegmentHovers.childShortName &&
      canvasRef.current
    ) {
      const allObjects = canvasRef.current.getObjects();
      if (allObjects && allObjects.length > 0) {
        const currentObject = allObjects.find(
          (item) => item.name === getDeleteSegmentHovers.childName
        );
        if (currentObject) {
          canvasRef.current.remove(currentObject);
          canvasRef.current.requestRenderAll();
        } else {
          alert("No group found");
        }

        dispatch(resetDeleteSegmenthoverLayer());
      }
    }
  }, [getDeleteSegmentHovers, canvasRef]);

  // hover over group
  const getHoverGroups = useSelector(getHoverGroup);
  const getNoHoverGroups = useSelector(getNoHoverGroup);
  useEffect(() => {
    if (getHoverGroups != "" && canvasRef.current) {
      // console.log("getHoverGroups---",getHoverGroups)
      hoverGroup(getHoverGroups);
    }
    if (getNoHoverGroups != "" && canvasRef.current) {
      // console.log("getNoHoverGroups---",getNoHoverGroups)
      noHoverGroup(getNoHoverGroups);
    }
  }, [getHoverGroups, getNoHoverGroups, canvasRef.current]);

  const hoverGroup = (grpName: string) => {
    // console.log("HoverGroup",grpName)
    const allObjects = canvasRef.current?.getObjects();
    if (allObjects && allObjects.length > 0) {
      allObjects.forEach((item: CustomGroupOptions) => {
        // console.log("grpName",grpName)
        if (item.groupName === grpName) {
          const currentObjGroup = item as fabric.Group;
          // console.log("item shown hover",item)
          const showObject = currentObjGroup?.getObjects();
          //  console.log("showObject",showObject)
          if (showObject) {
            showObject?.forEach((obj) => {
              //console.log("obj",obj)
              if (!obj.visible) {
                obj.set({
                  visible: true,
                });
              }
              if (obj.opacity == 0.1) {
                obj.set({
                  opacity: 10,
                  visible: true,
                });
              }

              canvasRef.current?.requestRenderAll();
            });
          }
        }
      });
    }
  };

  const noHoverGroup = (grpName: string) => {
    const allObjects = canvasRef.current?.getObjects();
    if (allObjects && allObjects.length > 0) {
      allObjects.forEach((item: CustomGroupOptions) => {
        // console.log("grpName",grpName)
        if (item.groupName === grpName) {
          const currentObjGroup = item as fabric.Group;
          //console.log("item shown hover",item)
          const showObject = currentObjGroup?.getObjects();
          //  console.log("showObject",showObject)
          if (showObject) {
            showObject?.forEach((obj) => {
              //console.log("obj",obj)
              if (obj.visible) {
                obj.set({
                  visible: false,
                });
              }
              if (obj.opacity == 10) {
                obj.set({
                  opacity: 0.1,
                  visible: false,
                });
              }

              canvasRef.current?.requestRenderAll();
            });
          }
        } else {
          //console.log("hide Annnoattion", grpName)
          // hideAnnotation(grpName)
          // hidehoverEffectFromLeftSection()
        }
      });
    }
  };

  // show hover effect from layer view
  useEffect(() => {
    if (getHoverLayerSegs != "") {
      const convertedString = getHoverLayerSegs?.replace(/\d+/g, "");
      const segColor = getAllSegments.find(
        (item) => item.name === convertedString
      );
      const Segmentcolor = segColor?.color_code;
      const color = isFillPolygon.current ? Segmentcolor : "transparent";

      showAnnotation(getHoverLayerSegs, color ?? "transparent");
    }
  }, [getHoverLayerSegs]);

  // update preexiting swatch into canavs
  // useEffect(()=>{

  //   if(getAllreadyExistSwatchs &&
  //      getAllreadyExistSwatchs.length>0 &&
  //      canvasRef.current
  //     ){
  //       getAllreadyExistSwatchs.map(item=>{
  //         const canvasImageGroup= canvasRef.current?.getObjects().find(obj=>obj.name==="imageGroup")as fabric.Group | undefined;
  //         console.log("canvasImageGroup",canvasImageGroup)
  //         const segName= item.segname;
  //         const names:string= segName+"pattern"
  //         const url=item.swatchUrl
  //         if(canvasImageGroup  && names && url){

  //            const checkpatternGroup = canvasImageGroup.getObjects().find(img=>img.name===names)
  //            console.log("checkpatternGroup",checkpatternGroup)
  //              if(checkpatternGroup){
  //               canvasImageGroup.removeWithUpdate(checkpatternGroup)
  //               fabric.Image.fromURL(url, (img) => {
  //                 const canvas = canvasRef.current;

  //                 if (canvas && canvas.width && canvas.height && img.width && img.height) {
  //                   const scaleX = canvas.width / img.width;
  //                   const scaleY = canvas.height / img.height;

  //                   const oImg = img.set({ scaleX: scaleX, scaleY: scaleY });
  //                   oImg.set({
  //                     name: names,
  //                     visible: true,
  //                   });

  //                   // Assuming you have a group, if not, you can directly add it to the canvas
  //                   canvasImageGroup.addWithUpdate(oImg);
  //                   canvas.requestRenderAll();
  //                 }
  //               }
  //               ,
  //               {
  //                 crossOrigin: 'anonymous', // Only needed if you load from URL, not required for Base64
  //               }
  //               );
  //              }  else{
  //               fabric.Image.fromURL(url, (img) => {
  //                 const canvas = canvasRef.current;

  //                 if (canvas && canvas.width && canvas.height && img.width && img.height) {
  //                   const scaleX = canvas.width / img.width;
  //                   const scaleY = canvas.height / img.height;

  //                   const oImg = img.set({ scaleX: scaleX, scaleY: scaleY });
  //                   oImg.set({
  //                     name: names,
  //                     visible: true,
  //                   });

  //                   // Assuming you have a group, if not, you can directly add it to the canvas
  //                   canvasImageGroup.addWithUpdate(oImg);
  //                   canvas.requestRenderAll();
  //                 }
  //               }
  //               ,
  //               {
  //                 crossOrigin: 'anonymous', // Only needed if you load from URL, not required for Base64
  //               }
  //               );
  //              }

  //         }

  //       })

  //       dispatch(resetAlreadyExistSwatch())
  //   }
  // },[getAllreadyExistSwatchs,canvasRef])

  const [screnShotImage, setScreenShotImge] = useState<File | null>(null);
  // upload the screen short into s3 Bucket
  const captureScreenshotImage = () => {
    dispatch(
      addMessage({
        isShow: true,
        mess: "Start processing screenshot",
        toastType: "success",
      })
    );
    const canvas = canvasRef.current;
    // console.log("gegg")
    if (!canvas) {
      console.error("Canvas element not found");
      return;
    }

    try {
      canvas.getElement().toBlob((blob) => {
        if (!blob) {
          console.error("Failed to create blob from canvas");
          return;
        }

        const file = new File([blob], `canvas_screenshot_${Date.now()}.png`, {
          type: "image/png",
        });
        setScreenShotImge(file);
        const url = URL.createObjectURL(file);
      });
      //dispatch(stopDownlaod())
    } catch (err) {
      // console.log("err",err)
      // dispatch(stopDownlaod())

      // console.error("Canvas is tainted and cannot be exported", err);
      alert(
        "Sorry, the screenshot cannot be captured due to a security restriction."
      );
    }
  };

  useEffect(() => {
    if (getStartUploadScreenShorts) {
      captureScreenshotImage();
    } else {
    }
  }, [getStartUploadScreenShorts]);

  const handleResetUploadScreenShot = () => {
    setScreenShotImge(null);
    dispatch(updateStartUploadScrenShot(false));
  };

  return (
    <>
   
    <div className='editor-canvas position-relative'
    ref={containerRef}
    style={{
      height: "100vh",
      overflow: "hidden",
      transformOrigin: "top left",
    }}
    >


      <canvas ref={canvasElementRef}  className='dzi-van' id='canvas'/>
      {/* <button onClick={createSvg}>Load SVG</button> */}
   {isLoading && 
    <RightShimmer/> }

        {/* <Loading/> */}

        {/* Add Svg option modal */}
        {isOptionModal && (
          <OptionModal
            isOptionModal={isOptionModal}
            onClose={handleCloseOptionModal}
            chooseOption={handleChooseOption}
          />
        )}
      </div>

      {/* upload screen Short */}
      {screnShotImage && (
        <CalculateDepthHome
          screnShotImage={screnShotImage}
          resetScreenShot={handleResetUploadScreenShot}
        />
      )}
    </>
  );
};

export default FabricCanvas;
