import React, { useEffect } from 'react'
import { SegDetectModel } from '../../../../Model/segDetectModel/SegDetect'
import { AddSegDetectModel } from '../../../../api/segDetectModelApi/SegDetectModelApi'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { updateSegDetectModel } from '../../../../slice/segDetectModelSlice/SegDetectModelSlice'

type Props = {
    addModel:SegDetectModel
    resetAddModel:()=>void
}
const AddModelDB = ({addModel,resetAddModel}:Props) => {
        const isApiCall= React.useRef<boolean>(true)
        const dispatch= useDispatch()

        useEffect(() => {
            if(addModel && isApiCall.current){
                uploadModelDB()
                isApiCall.current=false
            }
        },[addModel])

    const uploadModelDB = async () => {
        try{
            const response = await AddSegDetectModel(addModel);
            
            if(response.status===200 &&
                 response.data){
                    addModel.id=response.data.id
                    dispatch(updateSegDetectModel({
                        modelData:addModel 
                    }))
                resetAddModel()
            }
        }  catch(err){
            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
                   //resetUploadFile("")
                   resetAddModel()
                  
            }
           
       
        }finally {
            isApiCall.current = true
           
          }
    }
  return (
    <>
    </>
  )
}

export default AddModelDB