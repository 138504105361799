import React from 'react'
import ToolArea from '../../module/ImageView/fabricTool/toolArea/ToolArea'

const FabricPlayPage = () => {
  return (
    <>
    <ToolArea/>
    </>
  )
}

export default FabricPlayPage