import React from 'react'
import Header from '../header/Header'
import Projects from '../profile/projects/Projects'
import UserProjects from './UserProjects'
import { Tab, Tabs } from 'react-bootstrap'

const MyAccounts = () => {
  return (
    <div>
      <Header/>
        <div className="mt-5 pt-5">
        <div className="container">

        <div>
          <h4 className='pb-5'>My Account</h4>
        </div>
        <Tabs
      defaultActiveKey="profile"
      id="justify-tab-example"
      className="mb-3 pro_page"
      // justify
    >
      <Tab eventKey="projects" title="All Projects">
      <UserProjects/>
      </Tab>

      <Tab eventKey="downloads" title="Downloads">
        Tab content for Loooonger Tab
      </Tab>
      <Tab eventKey="favorites" title="Favorites">
        Tab content for Contact
      </Tab>
      <Tab eventKey="materials" title="Materials">
        Tab content for Profile
      </Tab>
      <Tab eventKey="orders" title="Orders">
        Tab content for Profile
      </Tab>
     
      
    </Tabs>
    
       
       

            </div>
        </div>
    </div>
  )
}

export default MyAccounts
